import React, { useEffect, useState } from "react";
import Text from "../../../visualComponents/Text";
import { Autocomplete, Chip, FormControl, Grid, TextField } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import { masterDataSkills } from "../../../apiServices/Constants";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MinimumTags = 1;
export default function DescriptionWithSkills(props) {
  const [description, setDescription] = useState(props.description);
  const [skills     , setSkills     ] = useState(props.skills ?? []);
  const labelProps = props.labelProps ?? {bold: true, color: "background", xs: 12};
  const fieldStyle = props.fieldStyle ?? {
    div: {color: Theme_WIP.palette.primary.main}, 
    "input, svg, label, path, .MuiInputLabel-root, span": {pointerEvents: "none !important"},
    "fieldset":{borderColor: Theme_WIP.palette.lighterPetrol.main}};
  const fieldProps = props.fieldProps ?? { 
    size: "small", 
    fullWidth: true, 
    sx: fieldStyle, 
    InputLabelProps: {sx: {pointerEvents: "none !important"}},
  };

  useEffect(() => {if(props.onChange) props.onChange(description, skills)}, [description, skills]);

  return( <React.Fragment>
  <Text {...labelProps} id={props.label} mb={-1} mt={2}/>
  <Grid item xs={12} mb={2} container gap={2}
  //sx={{border: "solid 1px "+Theme_WIP.palette.lighterPetrol.main}}
  >
      <FormControl variant="filled" fullWidth>
          <Autocomplete
          sx={fieldStyle}
          multiple
          options={masterDataSkills()}
          freeSolo
          autoSelect={true}
          filterSelectedOptions
          value={skills}
          onChange={(event, newvalue) => setSkills(newvalue.slice(0, 10))} //max 10
          renderTags={(value, getTagProps) =>
              value.map((option, index) => (
              <Chip
                  variant="outlined"
                  label={option}
                  size="small"
              />
              ))
          }
          renderInput={(params) => (
              <TextField
              {...params}
              sx={{
                  ...fieldStyle, 
                  "label": {width: "100%"},
                  ".MuiInputLabel-shrink": {width: "revert !important"}
                  //"fieldset": {border: "none"}
              }}
              size="small"
              label={<Grid container><Text {...labelProps} bold={false} item xs id="Tags"/><KeyboardArrowDownIcon/></Grid>}
              inputProps={{ ...params.inputProps, maxLength: 25 }}
              onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^\w\s]/gi, "")
                  e.target.value = e.target.value.split(" ").length > 2 ? e.target.value.trim() : e.target.value
                  e.target.value = e.target.value.substring(0, 25)
              }}
              />
          )}
          />
      </FormControl>
      {skills.length > 0 && skills.length < MinimumTags && <Text variant="body2" itallic id="TagsHint" color="background" align="right" item xs={12}/>}

      <TextField 
      value={description}
      inputProps={{ maxLength: 1000 }}
      {...fieldProps} 
      sx={{
          ...fieldStyle, 
          //"fieldset": {border: "none"}
      }}
      label={
          <Text color="background" id="DescriptionRequest" sx={{pointerEvents: "none !important"}}/>
      }
      //onChange={(event) => updateInput(props.inputField, event.target.value)}
      onChange={(event) => setDescription(event.target.value)} 
      minRows={2}
      multiline/>
      {description && description.length < 100 && <Text variant="body2" itallic id="DescriptionHint" color="background" align="right" item xs={12}/>}

  </Grid>
</React.Fragment>);}