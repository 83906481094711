//OBSOLETE & DEPRECATED

import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@mui/material/Container";
import CloseIcon from "@material-ui/icons/Close";
import styles from "../styles/container/RequestStyle";
import ProjectPartnersRequest from "../components/RequetsTabs/ProjectPartnersRequest";
import StrategicPartnersRequest from "../components/RequetsTabs/StrategicPartnersRequest";
import BusinessPartnersRequest from "../components/RequetsTabs/BusinessPartnersRequest";
import CoFoundingRequest from "../components/RequetsTabs/CoFoundingRequest";
import InvestmentRequest from "../components/RequetsTabs/InvestmentRequest";
import MentoringRequest from "../components/RequetsTabs/MentoringRequest";
import Experts from "../components/RequetsTabs/Experts";
import MastersForMaster from "../components/RequetsTabs/MastersForMaster";
import MasterMind from "../components/RequetsTabs/MasterMind";
import BrainStormingPartners from "../components/RequetsTabs/BrainStormingPartners";
import CoWorkingDays from "../components/RequetsTabs/CoWorkingDays";
import { Box } from "@mui/system";
import { useHistory, useLocation } from "react-router";
import { Autocomplete, Alert, Hidden, Skeleton, ThemeProvider, Button } from "@mui/material";
import Navigationbar from "../components/Navigationbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { CssBaseline, FormHelperText } from "@material-ui/core";
import {
  RequestCollaborationOfInterestArray,
  RequestCollaborationTypes,
  masterDataIndustries,
  masterDataSkills,
} from "../apiServices/Constants";
import { GetUserId } from "../apiServices/CommonMethods";
import SuccessAlertWithAction from "../components/Alert/SuccessAlertWithAction";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Chip,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import LocationAutoComplete from "../components/Autocomplete/LocationAutoComplete";
import { useMutation } from "@apollo/client";
import { 
  SAVE_STRATEGIC_PARTNERS_REQUEST,
  SAVE_PROJECT_PARTNERS_REQUEST,
  SAVE_MENTORING_REQUEST,
  SAVE_MASTERSOFMASTERS_REQUEST,
  SAVE_MASTERMIND_REQUEST,
  SAVE_INVESTMENT_REQUEST,
  SAVE_EXPERT_REQUEST,
  SAVE_COWORKING_REQUEST,
  SAVE_COFOUNDING_REQUEST,
  SAVE_BUSINESS_PARTNER_REQUEST,
  SAVE_BRAINSTORMING_PARTNERS_REQUEST
} from "../apiServices/mutations";
import { ButtonDarkBlue } from "../components/Buttons/ButtonDarkBlue";
import * as _theme from "../styles/container/Theme";
import ChatBubble from "../visualComponents/specificImplementations/ChatBubble";
import { ButtonGrey } from "../components/Buttons/ButtonGrey";
import AlertWithAction from "../components/Alert/AlertWithAction";
import ChipList from "../components/Forms/ChipList";
import Image from "../components/Media/Image";
import Text from "../visualComponents/Text";
import Theme_WIP from "../styles/themes/main/Theme_WIP";
import Background from "../visualComponents/Background";
import { useGetProfile } from "../apiServices/Queries/Profiles/GetProfile";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles(styles);

const requestCollaborationTypes = {
  projectPartners:       RequestCollaborationTypes.projectPartners.key,
  strategicPartners:     RequestCollaborationTypes.strategicPartners.key,
  businessPartners:      RequestCollaborationTypes.businessPartners.key,
  coFounding:            RequestCollaborationTypes.coFounding.key,
  investment:            RequestCollaborationTypes.investment.key,
  mentoring:             RequestCollaborationTypes.mentoring.key,
  experts:               RequestCollaborationTypes.experts.key,
  mastersOfMasters:      RequestCollaborationTypes.mastersOfMasters.key,
  masterMinds:           RequestCollaborationTypes.masterMinds.key,
  brainStormingPartners: RequestCollaborationTypes.brainStormingPartners.key,
  coworkingDates:        RequestCollaborationTypes.coworkingDates.key,
};

export default function RequestNew(props) {
  const globals = useGlobalContext();
  const intl = useIntl();
  const [collabType, setCollabType] = React.useState([]);
  const [collabType_temp, setCollabType_temp] = React.useState([]);
  const [profile, setProfile] = React.useState();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyles();
  const userId = GetUserId();
  const [skip, setSkip] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedReceivers, setSelectedReceivers] = React.useState([]);
  const [allReceivers, setAllReceivers] = React.useState([]);
  const [showReceiverSelectionError, setShowReceiverSelectionError] =
    React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [request, setRequest] = React.useState({});
  const [circles, setCircles] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const [data_req, setData_req] = React.useState();
  const [inspireMe, setInspire] = React.useState(null);

  const [mutationResponseCode, setMutationResponse] = React.useState();
  const mutationComplete = {onCompleted: (data) => {
    var jp = require('jsonpath');
    var code = jp.query(data, '$..errorCode');
    setMutationResponse(code[0]);
  }};

  const [SaveRequest1 ] = useMutation(SAVE_PROJECT_PARTNERS_REQUEST, mutationComplete);
  const [SaveRequest2 ] = useMutation(SAVE_STRATEGIC_PARTNERS_REQUEST, mutationComplete);
  const [SaveRequest3 ] = useMutation(SAVE_BUSINESS_PARTNER_REQUEST, mutationComplete);
  const [SaveRequest4 ] = useMutation(SAVE_COFOUNDING_REQUEST, mutationComplete);
  const [SaveRequest5 ] = useMutation(SAVE_INVESTMENT_REQUEST, mutationComplete);
  const [SaveRequest6 ] = useMutation(SAVE_MENTORING_REQUEST, mutationComplete);
  const [SaveRequest7 ] = useMutation(SAVE_EXPERT_REQUEST, mutationComplete);
  const [SaveRequest8 ] = useMutation(SAVE_MASTERSOFMASTERS_REQUEST, mutationComplete);
  const [SaveRequest9 ] = useMutation(SAVE_MASTERMIND_REQUEST, mutationComplete);
  const [SaveRequest10] = useMutation(SAVE_BRAINSTORMING_PARTNERS_REQUEST, mutationComplete);
  const [SaveRequest11] = useMutation(SAVE_COWORKING_REQUEST, mutationComplete);

  const {data: profileData} = useGetProfile(userId, userId);
  React.useEffect(() => setProfile(profileData), [profileData]);
  
  //makes sure the correct collaboration request gets mutated for the appropriate collab type
  function mutate(args) {
    if (collabType[0] === requestCollaborationTypes.projectPartners) {
      SaveRequest1(args);
    } else if (collabType[0] === requestCollaborationTypes.strategicPartners) {
      SaveRequest2(args);
    } else if (collabType[0] === requestCollaborationTypes.businessPartners) {
      SaveRequest3(args);
    } else if (collabType[0] === requestCollaborationTypes.coFounding) {
      SaveRequest4(args);
    } else if (collabType[0] === requestCollaborationTypes.investment) {
      SaveRequest5(args);
    } else if (collabType[0] === requestCollaborationTypes.mentoring) {
      SaveRequest6(args);
    } else if (collabType[0] === requestCollaborationTypes.experts) {
      SaveRequest7(args);
    } else if (collabType[0] === requestCollaborationTypes.mastersOfMasters) {
      SaveRequest8(args);
    } else if (collabType[0] === requestCollaborationTypes.masterMinds) {
      SaveRequest9(args);
    } else if (collabType[0] === requestCollaborationTypes.brainStormingPartners) {
      SaveRequest10(args);
    } else if (collabType[0] === requestCollaborationTypes.coworkingDates) {
      SaveRequest11(args);
    }
  }

  //maps the general input to the collab type appropriate variables
  const getVariables = () => {
    if (collabType[0] === requestCollaborationTypes.projectPartners) {
      return ({
        userId                   : userId,
        industries               : input.industries,
        location                 : input.location,
        projectDescription       : input.description,
        skills                   : input.skills,
        titleRequest             : input.titleRequest,
        selectedToWhomIds        : input.selectedToWhomIds,
        locationPlaceID          : input.locationPlaceID ?? "",
        id                       : input.id,
        averageDurationInMonths  : Number(input.months),
        hoursPerWeek             : Number(input.hours),
        startDate                : input.startDate
      });
    } else if (collabType[0] === requestCollaborationTypes.strategicPartners) {
      return ({
        userId                  : userId,
        industries              : input.industries,
        location                : input.location,
        myPartOfThePartnership  : input.descriptionPart2,
        partnershipDescription  : input.description,
        skills                  : input.skills,
        titleRequest            : input.titleRequest,
        selectedToWhomIds       : input.selectedToWhomIds,
        preferredBenefits       : input.preferredBenefits,
        id                      : input.id,
        locationPlaceID         : input.locationPlaceID ?? "",
      });
    } else if (collabType[0] === requestCollaborationTypes.businessPartners) {
      return ({
        id                      : input.id,
        userId                  : userId,
        industries              : input.industries,
        location                : input.location,
        partnershipDescription  : input.description,
        skills                  : input.skills,
        titleRequest            : input.titleRequest,
        selectedToWhomIds       : input.selectedToWhomIds,
        locationPlaceID         : input.locationPlaceID ?? "",
        commitment              : input.commitments,
        companyRole             : input.companyRole,
        benefitType             : input.benefitType
      });
    } else if (collabType[0] === requestCollaborationTypes.coFounding) {
      return ({
        id                              : input.id,
        userId                          : userId,
        industries                      : input.industries,
        location                        : input.location,
        businessOpportunityDescription  : input.description,
        skills                          : input.skills,
        titleRequest                    : input.titleRequest,
        selectedToWhomIds               : input.selectedToWhomIds,
        locationPlaceID                 : input.locationPlaceID ?? "",
        commitment                      : input.commitments,
        businessStage                   : input.businessStage,
        preferredCompanyRole            : input.preferredCompanyRole,
        sweatCommitmentInHours          : Number(input.hours)
      });
    } else if (collabType[0] === requestCollaborationTypes.investment) {
      return ({
        id                   : input.id,
        userId               : userId,
        industries           : input.industries,
        location             : input.location,
        businessDescription  : input.description,
        titleRequest         : input.titleRequest,
        selectedToWhomIds    : input.selectedToWhomIds,
        locationPlaceID      : input.locationPlaceID ?? ""
      });
    } else if (collabType[0] === requestCollaborationTypes.mentoring) {
      return ({
        id                         : input.id,
        userId                     : userId,
        industries                 : input.industries,
        wishFromMentor             : input.description,
        preferredFieldOfKnowledge  : input.skills,
        titleRequest               : input.titleRequest,
        selectedToWhomIds          : input.selectedToWhomIds,
        myChallenges               : input.descriptionPart2
      });
    } else if (collabType[0] === requestCollaborationTypes.experts) {
      return ({
        id                   : input.id,
        userId               : userId,
        industries           : input.industries,
        location             : input.location,
        jobDescription       : input.description,
        skills               : input.skills,
        titleRequest         : input.titleRequest,
        selectedToWhomIds    : input.selectedToWhomIds,
        locationPlaceID      : input.locationPlaceID ?? "",
        durationJobInMonths  : Number(input.months),
        hoursPerWeek         : Number(input.hours),
        startDate            : input.startDate
      });
    } else if (collabType[0] === requestCollaborationTypes.mastersOfMasters) {
      return ({
        id                        : input.id,
        userId                    : userId,
        industries                : input.industries,
        location                  : input.location,
        collaborationDescription  : input.description,
        preferredSkills           : input.skills,
        offeredSkills             : input.skills2,
        titleRequest              : input.titleRequest,
        selectedToWhomIds         : input.selectedToWhomIds,
        locationPlaceID           : input.locationPlaceID ?? "",
        durationInMonths          : Number(input.months),
        estimatedHours            : Number(input.hours),
        startDate                 : input.startDate
      });
    } else if (collabType[0] === requestCollaborationTypes.masterMinds) {
      return ({
        id                     : input.id,
        userId                 : userId,
        mastermindDescription  : input.description,
        titleRequest           : input.titleRequest,
        selectedToWhomIds      : input.selectedToWhomIds
      });
    } else if (collabType[0] === requestCollaborationTypes.brainStormingPartners) {
      return ({
        id                      : input.id,
        userId                  : userId,
        location                : input.location,
        partnershipDescription  : input.description,
        skills                  : input.skills,
        titleRequest            : input.titleRequest,
        selectedToWhomIds       : input.selectedToWhomIds,
        locationPlaceID         : input.locationPlaceID ?? "",
        entrepreneurshipLevel   : input.entrepreneurshipLevel,
        expertiseLevel          : input.expertiseLevel,
        frequencyInMonths       : Number(input.months)
      });
    } else if (collabType[0] === requestCollaborationTypes.coworkingDates) {
      return ({
        id                 : input.id,
        userId             : userId,
        location           : input.location,
        dateDescription    : input.description,
        skills             : input.skills,
        titleRequest       : input.titleRequest,
        selectedToWhomIds  : input.selectedToWhomIds,
        locationPlaceID    : input.locationPlaceID ?? "",
        period             : input.months2,
        days               : input.days
      });
    }
  }

  let [input, setInput] = React.useState({
    period                 : null,
    months                 : null,
    hours                  : null,
    months2                : [],
    days                   : [],
    startDate              : null,
    industries             : [],
    location               : "",
    descriptionPart2       : "",
    description            : "",
    skills                 : [],
    skills2                : [],
    titleRequest           : "",
    selectedToWhomIds      : props.receivers,
    id                     : null,
    locationPlaceID        : "",
    entrepreneurshipLevel  : [],
    expertiseLevel         : [],
    preferredBenefits      : [],
    companyRole            : null,
    benefitType            : [],
    businessStage          : [],
    commitments            : [],
    preferredCompanyRole   : null
  });

  let [inputError, setInputError] = React.useState({
    industries              : "",
    location                : "",
    myPartOfThePartnership  : "",
    description             : "",
    skills                  : "",
    titleRequest            : "",
    selectedToWhomIds       : "",
  });

  React.useEffect(() => {
    if (
      typeof data_req !== "undefined" &&
      typeof data_req[0] !== "undefined"
    ) {
      updateStatus(false, data_req[0].errorCode === 0);
    }

    if (globals.state.loggedInUser.circles) {
      if (
        location.state &&
        location.state.circleDetails &&
        !location.state.isFromCircle
      ) {
        const circle = location.state.circleDetails;
        setIsEdit(false);
        setAllReceivers([
          {
            type: circle.name,
            id: circle.id,
          },
        ]);
        setSelectedReceivers([]);
        setSelectedOptions([]);
      } else {
        setReceiversData();
      }
    }
    
    if (location.state && location.state.request) {
      setRequest(location.state.request);
    }
    if (location.state && location.state.circles) {
      setCircles(location.state.circles);
    }
    if (location.state && location.state.collabType) {
      setCollabType([location.state.collabType]);
      setIsEdit(true);
    }
  }, [location]);

  const setReceiversData = () => {
    let receiversCircles = [];
    const receivers = globals.state.loggedInUser.circles.map((circle) => {
      return {
        type: circle.name,
        id: circle.id,
      };
    });
    if (circles.length > 0) {
      receiversCircles = circles.map((circle) => {
        return {
          type: circle.name,
          id: circle.id,
        };
      });
    }
    const receiversWithTypes = [
      { type: "Masters zonder match", id: "2" },
      { type: "Mijn inner circle", id: "1" },
      ...receivers,
      ...receiversCircles,
    ];
    setAllReceivers(receiversWithTypes);
    if (location.state && location.state.requestScope) {
      const circleIds = circles.map(({ id }) => id);
      setSelectedReceivers([...location.state.requestScope, ...circleIds]);
      setSelectedOptions(
        setSelectedScopes(
          [...location.state.requestScope, ...circleIds],
          receiversWithTypes
        )
      );
    }
  };

  const setSelectedScopes = (scope, receiversList) => {
    const receivers = scope.map((typeValue) => {
      const filteredItem = receiversList.filter((item) => {
        return item.id === typeValue;
      });
      return filteredItem[0].type;
    });
    return receivers;
  };

  const handleChangeReceivers = (event) => {
    setShowSuccess(false);
    const {
      target: { value },
    } = event;
    setSelectedOptions(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const receivers = value.map(getReceiverTypeId);
    setSelectedReceivers(receivers);
    setShowReceiverSelectionError(false);
    
    setInput((prevState) => ({
      ...prevState,
      selectedToWhomIds: receivers,
    }));
  };

  function getReceiverTypeId(type) {
    const filteredItem = allReceivers.filter((item) => {
      return item.type === type;
    });
    return filteredItem[0].id;
  }

  const handleChangeCollabType = (value) => {
    setCollabType_temp(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setShowSuccess(false);
  };

  const applyCollabType = () => {
    setCollabType([]);
    clearInput();
    setTimeout(() => {  //accounts for fade in of chat bubble
      setCollabType(collabType_temp);
    }, 2);
  };

  const updateStatus = (status, requestStatus) => {
    if (status) {
      setShowReceiverSelectionError(true);
    } else {
      if (requestStatus) {
        setShowSuccess(true);
      } else {
        setShowError(true);
      }
      setCollabType([]);
      setSelectedOptions([]);
      setSelectedReceivers([]);
    }
  };

  //retrieves the appropriate form for the selected collab type
  const collabTabCurrentView = () => {
    let collabTypes = collabType[0];
    if (collabTypes === requestCollaborationTypes.projectPartners) {
      return (
        <ProjectPartnersRequest
          receivers={selectedReceivers}
          updateStatus={updateStatus}
          request={request}
          input={input}
          setInput={setInput}
          updateDate={updateDate}
          onChange={updateTextFields}
        />
      );
    } else if (collabTypes === requestCollaborationTypes.strategicPartners) {
      return (
        <StrategicPartnersRequest
          receivers={selectedReceivers}
          updateStatus={updateStatus}
          request={request}
          input={input}
          setInput={setInput}
          updateDate={updateDate}
          onChange={updateTextFields}
        />
      );
    } else if (collabTypes === requestCollaborationTypes.businessPartners) {
      return (
        <BusinessPartnersRequest
          receivers={selectedReceivers}
          updateStatus={updateStatus}
          request={request}
          input={input}
          setInput={setInput}
          updateDate={updateDate}
          onChange={updateTextFields}
        />
      );
    } else if (collabTypes === requestCollaborationTypes.coFounding) {
      return (
        <CoFoundingRequest
          receivers={selectedReceivers}
          updateStatus={updateStatus}
          request={request}
          input={input}
          setInput={setInput}
          updateDate={updateDate}
          onChange={updateTextFields}
        />
      );
    } else if (collabTypes === requestCollaborationTypes.investment) {
      return (
        <InvestmentRequest
          receivers={selectedReceivers}
          updateStatus={updateStatus}
          request={request}
          input={input}
          setInput={setInput}
          updateDate={updateDate}
          onChange={updateTextFields}
        />
      );
    } else if (collabTypes === requestCollaborationTypes.mentoring) {
      return (
        <MentoringRequest
          receivers={selectedReceivers}
          updateStatus={updateStatus}
          request={request}
          input={input}
          setInput={setInput}
          updateDate={updateDate}
          onChange={updateTextFields}
        />
      );
    } else if (collabTypes === requestCollaborationTypes.experts) {
      return (
        <Experts
          receivers={selectedReceivers}
          updateStatus={updateStatus}
          request={request}
          input={input}
          setInput={setInput}
          updateDate={updateDate}
          onChange={updateTextFields}
        />
      );
    } else if (collabTypes === requestCollaborationTypes.mastersOfMasters) {
      return (
        <MastersForMaster
          receivers={selectedReceivers}
          updateStatus={updateStatus}
          request={request}
          input={input}
          setInput={setInput}
          updateDate={updateDate}
          onChange={updateTextFields}
        />
      );
    } else if (collabTypes === requestCollaborationTypes.masterMinds) {
      return (
        <MasterMind
          receivers={selectedReceivers}
          updateStatus={updateStatus}
          request={request}
          input={input}
          setInput={setInput}
          updateDate={updateDate}
          onChange={updateTextFields}
        />
      );
    } else if (
      collabTypes === requestCollaborationTypes.brainStormingPartners
    ) {
      return (
        <BrainStormingPartners
          receivers={selectedReceivers}
          updateStatus={updateStatus}
          request={request}
          input={input}
          setInput={setInput}
          updateDate={updateDate}
          onChange={updateTextFields}
        />
      );
    } else if (collabTypes === requestCollaborationTypes.coworkingDates) {
      return (
        <CoWorkingDays
          receivers={selectedReceivers}
          updateStatus={updateStatus}
          request={request}
          input={input}
          setInput={setInput}
          updateDate={updateDate}
          onChange={updateTextFields}
        />
      );
    }
  };

  const putInBubble = (condition, component, _props) => {
    return !condition ? null : (<ChatBubble {..._props}>{component}</ChatBubble>);
  };

  const progress = () => {
    let noIndustries = 
         collabType[0] != requestCollaborationTypes.masterMinds
      || collabType[0] != requestCollaborationTypes.coworkingDates;
    let noSkills =
         collabType[0] != requestCollaborationTypes.investment
      || collabType[0] != requestCollaborationTypes.masterMinds
      || collabType[0] != requestCollaborationTypes.coworkingDates;

    let p = 0;
    if (collabTabCurrentView() != null)
      p = 1;
    if((noIndustries || input.industries.length) && 
       (noSkills     || input.skills.length    ) && 
       input.locationPlaceID) //location is always required
      p = 2;
    if((noOptionalFields() && p==2) || skip || inspireMe == false)
      p = 3;
    if(input.titleRequest && input.description)
      p = 4;

    return p;
  };

  const noOptionalFields = () => {
    return collabType[0]  === requestCollaborationTypes.masterMinds || collabType[0]  === requestCollaborationTypes.investment;
  }

  //whether the optional collab type specific fields are not empty
  const optionalFilledIn = () => {
    if(collabType[0] === requestCollaborationTypes.mastersOfMasters && !input.skills2.length)
      return false;
    return input.period                || input.months
        || input.hours                 || input.days.length
        || input.skills2.length        || input.descriptionPart2
        || input.commitments.length    || input.preferredBenefits.length
        || input.businessStage.length  || input.preferredCompanyRole
        || input.companyRole           || input.entrepreneurshipLevel.length
        || input.expertiseLevel.length;
  };

  //returns true if all required fields are filled in
  const canPost = () => {
    let noIndustries = 
         collabType[0] != requestCollaborationTypes.masterMinds
      || collabType[0] != requestCollaborationTypes.coworkingDates;
    let noSkills =
         collabType[0] != requestCollaborationTypes.investment
      || collabType[0] != requestCollaborationTypes.masterMinds
      || collabType[0] != requestCollaborationTypes.coworkingDates;

    return (noIndustries || input.industries.length) 
        && (noSkills     || input.skills.length    )
        && (inspireMe == false || (input.locationPlaceID && input.location))
        && input.titleRequest 
        && input.description 
        && input.selectedToWhomIds && input.selectedToWhomIds.length;
  }

  const handleSucessAlert = () => {
    if (
      (location.state && location.state.circleDetails) ||
      (location.state &&
        typeof location.state.isFromCircle !== "undefined" &&
        location.state.isFromCircle)
    ) {
      history.push("/dashboard/circleDetails/?id=" + location.state.circleDetails.id, {
        circleDetails: location.state.circleDetails,
        selectedTab: 3,
      });
    } else {
      history.push("/dashboard/Requests");
    }
  };

  const updateDate = (date) => {
    setInput((prevState) => ({
      ...prevState,
      startDate: date,
    }));
  };

  const updateTextFields = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };

  const onLocationChange = (location) => {
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        location:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
      setInput((prevState) => ({
        ...prevState,
        locationPlaceID:
          typeof location === "string" || location instanceof String
            ? input["locationPlaceID"]
            : location.place_id,
      }));
      setInputError((prevState) => ({
        ...prevState,
        location: "",
      }));
    }
  };

  const handleChangeAutoCompleteIndustries = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      industries: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      industries: "",
    }));
  };

  const handleChangeAutoCompleteSkills = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      skills: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      skills: "",
    }));
  };

  const handleSave = async () => { //the button to this is locked off by canPost() so no further checks on this are needed
    mutate({
        onCompleted: (data) => {setData_req(data)},
        variables: getVariables()
      });
  };

  //the collab type will stay the same cause it's not in the input
  const clearInput = () => {
    setSkip(false);
    setInput({
      period                 : null,
      months                 : null,
      hours                  : null,
      months2                : [],
      days                   : [],
      startDate              : null,
      industries             : [],
      location               : "",
      descriptionPart2       : "",
      description            : "",
      skills                 : [],
      skills2                : [],
      titleRequest           : "",
      selectedToWhomIds      : props.receivers,
      id                     : null,
      locationPlaceID        : "",
      entrepreneurshipLevel  : [],
      expertiseLevel         : [],
      preferredBenefits      : [],
      companyRole            : null,
      benefitType            : [],
      businessStage          : [],
      commitments            : [],
      preferredCompanyRole   : null
    });
  };

  /* ======
  Form Sections
     ====== */

  const collabTypeText = !collabType_temp.length? "" :
    RequestCollaborationOfInterestArray.filter(
      (collab) =>
        collab.itemType === collabType_temp[0]
    )[0].description;


  const toWho = () => {return(
    <Grid item sm={12} xs={12}>
      <Text variant="h2" id={"RequestNew_ToWho"} />
      <Text variant="body" id={"RequestNew_Field_Description"} /><br/>
      <Text variant="h3">
          <FormControl className={classes.Select}>
            <InputLabel id="demo-multiple-checkbox-label">
              {" "}
              <FormattedMessage id={"InputLabel_Text_Select"} />{" "}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedOptions}
              onChange={handleChangeReceivers}
              input={<OutlinedInput label="Select" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              disabled={isEdit}
            >
              {allReceivers.map((option, index) => (
                <MenuItem key={index} value={option.type}>
                  <Checkbox
                    checked={selectedOptions.indexOf(option.type) > -1}
                  />
                  <ListItemText primary={option.type} />
                </MenuItem>
              ))}
            </Select>
            {showReceiverSelectionError ? (
              <FormHelperText>
                <Text variant="body2" color="red" id={"PleaseSelectReceivers"} />
              </FormHelperText>
            ) : null}
          </FormControl>
      </Text>
    </Grid>
  );};

  const selectCollabType = () => {return(
    <Grid item sm={12} xs={12}>
      <ChipList selected={collabType} entries={JSON.stringify(RequestCollaborationOfInterestArray)} onClick={handleChangeCollabType} value={collabType_temp}/>
    </Grid>
  );};

  const requiredFields = () => {
    function industries() {
      return(
        <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"PartnerIndustries"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <Autocomplete
                multiple
                id="industries"
                options={masterDataIndustries()}
                value={input["industries"]}
                onChange={handleChangeAutoCompleteIndustries}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      size="small"
                      className={classes.chip}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // placeholder="Add"
                    variant="outlined"
                  />
                )}
              />
            {inputError["industries"] ? (
              <FormHelperText>
                <Text variant="body2" color="red" id={"Pleaseenterindustry"} />
              </FormHelperText>
            ) : null}
          </FormControl>
        </Text>
      </Grid>
      );
    }
    
    function skills() {
      return(
        <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"RequiredSkills"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>

              <Autocomplete
                multiple
                id="skills"
                freeSolo
                autoSelect={true}
                options={masterDataSkills()}
                value={input["skills"]}
                onChange={handleChangeAutoCompleteSkills}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      size="small"
                      className={classes.chip}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // placeholder="Add"
                    variant="outlined"
                    inputProps={{ ...params.inputProps, maxLength: 25 }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^\w\s]/gi, "")
                      e.target.value = e.target.value.split(" ").length > 2 ? e.target.value.trim() : e.target.value
                      e.target.value = e.target.value.substring(0, 25)
                    }}
                  />
                )}
              />
            {inputError["skills"] ? (
              <FormHelperText>
                <Text variant="body2" color="red" id={"Pleaseenterskills"} />
              </FormHelperText>
            ) : null}
          </FormControl>
        </Text>
      </Grid>
      );
    }

    function location() {
      return(
        <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"Location"} />
        <FormControl variant="filled" className={classes.Select}>
            <LocationAutoComplete
              onChange={onLocationChange}
              value={input["location"]}
            />
          {inputError["location"] ? (
            <FormHelperText>
              <Text variant="body2" color="red" id={"Pleaseenterlocation"} />
            </FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      );
    }

    return(
    <Grid container item xs={12} gap={4}>
      {/* TODO put complete checks on this!!! */}
      {  collabType[0] != requestCollaborationTypes.masterMinds
      && collabType[0] != requestCollaborationTypes.coworkingDates ? industries() : null}
      {  collabType[0] != requestCollaborationTypes.investment
      && collabType[0] != requestCollaborationTypes.masterMinds
      && collabType[0] != requestCollaborationTypes.coworkingDates ? skills() : null}
      {location()}
    </Grid>
  );};

  const requiredFieldsPart2 = () => {return(
    <Grid container item xs={12}>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"TitleRequest"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>

              <TextField
                id="titleRequest"
                label={intl.formatMessage({
                  id: "introduction_Field_input_user_type",
                })}
                size="small"
                value={input["titleRequest"]}
                onChange={updateTextFields}
                helperText={
                  inputError["titleRequest"] ? (
                    <Text variant="body2" color="red" id={"PleaseEnterTheTitle"} />
                  ) : null
                }
              />

          </FormControl>
        </Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"PartnershipDescription"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>

              <TextareaAutosize
                variant="filled"
                aria-label="minimum height"
                minRows={5}
                id="description"
                placeholder={intl.formatMessage({
                  id: "introduction_Field_input_user_type",
                })}
                className={classes.txtarea}
                value={input.description}
                onChange={updateTextFields}
              />

            {inputError["partnershipDescription"] ? (
              <Text variant="body2" color="red" id={"PleaseEnterThePartnershipDescription"} />
            ) : null}
          </FormControl>
        </Text>
      </Grid>
    </Grid>
  );};

  /* ======
   Full Form
     ====== */

  const conversation = profile ? 
  <React.Fragment>

    {putInBubble(true, (<Text item container id="requestForm_iam" variant="body1" bold color="primary" values={{naam: (profile ? profile.profile.givenName : "")}}/>), {iam: true})}
    {putInBubble(true, <Grid container gap={3} p={2}>
      <Grid item>
        <Button variant="contained" color={inspireMe == false ? "darkPetrol" : "primary"} disabled={collabType.length && collabType[0] != requestCollaborationTypes.masterMinds} onClick={() => {
            setInspire(false);
            setCollabType([requestCollaborationTypes.masterMinds]);
          }}>
          <Text variant="h2" color="primary" id="requestForm_iam_yes"/>
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" color={inspireMe == true ? "darkPetrol" : "primary"} disabled={input.description || input.titleRequest} onClick={() => {
            setInspire(true);
            if(!inspireMe)
              setCollabType([]);
          }}>
          <Text variant="h2" color="primary" id="requestForm_iam_no"/>
        </Button>
      </Grid>
    </Grid>, {image: (profile ? profile.profile.profilePictureUrl : null), delay: 5})}
    {!inspireMe ? null :
      <React.Fragment>
        <ChatBubble iam>
          <Text item container variant="body" bold color="primary"
          id="requestForm_iam1" />
        </ChatBubble>
        <ChatBubble image={(profile ? profile.profile.profilePictureUrl : null)} delay={5}>
          <Grid container item xs={12} gap={2} mt={2}>
            {selectCollabType()}
            <Background item xs={2} sm={1} 
            border="bottomLeft" display={collabTypeText ? "block" : "none"} overflow="hidden"
            sx={{aspectRatio:"1 / 1", minWidth: "45px", maxWidth: "45px", height:"fit-content"}}
            >
              <Image
                src="/assets/img/iamFull.png"
                alt="Profile Picture"
                width={"100%"}/>
            </Background>
            <Text item xs={9} sm={10} bold
              variant = "body1"
              color   = "background"
              id      = {collabTypeText}
            />
            <Grid item xs={12} style={{ marginTop: 15 }}>
              {collabType.toString() != collabType_temp.toString()?
                (collabType_temp.length ? (
                  <ButtonDarkBlue onClick={applyCollabType}>
                    <FormattedMessage id={collabType.length ? "renew" : "continue2"} />
                  </ButtonDarkBlue>
                ) : <ButtonGrey>
                      <FormattedMessage id={"continue"} />
                    </ButtonGrey>) : null}
              {collabType_temp.length && collabType.length && collabType.toString() != collabType_temp.toString() ? (
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    className={classes.warn}
                  >
                    <FormattedMessage id={"restart_conv_warning"} />
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </ChatBubble>
      </React.Fragment>
    }
    {putInBubble(progress() >= 1 && inspireMe != false, (<Text item container id="requestForm_iam2" variant="body1" bold color="primary"/>), {iam: true})}
    {putInBubble(progress() >= 1 && inspireMe != false, requiredFields(), {image: (profile ? profile.profile.profilePictureUrl : null), delay: 5})}

    {putInBubble(progress() >= 2 && !noOptionalFields(), (<Text item container id="requestForm_iam3" variant="body1" bold color="primary"/>), {iam: true})}
    {putInBubble(progress() >= 2 && !noOptionalFields(), (
        <Grid container item xs={12}>
          {collabTabCurrentView()}
          <Grid item xs={12} mt={2} style={{ marginTop: 30 }}>
          {optionalFilledIn() ? (
              <ButtonDarkBlue onClick={setSkip} value={true}>
                <FormattedMessage id={"continue"} />
              </ButtonDarkBlue>
            ) : (collabType[0] != RequestCollaborationTypes.mastersOfMasters ?
                (<ButtonGrey onClick={setSkip} value={true}>
                  <FormattedMessage id={"Skip"} />
                </ButtonGrey>) : null)}
          </Grid>
        </Grid>
      ), {image: (profile ? profile.profile.profilePictureUrl : null), delay: 5})}

    {putInBubble(progress() >= 3, (<Text item container id={inspireMe == false ? "requestForm_iam4.5" : "requestForm_iam4"} variant="body1" bold color="primary"/>), {iam: true})}
    {putInBubble(progress() >= 3, requiredFieldsPart2(), {image: (profile ? profile.profile.profilePictureUrl : null), delay: 5})}

    {putInBubble(progress() >= 4, (<Text item container id="requestForm_iam5" variant="body1" bold color="primary"/>), {iam: true})}
    {putInBubble(progress() >= 4, (
      <Grid container item xs={12}>
        {toWho()}
        <Grid item sm={3} xs={12}>
          <Box sx={{ mt: 5 }}>
            {canPost() ? (
              <ButtonDarkBlue onClick={handleSave}>
                <FormattedMessage id={"RequestTabs_Button_Text_Send"} />
              </ButtonDarkBlue>
            ) : <ButtonGrey disabled>
                  <FormattedMessage id={"RequestTabs_Button_Text_Send"} />
                </ButtonGrey>}
          </Box>
        </Grid>
      </Grid>
    ), {image: (profile ? profile.profile.profilePictureUrl : null), delay: 5})}
  </React.Fragment>
  : 
  (<Text variant="body" bold color="primary" id="requestForm_iam0"/>) ;

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={Theme_WIP}>
      <Navigationbar />
      <Background color="darkPetrol" page/>
      <Background color="darkPetrol" z="-100 !important" container py={6} px={{xs: 6, md:22, lg:75, xl:115}} mt={12}>
        <Grid item sm={12} xs={12}>
          {showSuccess ? (
            <SuccessAlertWithAction
              message={<FormattedMessage id={"Collab_Request_Sent_Successfully"} />}
              open={true}
              handleClose={handleSucessAlert}
            />
          ) : null}
          {showError ? (
            <Alert severity={"error"}>
              {<FormattedMessage id={"YouAlreadySentRequest"} />}
            </Alert>
          ) : null}
        </Grid>
        {conversation}
      </Background>
      {   
        mutationResponseCode != undefined ?
          (mutationResponseCode == 0 ? 
            (<AlertWithAction open={true} title={intl.formatMessage({
              id: "requestCreated_title",
            })} message={intl.formatMessage({
              id: "requestCreated_description",
            })} handleClose={() => {
              history.push("/dashboard/requests");
              window.location.reload(false);
            }}/>)
          :
            (<AlertWithAction open={true} title={intl.formatMessage({
              id: "requestError_title",
            })} message={intl.formatMessage({
              id: "requestError_description",
            })} handleClose={() => history.push("#")}/>))
        : 
          null
      }
      {/* <AlertWithAction open={mutationResponseCode == 0} title={"WOW"} message={"WOOOW"} onHandleClose={() => alert("YEAH")}/> */}
      <Hidden mdUp>
        <Footer />
      </Hidden>
      </ThemeProvider>
    </React.Fragment>
  );
}
