import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import Button from "@material-ui/core/Button";
import { Chip } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router";
import LocationAutoComplete from "../Autocomplete/LocationAutoComplete";
import { FormHelperText } from "@material-ui/core";
import { Autocomplete, Avatar, Grid, Skeleton, Stack, ThemeProvider } from "@mui/material";
import { ClearCache, toBase64 } from "../../apiServices/CommonMethods";
import {
  masterDataExpertLevel,
  masterDataSkills,
  masterDataCompanyLevel,
  masterDataIndustries,
  masterDataprofessionalLife,
  masterDataPersonalLife,
} from "../../apiServices/Constants";
import { toTitleCase } from "../../apiServices/CommonMethods";
import { FormattedMessage } from "react-intl";
import Styles from "../../styles/component/Onboarding/OnboardingStepper1Style";
import { GetUserId } from "../../apiServices/CommonMethods";
import { useMutation } from "@apollo/client";
import { UPDATE_ONBOARDING } from "../../apiServices/mutations";
import { useCurrentUserProfile } from "../../apiServices/CommonDataObjects";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";
import FormContainer from "../Forms/FormContainer";
import InputSection from "../Forms/InputSection";


const useStyles = makeStyles(Styles);
export default function OnboardingStepper1(props) {
  const userId = GetUserId();
  const Input = styled("input")({
    display: "none",
  });
  const history = useHistory();
  const classes = useStyles();
  const [fileSelected, setFileSelected] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [imageSize, setImageSize] = React.useState(false);
  const profile = useCurrentUserProfile();

  const [ProfileUpdate, { data, loading }] = useMutation(
    UPDATE_ONBOARDING,
    {
      onCompleted: (data) => {
        ClearCache(); //just to be sure everything is up to date upon first entering the dashboard
        // console.log(data);
        if (
          typeof data !== "undefined" &&
          data.updateOnboarding.errorCode === 0
        ) {
          // console.log("UPDATE_ONBOARDING sucess", data);
          localStorage.setItem("onBoardProgress", 1);
          let newSkipped = skipped;
          if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
          }

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setSkipped(newSkipped);
          props.showTab("PremiumFeatures");
        }
      },
    }
  );

  let [input, setInput] = React.useState({
    companyRole: "",
    companyName: "",
    country: "",
    fileSelected: "",
    locationPlaceID: "",
    skills: [],
    professional: [],
    personal: [],
    expertise: "",
    companyLevel: "",
    industry: [],
  });
  let [inputError, setInputError] = React.useState({
    companyRole: "",
    companyName: "",
    country: "",
    fileSelected: "",
    skills: "",
    professional: "",
    personal: "",
    expertise: "",
    companyLevel: "",
    industry: "",
  });

  React.useEffect(() => {
    if (localStorage.getItem("onBoardProgress") != null && localStorage.getItem("onBoardProgress") <= 3) {
      history.push("/OnboardingStepper");
    }
  }, []);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let isError = await checkForErrors();
    window.scrollTo(0, 0);
    if (isError) {
      let variable = {
        userId: userId,
        profilePictureUrl: fileSelected,
        role: input["companyRole"],
        companyRole: input["companyRole"],
        company: input["companyName"],
        name: input["companyName"],
        location: input["country"],
        locationPlaceID: input["locationPlaceID"],
        skills: input["skills"],
        professionalLife: input["professional"],
        personalLife: input["personal"],
        expertiseLevel: input["expertise"],
        companyLevel: input["companyLevel"],
        industry: input["industry"],
      };
      ProfileUpdate({
        variables: variable,
      });
      // console.log(data);
    }
  };

  const checkForErrors = async () => {
    setImageSize(false);
    setInputError((prevState) => ({
      ...prevState,
      companyRole: !input["companyRole"] ? "companyRoleError" : "",
      companyName: !input["companyName"].trim() ? "companyNameError" : "",
      country: !input["country"] ? "locationError" : "",
      fileSelected: !input["fileSelected"] ? "fileSelectedError" : "",
      skills: !input["skills"].length ? "skillError" : "",
      professional: !input["professional"].length ? "professionalError" : "",
      personal: !input["personal"].length ? "personalError" : "",
      expertise: !input["expertise"] ? "expertiseError" : "",
      companyLevel: !input["companyLevel"] ? "companyLevelError" : "",
      industry: !input["industry"].length ? "industryError" : "",
    }));
    // console.log("country", input);

    return (
      input["companyRole"] &&
      input["companyName"].trim() &&
      input["country"] &&
      input["fileSelected"] &&
      input["companyLevel"] &&
      input["industry"].length &&
      input["skills"] &&
      input["professional"].length &&
      input["personal"].length &&
      input["expertise"] &&
      input["companyLevel"]
    );
  };
  const handleOnChange = (event) => {
    const { id, value } = event.target;
    if (id === "personal" || id === "professional") {
      const arayWords = value.split(" ");
      if (arayWords.length < 4) {
        setInputError((prevState) => ({
          ...prevState,
          [id]: "",
        }));
        setInput((prevState) => ({
          ...prevState,
          [id]: value,
        }));
      }
    } else {
      setInputError((prevState) => ({
        ...prevState,
        [id]: "",
      }));
      setInput((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };
  const handleChangeAutoCompleteIndustries = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      industry: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      industry: "",
    }));
  };
  // const handleChangeAutoCompleteKeyValues = (event, newvalue) => {
  //   setInput((prevState) => ({
  //     ...prevState,
  //     keyValues: newvalue,
  //   }));
  //   setInputError((prevState) => ({
  //     ...prevState,
  //     keyValues: "",
  //   }));
  // };

  const onLocationChange = (location) => {
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        country:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
      setInput((prevState) => ({
        ...prevState,
        locationPlaceID:
          typeof location === "string" || location instanceof String
            ? input["locationPlaceID"]
            : location.place_id,
      }));
      setInputError((prevState) => ({
        ...prevState,
        country: "",
      }));
    }
  };

  const onImageSelection = async (event) => {
    let fileSelect = event.target.files[0];
    if (typeof fileSelect !== "undefined") {
      const maxfilesize = 1024 * 10240; // 10 Mb
      // console.log(event.target.files[0]);
      // console.log(URL.createObjectURL(event.target.files[0]));
      if (event.target.files[0].size < maxfilesize) {
        setImageSize(false);
        let imageUrl = await toBase64(fileSelect);
        setFileSelected(imageUrl);
        setInput((prevState) => ({
          ...prevState,
          fileSelected: URL.createObjectURL(event.target.files[0]),
        }));
        setInputError((prevState) => ({
          ...prevState,
          fileSelected: "",
        }));
      } else {
        setInputError((prevState) => ({
          ...prevState,
          fileSelected: "",
        }));
        setFileSelected("");
        setInput((prevState) => ({
          ...prevState,
          fileSelected: "",
        }));

        setImageSize(true);
      }
    }
  };
  const handleChangeAutoComplete = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      skills: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      skills: "",
    }));
  };
  const handleChangeIndustry = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      industry: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      industry: "",
    }));
  };

  const handleChangeSelect = (field, event) => {
    setInput((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [field]: "",
    }));
  };
  const handleChangeAutoCompletePersonal = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      personal: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      personal: "",
    }));
  };
  const handleChangeAutoCompleteProfessional = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      professional: newvalue,
    }));
    setInputError((prevState) => ({
      ...prevState,
      professional: "",
    }));
  };
  const deleteImage = () => {
    setFileSelected("");
    setInput((prevState) => ({
      ...prevState,
      fileSelected: "",
    }));
  };

  const pfpUpload = () => {
    if(!input["fileSelected"] && !loading) {
      return (<Grid item xs={12} sm={12}>
        {loading ? (
          <Skeleton variant="rectangular" height={40} />
        ) : (
          <Stack direction="row" alignItems="center" spacing={2}>
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={onImageSelection}
              />
              <Button
                variant="contained"
                component="span"
                size="small"
                color="default"
                className={classes.margin}
              >
                <FormattedMessage id={"UploadProfilePic"} />
                *:
              </Button>
            </label>
          </Stack>
        )}
        {inputError["fileSelected"] ? (
          <Typography
            component="span"
            variant="h6"
            color={"error"}
            className={classes.p15}
          >
            <FormattedMessage id={"SelectProfileImage"} />
          </Typography>
        ) : null}
      </Grid>);
    } else {
      return (<Grid item xs={10}>
      {loading ? (
        <Skeleton variant="rectangular" width={150} height={150} />
      ) : (
        <Avatar
          sx={{ bgcolor: "grey", width: 150, height: 150, boxShadow: 3 }}
          alt="Remy Sharp"
          src={
            input["fileSelected"]
              ? input["fileSelected"]
              : "../assets/img/Editprofile.png"
          }
          variant={"square"}
        />
      )}
      <Stack
        direction="row"
        justifyContent={"space-between"}
        sx={{ paddingTop: 2, width: 150 }}
      >
        <label htmlFor="contained-button-file">
          <Input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={onImageSelection}
          />
          <Typography
            component="span"
            variant="h5"
            color={"textSecondary"}
            className={classes.p15}
          >
            <FormattedMessage id={"edit"} />
          </Typography>
        </label>
        <label htmlFor="contained-button-file">
          <Typography
            component="span"
            variant="h5"
            color={"textSecondary"}
            className={classes.p15}
            onClick={deleteImage}
          >
            <FormattedMessage id={"delete"} />
          </Typography>
        </label>
      </Stack>
      </Grid>);
    }};
  const pfpUploadError = () => {
    if(imageSize)
      return (
      <Typography
        component="span"
        variant="h6"
        color={"error"}
        className={classes.p15}
      >
        <FormattedMessage id={"File_Upload_Size_Error_Message"} />
      </Typography>
      );
    };


  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container px={{xs: 0, sm: 5, md: 15, lg: 35, xl: 50}} justifyContent="center">
    <Text item xs={12} variant="h1" bold maxWidth="sm" id="OnboardingProfileInfo.Pagetitle1" values={{first_name: profile?.givenName ?? ""}}/>

    <FormContainer noFullscreen props={{mt: 0}}
      error        = {data?.updateOnboarding?.errorCode === -1 && data.updateOnboarding.message}
      loading      = {loading}
      onSave       = {handleNext}
      disabled     = {loading}
      saveId       = "OnBoarding_Button_Text_Save"
    >
      <InputSection
        props={{xs: 5.5}}
        type     = "text"
        title    = {<FormattedMessage id={"Companyrole"} />}
        id       = "companyRole"
        onChange = {handleOnChange}
        value    = {input["companyRole"]}
        hint     = {inputError["companyRole"] && <FormattedMessage id={"PleaseEnterCompanyRole"} />}
      />
      <InputSection
        props={{xs: 6}}
        type     = "text"
        title    = {<FormattedMessage id={"Company"} />}
        id       = "companyName"
        onChange = {handleOnChange}
        value    = {input["companyName"]}
        hint     = {inputError["companyName"] && <FormattedMessage id={"Pleaseentercompany"} />}
      />
      <InputSection
        type     = "location"
        title    = {<FormattedMessage id={"Location"} />}
        id       = "companyName"
        onChange = {onLocationChange}
        value    = {input["country"]}
        hint     = {inputError["country"] && <FormattedMessage id={"Pleaseenterlocation"} />}
      />
      <InputSection
        type        = "multipleText"
        title       = {<FormattedMessage id={"Skills"} />}
        description = {<FormattedMessage id={"EditBioInfo_Field_Description"} />}
        id          = "skills"
        options     = {masterDataSkills()}
        onChange    = {handleChangeAutoComplete}
        value       = {input["skills"]}
        hint        = {inputError["skills"] && <FormattedMessage id={"Pleaseenterskills"} />}
      />
      <InputSection
        type        = "multipleText"
        title       = {<FormattedMessage id={"MeProfessional"} />}
        description = {<FormattedMessage id={"EditBioInfo_Field_Description"} />}
        id          = "professional"
        options     = {masterDataprofessionalLife()}
        onChange    = {handleChangeAutoCompleteProfessional}
        value       = {input["professional"]}
        hint        = {inputError["professional"] && <FormattedMessage id={"Pleaseenterdetails"} />}
      />
      <InputSection
        type        = "multipleText"
        title       = {<FormattedMessage id={"MePerson"} />}
        description = {<FormattedMessage id={"EditBioInfo_Field_Description"} />}
        id          = "personal"
        options     = {masterDataPersonalLife()}
        onChange    = {handleChangeAutoCompletePersonal}
        value       = {input["personal"]}
        hint        = {inputError["personal"] && <FormattedMessage id={"Pleaseenterdetails"} />}
      />
      <InputSection
        type        = "select"
        title       = {<FormattedMessage id={"Myexpertise"} />}
        id          = "expertise"
        options     = {masterDataExpertLevel().map((option, index) => ({key: toTitleCase(option.key), value: toTitleCase(option.key)}))}
        onChange    = {(e) => handleChangeSelect("expertise", e)}
        value       = {input["expertise"]}
        hint        = {inputError["expertise"] && <FormattedMessage id={"Pleaseenterexpertise"} />}
      />
      <InputSection
        type        = "select"
        title       = {<FormattedMessage id={"Typeofcompany"} />}
        description = {<FormattedMessage id={"CompanyType_Edit_Description"} />}
        id          = "companyLevel"
        options     = {masterDataCompanyLevel().map((option, index) => ({key: option.key, value: option.key}))}
        onChange    = {(e) => handleChangeSelect("companyLevel", e)}
        value       = {input["companyLevel"]}
        hint        = {inputError["companyLevel"] && <FormattedMessage id={"Pleaseentercompanytype"} />}
      />
      <InputSection
        type        = "multipleText"
        title       = {<FormattedMessage id={"Industry"} />}
        id          = "industry"
        options     = {masterDataIndustries()}
        onChange    = {handleChangeIndustry}
        value       = {input["industry"]}
        hint        = {inputError["industry"] && <FormattedMessage id={"Pleaseenterindustry"} />}
      />
      <Grid item xs={12}>
        {pfpUpload()}
        {pfpUploadError()}
      </Grid>
    </FormContainer>
    </Grid>
    </ThemeProvider>
  );
}
