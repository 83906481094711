import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Container from "@material-ui/core/Container";
import { useHistory, useLocation } from "react-router-dom";
import InfoTab from "../components/ProfileTabs/InfoTab";
import RequestTab from "../components/ProfileTabs/RequestTab";
import CircleTab from "../components/ProfileTabs/CircleTab";
import SharedTab from "../components/ProfileTabs/SharedTab";
import InviteTab from "../components/ProfileTabs/InviteTab";
import EditProfileHeader from "../components/ProfileTabs/EditProfileHeader";
import ProfileHeader from "../components/ProfileTabs/ProfileHeader";
import { GetUserId } from "../apiServices/CommonMethods";
import { Button, CssBaseline, ThemeProvider } from "@mui/material";
import TabPanel from "../components/ProfileTabs/TabPanel";
import QueryString from "query-string";
import SelfInviteForm from "../components/Invites/SelfInviteForm";
import Theme_WIP from "../styles/themes/main/Theme_WIP";
import Text from "../visualComponents/Text";
import Background from "../visualComponents/Background";
import { StylesProvider } from "@material-ui/core";
import { clearSelfProfileCache, useGetProfile } from "../apiServices/Queries/Profiles/GetProfile";
import Navigationbar from "../components/Navigationbar";
import PageWithBanner from "../components/Layout/PageWithBanner";

export default function Profile(props) {
  let location = useLocation();
  const params = QueryString.parse(window.location.search);
  const userId = GetUserId();
  const history = useHistory();
  const [value, setValue] = React.useState(location.state?.tab ?? 0);
  const [isEditHeader, setIsEditHeader] = React.useState(false);
  const [profileInfo, setProfileInfo] = React.useState({});
  const [currentUserId, setCurrentUserId] = React.useState(null);
  const [needToBar, setNeedToBar] = React.useState(false);
  const vars = {
    userId: (location.state?.selectedUserId != "" ? location.state?.selectedUserId : undefined) ?? params.id ?? params.uuid ?? userId,
    loggedInUserId: userId,
  };
  const {data: data, updateVariables: refetch} = useGetProfile(vars.userId, vars.loggedInUserId);
  useEffect(() => refetch(vars), [location]);
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })
    if (params.id || params.uuid) {
      setCurrentUserId(params.id ?? params.uuid);
      if (!userId) {
        setNeedToBar(true);
      }
    } else if (location.state && location.state.selectedUserId) {
      setProfileInfo({});
      setCurrentUserId(location.state.selectedUserId);
    } else {
      setCurrentUserId(userId);
    }
  }, [location]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const editProfileHeader = () => {
    setIsEditHeader(true);
  };
  const closeEditProfileHeader = (profileInfos) => {
    setIsEditHeader(false);
    setProfileInfo(profileInfos);
  };
  const goBack = () => {
    history.goBack();
  };
  const updateProfileInfo = (profileInfos) => {
    setProfileInfo(profileInfos);
    clearSelfProfileCache();
  };

  return (
    <ThemeProvider theme={Theme_WIP}>
      {userId != null && <Navigationbar/>}
      <PageWithBanner 
        header={<ProfileHeader
          disableClose={params.uuid != null}
          editProfileHeader={editProfileHeader}
          userId={params.uuid ?? currentUserId ?? userId}
          handleChange={handleChange}
          goBack={goBack}
          profileInfo={updateProfileInfo}
          profileData={data}
          isNonMM={needToBar}
          uuid={params.uuid}
        />}

        tabs={!props.uuid && [
          {label: <Text variant="body1" color="inherit" id="ProfileHeader_Info"         />, value: 0},
          {label: <Text variant="body1" color="inherit" id="ProfileHeader_Requests"     />, value: 1},
          {label: <Text variant="body1" color="inherit" id="ProfileHeader_Circles"      />, value: typeof props.isNonMM !== "undefined" && !props.isNonMM && 2},
          {label: <Text variant="body1" color="inherit" id="ProfileHeader_Invite"       />, value: typeof props.isNonMM !== "undefined" && !props.isNonMM && props.userId === userId && 3},
          {label: <Text variant="body1" color="inherit" id="ProfileHeader_SharedMatches"/>, value: props.userId !== userId && 4},
        ]}

        tab={value}
      >
        <Grid container direction="row-reverse" >
          {params.uuid && <Grid container item xs={12} mb={5} md={3} justifyContent="end" sx={{top: 60, position:"sticky"}}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => window.location.href = "https://mastermatch.net/hoofdpagina"}>
                <Text p={0.5} variant="body1" color="inherit" id="UuidInvite.Button"/>
              </Button>
            </Grid>
          </Grid>}

          <Grid container item xs={12} md={params.uuid ? 9 : 12} sx={{ width: "100%", height: "100%", marginBottom: 100 }}>
            { params.uuid && userId == null && <Background color="lighterPetrol" border="bottomLeft" mb={6} p={4} height="fit-content" py={6}>
                <Text item xs={12} variant="h1"    id="UuidProfileTitle"       color="background" mb={3} bold/>  
                <Text item xs={12} variant="body1" id="UuidProfileDescription" color="background"/>  
              </Background>}
            { params.uuid && userId == null && <SelfInviteForm source="profiles"/>}
            {!params.uuid && <React.Fragment>
              <TabPanel value={value} index={0}>
                {isEditHeader ? (
                  <EditProfileHeader
                    saveAction={closeEditProfileHeader}
                    profileInfo={profileInfo}
                    userId={currentUserId ?? userId}
                  />
                ) : (
                  <InfoTab
                    profileData={data}
                    userId={currentUserId ?? userId}
                    updateProfileData={updateProfileInfo}
                  />
                )}
              </TabPanel>

              <TabPanel value={value} index={1}>
                <RequestTab viewUserProfileId={currentUserId ?? userId} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CircleTab userId={currentUserId ?? userId} />
              </TabPanel>
              {currentUserId === userId ? (
                <TabPanel value={value} index={3}>
                  <InviteTab profileInfo={profileInfo} />
                </TabPanel>
              ) : (
                <TabPanel value={value} index={3}>
                  <SharedTab viewUserProfileId={currentUserId ?? userId} />
                </TabPanel>
              )}
            </React.Fragment>}
            </Grid>
        </Grid>
      </PageWithBanner>
    </ThemeProvider>
  );
}
Profile.prototype = {};
