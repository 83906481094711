import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Styles from "../../../styles/container/ActivityPageStyle";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import { MARK_AS_DONE_MATCH_REQUEST } from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { ActivityStatus } from "../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import UserInfo from "./components/UserInfo";
import { useGlobalContext } from "../../../apiServices/Providers/AppGlobalStateProvider";

const useStyles = makeStyles(Styles);

export default function ActivityEventCancel(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const [activity, setActivity] = React.useState({});
  const [MarkAsDone, { data: dataMarkAsDone, loading: loadingMarkAsDone }] =
    useMutation(MARK_AS_DONE_MATCH_REQUEST);

  useEffect(() => {
    if (typeof props.activity !== "undefined") {
      setActivity(props.activity);
    }
    if (typeof dataMarkAsDone !== "undefined") {
      // console.log("dataMarkAsDone", dataMarkAsDone);
      props.onRefresh();
    }
  }, [props, dataMarkAsDone]);

  const markAsDoneMatchRequestAction = async () => {
    if (activity.activity.status !== ActivityStatus.Closed) {
      const variables = {
        activityType: activity.activity.activityTypeDesc,
        activityId: activity.activity.id,
      };
      // console.log("variable", variables);
      MarkAsDone({
        variables: variables,
      });
    }
  };
  const navigateToProfile = () => {
    if (activity.showProfileBasedOnPreference !== true) {
      props.onShowPreferenceWarning()
    } else if (activity.activity.userRole !== 1) {
      history.push("/dashboard/profile/", {
        selectedUserId: activity.userId,
      });
    }
  };
  const checkCurrentUser = () => {
    return (
      activity.activity && activity.activity.requesterUserProfileId !== userId
    );
  };
  return (
    <Grid container spacing={4} className={classes.mt15}>
      {!checkCurrentUser() && <Grid item container xs={0} sm={1} md={3} spacing={2}></Grid>}
      <Grid item container xs={12} sm={11} md={9} spacing={2}>
        <UserInfo sent={checkCurrentUser()} activity={activity} onClick={navigateToProfile} activityType={"EVENT_CANCELED"}/>
        <Grid item container xs={12} sm={12}>
          <div className={checkCurrentUser() ? classes.tabcontentInner : classes.cardSent}>
            {typeof activity.activity !== "undefined" ? (
              <Typography
                variant="h3"
                color={"textSecondary"}
                className={classes.p3}
              >
                {<FormattedMessage id={"TheEvent"} />}{" "}
                {/* <span className={classes.circleName}> */}
                {activity.activity.eventName.toUpperCase()}
                {/* </span> */}
                {" "}
                <FormattedMessage
                  id={"CanceledByTheEventManagerYouCanNoLongerAccessTheEvent"}
                />
              </Typography>
            ) : null}
            {checkCurrentUser() ? (
              <Grid item xs={12} sm={12}>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Grid item xs={12} sm={12} className={classes.rightAlign}>
                  {loadingMarkAsDone ? (
                    <CircularProgress />
                  ) : (
                    activity.activity.status === ActivityStatus.Closed ?
                      (
                        <Button
                          variant="text"
                          startIcon={
                            <Avatar
                              src={"/assets/img/icons8-checked-checkbox-50.png"}
                              style={{ width: 15, height: 15 }}
                            />
                          }
                          className={classes.leftAlign}
                        >
                          <Typography
                            component="span"
                            variant="h5"
                            color={"textSecondary"}
                          >
                            Klaar
                          </Typography>
                        </Button>
                      ) :
                      (
                        <Button
                          variant="text"
                          startIcon={
                            <Avatar
                              src={"/assets/img/icons8-checked-checkbox-OFF.png"}
                              style={{ width: 15, height: 15 }}
                            />
                          }
                          className={classes.leftAlign}
                          onClick={markAsDoneMatchRequestAction}
                        >
                          <Typography
                            component="span"
                            variant="h5"
                            color={"textSecondary"}
                          >
                            Klaar
                          </Typography>
                        </Button>
                      )
                  )}
                </Grid>
              </Grid>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
