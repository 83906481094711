
const InvitePeopleToEventStyle = (theme) => ({
    submit: {
        marginBottom: 20,
        color: "#fff",
        fontSize: 13,
        padding: "6px 0 6px 0",
    },
    mt7: {
        marginTop: "7px",
    },
    mt10: {
        marginTop: "10px",
    },
    mt15: {
        marginTop: "15px",
    },
    mt20: {
        marginTop: "20px",
    },
    mt30: {
        marginTop: "30px",
    },
    closeicon: {
        float: 'right',
        fontSize: 32,
        color: "#bdbdbd",
    },
    p7: {
        marginTop: 7,
    },
    redLine: {
        // color: '#ff3366',
        marginTop: 10,
    },
    select: {
        width: "100%"
    }
});
export default InvitePeopleToEventStyle;
