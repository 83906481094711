import React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import { CssBaseline, Hidden } from "@material-ui/core";
import Navigationbar from "../../components/Navigationbar";
import Header from "../../components/Header";
import { Box } from "@mui/system";
import { useHistory } from "react-router";
import { Alert, Skeleton } from "@mui/material";
import { useMutation } from "@apollo/client";

import { SAVE_NEW_CIRCLE } from "../../apiServices/mutations";
import ActivityMultipleUserSearch from "../../components/Autocomplete/ActivityMultipleUserSearch";
import { GetUserId } from "../../apiServices/CommonMethods";
import { FormattedMessage } from "react-intl";
import Styles from "../../styles/container/Circle/AddNewCircleStyle";
import SuccessAlertWithAction from "../../components/Alert/SuccessAlertWithAction";

const useStyles = makeStyles(Styles);

export default function AddNewCircle() {
  const userId = GetUserId();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [checked, setChecked] = React.useState([true, false]);

  const [SubmitCircle, { data, loading, error }] = useMutation(SAVE_NEW_CIRCLE);
  let [input, setInput] = React.useState({
    users: [],
    name: "",
    type: 1,
  });
  let [inputError, setInputError] = React.useState({
    type: "",
    userError: "",
    nameError: "",
  });

  const updateTextFileds = (id, value) => {
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, event.target.checked ? false : true]);
  };

  const handleChange3 = (event) => {
    setChecked([event.target.checked ? false : true, event.target.checked]);
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    updateTextFileds(id, value);
  };
  const setMembers = () => {
    const arrayMembers = input["users"].map((user) => ({
      userId: user.userId,
      role: "Moderator",
    }));
    // console.log("arrayMembers", arrayMembers);
    return arrayMembers;
  };

  const SubmitNewCircleAction = async () => {
    let isValid = await checkForErrors();
    const variable = {
      name: input["name"].trim(),
      circleTypeId: checked[0] ? "1" : "2",
      members: setMembers(),
      loggedinUser: userId,
    };
    // console.log("Variable :", variable);
    if (isValid) {
      SubmitCircle({
        variables: variable,
      });
    }
  };
  const checkForErrors = async () => {
    // console.log("input", input);
    setInputError((prevState) => ({
      ...prevState,
      name: !input["name"].trim() ? "nameError" : "",
      users: !input["users"].length ? "userError" : "",
    }));
    return input["name"].trim() && input["users"].length;
  };
  const searchAction = (searchUser) => {
    // console.log("searchUser", searchUser);
    setInput((prevState) => ({
      ...prevState,
      users: searchUser,
    }));
    setInputError((prevState) => ({
      ...prevState,
      users: "",
    }));
  };

  const handleSucessAlert = () => {
    // history.push("/dashboard/circleDetails", {
    //   circleDetails: { ...data.saveCircle.result },
    //   selectedTab: 7,
    // });
    history.push("/dashboard/circleDetails/?id=" + data.saveCircle.result.id, {
      circleDetails: { ...data.saveCircle.result },
      selectedTab: 7,
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1, mt: theme.spacing(2), mb: theme.spacing(4) }}>
          <Grid container spacing={4}>
            <Grid item sm={12} xs={12}>
              {typeof data !== "undefined" &&
                data.saveCircle.errorCode === 0 ? (
                // <SuccessAlertDialog
                //   message={
                //     <FormattedMessage id={"CircleCreatedSuccessfully"} />
                //   }
                //   open={true}
                // />
                <SuccessAlertWithAction
                  message={
                    <FormattedMessage id={"CircleCreatedSuccessfully"} />
                  }
                  handleClose={handleSucessAlert}
                  open={true}
                />
              ) : null}
              {error ? (
                <Alert severity={"error"}>
                  {<FormattedMessage id={"somethingWentWrong"} />}
                </Alert>
              ) : null}
            </Grid>
            <Grid item sm={10} xs={10}>
              <Typography component="h1" variant="h1" color={"textPrimary"}>
                <FormattedMessage id={"CreateANewCircle"} />
              </Typography>
            </Grid>
            <Grid item sm={2} xs={2}>
              <CloseIcon
                className={classes.closeicon}
                onClick={() => history.goBack()}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"Basic_Master_Circle"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textSecondary"}
                className={classes.mt7}
              >
                {loading ? (
                  <Skeleton variant="rectangular" height={80} />
                ) : (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={"Basic"}
                          checked={checked[0]}
                          onChange={handleChange2}
                        />
                      }
                      label={<FormattedMessage id={"Basic"} />}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={"Master"}
                          checked={checked[1]}
                          onChange={handleChange3}
                        />
                      }
                      label={<FormattedMessage id={"Master"} />}
                    />
                  </FormGroup>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"AddNewCircle_NameCircle"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      // label="Type here"
                      variant="filled"
                      size="small"
                      id={"name"}
                      value={input["name"]}
                      inputProps={{ maxLength: 50 }}
                      onChange={handleChange}
                    />
                  )}
                  {inputError["name"] ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"PleaseEnterTheCircleName"} />
                    </Typography>
                  ) : null}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"AddNewCircle_ToWho"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textSecondary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"AddNewCircle_Description"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <ActivityMultipleUserSearch onSearch={searchAction} />
                  )}
                  {inputError["users"] ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"pleaseEnterTheUserName"} />
                    </Typography>
                  ) : null}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item sm={3} xs={12}>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <ButtonDarkBlue onClick={SubmitNewCircleAction}>
                  <FormattedMessage id={"Button_Text_Create"} />
                </ButtonDarkBlue>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
