import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Styles from "../../styles/component/ProfileTabs/InviteTabStyle";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PROFILE_INVITATION_LISTS } from "../../apiServices/Queries";
import { Autocomplete, Button, Skeleton, Stack, TextareaAutosize, ThemeProvider } from "@mui/material";
import {
  Chip,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import {
  SAVEPROFILE_INVITE,
  DELETE_PROFILE_INVITE,
} from "../../apiServices/mutations";
import { isEmail } from "../General";
import { SHARE_PROFILE_URL } from "../../Config";
import { userRoles } from "../../apiServices/Constants";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";
import { useCurrentSubscription } from "../../apiServices/Queries/GetCurrentSubscription";
import InviteShareButtons from "../Generic/InviteShareButtons";
import { useHasExtendedTrial } from "../../apiServices/Queries/HasExtendedTrial";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";

const useStyles = makeStyles(Styles);

export default function InviteTab(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const intl = useIntl();
  const classes = useStyles();
  const [email       , setEmail       ] = React.useState([]);
  const [input       , setInput       ] = React.useState("");
  const [message     , setMessage     ] = React.useState("");
  const [inviteList  , setInviteList  ] = React.useState([]);
  const [inputError  , setInputError  ] = React.useState(false);
  const [isEmailEmpty, setIsEmailEmpty] = React.useState(false);
  const [isEmailError, setIsEmailError] = React.useState(false);
  const [page        , setPage        ] = useState(0);
  const pageSize = 10;
  const [extendedTrial , loadingTrial      , trialError] = useHasExtendedTrial();
  const [shareUrl, setShareUrl] = React.useState(SHARE_PROFILE_URL);

  const { loading, refetch } = useQuery(
    GET_PROFILE_INVITATION_LISTS,
    {
      variables: {
        requestedUserId: userId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (
          typeof data !== "undefined" &&
          data.profileInvitationList.errorCode === 0
        ) {
          // console.log("data.profileInvitationList", data.profileInvitationList)
          const response = data.profileInvitationList.result;
          setInviteList(response);
        }
      },
    }
  );

  React.useEffect(() => {
    if (typeof props.profileInfo !== "undefined") {
      setShareUrl(SHARE_PROFILE_URL + props.profileInfo.userId);
    }
  }, [props]);

  const onChange = (event, newvalue) => {
    setIsEmailEmpty(false);
    setInputError(false);
    setEmail(newvalue);
  };

  const updateTextFileds = (event) => {
    setInput(event.target.value);
  };

  const [saveInvite, {data: saveinvitedata, loading: saveinviteloading}] = 
  useMutation(SAVEPROFILE_INVITE, {
    onCompleted: (data) => {
      // console.log("sucess", data);
      if (
        typeof data !== "undefined" &&
        data.saveProfileInvite.errorCode === 0
      ) {
        // console.log("sucess");
        setEmail([]);
        setIsEmailError(false);
      }
      if (
        typeof data !== "undefined" &&
        data.saveProfileInvite.errorCode === -1
      ) {
        // console.log("error");
        setEmail([]);
        setIsEmailError(true);
      }
      refetch();
    },
  });

  const [deleteInvite] = useMutation(DELETE_PROFILE_INVITE, {
    onCompleted: (data) => {
      // console.log(data);
      if (
        typeof data !== "undefined" &&
        data.deleteInvitation.errorCode === 0
      ) {
        // console.log("sucess", data);
        refetch();
      }
    },
  });

  const sendInvite = () => {
    const isEmailEmpty = email.length === 0 ? true : false;
    setIsEmailEmpty(isEmailEmpty);
    if (!isEmailEmpty) {
      const emailFilter = email.map((option) =>
        !isEmail(option) ? "notvalidemail" : ""
      );
      let result = emailFilter.includes("notvalidemail");
      const isValidemail = result ? true : false;
      setInputError(isValidemail);
      if (isValidemail === false) {
        setIsEmailError(false);
        const variables = {
          invitedMembers: email,
          requestedUserId: userId,
          message: message
        };
        // console.log("saveInviteVariables", variables);
        saveInvite({
          variables: variables,
        });
      }
    } else {
      setIsEmailError(false);
    }
  };
  const deleteInvitations = (data) => {
    const variables = {
      id: data.id,
      userID: data.invitedMembers[0].userId,
      requestedUserId: data.requestedUserId,
    };
    // console.log("saveInviteVariables", variables);
    deleteInvite({
      variables: variables,
    });
  };

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Container component="main" maxWidth="sm" className={classes.container}>
      <div className={classes.root}>
        {!loadingTrial && <React.Fragment>
          <Text item xs={12} variant="h1"    id={"ProfileInvite_intro_title"       + (extendedTrial ? "2":"")} bold/>
          <Text item xs={12} variant="body1" id={"ProfileInvite_intro_description" + (extendedTrial ? "2":"")} mb={8} mt={4}/>
        </React.Fragment>}

        <Text item xs={12} variant="h1"    bold   id="Profile/Invite.Title2" mb={8}/>
        <InviteShareButtons profile shareContent="" shareUrl={shareUrl} />

        <Grid container spacing={4} mt={10}>
          {/* email */}
          <Text item xs={12} variant="h1"    id="Profile/Invite.MailTitle" bold/>
          <Grid item sm={12} >
            <Text variant="h2" bold>
              {userRole === userRoles.admin ? <FormattedMessage id={"ProfileInvite_Admin_title"} /> :
                <FormattedMessage id={"ProfileInvite_title"} />}
            </Text>
            <Text variant="body" mt={2} id={"Invite_Description"} />
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography
              component="h3"
              variant="h3"
              color={"textPrimary"}
              className={classes.p7}
            >
              {saveinviteloading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <FormControl variant="filled" className={classes.Select}>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="emailIds"
                    autoSelect={true}
                    onChange={onChange}
                    filterOptions={(x) => x}
                    disableClearable
                    options={[]}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          size="small"
                          className={classes.chip}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        label={
                          <FormattedMessage
                            id={"Profile_Invite_Placeholder"}
                          />
                        }
                        {...params}
                        variant="outlined"
                        onChange={updateTextFileds}
                        value={input}
                        size={"small"}
                        InputProps={{
                          ...params.InputProps,
                          type: "input",
                        }}
                      />
                    )}
                  />

                  {isEmailError ? (
                    <FormHelperText>
                      {" "}
                      <Typography
                        variant="h5"
                        component="span"
                        color="error"
                        className={classes.mt6}
                      >
                        {saveinvitedata.saveProfileInvite.message}
                      </Typography>{" "}
                    </FormHelperText>
                  ) : null}
                  {isEmailEmpty ? (
                    <FormHelperText>
                      {" "} <Text variant="body2" color="red" id="invite_email_error" />{" "}
                    </FormHelperText>
                  ) : null}
                  {inputError ? (
                    <FormHelperText>
                      {" "}<Text variant="body2" color="red" id="invite_email_valid_error" />{" "}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={4}>
            <Typography component="h3" variant="h3" className={classes.ml20}>
              {saveinviteloading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <ButtonDarkBlue onClick={sendInvite}>
                  <FormattedMessage id={"Button_text_Send"} />
                </ButtonDarkBlue>
              )}
            </Typography>
          </Grid>
          
          {/* message */}
          <Text item xs={12} variant="h2" bold id={"OnboardingInvite.message.label"} />
          <Text item xs={12} variant="body1" id="Profile/Invite.MailDescription" mt={-2}/>
          <FormControl style={{padding: 10}} className={classes.Select}>
            <TextareaAutosize
              maxLength   = {300}
              minRows     = {4}
              style       = {{padding: 10, background: "none"}}
              placeholder = {intl.formatMessage({id: "Message"})}
              onChange    = {(value) => setMessage(value.target.value)}
              value       = {message}
            />
          </FormControl>
        </Grid>

        {loading && !inviteList.length ? (
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              alignItems: "center",
              flex: "row",
              justifyContent: "center",
              width: "100%",
              height: 300,
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <CircularProgress />{" "}
            </Stack>
          </Box>
        ) :
            inviteList.length > 0 ? 
            <Text item xs={12} variant="body1" bold id="Profile/Invite.Invites"/> 
            && (inviteList.map((data, index) => (
              index >= page*pageSize && index < (page+1)*pageSize && <Grid container spacing={4} className={classes.p20}>
                <Grid item sm={7} xs={6}>
                  <Text component="body1" noWrap>
                    {data.invitedMembers[0].userId}
                  </Text>
                </Grid>
                <Grid item sm={3} xs={4}>
                  {data.invitedMembers[0].inviteStatus === "PENDING" ? (
                    <Text variant="body1" id="InviteTab_Pending" />
                  ) : (
                    ""
                  )}
                  {data.invitedMembers[0].inviteStatus === "ACCEPTED" ? (
                    <Typography variant="body1" id="InviteTab_Accepted" />
                  ) : (
                    ""
                  )}
                </Grid>
                {data.invitedMembers[0].inviteStatus === "PENDING" ? (
                  <Grid item sm={2} xs={2}>
                    <Typography color={"textSecondary"}>
                      <CloseIcon
                        className={classes.icoClose}
                        onClick={() => deleteInvitations(data)}
                      />
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            ))
          ) : (
            ""
          )}
          {inviteList.length > 0 &&
          <Grid xs={12} my={4} justifyContent={"space-between"} container>
            <Button disabled={page == 0} onClick={() => setPage(page - 1)}>Vorige</Button>
            <Button>Pagina {page + 1}/{Math.floor(inviteList.length/pageSize) + 1}</Button>
            <Button disabled={(page+1)*pageSize >= inviteList.length} onClick={() => setPage(page + 1)}>Volgende</Button>
          </Grid>
          }
      </div>
    </Container>
    </ThemeProvider>
  );
}
