import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Styles from "../../../styles/container/ActivityPageStyle";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import {
  CIRCLE_INVITE_ACCEPT,
  CIRCLE_INVITE_DECLINE,
  MARK_AS_DONE_MATCH_REQUEST,
} from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { ActivityStatus } from "../../../apiServices/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import UserInfo from "./components/UserInfo";
import { ButtonDarkBlue } from "../../Buttons/ButtonDarkBlue";
import { useGlobalContext } from "../../../apiServices/Providers/AppGlobalStateProvider";
import { ClearCache } from "../../../apiServices/CommonMethods";

const useStyles = makeStyles(Styles);

export default function ActivityCircleInvite(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const [activity, setActivity] = React.useState({});

  const [acceptInvitation, { data: dataAccept }] =
    useMutation(CIRCLE_INVITE_ACCEPT);
  const [declineInvitation, { data: dataDecline }] =
    useMutation(CIRCLE_INVITE_DECLINE);
  const [MarkAsDone, { data: dataMarkAsDone, loading: loadingMarkAsDone }] =
    useMutation(MARK_AS_DONE_MATCH_REQUEST);

  useEffect(() => {
    if (typeof props.activity !== "undefined") {
      setActivity(props.activity);
    }
    if (
      typeof dataMarkAsDone !== "undefined" ||
      typeof dataAccept !== "undefined" ||
      typeof dataDecline !== "undefined"
    ) {
      props.onRefresh();
    }
  }, [props, dataMarkAsDone, dataAccept, dataDecline]);

  const acceptInvitationAction = (meeting) => {
    ClearCache("circle");
    ClearCache("allCircles");
    const variables = {
      activityId: activity.activity.id,
      loginUserId: userId,
    };
    acceptInvitation({
      variables: variables,
    });
  };
  const declineInvitationAction = () => {
    const variables = {
      activityId: activity.activity.id,
      loginUserId: userId,
    };
    declineInvitation({
      variables: variables,
    });
  };
  const markAsDoneMatchRequestAction = () => {
    if (activity.activity.status !== ActivityStatus.Closed) {
      const variables = {
        activityType: activity.activity.activityTypeDesc,
        activityId: activity.activity.id,
      };
      MarkAsDone({
        variables: variables,
      });
    }
  };

  const navigateToProfile = () => {
    if (activity.showProfileBasedOnPreference !== true) {
      props.onShowPreferenceWarning()
    } else if (activity.activity.userRole !== 1) {
      history.push("/dashboard/profile/", {
        selectedUserId: activity.userId,
      });
    }
  };
  const navigateToCircleDetail = () => {
    // history.push("/dashboard/circleDetails", {
    //   selectedCircleId: activity.activity.circleId,
    // });
    history.push("/dashboard/circleDetails/?id=" + activity.activity.circleId, {
      selectedCircleId: activity.activity.circleId,
    });
  };

  const checkCurrentUser = () => {
    return (
      activity.activity && activity.activity.requesterUserProfileId !== userId
    );
  };

  return (
    <Grid container spacing={4} className={classes.mt15}>
      {!checkCurrentUser() && <Grid item container xs={0} sm={1} md={3} spacing={2}></Grid>}
      <Grid item container xs={12} sm={11} md={9} spacing={2}>
        <UserInfo sent={checkCurrentUser()} activity={activity} onClick={navigateToProfile} activityType={"CIRCLE_INVITE"}/>
        <Grid item container xs={12} sm={12}>
          <div className={checkCurrentUser() ? classes.tabcontentInner : classes.cardSent}>
            {typeof activity.activity !== "undefined" ? (
              <Typography
                variant="h3"
                color={"textSecondary"}
                className={classes.p3}
              >
                {activity.activity.activitySource === "MADE_YOU_CIRCLE_MANAGER" ?
                  <FormattedMessage id={"MadeYouCircleManagerOfTheCircle"} /> :
                  <FormattedMessage id={"InvitationToTheCircle1"} />}
                {" "}
                {activity.activity.status === ActivityStatus.Closed ? (
                  <span className={classes.circleName}>
                    {activity.activity.circleName}
                  </span>
                ) : (
                  <Link
                    component={"button"}
                    underline={"always"}
                    onClick={navigateToCircleDetail}
                  >
                    {activity.activity.circleName}
                  </Link>
                )}
                {activity.activity.activitySource === "MADE_YOU_CIRCLE_MANAGER" ? "." :
                  <FormattedMessage id={"InvitationToTheCircle2"} />}
              </Typography>
            ) : (
              ""
            )}
            {checkCurrentUser() ? (
              <Grid item xs={12} sm={12}>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Grid item xs={12} sm={12} className={classes.rightAlign}>
                  {loadingMarkAsDone ? (
                    <CircularProgress />
                  ) : (
                    activity.activity &&
                    (activity.activity.status === ActivityStatus.activityInvitationAccepted ||
                      activity.activity.status === ActivityStatus.activityInvitationDeclined ||
                      activity.activity.status === ActivityStatus.Closed || activity.activity.isActionNeeded === false) &&
                    (activity.activity.status === ActivityStatus.Closed ?
                      (
                        <Button
                          variant="text"
                          startIcon={
                            <Avatar
                              src={"/assets/img/icons8-checked-checkbox-50.png"}
                              style={{ width: 15, height: 15 }}
                            />
                          }
                          className={classes.leftAlign}
                        >
                          <Typography
                            component="span"
                            variant="h5"
                            color={"textSecondary"}
                          >
                            Klaar
                          </Typography>
                        </Button>
                      ) :
                      (
                        <Button
                          variant="text"
                          startIcon={
                            <Avatar
                              src={"/assets/img/icons8-checked-checkbox-OFF.png"}
                              style={{ width: 15, height: 15 }}
                            />
                          }
                          className={classes.leftAlign}
                          onClick={markAsDoneMatchRequestAction}
                        >
                          <Typography
                            component="span"
                            variant="h5"
                            color={"textSecondary"}
                          >
                            Klaar
                          </Typography>
                        </Button>
                      )
                    ))}
                  {activity.activity.status !== ActivityStatus.Closed &&
                    activity.activity.status !==
                    ActivityStatus.activityInvitationAccepted &&
                    activity.activity.status !==
                    ActivityStatus.activityInvitationDeclined &&
                    activity.activity.isActionNeeded === true ? (
                      <Grid container spacing={2}>
                        <Grid item>
                        <ButtonDarkBlue onClick={acceptInvitationAction}>
                          <FormattedMessage id={"EventVisitors_MenuItem_Accept"} />
                        </ButtonDarkBlue>
                        </Grid>
                        <Grid item>
                        <ButtonDarkBlue onClick={declineInvitationAction}>
                          <FormattedMessage id={"EventVisitors_MenuItem_Decline"} />
                        </ButtonDarkBlue>
                        </Grid>
                      </Grid>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
