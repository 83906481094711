export default function getMatchProfile(
    intl,
    introduction,
    who,
    what,
    forWhom,
    extra,
    why
) {
    if(introduction) {
        return (
        intl.formatMessage({id: "IntroductionProfile1"}, {who: who})
        + (forWhom ? intl.formatMessage({id: "IntroductionProfile2"}, {forWhom: forWhom}) : "")
        + intl.formatMessage({id: "IntroductionProfile3"}, {what: what})
        + (why   ? intl.formatMessage({id: "IntroductionProfile5"}, {why: why})     : ""))
        + (extra ? intl.formatMessage({id: "IntroductionProfile4"}, {extra: extra})   : "");
    } else {
        return (
            intl.formatMessage({id: "RequestProfile1"}, {who: who})
            + intl.formatMessage({id: "RequestProfile2"}, {what: what})
            + (why   ? intl.formatMessage({id: "RequestProfile4"}, {why: why})     : ""));
    }
}

export const suggestionsShouldBeGenerated = new Date().getHours()  < 16 //before 15:00

export const canEditMatchProfiles = new Date().getHours()  < 15 //before 15:00
export const isItMatchTime        = new Date().getDay  () ==  1 //monday

export const areSwipesPending   = (meta) =>  meta?.pendingSwipes  > 0;
export const isIAmMakingMatches = (meta) =>  meta?.pendingSwipes == 0 && meta?.swipedToday == 0 && suggestionsShouldBeGenerated;
export const didIAmFindMatches  = (meta) =>  meta?.pendingSwipes  > 0 || meta?.swipedToday  > 0;
export const doneSwiping        = (meta) =>  meta?.pendingSwipes == 0 && meta?.swipedToday  > 0;