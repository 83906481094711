import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_CIRCLE_MEMBERS } from '../apiServices/Queries';
import { CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useGlobalContext } from '../apiServices/Providers/AppGlobalStateProvider';

export default function CircleAutosearchPage(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const [searchText, setSearchText] = React.useState("");
  const [value, setValue] = React.useState('');
  const [searchdata, setSearchdata] = React.useState('');
  const [circledata, setCircleData] = React.useState({});

  const getVariable = () => {
    const variable = {
      circleId: circledata.id,
      userId: userId,
      pageNumber: 1,
      pageSize: parseInt(100),
      searchInput: searchText,
      skills: null,
      industries: null,
      collaborationType: null,
      businessTypes: null,
      location: null,
      maxDistance: null
    }
    return variable
  }

  const { loading, fetchMore } = useQuery(GET_CIRCLE_MEMBERS, {
    variables: getVariable(),
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (typeof data !== "undefined" && data.circleMembers.errorCode === 0) {
        const response = data.circleMembers.result;

        if (circledata.loggedInUserRole !== "Moderator") {
          // console.log("circleRole search notmoderator");
          let circleList = response.filter(list => {
            return list.isMember === true;
          });
          setSearchdata(circleList);

        } else {
          // console.log("circleRole search moderator");
          setSearchdata(response);
        }

      } else {
        setSearchdata([])
      }

    }
  });

  useEffect(() => {
    if (typeof props.circleDetails !== "undefined") {
      setCircleData(props.circleDetails)
    }
  }, [searchdata, props]);

  const updateTextFileds = (event) => {
    setSearchText(event.target.value)
    fetchMore({
      variables: {
        searchInput: event.target.value,
      },
    })

    if (!event.target.value.length) {
      if (typeof props.onUserSelection === "function") {
        props.onUserSelection("")
        setValue("")
      }
    }
  }
  const onFocus = () => {
    if (typeof props.onFocus === "function") {
      props.onFocus();
    }
  }
  const onChange = (event, newvalue) => {
    setValue(newvalue)
    if (searchText.length) {
      const arryayMaster = searchdata.filter(master => {
        return (master.givenName + " " + master.familyName) === newvalue;
      });
      // console.log("arryayMaster", arryayMaster)
      if (typeof props.onUserSelection === "function") {
        // console.log("onUserSelection", newvalue)
        props.onUserSelection(newvalue)
      }
    }
  };
  const getupdatedList = () => {
    const arrayFiltered = searchdata.filter((item) => {
      return item.givenName !== null;
    });
    return arrayFiltered;
  };
  return (
    <Autocomplete
      freeSolo
      value={value}
      size={"small"}
      id="free-solo-2-demo"
      onChange={onChange}
      onFocus={onFocus}
      filterOptions={(x) => x}
      disableClearable
      options={

        typeof searchdata !== "undefined" && searchText.length ? getupdatedList().map((option, index) => (
          option.givenName + " " + option.familyName
        )) : []

      }
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={updateTextFileds}
          value={searchText}
          placeholder={typeof props.placeHolder !== "undefined" ? props.placeHolder : "Search"}
          InputLabelProps={{
            style: {
              fontSize: 14,
              fontFamily: 'Montserrat', //h2
              fontWeight: 800,
            }
          }}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            style: {
              fontSize: 14,
              fontFamily: 'Montserrat', //h2
              fontWeight: 800,
            },
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : !searchText.length ? <SearchIcon /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
CircleAutosearchPage.prototype = {
  onSearch: PropTypes.func,
};
