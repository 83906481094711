import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import AES from "crypto-js/aes";
import MD5 from "crypto-js/md5";
import * as CryptoJS from 'crypto-js';
import { useGlobalContext } from "../Providers/AppGlobalStateProvider";
import { Environment } from "../../Config";
import { ClearCache } from "../CommonMethods";

export function useCachedQuery(
    id,
    query,
    variables_,
    dataField       = () => {},
    expiryInMinutes, 
    storeLocal      = true, //store in localstorage or in memory?
    fetchPolicy     = "cache-and-network", 
    nextFetchPolicy = "cache-and-network", 
    onComplete      = () => {},
) {
    const dev             = Environment.toLowerCase() != "production";
    const globals         = useGlobalContext();
    const [variables, setVariables] = useState(variables_)
    const now                   = new Date();
    const hash                  = "cache_"+id+"_"+MD5(JSON.stringify(query) + JSON.stringify(variables));

    //only use encryption in production to prevent people messing with this. Don't encrypt in dev. we like debugging
    const encrypt = (string) => dev? string : AES.encrypt(string, hash).toString();
    const decrypt = (string) => dev? string : AES.decrypt(string, hash).toString(CryptoJS.enc.Utf8);

    const cacheEntry      = () => globals.state?.cache?.get?.find(c => c.hash == hash);
    const updateCache     = (newEntry) => globals.state.cache.set(
        !globals.state.cache.get.find(c => c.hash == hash) ?
        globals.state.cache.get.concat(newEntry) :
        globals.state.cache.get.map(c => c.hash == hash ? newEntry : c));

    const saved                 = () => localStorage.getItem(hash) ? JSON.parse(decrypt(localStorage.getItem(hash))) : undefined;
    const expiration            =       storeLocal ? saved()?.expiration : cacheEntry()?.expires ?? now;
    const getCached             = () => storeLocal ? saved()?.data       : cacheEntry()?.data;
    const [result, setResult]   = useState(getCached);

    const [runQuery, {loading, error, refetch}] = useLazyQuery(query, {
        notifyOnNetworkStatusChange : true,
        fetchPolicy                 : fetchPolicy,
        nextFetchPolicy             : nextFetchPolicy,
        onCompleted                 : (data) => {
            var r   = dataField(data);
            var exp = new Date().getTime() + expiryInMinutes * 60000;
            var oldEntry = cacheEntry();
            var newEntry = {...oldEntry, hash: hash, data: r, expiration: exp, queried: (oldEntry?.queried ?? 0) + 1};

            onComplete  (r);
            if(storeLocal)
                r == undefined? localStorage.removeItem(hash) : //ensurance. Remove this if it causes more harm than good.
                localStorage.setItem(hash, encrypt(JSON.stringify({data: r, expiration: exp})));
            else //use else to save on memory, but in theory it is not needed
                updateCache (newEntry);
            setResult   (r);
        }
    });

    useEffect(() => {
        var c       = getCached();
        var expired = (expiration - now < 0);
        // if(expired)
        //     ClearCache(hash);   //clean up code. not sure when this would be useful but i feel like it might come in handy
        if(!c || expired) { //todo: only honor the expiration if connected to the internet. If not connected we should display a warning and rely on cache for everything
            runQuery({variables: {...variables}});
        } else if(c)
            setResult(c);
    }, [variables]);

    const dirtyRefetch = (v) => runQuery({variables: v ?? variables});

    return {data: result, loading: loading, error: (error != undefined), updateVariables: setVariables, hash: hash, refetch: dirtyRefetch};
  }
  