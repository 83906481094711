import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@material-ui/core/Grid";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import SliderPage from "../SliderPage";
import StartDatePicker from "../DatePicker/StartDatePicker";
import LocationAutoComplete from "../Autocomplete/LocationAutoComplete";
import { Box } from "@mui/system";
import {
  masterDataCircleOperatesIn,
  masterDataEventTags,
} from "../../apiServices/Constants";
import { removeItemOnce } from "../../apiServices/CommonMethods";
import { FormControl } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import CircleInfoStyle from "../../styles/component/Circles/CircleInfoStyle";

const useStyles = makeStyles(CircleInfoStyle);

export default function EventFilter(props) {
  const classes = useStyles();
  const intl = useIntl();

  let [input, setInput] = React.useState({
    location: null,
    maxDistance: null,
    eventTags: null,
    operatesIn: null,
    pageNumber: 1,
    title: null,
    startDate: null
  });
  const onSliderChange = (value) => {
    // console.log("sliderValue", value);
    setInput((prevState) => ({
      ...prevState,
      maxDistance: value,
    }));
  };

  const onLocationChange = (location) => {
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        location:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
    } else {
      setInput((prevState) => ({
        ...prevState,
        location: null,
        maxDistance: null
      }));
    }
  };
  const onSearch = (event) => {
    // console.log("selcetd items", input);
    props.onSearch(input);
  };
  const onEventTagSelection = (event) => {
    var newTags = input["eventTags"] ?? [];
    if (event.target.checked) {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        let tagsAll = masterDataEventTags().map(({ key }) => key);
        newTags = tagsAll;
      } else if (!newTags.includes(event.target.name)) {
        newTags.push(event.target.name);
      }
    } else {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        newTags = [];
      } else {
        removeItemOnce(newTags, event.target.name);
      }
    }
    // console.log("newTags", newTags);
    setInput((prevState) => ({
      ...prevState,
      eventTags: newTags,
    }));
  };
  const onEventOperatesInSelection = (event) => {
    var newOperatesIn = input["operatesIn"] ?? [];
    if (event.target.checked) {
      if (!newOperatesIn.includes(event.target.name)) {
        newOperatesIn.push(event.target.name);
      }
    } else {
      removeItemOnce(newOperatesIn, event.target.name);
    }
    // console.log("newOperatesIn", newOperatesIn);
    setInput((prevState) => ({
      ...prevState,
      operatesIn: newOperatesIn,
    }));
  };
  const isCheckedTag = (option) => {
    const arrayEventTags = input["eventTags"] ?? [];
    if (arrayEventTags.includes(option)) {
      return true;
    }
    return false;
  };

  const isCheckedAllTag = () => {
    const arrayEventTags = input["eventTags"] ?? [];
    if (arrayEventTags.length === masterDataEventTags().length) {
      return true;
    }
    return false;
  };
  const isCheckedOperatesIn = (option) => {
    const arrayOperatesIn = input["operatesIn"] ?? [];
    if (arrayOperatesIn.includes(option)) {
      return true;
    }
    return false;
  };

  const onEventDateSelection = (dates) => {
    // console.log("daye", dates);
    var date = new Date(dates)
    var finaldate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'Z'
    // console.log("vvv", finaldate);
    setInput((prevState) => ({
      ...prevState,
      startDate: finaldate,
    }));
  };
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <FormattedMessage id={"EventPage_FilterItem_Text"} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <Typography variant="h6" color={"textSecondary"}>
              <FormControlLabel
                control={<Checkbox checked={isCheckedAllTag()} />}
                label={intl.formatMessage({ id: "All" })}
                onChange={onEventTagSelection}
                name={intl.formatMessage({ id: "All" })}
              />
            </Typography>
            {masterDataEventTags().map((option, index) => (
              <Typography variant="h6" color={"textSecondary"} key={index}>
                <FormControlLabel
                  control={<Checkbox checked={isCheckedTag(option.key)} />}
                  label={option.key}
                  onChange={onEventTagSelection}
                  name={option.key}
                />
              </Typography>
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.noAccordian}>
        <FormGroup>
          {masterDataCircleOperatesIn().map((option, index) => (
            <Typography variant="h6" color={"textSecondary"} key={index}>
              <FormControlLabel
                control={<Checkbox checked={isCheckedOperatesIn(option.key)} />}
                label={option.key}
                onChange={onEventOperatesInSelection}
                name={option.key}
              />
            </Typography>
          ))}
        </FormGroup>
      </Accordion>
      <Accordion className={classes.noAccordian}>
        <Grid item xs={12} sm={12}>
          <Typography
            component="h3"
            variant="h3"
            color={"textPrimary"}
            className={classes.mt7}
            gutterBottom
          >
            <FormControl variant="filled" className={classes.Select}>
              <StartDatePicker
                updateDate={onEventDateSelection}
              //onError={onErrorSuggestion1MeetingDate}
              />
            </FormControl>
          </Typography>
        </Grid>
      </Accordion>
      <Accordion>
        <AccordionDetails>
          <Box sx={{ width: "100%" }}>
            <LocationAutoComplete
              onChange={onLocationChange}
              placeHolder={intl.formatMessage({
                id: "circlePage_FilterItem_Location",
              })}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.noAccordian}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <SliderPage onChange={onSliderChange} disabled={false} position={input.maxDistance} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ButtonDarkBlue onClick={onSearch}>
              <FormattedMessage id={"circlePage_Filter_Button_Text_Search"} />
            </ButtonDarkBlue>
          </Grid>
        </Grid>
      </Accordion>
    </div>
  );
}
