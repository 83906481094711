import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import {
  Button,
  CircularProgress,
  CssBaseline,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import styles from "../../styles/container/CirclesPageStyle";
import { Icon, Stack } from "@mui/material";
import CircleFilter from "../../components/Circles/CircleFilter";
import CircleCard from "../../components/Cards/CircleCard";
import {
  CirclesTabItems,
  CompaniesTabItems,
  masterDataCircleOperatesIn,
  masterDataCircleTags,
} from "../../apiServices/Constants";
import CircleSearch from "../../components/Circles/CircleSearch";
import { useDispatch } from "react-redux";
import { updateBrandColor } from "../../apiServices/Redux/Reducer";
import { START_CIRCLE } from "../../Config";
import { FormattedMessage, useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";
import { Hidden } from "@material-ui/core";
import { userRoles } from "../../apiServices/Constants";
import { useHasCompanyAccess } from "../../apiServices/Queries/Profiles/MetaData";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { useListAllCircles } from "../../apiServices/Queries/Circles/GetUserCircles";
import { onlyUnique } from "../../apiServices/CommonMethods";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";

const useStyles   = makeStyles(styles);
const maxPageSize = 50;

export default function CirclesPage(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const companies = props.companies;
  const tabItems = companies ? CompaniesTabItems : CirclesTabItems;
  const dispatch = useDispatch();
  const companyAccess = useHasCompanyAccess();
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const theme = useTheme();
  const [isShowFilter, setIsShowFilter] = React.useState(false);
  const [allCircles, setAllCircles] = React.useState([]);
  const [circleTabType, setCircleTabType] = React.useState(companies && companyAccess.myCompaniesOnly ? tabItems[1] : tabItems[0]);
  const [searchText, setSearchText] = React.useState("");

  let [input, setInput] = React.useState({
    selectedPlace: null,
    maxDistance: null,
    selectedCircleTags: null,
    selectedOperatesIn: null,
    pageNumber: 1,
    searchInput: "",
  });

  const getCircleTags = () => {
    const arraySelectedTags = input["selectedCircleTags"];
    const array = masterDataCircleTags()
      .filter((item) => {
        return arraySelectedTags.includes(item.key);
      })
      .map((item) => {
        return item.value.toString();
      });
    return array.length ? array : null;
  };
  const getOperatesIn = () => {
    const arraySelectedOperates = input["selectedOperatesIn"];
    const array = masterDataCircleOperatesIn()
      .filter((item) => {
        return arraySelectedOperates.includes(item.key);
      })
      .map((item) => {
        return item.value.toString();
      });
    return array.length ? array.join(",") : null;
  };
  const getVariable = () => ({
      selectedPlace: input["selectedPlace"],
      maxDistance: input["maxDistance"],
      selectedCircleTags: input["selectedCircleTags"] ? getCircleTags() : null,
      selectedOperatesIn: input["selectedOperatesIn"] ? getOperatesIn() : null,
      loggedInUser: userId,
      isPublished: circleTabType.type === "All" ? 1 : null,
      memberId: circleTabType.type === "All" ? null : userId,
      pageSize: maxPageSize,
      pageNumber: input["pageNumber"],
      searchInput: searchText,
      isCompany: companies
    });

  const {data: data, loading: loading, updateVariables: refetch} = useListAllCircles(getVariable(), 60, true);
  useEffect(() => {
    if(!data || !data.length)
      return;
    if (input["pageNumber"] === 1)
      setAllCircles(data);
    else
      setAllCircles((prevState) => [
        ...prevState,
        ...data,
      ]);
  }, [data]);

  useEffect(() => { //deduplication redundancy
    if(loading || !allCircles)
      return;
    let flattened = allCircles.filter(onlyUnique);
    if(flattened.length != allCircles.length)
      setAllCircles(flattened);
  }, [allCircles]);

  useEffect(() => {
    if(!companyAccess.loading && !companyAccess.accessToCompanies && companies) {
      history.push("/");
    }
    refetch(getVariable());
  }, [circleTabType, input]);

  const handleChange = (event, newValue) => {
    setInput((prevState) => ({
      ...prevState,
      pageNumber: 1,
    }));
    let filteredTabItems = tabItems.filter((tabItem) => {
      return tabItem.type === newValue;
    });
    setCircleTabType(filteredTabItems[0]);
  };
  const onSearch = (circle) => {
    setAllCircles([circle]);
  };
  const onSearchTextChanges = (searchText) => {
    setSearchText(searchText);
    if (!searchText.length) {
      setAllCircles([]);
      setInput((prevState) => ({
        ...prevState,
        selectedPlace: null,
        maxDistance: null,
        searchInput: null,
        selectedCircleTags: null,
        selectedOperatesIn: null,
      }));
      // refetch();
    }
  };
  const showFilter = () => {
    setIsShowFilter(!isShowFilter);
  };
  const filterAction = (inputs) => {
    // console.log("inuts", inputs);
    setAllCircles([]);
    setInput(inputs);
    setIsShowFilter(!isShowFilter);
    // refetch();
  };

  const onReset = () => {
    setAllCircles([]);
    setInput((prevState) => ({
      ...prevState,
      selectedPlace: null,
      maxDistance: null,
      searchInput: null,
      selectedCircleTags: null,
      selectedOperatesIn: null,
      pageNumber: 1
    }));
    // refetch();
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container spacing={4} mt={0}>
        <Grid item sm={5} xs={6}>
          <CircleSearch
            companies={props.companies}
            onSearch={onSearch}
            onSearchTextChanges={onSearchTextChanges}
            circleTabType={circleTabType.type}
            placeHolder={
              <FormattedMessage
                id={intl.formatMessage({
                  id: companies ? "companyPage_SearchBar_Text" : "circlePage_SearchBar_Text",
                })}
              />
            }
          />
        </Grid>
        {!companies && <Grid item sm={1} xs={1}>
          <FilterAltOutlinedIcon
            className={classes.iconFilter}
            onClick={showFilter}
          />
        </Grid>}
        <Grid item sm={2} xs={2}>
          {(input.selectedCircleTags != null &&
            input.selectedCircleTags.length) ||
            (input.selectedOperatesIn != null &&
              input.selectedOperatesIn.length) ||
            input.selectedPlace != null ||
            input.maxDistance != null ? (
            // <RestartAltIcon
            //   className={classes.iconReset}
            //   onClick={onReset}
            // />
            <Typography
              component="h5"
              variant="h5"
              className={classes.iconReset}
              onClick={onReset}
            >
              <FormattedMessage id={"Resetten"} />
            </Typography>
          ) : null}
        </Grid>
        <Grid item sm={1} xs={userRole === userRoles.admin ? 2 : 1}>
          {userRole === userRoles.admin &&

            <AddIcon
              className={classes.iconAddAdmin}
              onClick={() => history.push("/addNewCircle")}
            />}
        </Grid>

        {!companies && userRole != userRoles.admin && <React.Fragment>
          <Hidden mdUp>
            <Grid item sm={1} xs={2}>
                <Icon
                  sx={{
                    color: theme.palette.secondary.main,
                    fontSize: 40,
                  }}
                  onClick={() => {
                    window.open(START_CIRCLE, "_blank");
                  }}
                >
                  add_circle
                </Icon>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item sm={3} xs={4}>
              <Button
                href={START_CIRCLE}
                target={"_blank"}
                rel="noopener"
                className={classes.buttonLink}
              >
                <FormattedMessage
                  id={"circlePage_Button_Text_StartACircle"}
                />
              </Button>
            </Grid>
          </Hidden>
        </React.Fragment>}

      </Grid>
      {isShowFilter ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <CircleFilter onSearch={filterAction} />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container spacing={4} className={classes.mt5}>
            <Grid item xs={12} sm={12}>
              <Tabs
                value={circleTabType.type}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: { background: theme.palette.color2.main },
                }}
              >
                {tabItems.map((tabItem) => (
                  (!companies || !companyAccess.myCompaniesOnly || tabItem.key == tabItems[1].key) && <Tab
                    key={tabItem.key}
                    value={tabItem.type}
                    label={intl.formatMessage({
                      id: tabItem.type,
                    })}
                    wrapped
                    className={classes.masterTab}
                  />
                ))}
              </Tabs>
            </Grid>
          </Grid>
          <Grid container gap={ThemeConstants.custom.spacings.md} className={classes.mt5}>
            {loading && allCircles.length ? (
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 300,
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <CircularProgress />
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.title}
                  ></Typography>
                </Stack>
              </Box>
            ) : allCircles.length ? (
              allCircles.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    key={index}
                    onClick={() => {
                      // history.push("/dashboard/circleDetails", {
                      //   circleDetails: item,
                      // });
                      history.push("/dashboard/circleDetails/?id=" + item.id);

                      dispatch(
                        updateBrandColor(
                          item.brandColor ?? theme.palette.primary.main
                        )
                      );
                    }}
                  >
                    <CircleCard circleData={item} />
                  </Grid>
                  {index == allCircles.length - 2 && allCircles.length >= maxPageSize && (
                    <Waypoint
                      scrollableAncestor={window}
                      onEnter={() => {
                        // console.log("index", index);
                        const newPage = input["pageNumber"] + 1;
                        setInput((prevState) => ({
                          ...prevState,
                          pageNumber: newPage,
                        }));
                      }}
                    />
                  )}
                </React.Fragment>
              ))
            ) : (
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 300,
                }}
              >
                <Typography
                  component="span"
                  variant="h3"
                  color={"textPrimary"}
                >
                  <FormattedMessage id={"noDataAvailable"} />
                </Typography>
              </Box>
            )}
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}
