import React from "react";
import Button from "@mui/material/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import QRCode from "qrcode.react";
import { Grid, ThemeProvider } from "@mui/material";
import Text from "../../visualComponents/Text";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";

export default function InviteShareButtons(props) {
  const shareUrl     = props.shareUrl ?? "xxx";
  const shareContent = props.shareContent ?? "xxx";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container>

    <Grid container item sm={6} rowGap={4}>
            <Text item xs={12} bold variant="body1" id={props.profile? "ProfileInvite_ScanQRCode" :"EventInvite_ScanQRCode"}/>
            <QRCode
            id="qrCodeId"
            value={shareUrl}
            size={100}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"H"}
            includeMargin={true}
            />
        </Grid>
        <Grid container item sm={6} gap={2}>
            <Text item xs={12} bold variant="body1" id="share" mb={2}/>
            <Button
            variant="contained"
            color="white"
            id="demo-positioned-button"
            aria-controls="demo-positioned-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{ boxShadow: "none" }}
            >
                <Text id={"invite_socialmedia"} p={0.5}/>
            </Button>
            <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            >
            <FacebookShareButton
            url={shareUrl}
            quote={shareContent}
            >
                <MenuItem onClick={handleClose}>
                        <Button>
                            <Text id={"invite_facebook"} />
                        </Button>
                </MenuItem>
            </FacebookShareButton>
            <LinkedinShareButton
            url={shareUrl}
            >
                <MenuItem onClick={handleClose}>
                        <Button>
                            <Text id={"invite_linkedin"} />
                        </Button>
                </MenuItem>
            </LinkedinShareButton>
            {/* <TwitterShareButton
            url={shareUrl}
            title={shareContent}
            >
                <MenuItem onClick={handleClose}>
                        <Button>
                            <Text id={"invite_twitter"} />
                        </Button>
                </MenuItem>
            </TwitterShareButton> */}
            </Menu>
            <WhatsappShareButton url={shareContent + shareUrl}>
                <Button variant="contained" color="white" style={{ boxShadow: "none" }}>
                    <Text id={"invite_whatsapp"} p={0.5}/>
                </Button>
            </WhatsappShareButton>
            {/* <EmailShareButton url={shareContent + shareUrl}>
                <Button variant="contained" color="white" style={{ boxShadow: "none" }}>
                    <Text id={"invite_email"} p={0.5}/>
                </Button>
            </EmailShareButton> */}
            <Button variant="contained" color="white" style={{ boxShadow: "none" }}
                onClick={() => {navigator.clipboard.writeText(shareUrl);}}
            >
                <Text id={"Copy_Link"} />
            </Button>
        </Grid>
    </Grid>
    </ThemeProvider>
  );
}