import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Styles from "../../styles/container/RequestStyle";
import {
  Chip,
  TextField,
} from "@material-ui/core";
import {
  masterDataCommitment,
  masterDataPreferedBenefit,
} from "../../apiServices/Constants";
import { Autocomplete } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Text from "../../visualComponents/Text";

const useStyles = makeStyles(Styles);

export default function BusinessPartnersRequest(props) {
  const classes = useStyles();

  const handleChangeCommitments = (event, newvalue) => {
    props.setInput((prevState) => ({
      ...prevState,
      commitments: newvalue,
    }));

  };
  const handleChangePreferedBenefits = (event, newvalue) => {
    props.setInput((prevState) => ({
      ...prevState,
      benefitType: newvalue,
    }));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"Optional"} />
        <Text variant="h2" id={"RequiredCommitment"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <Autocomplete
                multiple
                id="commitments"
                options={masterDataCommitment().map(({ key }) => key)}
                value={props.input.commitments}
                onChange={handleChangeCommitments}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      size="small"
                      className={classes.chip}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                  />
                )}
              />
          </FormControl>
        </Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"RequiredCompanyRolePartner"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <TextField
                id="companyRole"
                size="small"
                value={props.input.companyRole}
                onChange={props.onChange}
              />
          </FormControl>
        </Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"PreferredBenefits"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <Autocomplete
                multiple
                id="benefitType"
                options={masterDataPreferedBenefit().map(({ key }) => key)}
                value={props.input.benefitType}
                onChange={handleChangePreferedBenefits}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      size="small"
                      className={classes.chip}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // placeholder="Add"
                    variant="outlined"
                  />
                )}
              />
          </FormControl>
        </Text>
      </Grid>
    </Grid>
  );
}
BusinessPartnersRequest.prototype = {
  receivers: PropTypes.array,
  updateStatus: PropTypes.func,
};
