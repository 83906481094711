import Masters from "./containers/Masters";
import Requests from "./containers/Requests";
import AccountSettings from "./containers/AccountSettings";
import Profile from "./containers/Profile";
import Activity from "./containers/ActivityPage";
import Events from "./containers/Events/EventPage";
import EventDetails from "./containers/Events/EventDetails";
import CircleDetails from "./containers/Circles/CircleDetails";
import Circles from "./containers/Circles/CirclesPage";
import AdminExtension from "./containers/AdminExtension";
import TaskTab from "./containers/Dashboard/Admin/TaskTab";
import MatchTimeTab from "./containers/Dashboard/Admin/MatchTimeTab";
import VideoTab from "./containers/Dashboard/Admin/VideoTab";
import RequestsTab from "./containers/Dashboard/Admin/RequestsTab";
import RequestDetails from "./containers/Dashboard/Requests/RequestDetails";
import {ReactComponent as MastersIcon} from "./svg/HeaderNavigationEtc/MastersIcon.svg";
import {ReactComponent as RequestsIcon} from "./svg/HeaderNavigationEtc/RequestsIcon.svg";
import {ReactComponent as TasksIcon} from "./svg/HeaderNavigationEtc/TaskIcon.svg";
import {ReactComponent as EventsIcon} from "./svg/HeaderNavigationEtc/EventsIcon.svg";

const routes = [
  {
    path: "/masters",
    root: "/dashboard",
    name: "Masters",
    displayInDrawer: true,
    localisationId: "Side_menu.Masters",
    component: Masters,
    icon: <MastersIcon/>
  }, {
    path: "/requests",
    root: "/dashboard",
    name: "Requests",
    displayInDrawer: true,
    localisationId: "Side_menu.Requests",
    component: Requests,
    icon: <RequestsIcon/>
  }, {
    path: "/request",
    root: "/dashboard",
    name: "Request",
    displayInDrawer: false,
    localisationId: "Side_menu.Request",
    component: RequestDetails,
  },
  {
    path: "/activity",
    root: "/dashboard",
    name: "Activity",
    displayInDrawer: false,
    localisationId: "Side_menu.Activity",
    component: Activity,
  },
  {
    path: "/todos",
    root: "/dashboard",
    name: "TODOs",
    displayInDrawer: true,
    localisationId: "Side_menu.Activity",
    component: Activity,
    icon: <TasksIcon/>
  },
  {
    path: "/events",
    root: "/dashboard",
    name: "Events",
    displayInDrawer: true,
    localisationId: "Side_menu.Events",
    component: Events,
    icon: <EventsIcon/>
  },
  {
    path: "/companies",
    root: "/dashboard",
    name: "Companies",
    displayInDrawer: false,
    localisationId: "Side_menu.Companies",
    component: () => <Circles companies/>,
  },

  {
    path: "/settings",
    root: "/dashboard",
    name: "Settings",
    displayInDrawer: false,
    component: AccountSettings,
  },
  {
    path: "/profile",
    root: "/dashboard",
    name: "Profile",
    displayInDrawer: false,
    component: Profile,
  },
  {
    path: "/eventDetails",
    root: "/dashboard",
    name: "EventDetails",
    displayInDrawer: false,
    component: EventDetails,
  },
  {
    path: "/circles",
    root: "/dashboard",
    name: "Circles",
    displayInDrawer: false,
    component: Circles,
  },
  {
    path: "/circleDetails",
    root: "/dashboard",
    name: "CircleDetails",
    displayInDrawer: false,
    component: CircleDetails,
  },
  {
    path: "/admin/tasks",
    root: "/dashboard",
    name: "Admin task board",
    displayInDrawer: false,
    localisationId: "Task_menu.Admin",
    component: TaskTab,
  },
  {
    path: "/admin/videos",
    root: "/dashboard",
    name: "Admin video menu",
    displayInDrawer: false,
    localisationId: "Video_menu.Admin",
    component: VideoTab,
  },
  {
    path: "/admin/requests",
    root: "/dashboard",
    name: "Admin request menu",
    displayInDrawer: false,
    localisationId: "Request_menu.Admin",
    component: RequestsTab,
  },
  {
    path: "/admin/matchtime",
    root: "/dashboard",
    name: "Admin match time board",
    displayInDrawer: false,
    localisationId: "Matchtime_menu.Admin",
    component: MatchTimeTab,
  },
  {
    path: "/admin",
    root: "/dashboard",
    name: "Admin",
    displayInDrawer: false,
    localisationId: "Side_menu.Admin",
    component: AdminExtension,
  },

];

export default routes;
