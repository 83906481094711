import React from "react";
import { SELF_SAVE_CIRCLE_INVITE, SELF_SAVE_EVENTS_INVITE, SELF_SAVE_PROFILE_INVITE } from "../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { Grid, ThemeProvider } from '@mui/material';
import EmailInputField from "../Forms/EmailInputField";
import QueryString from "query-string";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import Text from "../../visualComponents/Text";

export default function SelfInviteForm(props) {
    const source = props.source
    const params = QueryString.parse(window.location.search);
    const [savedInvite, setSavedInvite] = React.useState(localStorage.getItem("savedSelfInvite_"+source) != null);
    const [saveInvite, { data, loading }] =
        useMutation(source == "profiles" ? SELF_SAVE_PROFILE_INVITE : (source == "events" ? SELF_SAVE_EVENTS_INVITE : SELF_SAVE_CIRCLE_INVITE), { //todo profile self invite mutation as well
            onCompleted: (data) => {
                setSavedInvite(true);
                localStorage.setItem("savedSelfInvite_"+source, true);
            }});

    const SaveInvite = (email) =>
        saveInvite({
            variables: {
                email: email,
                uuid: params.uuid
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "network-only",
        });

    return (
        <ThemeProvider theme={Theme_WIP}>
            <Background 
            rowGap={6}
            container item
            alignItems="center"
            mb={5}
            ml={0} mr={0}
            color="darkPetrol"
            border="bottomLeft"
            sx={{
                height: "fit-content",
                width: "100%"
            }}>
                <Grid container item rowGap={6} p={5}>
                    <Text 
                    item xs={12}
                    variant="h2" bold color="primary" id={"SelfInviteForm_Title_" + source}/>
                    {!savedInvite? (<EmailInputField onSubmit={SaveInvite}/>) : (
                        <Text item xs={12} color="primary" id={"Invite_Sent"}/>
                    )}
                    <Text item xs={12} color="primary" variant="body2" id="SelfInviteForm_Caption"/>
                </Grid>
            </Background>
        </ThemeProvider>
    );
}