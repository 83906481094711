import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";

export default function MarkAsDoneDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const handleClose = () => {
        setOpen(false);
        props.handleClose()
    };

    const succcessDialogOnClose = () => {
        setOpen(false);
        props.handleMarkAsDone();
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box sx={{ marginLeft: 5, marginRight: 5 }}>
                <DialogTitle id="alert-dialog-title">
                    {"Klaar?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id={"markAsDone_Description"} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus
                        disableElevation
                        variant="contained"
                        style={{
                            backgroundColor: "white",
                            border: "1px solid #bdbdbd",
                            fontSize: 14,
                            fontFamily: 'Montserrat', //h2
                            color: "#828282",
                            textTransform: 'none'
                        }}>
                        <FormattedMessage id={"go_Back"} />
                    </Button>
                    <Box sx={{ marginLeft: 2, width: 100 }}>
                        <ButtonDarkBlue onClick={succcessDialogOnClose} autoFocus>
                            <FormattedMessage id={"Done_Confirm"} />
                        </ButtonDarkBlue>
                    </Box>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
MarkAsDoneDialog.prototype = {
    message: PropTypes.string,
    open: PropTypes.bool,
};
