
const CircleNewsCardStyle = (theme) => ({
    root: {
        width: "100%",
    },
    logosmall: {
        // width: "100%",
        // borderRadius: 6,
        // aspectRatio: 1
        width: "100%",
        borderRadius: 6,
        marginBottom: 10,
        aspectRatio: 1
    },
    typoVarient: {
        color: theme.palette.color5.main,
        paddingTop: 5,
    },
    // tabcontent: {
    //     borderRadius: 4,
    //     width: "100%",
    //     fontWeight: '400',
    //     marginTop: 8,
    //     border: "1px solid #bdbdbd",
    //     padding: "10px !important",
    // },
    p5: {
        marginTop: 5,
    },
    p15: {
        marginTop: 15,
    },
    violet: {
        color: "#8247ab",
    },
    // grey: {
    //     color: theme.palette.color4.main,
    // },
    mt10: {
        marginTop: 10,
    },
    doticonbottom: {
        float: 'right',
        color: "#7f7c7c",
    },
    msgicon: {
        fontSize: 20,
        color: "#006080",
        textAlign: 'right',
        marginLeft: 5,
    },
    tabcontent: {
        padding: "10px !important",
    },
    right: {
        float: 'right',
        height: '15px',
        minWidth: '55px !important'
    },
    rightAlign: {
        float: 'right',
    },
    chip: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chipItem: {
        borderRadius: 5,
    },
    blueColor: {
        color: theme.palette.color2.main,
    },
    btnModerator: {
        backgroundColor: theme.palette.secondary.main,
        padding: "2px 10px",
        borderRadius: 5,
        color: '#fff',
        fontSize: 10,
        marginLeft: 10,
    },
    iconSearch: {
        position: 'relative',
        right: 50,
        top: 15,
        color: theme.palette.color5.main,
    },
    closeicon: {
        float: 'right',
        fontSize: 32,
        color: theme.palette.color5.main
    },
    txtarea: {
        width: "100%",
        border: 'none',
        backgroundColor: theme.palette.color3.main,
        borderRadius: 6,
        padding: "2%",
    },
    Select: {
        width: "100%",
    },
    mt7: {
        marginTop: 7,
    },
    mb15: {
        marginBottom: 15,
    },
    updateChip: {
        position: 'relative',
        left: 18,
        top: 2,
    },
    bottomArrow: {
        transform: 'rotate(270deg)',
        color: theme.palette.color5.main,
        float: 'right',
    },
    grey: {
        color: theme.palette.color5.main,
    },
    boldFont: {
        fontWeight: "bold"
    }
});
export default CircleNewsCardStyle;