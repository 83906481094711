import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Styles from "../../styles/container/RequestStyle";
import {
  TextareaAutosize,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import Text from "../../visualComponents/Text";

const useStyles = makeStyles(Styles);

export default function MentoringRequest(props) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"Optional"} />
        <Text variant="h2" id={"MyChallenges"} />
        <Text variant="h3" >
          <FormControl variant="filled" className={classes.Select}>
              <TextareaAutosize
                variant="filled"
                aria-label="minimum height"
                minRows={5}
                id="descriptionPart2"
                placeholder={intl.formatMessage({
                  id: "introduction_Field_input_user_type",
                })}
                className={classes.txtarea}
                value={props.input.descriptionPart2}
                onChange={props.onChange}
              />
          </FormControl>
        </Text>
      </Grid>
    </Grid>
  );
}
MentoringRequest.prototype = {
  receivers: PropTypes.array,
  updateStatus: PropTypes.func,
};
