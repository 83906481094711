import React, { useEffect, useState } from "react";
import Player from "@vimeo/player";
import { useId } from 'react';
import Grid from "@mui/material/Grid";
import { Box} from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

export default function VideoPlayer(props) {
  const id = "video_" + Math.random();
  const source = props.source;
  const [playbackTime, setPlaybackTime] = React.useState(1);
  const [play, setPlay] = React.useState(false);
  const [complete, setComplete] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const video = () => (
        <div width="100%" height="100%" onClick={togglePlay} style={{position: "relative"}}>
            <video id={id} width="100%" height="100%" position="absolute" controls={false} hidden={loading}>
                <source src={source} type="video/mp4" />
            </video>
            <Box id={id + "_overlay"} 
                bgcolor={"#5eb0ccbf"} 
                hidden={play}
                sx={{
                        position:"absolute",
                        top: 0, bottom: 0, left: 0, right: 0,
                        zIndex: 1
                }}/>
            {play? null :
                <PlayArrowIcon onClick={togglePlay} sx={{
                    zIndex: 2,
                    fontSize: "4rem",
                    position: "absolute",
                    left: "45%",
                    bottom: "40%",
                    "filter": "drop-shadow( 0px 2px 7px rgba(0, 0, 0, .2))"
                }}/>}
        </div>
        );

  React.useEffect(() => {
    var vidElement = document.getElementById(id);
    vidElement.addEventListener("loadeddata", () => {
        if (vidElement.readyState > 2) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    });

    if(play) {
        vidElement.play();
    } else {
        vidElement.pause();
    }

    vidElement.addEventListener("timeupdate", () => {
        setPlaybackTime(vidElement.currentTime/vidElement.duration * 100);
        setComplete(vidElement.currentTime == vidElement.duration);

        if(complete) {
            props.onComplete();
            vidElement.currentTime = 0;
            setPlay(false);
        }
    });
  });

  const togglePlay = () => setPlay(!play);
  const rewind = () => {
    var vidElement = document.getElementById(id);
    vidElement.currentTime = 0;
  };


  return (
    <Box bgcolor={"white"} height={"100%"}
    sx={{
        borderRadius: '15px 15px 15px 0px',
    }}>
        <Grid container spacing={2} flexDirection={"3"} p={2} pt={0}>
            <Grid item xs={12} sx={{aspectRatio:"16 / 9", width: "100%", height: "auto"}}>
                {video()}
                <Box bgcolor={"#5EB0CC"} height={"100%"} hidden={!loading}/>
            </Grid>
            <Grid item xs={1}>
            {play?
                <PauseIcon onClick={togglePlay} fontSize="large" width="100%" sx={{color:"#5EB0CC", marginTop: -0.5}}/>
                :
                <PlayArrowIcon onClick={togglePlay} fontSize="large" width="100%" sx={{color:"#5EB0CC", marginTop: -0.5}}/>
            }
            </Grid>
            <Grid item xs={1}>
                <FastRewindIcon onClick={rewind} fontSize="large" width="100%" sx={{color:"#5EB0CC", marginTop: -0.5}}/>
            </Grid>
            <Grid item xs={10}>
                <LinearProgress height={20} variant="determinate" value={playbackTime} sx={{
                    marginTop: 0.5,
                    height: 15,
                    borderRadius: 2,
                    [`&.${linearProgressClasses.colorPrimary}`]: {
                        backgroundColor: "#C2E3ED",
                    },
                    [`& .${linearProgressClasses.bar}`]: {
                        borderRadius: 5,
                        backgroundColor: "#5EB0CC",
                    },
                }} />
            </Grid>
        </Grid>
    </Box>
  );
}

export function VimeoVideo(props) {

    useEffect(() => {
        const vimeo  = document.getElementById('vimeo');
        const player = vimeo? new Player(vimeo) : null;
        
        if(props.onComplete != undefined && vimeo != null)
            player.on('ended', function(data) {
                props.onComplete();
            });
            
    });

    return(
        <iframe
        id="vimeo"
        style={{
            width: props.width ?? "100%",
            height: "unset",
            aspectRatio: "16 / 9",
            maxWidth: props.maxWidth ?? "90vw"
        }} 
        src={"https://player.vimeo.com/video/" + props.id + "?portrait=0&byline=0"} 
        width="640" 
        height="360" 
        frameborder="0"/>
    );
}