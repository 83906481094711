import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import { Box } from "@mui/system";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../Header";
import DatePicker from "../DatePicker/DatePicker";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Alert, Hidden, Skeleton, ThemeProvider } from "@mui/material";
import Navigationbar from "../Navigationbar";
import Footer from "../Footer";
import { CssBaseline, useTheme } from "@material-ui/core";
import ActivityUserSearch from "../ActivityUserSearch";
import { useMutation } from "@apollo/client";
import {
  CANCEL_AND_SUGGEST_ONE_ON_ONE,
  SUGGEST_ONE_ON_ONE,
} from "../../apiServices/mutations";
import TimePicker from "../DatePicker/TimePickerPage";
import { ActivityTypes } from "../../apiServices/Constants";
import { YYYYMMDDTHHMM, combineDateAndTime } from "../../apiServices/CommonMethods";
import SuccessAlertDialog from "../Alert/SuccessAlertDialog";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "../../styles/component/Activities/SuggestOneToOneStyle";
import LocationAutoComplete from "../Autocomplete/LocationAutoComplete";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import FormContainer from "../Forms/FormContainer";
import InputSection from "../Forms/InputSection";
import Text from "../../visualComponents/Text";
import { isValid } from "date-fns";

export default function SuggestOneToOne() {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const [activity, setActivity] = React.useState({});
  const [isCancel, setIsCancel] = React.useState(false);
  const [isDatePicker1Error, setIsDatePicker1Error] = React.useState(false);
  const [isDatePicker2Error, setIsDatePicker2Error] = React.useState(false);
  const [isDatePicker3Error, setIsDatePicker3Error] = React.useState(false);
  const [isTimePicker1Error, setIsTimePicker1Error] = React.useState(false);
  const [isTimePicker2Error, setIsTimePicker2Error] = React.useState(false);
  const [isTimePicker3Error, setIsTimePicker3Error] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const [sendSuggestions, { data, loading }] = useMutation(SUGGEST_ONE_ON_ONE);
  const [
    cancelAndCreateNewSuggestion,
    { data: cancelAndCreateData, loading: cancelAndCreateLoading },
  ] = useMutation(CANCEL_AND_SUGGEST_ONE_ON_ONE);

  React.useEffect(() => {
    if (location.state && location.state.activity) {
      setActivity(location.state.activity);
      setIsCancel(location.state.isCancel ? location.state.isCancel : false);
      if (location.state.isCancel) {
        setInput((prevState) => ({
          ...prevState,
          message: location.state.activity.activity.messages ?? "",
        }));
      }
      setInput((prevState) => ({
        ...prevState,
        recipient:
          ActivityTypes.introduction ===
            location.state.activity.activityTypeDesc
            ? location.state.activity.activity.requesterUserProfileId
            : location.state.activity.activity.recipientUserProfileIds,
      }));
    }
    if (location.state && location.state.selectedUser) {
      setSelectedUser(location.state.selectedUser);
      setInput((prevState) => ({
        ...prevState,
        recipient: location.state.selectedUser.userId
      }));
    }
    if (typeof data !== "undefined") {
      // console.log("data", data);
    }
  }, [location, data, cancelAndCreateData]);
  let [input, setInput] = React.useState({
    message: "",
    recipient: [],
    suggestions1MeetingDate: null,
    suggestions1MeetingTime: null,
    suggestions1MeetingType: "Offline",
    suggestions1LocationURL: null,
    suggestions2MeetingDate: null,
    suggestions2MeetingTime: null,
    suggestions2MeetingType: "Offline",
    suggestions2LocationURL: null,
    suggestions3MeetingDate: null,
    suggestions3MeetingTime: null,
    suggestions3MeetingType: "Offline",
    suggestions3LocationURL: null,
  });
  let [inputError, setInputError] = React.useState({
    message: "",
    recipient: "",
    suggestions1MeetingDate: null,
    suggestions1MeetingTime: null,
    suggestions1MeetingType: null,
    suggestions1LocationURL: null,
    suggestions2MeetingDate: null,
    suggestions2MeetingTime: null,
    suggestions2MeetingType: null,
    suggestions2LocationURL: null,
    suggestions3MeetingDate: null,
    suggestions3MeetingTime: null,
    suggestions3MeetingType: null,
    suggestions3LocationURL: null,
  });
  const toggleSuggestion1 = (event, newValue) => {
    setInput((prevState) => ({
      ...prevState,
      suggestions1MeetingType: newValue,
      suggestions1LocationURL: "",
    }));
    setInputError((prevState) => ({
      ...prevState,
      suggestions1MeetingType: "",
      suggestions1LocationURL: "",
    }));
  };
  const toggleSuggestion2 = (event, newValue) => {
    setInput((prevState) => ({
      ...prevState,
      suggestions2MeetingType: newValue,
      suggestions2LocationURL: "",
    }));
    setInputError((prevState) => ({
      ...prevState,
      suggestions2MeetingType: "",
      suggestions2LocationURL: "",
    }));
  };
  const toggleSuggestion3 = (event, newValue) => {
    setInput((prevState) => ({
      ...prevState,
      suggestions3MeetingType: newValue,
      suggestions3LocationURL: "",
    }));
    setInputError((prevState) => ({
      ...prevState,
      suggestions3MeetingType: "",
      suggestions3LocationURL: "",
    }));
  };
  const updateDateSuggestion1 = (date) => {
    setInput((prevState) => ({
      ...prevState,
      suggestions1MeetingDate: date
    }));
    setInputError((prevState) => ({
      ...prevState,
      suggestions1MeetingDate: ""
    }));
  };

  const updateDateSuggestion2 = (date) => {
    setInput((prevState) => ({
      ...prevState,
      suggestions2MeetingDate: date
    }));
    setInputError((prevState) => ({
      ...prevState,
      suggestions2MeetingDate: ""
    }));
  };
  const updateDateSuggestion3 = (date) => {
    setInput((prevState) => ({
      ...prevState,
      suggestions3MeetingDate: date
    }));
    setInputError((prevState) => ({
      ...prevState,
      suggestions3MeetingDate: ""
    }));
  };
  const updateTimeSuggestion1 = (time) => {
    // console.log("updateTimeSuggestion1");
    setInput((prevState) => ({
      ...prevState,
      suggestions1MeetingTime: time,
    }));
    setInputError((prevState) => ({
      ...prevState,
      suggestions1MeetingTime: "",
    }));
  };
  const updateTimeSuggestion2 = (time) => {
    setInput((prevState) => ({
      ...prevState,
      suggestions2MeetingTime: time,
    }));
    setInputError((prevState) => ({
      ...prevState,
      suggestions2MeetingTime: "",
    }));
  };
  const updateTimeSuggestion3 = (time) => {
    setInput((prevState) => ({
      ...prevState,
      suggestions3MeetingTime: time,
    }));
    setInputError((prevState) => ({
      ...prevState,
      suggestions3MeetingTime: "",
    }));
  };
  const searchAction = (searchUser) => {
    // console.log("searchUser", searchUser);
    setInput((prevState) => ({
      ...prevState,
      recipient: [searchUser.userId],
    }));
    setInputError((prevState) => ({
      ...prevState,
      recipient: "",
    }));
  };
  const updateTextFileds = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const checkSuggestion1 = () => {
    return (
      //input["suggestions1LocationURL"] &&
      input["suggestions1MeetingDate"]
    );
  };
  const checkSuggestion2 = () => {
    return (
      //input["suggestions2LocationURL"] &&
      input["suggestions2MeetingDate"]
    );
  };
  const checkSuggestion3 = () => {
    return (
      //input["suggestions3LocationURL"] &&
      input["suggestions3MeetingDate"]
    );
  };

  const onLocationChangeSuggestion1 = (locations) => {
    // console.log("onLocationChange ", locations);
    if (locations) {
      setInput((prevState) => ({
        ...prevState,
        suggestions1LocationURL:
          typeof locations === "string" || locations instanceof String
            ? locations
            : locations.description,
      }));
      setInputError((prevState) => ({
        ...prevState,
        suggestions1LocationURL: "",
      }));
    }
  };
  const onLocationChangeSuggestion2 = (locations) => {
    // console.log("onLocationChange ", locations);
    if (locations) {
      setInput((prevState) => ({
        ...prevState,
        suggestions2LocationURL:
          typeof locations === "string" || locations instanceof String
            ? locations
            : locations.description,
      }));
      setInputError((prevState) => ({
        ...prevState,
        suggestions2LocationURL: "",
      }));
    }
  };
  const onLocationChangeSuggestion3 = (locations) => {
    // console.log("onLocationChange ", locations);
    if (locations) {
      setInput((prevState) => ({
        ...prevState,
        suggestions3LocationURL:
          typeof locations === "string" || locations instanceof String
            ? locations
            : locations.description,
      }));
      setInputError((prevState) => ({
        ...prevState,
        suggestions3LocationURL: "",
      }));
    }
  };
  const getMeeting = () => {
    const arrayMeetings = [
      {
        meetingDateTime: input["suggestions1MeetingDate"],
        meetingType: input["suggestions1MeetingType"],
        locationURL: input["suggestions1LocationURL"],
      },
    ];
    if (!!input["suggestions2MeetingDate"]) {
      arrayMeetings.push({
        meetingDateTime: input["suggestions2MeetingDate"],
        meetingType: input["suggestions2MeetingType"]
          ? input["suggestions2MeetingType"]
          : null,
        locationURL: input["suggestions2LocationURL"]
          ? input["suggestions2LocationURL"]
          : null,
      });
    }
    if (!!input["suggestions3MeetingDate"]) {
      arrayMeetings.push({
        meetingDateTime: input["suggestions3MeetingDate"],
        meetingType: input["suggestions3MeetingType"]
          ? input["suggestions3MeetingType"]
          : null,
        locationURL: input["suggestions3LocationURL"]
          ? input["suggestions3LocationURL"]
          : null,
      });
    }
    return arrayMeetings;
  };

  const sendSuggestOneOnOne = async () => {
    let isValid = checkForErrors();
    if (!isValid){
      setInputError((prevState) => ({
        ...prevState,
        recipient: !input["recipient"].length ? "recipientError" : "",
        message: !input["message"].trim() ? "messageError" : "",
        suggestions1MeetingDate: !input["suggestions1MeetingDate"]
          ? "MeetingDateError"
          : "",
        suggestions1MeetingType: !input["suggestions1MeetingType"]
          ? "MeetingTypeError"
          : "",
  
        suggestions2MeetingDate: !input["suggestions2MeetingDate"]
          ? "MeetingDateError"
          : "",
        suggestions2MeetingType: !input["suggestions2MeetingType"]
          ? "MeetingTypeError"
          : "",
  
        suggestions3MeetingDate: !input["suggestions3MeetingDate"]
          ? "MeetingDateError"
          : "",
        suggestions3MeetingType: !input["suggestions3MeetingType"]
          ? "MeetingTypeError"
          : "",
      }));
      return;
    }

    if (isCancel) {
      cancelAndSendSuggestOneOnOne();
    } else {
      const variable = activity.activity
        ? {
          activitySource: activity.activity.activitySource,
          messages: input["message"],
          recipientUserProfileIds: activity.activity.requesterUserProfileId,
          requesterUserProfileId: userId,
          activitySourceId: activity.activity.activitySourceId,
          meetings: getMeeting(),
        }
        : {
          messages: input["message"],
          recipientUserProfileIds: input["recipient"],
          requesterUserProfileId: userId,
          meetings: getMeeting(),
          activitySource: null,
          activitySourceId: null,
        };
      // console.log("activity.activity ", activity.activity);
      // console.log("variable", variable);
      sendSuggestions({
        variables: variable,
      });
    }
  };
  const cancelAndSendSuggestOneOnOne = async () => {
    // console.log("valid", isValid);
    const variable = {
      activityId: activity.activity.id,
      meetings: getMeeting(),
    };
    // console.log("activity.activity ", activity.activity);
    // console.log("variable", variable);
    cancelAndCreateNewSuggestion({
      variables: variable,
    });
  };

  const checkForErrors = () => {
    // console.log("input", input);

    return (
      input["message"].trim() &&
      input["recipient"].length &&
      checkSuggestion1()
    );
  };

  const getUserName = () => {
    if (typeof activity.givenName !== "undefined") {
      return activity.givenName + " " + activity.familyName
    } else if (selectedUser !== null) {
      return selectedUser.givenName + " " + selectedUser.familyName
    } else {
      return ""
    }
  }

  return (
    <ThemeProvider theme={Theme_WIP}>
      {history?.location?.state?.matchtime && 
        <Background page color="linear-gradient(180deg, #FFA303 53.5%, #F17844 100%)"/>  
      }
      <CssBaseline />
      <Navigationbar />
      <FormContainer 
        modalMessage = {(data?.createSuggestionActivity?.errorCode ?? cancelAndCreateData?.suggestionDeclinedandCreatedNewSuggestion?.errorCode) ==  0 ? <FormattedMessage id="suggestionSentSuccessfully" /> : undefined}
        onModalClose = {() => history.goBack()}
        error        = {(data?.createSuggestionActivity?.errorCode ?? cancelAndCreateData?.suggestionDeclinedandCreatedNewSuggestion?.errorCode) == -1 ? data?.createSuggestionActivity?.message ?? cancelAndCreateData?.suggestionDeclinedandCreatedNewSuggestion?.message : undefined}
        title        = {<FormattedMessage id="suggest1on1"/>}
        description  = {<FormattedMessage id="SuggestOneToOne_PageDescription"/>}
        loading      = {loading || cancelAndCreateLoading}
        onSave       = {sendSuggestOneOnOne}
        disabled     = {loading || cancelAndCreateLoading || !checkForErrors()}
        onCancel     = {() => history.goBack()}
        saveId       = "Button_text_Send"
      >
        <InputSection
        type        = {selectedUser || activity.givenName ? "text" : "contactsearch"}
        disabled    = {selectedUser || cancelAndCreateLoading || loading || activity.givenName}
        id          = "recipient"
        title       = {<FormattedMessage id="toWho"/>}
        value       = {selectedUser || activity.givenName ? getUserName() : undefined}
        placeholder = {intl.formatMessage({ id: "userName" })}
        onChange    = {searchAction}
        hint        = {inputError["recipient"] ? <FormattedMessage id="pleaseEnterTheUserName" /> : undefined}
        />

        <InputSection
        type        = "textarea"
        disabled    = {loading || cancelAndCreateLoading}
        id          = "message"
        placeholder = {intl.formatMessage({ id: "Type" })}
        title       = {<FormattedMessage id="introduction_Field_label_message"/>} 
        value       = {input["message"]}
        onChange    = {updateTextFileds}
        hint        = {inputError["message"] ? <FormattedMessage id="pleaseEnterMessage" /> : undefined}
        max         = {250}
        />

        <Suggestion 
        index          = {1} 
        onChangeDate   = {updateDateSuggestion1}
        onChangeTime   = {updateTimeSuggestion1}
        onChangeToggle = {toggleSuggestion1}
        hint           = {inputError["suggestions1MeetingDate"] || isDatePicker1Error ? <FormattedMessage id="pleaseEnteTheDate" /> : undefined}
        disabled       = {loading || cancelAndCreateLoading}
        toggleValue    = {input.suggestions1MeetingType}
        onChangeUrl    = {input.suggestions1MeetingType == "Offline" ? onLocationChangeSuggestion1 : updateTextFileds}
        />
        {//checkSuggestion1() && 
        <Suggestion 
        index          = {2} 
        onChangeDate   = {updateDateSuggestion2} 
        onChangeTime   = {updateTimeSuggestion2}
        onChangeToggle = {toggleSuggestion2}
        hint           = {inputError["suggestions2MeetingDate"] || isDatePicker1Error ? <FormattedMessage id="pleaseEnteTheDate" /> : undefined}
        disabled       = {loading || cancelAndCreateLoading}
        toggleValue    = {input.suggestions2MeetingType}
        onChangeUrl    = {input.suggestions2MeetingType == "Offline" ? onLocationChangeSuggestion2 : updateTextFileds}
        />}
        {//checkSuggestion1() && checkSuggestion2() && 
        <Suggestion 
        index          = {3} 
        onChangeDate   = {updateDateSuggestion3} 
        onChangeTime   = {updateTimeSuggestion3}
        onChangeToggle = {toggleSuggestion3}
        hint           = {inputError["suggestions3MeetingDate"] || isDatePicker1Error ? <FormattedMessage id="pleaseEnteTheDate" /> : undefined}
        disabled       = {loading || cancelAndCreateLoading}
        toggleValue    = {input.suggestions3MeetingType}
        onChangeUrl    = {input.suggestions3MeetingType == "Offline" ? onLocationChangeSuggestion3 : updateTextFileds}
        />}

      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </ThemeProvider>
  );
}

function Suggestion ({index, min, onChangeDate, onChangeUrl, toggleValue, onChangeToggle, onChangeTime, disabled, hint}) {
const intl = useIntl();
return <>
<Text variant="body1" bold item xs={12} id={"suggestion" + index}/>

<InputSection
type        = "datetime"
disabled    = {disabled}
id          = {"date" + index}
onChange    = {onChangeDate}
hint        = {hint}
min         = {min ?? YYYYMMDDTHHMM(new Date())}
/>

<InputSection
type        = "toggle"
disabled    = {disabled}
id          = {"toggle" + index}
onChange    = {onChangeToggle}
value       = {toggleValue}
props       = {{sm: 5.5}}
options     = {[
  {key: <FormattedMessage id="Toggle_Button_Online" />, value: "Online" },
  {key: <FormattedMessage id="Toggle_Button_Offline"/>, value: "Offline"}
]}
/>
<InputSection
type        = {toggleValue == "Offline" ? "location" : "text"}
disabled    = {disabled}
id          = {"suggestions" + index + "LocationURL"}
onChange    = {onChangeUrl}
props       = {{sm: 6.1}}
placeholder = {intl.formatMessage({ id: toggleValue == "Offline" ? "location" : "videoLink", })}
max         = {250}
/>
{/*
      {inputError["suggestions1MeetingTime"] ? (
        <Typography variant="h5" component="span" color="error">
          <FormattedMessage id={"pleaseEnterTheTime"} />
        </Typography>
      ) : isTimePicker1Error ? (
        <Typography variant="h5" component="span" color="error">
          <FormattedMessage id={"pleaseEnterTheValidTime"} />
        </Typography>
      ) : null}

      {inputError["suggestions1MeetingType"] ? (
        <Typography variant="h5" component="span" color="error">
          <FormattedMessage id={"pleaseSelectMeetingType"} />
        </Typography>
      ) : null}
*/}
</>;
}