import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import MainListItems from '../components/ListItems';
import routes from "../routes";
import { Route, Switch } from 'react-router';
import Navigationbar from '../components/Navigationbar';
import BottomBar from '../components/BottomBar';
import styles from "../styles/container/DashboardStyle";
import { Grid, Hidden } from '@mui/material';
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom';
import { TaxonomyList } from '../apiServices/Constants';
import { selectedBrandColor } from '../apiServices/Redux/Reducer';
import { useSelector } from 'react-redux';
import { useOpenDashboard } from '../apiServices/Mutations/OpenDashboard';
import MatchTimeButton from '../components/MatchTimeButton';
import { useCircleDomain, useCollaborationDomain, useEventDomain, useIndustries, usePersonalLife, useProfessionalLife, useProfileDomain, useSkills } from '../apiServices/Queries/GeneralQueries';
import { ThemeConstants } from '../styles/themes/main/Theme_WIP';

const useStyles = makeStyles(styles);
const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            return (
                <Route
                    path={prop.root + prop.path}
                    component={prop.component}
                    key={key}
                />
            );
        })}
    </Switch>
);


export default function Dashboard() {
    //const {data, loading, error, mutate} = useOpenDashboard(); //uncomment whe we want to deploy iam messages
    const brandColor = useSelector(selectedBrandColor)
    const classes = useStyles();

    const { data: dataCollabPayLoad    } = useCollaborationDomain();
    const { data: dataSkills           } = useSkills             ();
    const { data: dataIndustries       } = useIndustries         ();
    const { data: dataProfilePayload   } = useProfileDomain      ();
    const { data: dataCirclePayload    } = useCircleDomain       ();
    const { data: dataEventPayload     } = useEventDomain        ();
    const { data: dataPersonalLife     } = usePersonalLife       ();
    const { data: dataProfessionalLife } = useProfessionalLife   ();


    React.useEffect(() => {
        // if(!data && !loading) { //uncomment whe we want to deploy iam messages
        //     mutate();
        // }
        if (dataCollabPayLoad) {
            //This has been set up in such a weird way. What's the point of saving this if you query it anyway?
            //TODO: get rid of local storage here now that we've made cached queries
            localStorage.setItem(TaxonomyList.commitment, JSON.stringify(dataCollabPayLoad.commitment));
            localStorage.setItem(TaxonomyList.investmentType, JSON.stringify(dataCollabPayLoad.investmentType));
            localStorage.setItem(TaxonomyList.investorType, JSON.stringify(dataCollabPayLoad.investorType));
            localStorage.setItem(TaxonomyList.companyStage, JSON.stringify(dataCollabPayLoad.companyStage));
            localStorage.setItem(TaxonomyList.businessStage, JSON.stringify(dataCollabPayLoad.businessStage));
            localStorage.setItem(TaxonomyList.businessPartnerBenefitType, JSON.stringify(dataCollabPayLoad.businessPartnerBenefitType));
            localStorage.setItem(TaxonomyList.period, JSON.stringify(dataCollabPayLoad.period));
            localStorage.setItem(TaxonomyList.days, JSON.stringify(dataCollabPayLoad.days));
        }
        if (dataIndustries) {
            let industryValues = dataIndustries.map(({ industry }) => industry);
            localStorage.setItem(TaxonomyList.industries, JSON.stringify(industryValues));
        }
        if (dataSkills) {
            let skillValues = dataSkills.map(({ name }) => name);
            localStorage.setItem(TaxonomyList.skills, JSON.stringify(skillValues));
        }
        if (dataProfilePayload) {
            localStorage.setItem(TaxonomyList.expertLevel, JSON.stringify(dataProfilePayload.experienceLevel));
            localStorage.setItem(TaxonomyList.companyLevel, JSON.stringify(dataProfilePayload.companyLevel));
            localStorage.setItem(TaxonomyList.companyRole, JSON.stringify(dataProfilePayload.companyRole));
        }
        if (dataCirclePayload) {
            // console.log("dataCirclePayload.circleDomain", dataCirclePayload.circleDomain)
            localStorage.setItem(TaxonomyList.circleTags, JSON.stringify(dataCirclePayload.getAllCircleTags));
            localStorage.setItem(TaxonomyList.circleOperatesIn, JSON.stringify(dataCirclePayload.getAllOperatesIn));
        }
        if (dataEventPayload) {
            localStorage.setItem(TaxonomyList.eventTags, JSON.stringify(dataEventPayload.eventTags));
        }
        if (dataPersonalLife) {
            let personalLifeValues = dataPersonalLife.map(({ personalLife }) => personalLife);
            localStorage.setItem(TaxonomyList.personalLife, JSON.stringify(personalLifeValues));
        }
        if (dataProfessionalLife) {
            let ProfessionalValues = dataProfessionalLife.map(({ professionalLife }) => professionalLife);
            localStorage.setItem(TaxonomyList.professionalLife, JSON.stringify(ProfessionalValues));
        }
    }, [dataProfilePayload, dataCollabPayLoad, dataSkills, dataIndustries, dataCirclePayload, dataPersonalLife, dataProfessionalLife]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Navigationbar />
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,

                }}
                anchor="left"
                PaperProps={{
                    sx: {
                      top: "50px",
                      backgroundColor: ThemeConstants.palette.sideNav.main,
                      border: "none",
                      borderRight: "1px solid " + ThemeConstants.palette.lighterPetrol.main,
                      "-ms-overflow-style": "none",
                      scrollbarWidth: "none",
                      paddingBottom: "75px"
                    }
                  }}
                >
                <div style={{flex: 1 }}>
                    <List> <MainListItems routes={routes} /> </List>
                    <MatchTimeButton/>
                </div>
            </Drawer>
            <Grid container justifyContent="center" className={classes.content}>
                <Grid px={{xs: ThemeConstants.custom.spacings.md, md: 0}} container item className={classes.container} maxWidth="690px">{switchRoutes}</Grid>
            </Grid>
            <BottomBar/>
            <Hidden mdUp>
                <Grid style={{
                    position: "fixed",
                    scale: "1.25",
                    right: 10,
                    bottom: 65
                }}>
                    <MatchTimeButton mobile/>
                </Grid>
                <Footer />
            </ Hidden >
        </div >
    );
}