import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";
import { useGetActivityUsers } from "../apiServices/Queries/Activities/ActivityUsers";

export default function ActivityPageUserSearch(props) {
    const globals = useGlobalContext();
    const userId  = globals.state.loggedInUser.userId;
    const [searchText, setSearchText] = React.useState("");
    const [value, setValue] = React.useState("");

    const { loading, data, updateVariables } = useGetActivityUsers({
            userId: userId,
            searchString: searchText,
        });

    useEffect(() => {
        // console.log("data serach 123", data);
        if (typeof props.serchTextCheck !== "undefined" && props.serchTextCheck === true) {
            setSearchText(null);
            setValue(null);
        }
    }, [props]);

    const updateTextFileds = (event) => {
        setSearchText(event.target.value.split(" ").join(''));
        updateVariables({
                userId: userId,
                searchString: event.target.value,
        });

        if (!event.target.value.length) {
            if (typeof props.onUserSelection === "function") {
                props.onUserSelection("");
                setValue("");
            }
        }
    };
    const onChange = (event, newvalue) => {
        setValue(newvalue);
        if (searchText.length) {
            const arryayMaster = data.filter((master) => {
                return master.givenName + " " + master.familyName === newvalue;
            });
            props.onSearch(arryayMaster);
            if (typeof props.onUserSelection === "function") {
                props.onUserSelection(newvalue);
            }
        }
    };
    const onFocus = () => {
        // props.onFocus();
    }
    return (
        <Autocomplete
            freeSolo
            value={value}
            size={"small"}
            id="free-solo-2-demo"
            onChange={onChange}
            filterOptions={(x) => x}
            disableClearable
            onFocus={onFocus}
            options={data &&
                    searchText !== null &&
                    searchText.length
                    ? data.map(
                        (option, index) => option.givenName + " " + option.familyName
                    )
                    : []
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={updateTextFileds}
                    value={searchText}
                    placeholder={
                        typeof props.placeHolder !== "undefined"
                            ? props.placeHolder
                            : "Search"
                    }
                    InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : searchText !== null && !searchText.length ? (
                                    <SearchIcon />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
ActivityPageUserSearch.prototype = {
    onSearch: PropTypes.func,
};
