import { ThemeProvider } from "@mui/material";
import { Environment } from "../../Config";
import Text from "../../visualComponents/Text";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";

export default function EnvIndicator(props) {
    if(Environment.toLowerCase() != "production") {
        document.title = "🚧" + Environment + "🚧";
        return(
        <ThemeProvider theme={Theme_WIP}>
            <Background color="rgba(255,0,100,0.75)" container sx={{
                position: "fixed",
                left: 0, top: 0,
                zIndex: "2000 !important",
                width: "fit-content",
                height: "fit-content"
            }}><Text container color="primary" bold p={2} px={6}>{Environment}</Text></Background>
        </ThemeProvider>);
    } else
        return null;
}