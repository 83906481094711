import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ButtonDarkBlue } from "../components/Buttons/ButtonDarkBlue";
import styles from "../styles/container/ForgotPasswordStyle";
import {
    Box,
    Skeleton,
    Switch,
    TextareaAutosize,
    ThemeProvider,
} from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_USER_SUBSCRIPTION } from "../apiServices/mutations";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useIntl } from "react-intl";
import AdminAutoSearch from "./AdminAutoSearch";
import { GET_USER_SUBSCRIPTIONS } from "../apiServices/Queries";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TablePagination from '@mui/material/TablePagination';
import { getDateWithShortMonth } from "../apiServices/CommonMethods";
import Theme_WIP from "../styles/themes/main/Theme_WIP";
import Text from "../visualComponents/Text";
import { useUpdateMetaData } from "../apiServices/Mutations/Profiles/MetaData";
import { gql } from "@apollo/client";

const useStyles = makeStyles(styles);
export default function AdminExtension() {
    const theme = useTheme();
    const classes = useStyles();
    let [selectedUser, setSelectedUser] = React.useState(null);
    let [subscriptions, setSubscriptions] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [totalpage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [endDate, setEndDate] = React.useState(null);
    const [isConnector, setConnector] = React.useState();
    const [doMutate , setDoMutate] = React.useState(false);
    const [doRefetch, setRefetch ] = React.useState(false);
    const metaMutations = useUpdateMetaData(selectedUser?.userId, isConnector); //{data, loading, error, mutate}
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (selectedUser) {
            userSubscriptions(
                {
                    variables: {
                        userId: selectedUser.userId,
                        pageNumber: newPage + 1,
                        pageSize: rowsPerPage
                    },
                }
            )
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [updateSubscription, { loading: loadingUpdate }] = useMutation(
        UPDATE_USER_SUBSCRIPTION,
        {
            onCompleted: (data) => {
                // console.log("UPDATE_USER_SUBSCRIPTION", data);
                userSubscriptions(
                    {
                        variables: {
                            userId: selectedUser.userId,
                            pageNumber: page + 1,
                            pageSize: rowsPerPage
                        },
                    }
                )
            },
        }
    );


    const [meta] = useLazyQuery(gql`query get($userId: String!) { 
        profileMetaData(userId: $userId){
          connector
        }
      }`, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
          if(data != null)
            setConnector(data.profileMetaData?.connector ?? false);
        },
        variables: {
          userId: selectedUser?.userId
        },
      });

    const [userSubscriptions, { loading }] = useLazyQuery(GET_USER_SUBSCRIPTIONS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            // console.log("userSubscriptions", data);
            if (typeof data !== "undefined" && data.allUserSubscriptions.errorCode === 0) {
                // console.log("total page", data.allUserSubscriptions.totalRecords)
                setTotalPage(data.allUserSubscriptions.totalRecords)
                const response = data.allUserSubscriptions.result;
                const userSubscriptions = response.map((userSubscription) => {
                    return createData(
                        userSubscription.email,
                        userSubscription.firstName,
                        userSubscription.lastName,
                        userSubscription.role,
                        userSubscription.subscriptionStartDate,
                        userSubscription.subscriptionEndDate,
                        userSubscription.remarks,
                        userSubscription.userId,
                        userSubscription.id,
                        userSubscription.subscriptionType,
                        userSubscription.subscriptionTypeDesc
                    )
                })
                // console.log("userSubscriptions", userSubscriptions)
                setSubscriptions(userSubscriptions)
            }
        }
    })

    function createData(email, firstName, lastName, role, startDate, endDate, reason, userId, id, subscriptionType, subscriptionTypeDesc) {
        return { email, firstName, lastName, role, startDate, endDate, reason, userId, id, subscriptionType, subscriptionTypeDesc};
    }

    const handleSave = async () => {
        if (subscriptions.length > 0) {
            const latestSubscription = subscriptions[0]
            // console.log("latestSubscription", latestSubscription)
            let variable = {
                id: latestSubscription.id,
                remarks: latestSubscription.reason,
                // subscriptionEndDate: latestSubscription.endDate,
                subscriptionEndDate: endDate,
                userId: latestSubscription.userId
            };
            // console.log("variable Subscription", variable);
            setEndDate(null)
            updateSubscription({
                variables: variable,
            });
        }
    };
    const onUserSelection = users => {
        // console.log("Users", users)
        if (users.length) {
            setSelectedUser(users[0])
            userSubscriptions(
                {
                    variables: {
                        userId: users[0].userId,
                        pageNumber: page,
                        pageSize: rowsPerPage
                    },
                }
            )
            setRefetch(true);
        } else {
            setSubscriptions([])
            setPage(0)
            setTotalPage(0)
            setEndDate(null)
            setConnector(null);
        }
    };
    const onFocus = () => {
    };
    const onReasonChange = (event) => {
        var userSubscription = subscriptions[0]
        const data = createData(
            userSubscription.email,
            userSubscription.firstName,
            userSubscription.lastName,
            userSubscription.role,
            userSubscription.startDate,
            userSubscription.endDate,
            event.target.value,
            userSubscription.userId,
            userSubscription.id,
            userSubscription.subscriptionType,
            userSubscription.subscriptionTypeDesc
        )
        // console.log("edit subscriptionsAll", data)
        var subscriptionsAll = subscriptions
        subscriptionsAll[0] = data
        // console.log("subscriptionsAll", subscriptionsAll)

        setSubscriptions([...subscriptionsAll])

    };
    const onEndDateChange = (value) => {
        // var userSubscription = subscriptions[0]
        // const data = createData(
        //     userSubscription.email,
        //     userSubscription.firstName,
        //     userSubscription.lastName,
        //     userSubscription.role,
        //     userSubscription.startDate,
        //     value,
        //     userSubscription.reason,
        //     userSubscription.userId,
        //     userSubscription.id,
        //     userSubscription.subscriptionType,
        // )
        // console.log("edit subscriptionsAll", data)
        // var subscriptionsAll = subscriptions
        // subscriptionsAll[0] = data
        // console.log("subscriptionsAll", subscriptionsAll)

        // setSubscriptions([...subscriptionsAll])
        setEndDate(value)

    };
    const getMinDate = (rowData) => {
        // console.log("Data selected", rowData)
        if (rowData.subscriptionType === "PAID_SUBSCRIPTION" && rowData.endDate !== null) {
            return (rowData.endDate)
        } else {
            return (rowData.startDate)
        }
    }

    const updateMeta = () => {
        setConnector(!isConnector);
        setDoMutate(true);
    }

    React.useEffect(() => {
        if(selectedUser && doMutate) {
            metaMutations.mutate();
            setDoMutate(false);
        }
        if(doRefetch) {
            meta();
        }
    }, [doMutate, doRefetch, selectedUser]);

    return (
        <React.Fragment>
            <Container maxWidth="lg">
                <Box sx={{ flexGrow: 1, mt: theme.spacing(2), mb: theme.spacing(4), }}>
                    <Grid container spacing={4}>
                        <a href="/dashboard/admin/tasks">- Tasks -</a><p>-</p>
                        <a href="/dashboard/admin/matchtime">- Match Time -</a><p>-</p>
                        <a href="/dashboard/admin/videos">- Videos -</a><p>-</p>
                        <a href="/dashboard/admin/requests">- Requests -</a>
                        <Grid item sm={12} xs={12} >
                            <Typography component="h1" variant="h1" color={"textPrimary"}>
                                Aanpassen Rol
                            </Typography>
                        </Grid>
                        {isConnector != null && selectedUser && !metaMutations.loading != null && <Grid item xs={12}>
                            <ThemeProvider theme={Theme_WIP}>
                                <Text bold my={2}>Is verbinder?</Text>
                            </ThemeProvider>
                            <Switch
                                checked={isConnector}
                                onChange={updateMeta}
                                name={"isCompany"}
                            />
                        </Grid>}
                        <Grid item sm={4} xs={8} className={classes.mt20}>
                            <Typography component="h2" variant="h2" color={"textPrimary"} className={classes.mtb10}>
                                Zoek gebruiker
                            </Typography>
                            <AdminAutoSearch
                                onFocus={onFocus}
                                showLoggedInUser={false}
                                onSearch={onUserSelection}
                                placeHolder="gebruikernaam"
                            />
                        </Grid>
                        < Grid container item>

                            {(loading) || loadingUpdate ? (
                                <Grid item sm={12} xs={12} >
                                    <Skeleton variant="rectangular" height={200} />
                                </Grid>
                            ) :

                                <Grid item sm={12} xs={12}>
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer component={Paper} className={classes.mt10} sx={{ maxHeight: 440 }} >
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Gebruiker</TableCell>
                                                        <TableCell>Voornaam</TableCell>
                                                        <TableCell>Achternaam</TableCell>
                                                        <TableCell>Rol</TableCell>
                                                        <TableCell>Start datum</TableCell>
                                                        <TableCell>Eind datum</TableCell>
                                                        <TableCell>Eind datum</TableCell>
                                                        <TableCell>Reden wijziging</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {subscriptions.map((row, index) => (
                                                        <TableRow
                                                            key={"dfs"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {row.email}
                                                                {/* <TextField size="small" id="filled-read-only-input" hiddenLabel
                                                                InputProps={{ readOnly: true, style: { fontSize: 15 } }}
                                                                variant="filled" value={row.email} /> */}
                                                            </TableCell>
                                                            <TableCell>
                                                                {/* <TextField size="small" id="filled-read-only-input" hiddenLabel
                                                                InputProps={{ readOnly: true, style: { fontSize: 15 } }}
                                                                variant="filled" value={row.firstName} /> */}
                                                                {row.firstName}
                                                            </TableCell>
                                                            <TableCell>
                                                                {/* <TextField size="small" id="filled-read-only-input" hiddenLabel
                                                                InputProps={{ readOnly: true, style: { fontSize: 15 } }}
                                                                variant="filled" value={row.lastName} /> */}
                                                                {row.lastName}
                                                            </TableCell>
                                                            <TableCell>
                                                                {/* <TextField size="small" id="filled-read-only-input" hiddenLabel
                                                                InputProps={{ readOnly: true, style: { fontSize: 15 } }}
                                                                variant="filled" value={row.role} /> */}
                                                                {row.subscriptionTypeDesc}
                                                            </TableCell>
                                                            <TableCell>
                                                                {/* <TextField hiddenLabel size="small" id="filled-read-only-input"
                                                                InputProps={{ readOnly: true, style: { fontSize: 15 } }}
                                                                variant="filled" value={row.startDate} /> */}
                                                                {getDateWithShortMonth(row.startDate)}
                                                                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    inputFormat="dd/MM/yy"
                                                                    readOnly
                                                                    value={row.startDate}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </LocalizationProvider> */}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.endDate !== null ? getDateWithShortMonth(row.endDate) : ""}
                                                            </TableCell>
                                                            <TableCell>
                                                                {/* <TextField hiddenLabel id={index} variant="outlined" size="small"
                                                                value={row.endDate}
                                                                InputProps={{ readOnly: (index === 0) ? false : true, style: { fontSize: 15 } }} /> */}
                                                                {index === 0 & page === 0 ?
                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                        <DatePicker
                                                                            inputFormat="dd/MM/yy"
                                                                            disablePast
                                                                            minDate={new Date(getMinDate(row))}
                                                                            error={false}
                                                                            helperText={null}
                                                                            InputProps={{
                                                                                error: false,
                                                                            }}
                                                                            disabled={(index === 0 & page === 0) ? false : true}
                                                                            readOnly={(index === 0 && page === 0) ? false : true}
                                                                            value={(index === 0 & page === 0) ? endDate : row.endDate}
                                                                            onChange={(newValue) => {
                                                                                onEndDateChange(newValue);
                                                                            }}
                                                                            renderInput={(params) => <TextField {...params} />}
                                                                            onAccept={(newValue) => {
                                                                                // console.log("onAccept", newValue)
                                                                            }}
                                                                        />
                                                                    </LocalizationProvider> : <span />}
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextareaAutosize
                                                                    onChange={onReasonChange}
                                                                    value={row.reason ?? ""}
                                                                    disabled={(index === 0 && page === 0) ? false : true}
                                                                    InputProps={{ readOnly: (index === 0 && page === 0) ? false : true, style: { fontSize: 15 } }}
                                                                    minRows={2}
                                                                    maxLength={100}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 15]}
                                            component="div"
                                            count={totalpage}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Paper>

                                </Grid>
                            }
                        </Grid>
                        <Grid item sm={2} xs={4} className={classes.mt20}>
                            <ButtonDarkBlue onClick={handleSave}>
                                Opslaan
                            </ButtonDarkBlue>
                        </Grid>
                    </Grid>
                </Box >
            </Container >
        </React.Fragment >
    );
}

