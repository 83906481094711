import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import jsPDF from "jspdf";
import QRCode from "qrcode.react";
import { SHARE_CIRCLE_URL } from "../../Config";
import Styles from "../../styles/component/Circles/CircleInviteStyle";
import { FormattedMessage } from "react-intl";
import InviteShareButtons from "../Generic/InviteShareButtons";
import { useUUID } from "../../apiServices/Queries/Circles/GetUUID";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";


const useStyles = makeStyles(Styles);

export default function CircleInvite(props) {
  const globals = useGlobalContext();
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [circleData, setCircleData] = React.useState({});
  const [shareContent, setShareContent] = React.useState("");
  const {data: uuid} = useUUID(false, props.circleDetails.id);
  const shareUrl = SHARE_CIRCLE_URL + uuid;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [qrbase64, setQrbase64] = React.useState("");

  React.useEffect(() => {
    if (typeof props.circleDetails !== "undefined") {
      setCircleData(props.circleDetails);
      setShareContent("Ik ben lid van " + props.circleDetails.name + ". Kom je kennis maken? Check voor meer info: ")
    }
    if (
      typeof props.circleDetails !== "undefined" &&
      uuid
    ) {
      const canvas = document.getElementById("qrCodeId");
      const base64QrImage = canvas.toDataURL("image/png");
      setQrbase64(base64QrImage);
    }
  }, [props]);

  function generatePDF() {
    const title = "Wie zijn jouw toekomstige zakelijke partnerships?";
    const doc = new jsPDF("p", "pt", "a4");
    doc.setFont("Century Gothic", "bold");
    doc.setFontSize(36);
    const splitTitle = doc.splitTextToSize(title, 500);
    doc.text(splitTitle, 85, 100, "left");
    doc.setFontSize(20);
    doc.setFont("Century Gothic", "normal");
    doc.text("Scan de QR en ontdek wat anderen zoeken. ", 85, 210, "left");
    doc.text(circleData.name, 85, 270, "left");
    doc.addImage(qrbase64, "png", 70, 290, 140, 140);
    doc.addImage(
      "../assets/img/mastermatch_logo_petrol_blue_large.svg",
      "JPEG",
      70,
      680,
      300,
      80
    );
    doc.save(circleData.name + ".pdf");
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <InviteShareButtons shareContent={shareContent} shareUrl={shareUrl} />
        {(circleData.loggedInUserRole == "Moderator" || circleData.loggedInUserRole == "SuperUser" || globals.state.loggedInUser.userId == globals.state.admin.userId) && (
          <Grid item sm={5} xs={12}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                history.push("/InvitePeopleToCircle", {
                  circleDetails: circleData,
                });
              }}
            >
              <FormattedMessage id={"invitePeopleToCircle"} />
            </Button>

            <Button onClick={generatePDF} variant="outlined" fullWidth>
              <FormattedMessage id={"dwnldQrCode"} />
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
