import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Chip } from "@material-ui/core";
import Styles from "../../../styles/component/Cards/RequestCardStyle";
import { getDateOnlyString } from "../../../apiServices/CommonMethods";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(Styles);

export default function MastersOfMastersCardDetails(props) {
  const classes = useStyles();

  const [requestDetails, setRequestDetails] = React.useState({});

  useEffect(() => {
    if (typeof props.request !== "undefined") {
      setRequestDetails(props.request);
    }
  }, [props]);

  return (
    <Grid item xs={12}>
      <Typography variant="h3" className={classes.p15} display="inline">
        {requestDetails.collaborationDescription ?? ""}
      </Typography>
      <Typography variant="h3" className={[classes.boldFont, classes.p15]}>
        <FormattedMessage id={"PreferredSkills"} />:{" "}
        <Typography variant="h3" className={classes.chip} display="inline">
          {(requestDetails.preferredSkills ?? []).map((skill, index) => (
            <Chip
              key={index}
              label={
                <Typography
                  variant="h3"
                  className={classes.p15}
                  display="inline"
                >
                  {skill}
                </Typography>
              }
              className={classes.chipItem2}
              size="small"
            />
          ))}
        </Typography>
      </Typography>
      <Typography variant="h3" className={[classes.boldFont, classes.p15]}>
        <FormattedMessage id={"OfferedSkills"} />:{" "}
        <Typography variant="h3" className={classes.chip} display="inline">
          {(requestDetails.offeredSkills ?? []).map((skill, index) => (
            <Chip
              key={index}
              label={
                <Typography
                  variant="h3"
                  className={classes.p15}
                  display="inline"
                >
                  {skill}
                </Typography>
              }
              className={classes.chipItem2}
              size="small"
            />
          ))}
        </Typography>
      </Typography>

      {requestDetails.industries ? (
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"RequiredIndustries"} />:{" "}
          <Typography variant="h3" className={classes.chip} display="inline">
            {(requestDetails.industries ?? []).map((industry, index) => (
              <Chip
                key={index}
                label={
                  <Typography
                    variant="h3"
                    className={classes.p15}
                    display="inline"
                  >
                    {industry}
                  </Typography>
                }
                className={classes.chipItem2}
                size="small"
              />
            ))}
          </Typography>
        </Typography>
      ) : null}
      {requestDetails.location ? (
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"Location"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {requestDetails.location ?? ""}
          </Typography>
        </Typography>
      ) : null}
      {requestDetails.durationInMonths ? (
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"DurationInMonths"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {requestDetails.durationInMonths ?? ""}
          </Typography>
        </Typography>
      ) : null}
      {requestDetails.estimatedHours ? (
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"EstimatedHours"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {requestDetails.estimatedHours ?? ""}
          </Typography>
        </Typography>
      ) : null}
      {requestDetails.startDate ? (
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"StartDate"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {getDateOnlyString(new Date(requestDetails.startDate))}
          </Typography>
        </Typography>
      ) : null}
    </Grid>
  );
}
MastersOfMastersCardDetails.prototype = {};
