import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Styles from "../../../styles/component/Cards/RequestCardStyle";
import { Chip } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(Styles);

export default function CoworkingCardDetails(props) {
  const classes = useStyles();

  const [requestDetails, setRequestDetails] = React.useState({});

  useEffect(() => {
    if (typeof props.request !== "undefined") {
      setRequestDetails(props.request);
    }
  }, [props]);

  return (
    <Grid item xs={12}>
        <Typography variant="h3" className={classes.p15} display="inline">
          {requestDetails.dateDescription ?? ""}
        </Typography>
      <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
        <FormattedMessage id={"Location"} />:
        <Typography
          variant="h3"
          className={classes.p15}
          display="inline"
        >
          {" "}
          {requestDetails.location ?? ""}
        </Typography>
      </Typography>
      {requestDetails.period && requestDetails.period.length > 0 &&
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"Period"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {requestDetails.period ? requestDetails.period.join(', ') : ""}
          </Typography>
        </Typography>}
      {requestDetails.days && requestDetails.days.lemth > 0 &&
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"Days"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {requestDetails.days ? requestDetails.days.join(', ') : ""}
          </Typography>
        </Typography>}
      {requestDetails.skills && requestDetails.skills.length > 0 ? (
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"RequiredSkillsPartner"} />:{" "}
          <Typography variant="h3" className={classes.chip} display="inline">
            {(requestDetails.skills ?? []).map((skill, index) => (
              <Chip
                key={index}
                label={
                  <Typography
                    variant="h3"
                    className={classes.p15}
                    display="inline"
                  >
                    {skill}
                  </Typography>
                }
                className={classes.chipItem2}
                size="small"
              />
            ))}
          </Typography>
        </Typography>
      ) : null}
    </Grid>
  );
}
CoworkingCardDetails.prototype = {};
