import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Styles from "../../styles/container/RequestStyle";
import {
  Chip,
  TextField,
} from "@material-ui/core";
import {
  masterDataExpertLevel
} from "../../apiServices/Constants";
import { Autocomplete } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import Text from "../../visualComponents/Text";

const useStyles = makeStyles(Styles);

export default function BrainStormingPartners(props) {
  const classes = useStyles();
  const intl = useIntl();

  const handleChangeExpertLevel = (event, newvalue) => {
    props.setInput((prevState) => ({
      ...prevState,
      expertiseLevel: newvalue,
    }));
  };
  const handleChangeEntrepreneurshipLevel = (event, newvalue) => {
    props.setInput((prevState) => ({
      ...prevState,
      entrepreneurshipLevel: newvalue,
    }));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"Optional"} />
        <Text variant="h2" id={"FrequencyInMonths"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <TextField
                id="months"
                label={intl.formatMessage({
                  id: "introduction_Field_input_user_type",
                })}
                size="small"
                value={props.input.months}
                onChange={props.onChange}
                type={"number"}
              />
          </FormControl>
        </Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"RequiredLevelInEntrepreneurship"} />
        <Text variant="h3" >
          <FormControl variant="filled" className={classes.Select}>
              <Autocomplete
                multiple
                id="entrepreneurshipLevel"
                options={masterDataExpertLevel().filter((item) => {
                  return item.key !== "Top level" && item.key !== "Top";
                }).map(({ key }) => key)}
                value={props.input.entrepreneurshipLevel}
                onChange={handleChangeEntrepreneurshipLevel}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      size="small"
                      className={classes.chip}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // placeholder="Add"
                    variant="outlined"
                  />
                )}
              />
          </FormControl>
        </Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"RequiredLevelOfExpertise"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <Autocomplete
                multiple
                id="expertiseLevel"
                options={masterDataExpertLevel().map(({ key }) => key)}
                value={props.input.expertiseLevel}
                onChange={handleChangeExpertLevel}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      size="small"
                      className={classes.chip}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // placeholder="Add"
                    variant="outlined"
                  />
                )}
              />
          </FormControl>
        </Text>
      </Grid>
    </Grid >
  );
}
BrainStormingPartners.prototype = {
  receivers: PropTypes.array,
  updateStatus: PropTypes.func,
};
