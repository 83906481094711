import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Styles from "../../../styles/container/ActivityPageStyle";
import {
  Avatar,
  Button,
  CardMedia,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import {
  MARK_AS_DONE_MATCH_REQUEST,
  SUGGESTION_ACCEPT,
  SUGGESTION_DECLINE,
} from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import {
  getDateString,
  getLongDateWithYear,
  GetUserId,
  format24Time,
} from "../../../apiServices/CommonMethods";
import { ArrowForwardIos } from "@material-ui/icons";
import { ActivityStatus } from "../../../apiServices/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import MarkAsDoneDialog from "../../Alert/MarkAsDoneDialog";
import UserInfo from "./components/UserInfo";

const useStyles = makeStyles(Styles);

export default function ActivitySuggestionCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const userId = GetUserId();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activity, setActivity] = React.useState({});
  const open = Boolean(anchorEl);
  const acceptedSuggestion = (activity?.activity?.meetings?.findIndex((m) => m.status === "ACCEPT") ?? -1) + 1;
  const [showMarkAsDoneAlert, setShowMarkAsDoneAlert] = React.useState(false);

  const [acceptSuggestion, { data: dataAccept, loading: loadingAccept }] =
    useMutation(SUGGESTION_ACCEPT);
  const [declineSuggestion, { data: dataDecline, loading: loadingDecline }] =
    useMutation(SUGGESTION_DECLINE);
  const [MarkAsDone, { data: dataMarkAsDone, loading: loadingMarkAsDone }] =
    useMutation(MARK_AS_DONE_MATCH_REQUEST);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (typeof props.activity !== "undefined") {
      setActivity(props.activity);
    }
    if (
      typeof dataMarkAsDone !== "undefined" ||
      typeof dataAccept !== "undefined" ||
      typeof dataDecline !== "undefined"
    ) {
      props.onRefresh();
    }
  }, [props, dataMarkAsDone, dataAccept, dataDecline]);

  const acceptSuggestions = (meeting) => {
    setAnchorEl(null);
    const variables = {
      activityId: activity.activity.id,
      meetingID: meeting.id,
    };
    acceptSuggestion({
      variables: variables,
    });
  };
  const declineSuggestions = () => {
    setAnchorEl(null);
    const variables = {
      activityId: activity.activity.id,
    };
    declineSuggestion({
      variables: variables,
    });
  };
  // const declineSuggestionAndCreateNew = () => {
  //   setAnchorEl(null);
  //   history.push("/suggestOneToOne", {
  //     activity: activity,
  //     isCancel: true,
  //   });
  // };
  const markAsDoneMatchRequestAction = () => {
    if (activity.activity.status !== ActivityStatus.Closed) {
      setAnchorEl(null);
      const variables = {
        activityType: activity.activity.activityTypeDesc,
        activityId: activity.activity.id,
      };
      MarkAsDone({
        variables: variables,
      });
    }
  };
  const navigateToMessage = () => {
    setAnchorEl(null);
    history.push("/sendMessage", {
      activity: activity,
    });
  };
  const navigateToProfile = () => {
    if (activity.showProfileBasedOnPreference !== true) {
      props.onShowPreferenceWarning()
    } else if (activity.activity.userRole !== 1) {
      history.push("/dashboard/profile/", {
        selectedUserId: activity.userId,
      });
    }
  };
  const getMessageForAcceptedMeeting = () => {
    const meetings = activity.activity ? activity.activity.meetings : [];
    var index = meetings.findIndex(function (meeting) {
      return meeting.status === "ACCEPT";
    });
    return intl.formatMessage({ id: "AcceptedOption" }) + " " + (index + 1) + intl.formatMessage({ id: "AcceptedOption_Description" });
  };
  const menuItemForNewActivity = () => {
    return (
      <div>
        {activity.activity &&
          activity.activity.meetings.map((meeting, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                acceptSuggestions(meeting);
              }}
            >
              {intl.formatMessage({ id: "AcceptMeeting" }) + " " + (index + 1)}
            </MenuItem>
          ))}
        <MenuItem onClick={declineSuggestions}>
          <FormattedMessage id={"DeclineAll"} />
        </MenuItem>
        {/* <MenuItem onClick={declineSuggestionAndCreateNew}>
          {" "}
          <FormattedMessage id={"DeclineAndSuggestNew_1_On_1"} />
        </MenuItem> */}
        <MenuItem onClick={navigateToMessage}>
          <FormattedMessage id={"Message"} />
        </MenuItem>
        <MenuItem onClick={fetchAllActivity}>
          <FormattedMessage id={"ToAllActivities"} />
        </MenuItem>
      </div>
    );
  };
  const menuItemsForAccept = () => {
    return (
      <div>
        <MenuItem onClick={declineSuggestions}>
          <FormattedMessage id={"CancelMeeting"} />
        </MenuItem>
        {/* <MenuItem onClick={declineSuggestionAndCreateNew}>
          <FormattedMessage id={"CancelMeetingAndSuggestNew_1_On_1"} />
        </MenuItem> */}
        <MenuItem onClick={navigateToMessage}>
          <FormattedMessage id={"Message"} />
        </MenuItem>
        <MenuItem onClick={fetchAllActivity}>
          <FormattedMessage id={"ToAllActivities"} />
        </MenuItem>
      </div>
    );
  };

  const getSuggestionStatus = () => {
    if (
      activity.activity.statusBeforeClosing === ActivityStatus.oneOnOneAccepted
    ) {
      return getMessageForAcceptedMeeting();
    } else if (
      activity.activity.statusBeforeClosing === ActivityStatus.oneOnOneDeclined
    ) {
      // return "Declined all options";
      return intl.formatMessage({ id: "DeclinedAllOptions" });
    } else if (
      activity.activity.statusBeforeClosing ===
      ActivityStatus.oneOnOneDeclinedAndInitiatedNew
    ) {
      // return "Declined all options and suggested new";
      return intl.formatMessage({ id: "DeclinedAllOptionsAndSuggestedNew" });
    }
  };
  const checkCurrentUser = () => {
    return (
      activity.activity && activity.activity.requesterUserProfileId !== userId
    );
  };
  const fetchAllActivity = () => {
    props.onRefreshAllActivity(activity);
  };
  return (
    <Grid container spacing={4} className={classes.mt15}>
      {showMarkAsDoneAlert ? (
        <MarkAsDoneDialog
          message={
            intl.formatMessage({ id: "preference_permission_error", })
          }
          title={
            intl.formatMessage({ id: "warning" })}
          handleMarkAsDone={markAsDoneMatchRequestAction}
          handleClose={() => {
            setShowMarkAsDoneAlert(false)
          }}
          open={true}
        />
      ) : null}
      {!checkCurrentUser() && <Grid item container xs={0} sm={1} md={3} spacing={2}></Grid>}
      <Grid item container xs={12} sm={11} md={9} spacing={2}>
        <UserInfo sent={checkCurrentUser()} activity={activity} onClick={navigateToProfile} activityType={"1_ON_1"}/>
        <Grid item container xs={12} sm={12}>
          <div
            className={checkCurrentUser() ? classes.tabcontentInner : classes.cardSent}
          >
            {activity.activity && (
              <Typography
                variant="h3"
                color={"textSecondary"}
                display="inline"
              >
                {activity.activity && activity.activity.messages
                  ? activity.activity.messages
                  : ""}
              </Typography>
            )}
            {
              activity.activity &&
              activity.activity &&
              (activity.activity.statusBeforeClosing ===
                ActivityStatus.oneOnOneAccepted ||
                activity.activity.statusBeforeClosing ===
                ActivityStatus.oneOnOneDeclined ||
                activity.activity.statusBeforeClosing ===
                ActivityStatus.oneOnOneDeclinedAndInitiatedNew) && (
                <div className={classes.mt10}>
                  <Typography
                    variant="h4"
                    color={"textPrimary"}
                    display="inline"
                  >
                    {activity.activity ? getSuggestionStatus() : ""}
                  </Typography>
                </div>
              )}
            {activity.activity && (
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt10}
              >
                {activity.activity.meetings.map((meeting, index) => (
                  (!acceptedSuggestion || acceptedSuggestion == index + 1) &&
                  <>
                    <Typography
                      component="h3"
                      variant="h3"
                      color={"textPrimary"}
                      className={classes.p3}
                      display="inline"
                    >
                      {intl.formatMessage({ id: "Option" }) +
                        " " +
                        (index + 1) +
                        ": " +
                        getLongDateWithYear(meeting.meetingDateTime) +
                        ", " +
                        format24Time(new Date(meeting.meetingDateTime)) +
                        ", "}
                    </Typography>
                    {meeting.locationURL !== null && (
                      <Typography
                        component="h3"
                        variant="h3"
                        color={"textPrimary"}
                        className={classes.p3}
                        display="inline"
                        style={{ wordBreak: "break-all" }}
                      >
                        {meeting.meetingType === "OFFLINE" ? (
                          meeting.locationURL
                        ) : (
                          <Link
                            href={
                              meeting.locationURL.startsWith("http")
                                ? meeting.locationURL
                                : "https://" + meeting.locationURL
                            }
                            target={"_blank"}
                            rel="noopener noreferrer"
                          >
                            {meeting.locationURL}
                          </Link>
                        )}
                      </Typography>
                    )}
                  </>
                ))}
              </Typography>
            )}

            {checkCurrentUser() ? (
              <Grid item xs={12} sm={12}>
                <Divider style={{ marginTop: 30, marginBottom: 10 }} />
                <Grid item xs={12} sm={12} className={classes.rightAlign}>
                  {loadingMarkAsDone ? (
                    <CircularProgress />
                  ) : activity.activity.status === ActivityStatus.Closed ? (
                    <Button
                      variant="text"
                      startIcon={
                        <Avatar
                          src={"/assets/img/icons8-checked-checkbox-50.png"}
                          style={{ width: 15, height: 15 }}
                        />
                      }
                      className={classes.leftAlign}
                    >
                      <Typography
                        component="span"
                        variant="h5"
                        color={"textSecondary"}
                      >
                        Klaar
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      startIcon={
                        <Avatar
                          src={"/assets/img/icons8-checked-checkbox-OFF.png"}
                          style={{ width: 15, height: 15 }}
                        />
                      }
                      className={classes.leftAlign}
                      onClick={() => { setShowMarkAsDoneAlert(true) }}
                    >
                      <Typography
                        component="span"
                        variant="h5"
                        color={"textSecondary"}
                      >
                        Klaar
                      </Typography>
                    </Button>
                  )}

                  {loadingAccept || loadingDecline ? (
                    <CircularProgress />
                  ) : activity.activity.status !==
                    ActivityStatus.oneOnOneDeclined &&
                    activity.activity.status !==
                    ActivityStatus.oneOnOneDeclinedAndInitiatedNew &&
                    activity.activity.status !== ActivityStatus.Closed ? (
                    <Button
                      variant="text"
                      startIcon={
                        <Avatar
                          src={"/assets/img/icons8-u-turn-to-right-60.png"}
                          style={{ width: 15, height: 15 }}
                        />
                      }
                      className={classes.leftAlign}
                      onClick={handleClick}
                    >
                      <Typography
                        component="span"
                        variant="h5"
                        color={"textSecondary"}
                      >
                        Opvolgen
                      </Typography>
                    </Button>
                  ) : null}

                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {activity.activity.status ===
                      ActivityStatus.oneOnOneAccepted
                      ? menuItemsForAccept()
                      : menuItemForNewActivity()}
                  </Menu>
                </Grid>
              </Grid>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
