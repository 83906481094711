import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';

export default function CircleNewsAutosearchPage(props) {
  const [searchText, setSearchText] = React.useState("");
  const [value, setValue] = React.useState('');

  const updateTextFileds = (event) => {
    setSearchText(event.target.value)
    props.onSearch(event.target.value)

    if (!event.target.value.length) {
      props.onSearch("")
      setValue("")
    }
  }
  const onFocus = () => {
    props.onFocus();
  }
  const onChange = (event, newvalue) => {
    setValue(newvalue)
    if (searchText.length) {
      props.onSearch(newvalue)
    }
  };
  return (
    <Autocomplete
      freeSolo
      value={value}
      size={"small"}
      id="free-solo-2-demo"
      onChange={onChange}
      onFocus={onFocus}
      filterOptions={(x) => x}
      disableClearable
      options={[]}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={updateTextFileds}
          value={searchText}
          placeholder={typeof props.placeHolder !== "undefined" ? props.placeHolder : "Search"}
          InputLabelProps={{
            style: {
              fontSize: 14,
              fontFamily: 'Montserrat', //h2
              fontWeight: 800,
            }
          }}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            style: {
              fontSize: 14,
              fontFamily: 'Montserrat', //h2
              fontWeight: 800,
            },
            endAdornment: (
              <React.Fragment>
                {!searchText.length ? <SearchIcon /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
CircleNewsAutosearchPage.prototype = {
  onSearch: PropTypes.func,
};
