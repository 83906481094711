import * as React from "react";
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { Button, ThemeProvider } from "@mui/material";
import Image from "../Media/Image";
import Text from "../../visualComponents/Text";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";

export default function LearnMasterMatch(props) {
  const history = useHistory();
  const handleNext = () => {
    localStorage.setItem("onBoardProgress", 2);
    props.showTab("MeetCollaborations");
  };

  const highlight = {hl: (...chunks) => <span style={{color: Theme_WIP.palette.primary.main}}>{chunks}</span>};

  React.useEffect(() => {
    if (localStorage.getItem("onBoardProgress") != null && localStorage.getItem("onBoardProgress") <= 3) {
      history.push("/OnboardingStepper");
    }
  }, []);
  return (
    <ThemeProvider theme={Theme_WIP}>
    <Background page color="primary"/>
    <Grid container px={{xs: 0, sm: 25, md: 35, lg: 60, xl: 90}} justifyContent="center">
    <Grid container item sx={{maxWidth: "690px", minWidth: "330px"}} spacing={8}>
      <Grid item xs={2}>
        <Image
          src     = "/assets/img/iamFull.png"
          alt     = "iAm"
          sx      = {{
              minWidth: "35px", 
              borderRadius: Theme_WIP.custom.borderRadius.bottomLeftTopRight
          }}
          width   = "100%"/>
      </Grid>
      <Grid container item xs={12} md={10} rowGap={8}>

        <Background color="white" border="topLeft" p={8} sx={{height: "unset"}}>
          <Grid container direction="row" item xs={12}>
            <Grid item sm={12} xs={12}>
              <Text variant="h2" bold>
                <FormattedMessage id={"OnboardingStep3_Caption1"} values={highlight}/><br/><br/>
                <FormattedMessage id={"OnboardingStep3_Caption2"} values={highlight}/><br/><br/>
                <FormattedMessage id={"OnboardingStep3_Caption3"} values={highlight}/><br/>
              </Text>
            </Grid>
          </Grid>
        </Background>

        <Grid item sm={4} xs={12}>
          <Button color="background" variant="contained" onClick={handleNext} fullWidth>
                  <FormattedMessage id={"continue"} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
    </Grid>
    </ThemeProvider>
  );
}
