import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  DELETE_CIRCLE_POST,
  DONE_CIRCLE_POST,
} from "../../apiServices/mutations";
import { getDateFormatNews } from "../../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import { updateBrandColor } from "../../apiServices/Redux/Reducer";
import theme from "../../styles/container/Theme";
import { useDispatch } from "react-redux";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import UserPostedCard from "./Defaults/UserPostedCard";
import Text from "../../visualComponents/Text";

export default function FeedsCard(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const history = useHistory();
  const intl = useIntl();
  const [isLess, setIsLess] = React.useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof props.newsDetails !== "undefined") {
      // console.log("Role :", props.data.companyRole);
    }
  }, [props]);
  const [removeNewsData, { loading }] = useMutation(DELETE_CIRCLE_POST, {
    onCompleted: (data) => {
      // console.log("remove circle post : ", data);
      if (
        typeof data !== "undefined" &&
        typeof props.onRefresh === "function" &&
        data.deleteCirclePost.errorCode === 0
      ) {
        props.onRefresh();
      }
    },
  });
  const [markasDone, { loading: loadingMarkasDone }] =
    useMutation(DONE_CIRCLE_POST, {
      onCompleted: (data) => {
        // console.log("DONE_CIRCLE_POST : ", data);
        if (
          typeof data !== "undefined" &&
          typeof props.onRefresh === "function" &&
          data.doneCirclePost.errorCode === 0
        ) {
          props.onRefresh();
        }
      },
    });

  const removeNews = () => {
    const variables = {
      circleId: props.data.circlePosts.circleId,
      loginProfileId: userId,
      postId: props.data.circlePosts.id,
      postInfo: props.data.circlePosts.message,
    };

    // console.log("remove news request :", variables);
    removeNewsData({
      variables: variables,
    });
  };
  const navigateToEditPost = () => {
    history.push("/addCirclePost", {
      feedsData: props.data.circlePosts,
      circleDetails: props.circleData,
      isEdit: true,
    });
  };
  const navigateToRequest = () => {
    // console.log("feedscard propspass:", props.data.circlePosts);

    history.push("/respondToNews", {
      feeds: props.data.circlePosts,
      circleDetails: props.circleData,
    });
  };
  const navigateToIntroduction = () => {
    // console.log("user id intor ", props.data.userId);
    history.push("/introduction", {
      request: props.data,
    });
  };
  const navigateToActivity = () => {
    // console.log("All response :", props.data.circlePosts.id);
    dispatch(updateBrandColor(theme.palette.primary.main));
    history.push("/dashboard/activity", {
      activitySourceId: props.data.circlePosts.id,
    });
  };

  const markDone = () => {
    const variables = {
      id: props.data.circlePosts.id,
      loginProfileId: userId,
    };
    // console.log("markdone variable:", variables);
    markasDone({
      variables: variables,
    });
  };
  return (
    <UserPostedCard
    id            = {props.data.userId}
    username      = {props.data.givenName ? props.data.givenName + " " + props.data.familyName : props.data.userId}
    pfp           = {props.data.profilePictureUrl}
    label         = {<FormattedMessage id={"filter_title_circle_post"} />}
    location      = {props.data.location ? " - " + props.data.location : ""}
    role          = {props?.data?.companyRole}
    company       = {props?.data?.companyName}
    circles       = {props?.data?.sharedCircles}
    sharedMatches = {props?.data?.sharedMatchesCount}
    date          = {<React.Fragment>{props.data.circlePosts.createdDate !== null
      ? intl.formatMessage({
        id: "posted",
      }) +
      getDateFormatNews(props.data.circlePosts.createdDate) +
      " "
      : ""}
    {props.data.circlePosts.postedUser === userId ? (
      <Link
        component="button"
        variant="h5"
        underline={"none"}
        onClick={navigateToActivity}
      >
        {"(All responses)"}
      </Link>
    ) : null}</React.Fragment>}
    menuItems     =
      {(props.data.circlePosts.postedUser === userId ||
        props.circleData.loggedInUserRole === "Moderator") 
        && <>
        {props.data.circlePosts.postedUser === userId && (
          <MenuItem onClick={navigateToEditPost}>
            <FormattedMessage id={"edit"} />
          </MenuItem>
        )}
        <MenuItem onClick={removeNews}>
          <FormattedMessage id={"delete"} />
        </MenuItem>
        {props.data.circlePosts.postedUser === userId && (
          <MenuItem onClick={markDone}>
            <FormattedMessage id={"Markasdone"} />
          </MenuItem>
        )}
      </>}
    respond =
      {props.data.circlePosts.postedUser !== userId && navigateToRequest}
    introduce =
      {props.data.circlePosts.postedUser !== userId && navigateToIntroduction}
    onExpand = {setIsLess}
    >
      <Grid container spacing={4}>
        <Grid item xs={10} sm={10}>
            {isLess && props.data.circlePosts.message.length > 150 ? (
              <Text variant="body1" color="white" >
                {props.data.circlePosts.message.substring(0, 150) + " "}
              </Text>
            ) : (
              <Text variant="body1" color="white">
                {props.data.circlePosts.message}
              </Text>
            )}
        </Grid>
      </Grid>
    </UserPostedCard>
  );
}
