import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import { Box } from "@mui/system";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../Header";
import { Alert, Hidden, Skeleton } from "@mui/material";
import Navigationbar from "../Navigationbar";
import Footer from "../Footer";
import { CssBaseline, useTheme } from "@material-ui/core";
import { SEND_MESSAGE } from "../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import ActivityUserSearch from "../ActivityUserSearch";
import SuccessAlertDialog from "../Alert/SuccessAlertDialog";
import { ActivityTypes } from "../../apiServices/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "../../styles/component/Activities/SendMessageStyle";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import FormContainer from "../Forms/FormContainer";
import InputSection from "../Forms/InputSection";

const useStyles = makeStyles(styles);

export default function SendMessage() {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const intl = useIntl();

  const [activity, setActivity] = React.useState({});
  const [selectedUser, setSelectedUser] = React.useState(location.state?.selectedUser ?? location.state?.request ?? location.state?.activity ?? null);

  const [SendMessages, { data, loading }] = useMutation(SEND_MESSAGE);
  let [input, setInput] = React.useState({
    message: "",
    recipient: [],
  });
  let [inputError, setInputError] = React.useState({
    message: "",
    recipient: "",
  });
  React.useEffect(() => {
    if (location.state && location.state.activity) {
      setActivity(location.state.activity);
      setInput((prevState) => ({
        ...prevState,
        recipient:
          ActivityTypes.introduction ===
            location.state.activity.activityTypeDesc
            ? location.state.activity.activity.requesterUserProfileId
            : location.state.activity.activity.recipientUserProfileIds,
      }));
    }
    if (location.state && location.state.selectedUser) {
      setSelectedUser(location.state.selectedUser);
      setInput((prevState) => ({
        ...prevState,
        recipient: location.state.selectedUser.userId
      }));
    }
    if (typeof data !== "undefined") {
      // console.log("Message sent result", data);
      setInput((prevState) => ({
        ...prevState,
        message: "",
      }));
    }
  }, [location, data]);

  const updateTextFileds = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const sendMessageAction = async () => {
    let isValid = await checkForErrors();
    const variable = activity.activity
      ? {
        activitySource: activity.activity.activitySourceDesc,
        messages: input["message"],
        recipientUserProfileIds: activity.activity.requesterUserProfileId,
        requesterUserProfileId: userId,
        activitySourceId: activity.activity.id,
      }
      : {
        messages: input["message"],
        recipientUserProfileIds: input["recipient"],
        requesterUserProfileId: userId,
      };
    // console.log("variable", variable);
    if (isValid) {
      SendMessages({
        variables: variable,
      });
    }
  };

  const checkForErrors = async () => {
    // console.log("input", input);
    setInputError((prevState) => ({
      ...prevState,
      recipient: !input["recipient"].length ? "recipientError" : "",
      message: !input["message"].trim() ? "messageError" : "",
    }));
    return input["message"].trim() && input["recipient"].length;
  };
  const searchAction = (searchUser) => {
    // console.log("searchUser", searchUser);
    setInput((prevState) => ({
      ...prevState,
      recipient: [searchUser.userId],
    }));
    setInputError((prevState) => ({
      ...prevState,
      recipient: "",
    }));
  };
  const getUserName = () => {
    if (typeof activity.givenName !== "undefined") {
      return activity.givenName + " " + activity.familyName
    } else if (selectedUser !== null) {
      return selectedUser.givenName + " " + selectedUser.familyName
    } else {
      return ""
    }
  }


  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <FormContainer
        modalMessage = {data?.sendMessageActivity?.errorCode ==  0 ? <FormattedMessage id={"messageSentSuccessfully"} /> : undefined}
        onModalClose = {() => history.goBack()}
        error        = {data?.sendMessageActivity?.errorCode == -1 ? data.sendMessageActivity.message : undefined}
        title        = {<FormattedMessage id="sendAMessage"/>}
        loading      = {loading}
        onSave       = {sendMessageAction}
        disabled     = {loading || !input.message.length || !input.recipient.length}
        onCancel     = {() => history.goBack()}
        saveId       = "Button_text_Send"
      >
        <InputSection
        type        = {selectedUser ? "text" : "contactsearch"}
        disabled    = {selectedUser || loading}
        id          = "recipient"
        title       = {<FormattedMessage id="toWho"/>}
        value       = {selectedUser ? getUserName() : undefined}
        onChange    = {(newVal) => selectedUser ? setInput({...input, role: newVal.target.value}) : searchAction(newVal)}
        placeHolder = {intl.formatMessage({ id: "userName" })}
        hint        = {inputError["recipient"] ? <FormattedMessage id="pleaseEnterTheUserName" /> : undefined}
        />

        <InputSection
        type        = "textarea"
        disabled    = {loading}
        id          = "message"
        placeholder = {intl.formatMessage({ id: "Type" })}
        title       = {<FormattedMessage id="writeAMessage"/>}
        onChange    = {updateTextFileds}
        hint        = {inputError["message"] ? <FormattedMessage id="pleaseEnterMessage" /> : undefined}
        max         = {250}
        />
      </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
