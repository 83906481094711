import { Alert, Button, FormControl, FormHelperText, Grid, Hidden, Skeleton, TextField, TextareaAutosize, Zoom, useTheme } from "@mui/material";
import Background from "../../visualComponents/Background";
import SuccessAlertDialog from "../Alert/SuccessAlertDialog";
import { FormattedMessage, useIntl } from "react-intl";
import Text from "../../visualComponents/Text";
import ActivityUserSearch from "../ActivityUserSearch";
import IntroductionAutoSearchPage from "../IntroductionAutoSearchPage";
import { GetUserId } from "../../apiServices/CommonMethods";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { CREATE_INTRODUCTION } from "../../apiServices/mutations";
import React from "react";
import { useMutation } from "@apollo/client";
import { ActivitySource } from "../../apiServices/Constants";
import { isEmail } from "../General";
import Image from "../Media/Image";

export default function IntroductionForm(props) {
  const isPrefilled = props.name && props.userId;
  const userName    = props.name ?? "";
  const userId      = GetUserId();
  const theme       = useTheme();
  const location    = useLocation();
  const intl        = useIntl();

  const [createIntroduction, { data, loading }] = useMutation(CREATE_INTRODUCTION);

  let [input, setInput] = React.useState({
    recipientUserProfileIds : [],
    activitySource          : null,
    activitySourceId        : null,
    messages                : "",
    requesterUserProfileId  : "",
    selectedUser            : "",
    recipientEmails         : [],
  });
  let [inputError, setInputError] = React.useState({
    selectedUser : "",
    toUser       : "",
    messages     : "",
    validEmail   : "",
  });

  React.useEffect(() => {
    if (isPrefilled) {
      setInput((prevState) => ({
        ...prevState,
        selectedUser: props.userId
      }));
    }
    if (data == null) {
      setInput((prevState) => ({
        ...prevState,
        messages: "",
      }));
    }
  }, [location, data]);

  const updateTextFields = (event) => {
    const { value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      messages: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      messages: "",
    }));
  };
  const searchAction = (searchUser) => {
    setInput((prevState) => ({
      ...prevState,
      selectedUser: searchUser.userId,
    }));
    setInputError((prevState) => ({
      ...prevState,
      selectedUser: "",
    }));
  };
  const searchActionToUser = (searchUser) => {
    if (typeof searchUser !== "undefined") {
      setInput((prevState) => ({
        ...prevState,
        recipientUserProfileIds: [searchUser.userId],
        recipientEmails: [],
      }));
      setInputError((prevState) => ({
        ...prevState,
        toUser: "",
      }));
    }
  };
  const onSearchTextChanges = (searchText) => {
    setInput((prevState) => ({
      ...prevState,
      recipientEmails: [searchText],
      recipientUserProfileIds: [],
    }));
    setInputError((prevState) => ({
      ...prevState,
      toUser: "",
    }));
    if (!searchText.length) {
      setInput((prevState) => ({
        ...prevState,
        recipientUserProfileIds: [],
      }));
    }
  };
  const onSearchTextChangesOnIntroduceUser = (searchText) => {
    if (!searchText.length) {
      setInput((prevState) => ({
        ...prevState,
        selectedUser: "",
      }));
    }
  };

  const handleSave = async () => {
    let isValid = await checkForErrors();
    const variable = {
        recipientUserProfileIds: [
          ...input["recipientUserProfileIds"],
          input["selectedUser"],
        ],
        messages               : input["messages"],
        requesterUserProfileId : userId,
        activitySource         : null, //TODO
        activitySourceId       : null, //TODO
        recipientEmails        : input["recipientEmails"].length > 0 ? input["recipientEmails"] : null,
      };

    if (isValid) {
        createIntroduction({
            variables: variable
        });
        if(props.afterIntroduction != null)
            props.afterIntroduction();
        if(props.goBack != null)
            setTimeout(() => {
                props.goBack();
            }, 1000);
    }
  };

  const toUserCheck = () => {
    return (
      input["recipientUserProfileIds"].length > 0 ||
      (input["recipientEmails"].length > 0 &&
        isEmail(input["recipientEmails"][0]))
    );
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      messages     : !input["messages"].trim() ? "messageError"   : "",
      toUser       : !toUserCheck()            ? "toUserError"    : "",
      selectedUser : !input["selectedUser"]    ? "fromEmailError" : "",
    }));
    return input["messages"].trim() && input["selectedUser"] && toUserCheck();
  };

  return(
    <Zoom in={true}>
    <Grid container>
    <Background container gap={8} border="bottomLeft" p={7}>
        {/* Result notifications */}
        {data != null && <Grid item sm={12} xs={12}>
        {data.createIndroductionActivity.errorCode === 0 &&
            <Text item xs={12} variant="h1" id={"IntroductionSentSuccessfully"} my={5} align="center" bold/>}
        {data.createIndroductionActivity.errorCode !== 0 &&
            <Text item xs={12} variant="h1" my={5} align="center" color="red" bold>
                {data.createIndroductionActivity.message}
            </Text>}
        </Grid>}
        <Hidden xsUp={data != null}>
            <Text item xs={12} variant="h1" id={"/matchtime/results.introduction.title"} bold/>
            <Hidden smDown>
                <Grid item>
                    <Image
                        src     = {props.pfp}
                        alt     = "pfp"
                        sx      = {{ borderRadius: theme.custom.borderRadius.bottomLeft, aspectRatio: "1 / 1" }}
                        width   = "90px"/>
                </Grid>
            </Hidden>
            <Grid item xs gap={5}>
                <Text variant="h2" id={"/matchtime/results.introduction.label1"} mb={2} bold/>
                <FormControl fullWidth variant="filled">
                    {loading ? (
                        <Skeleton variant="rectangular" height={40} />
                    ) : isPrefilled ? (
                        <TextField
                        id="recipient"
                        variant="filled"
                        value={userName}
                        style={{background: theme.palette.background.main, border: "none", borderRadius: 5}}
                        disabled
                        />
                    ) : (
                        <ActivityUserSearch
                        placeHolder={intl.formatMessage({
                            id: "Input_Label_Text_Type_UserName",
                        })}
                        style={{background: theme.palette.background.main, border: "none", borderRadius: 5}}
                        onSearch={searchAction}
                        onSearchTextChanges={onSearchTextChangesOnIntroduceUser}
                        userToRemove={
                            input["recipientUserProfileIds"].length
                            ? input["recipientUserProfileIds"][0]
                            : null
                        }
                        />
                    )}
                    {inputError["selectedUser"] && ( 
                        <Text variant="h5" color="red" id={"pleaseEnterTheUserName"} />
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Text variant="h2"    id={"/matchtime/results.introduction.label2"} bold/>
                <Text variant="body2" id={"/matchtime/results.introduction.description1"}  mb={2}/>
                <FormControl fullWidth variant="filled" style={{background: theme.palette.background.main, border: "none", borderRadius: 5}}>
                    {loading ? (
                        <Skeleton variant="rectangular" height={40} />
                    ) : (
                        <IntroductionAutoSearchPage
                        placeHolder={intl.formatMessage({
                            id: "TypeUsernameOrEmailAddress",
                        })}
                        onSearch={searchActionToUser}
                        onSearchTextChanges={onSearchTextChanges}
                        userToRemove={input["selectedUser"] ?? null}
                        />
                    )}
                    {inputError["toUser"] ? (
                        <Text variant="h5" color="red" id={input["recipientUserProfileIds"].length > 0 ?  "pleaseEnterTheUserName" : "emailWrong"}/>
                    ) : null}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Text variant="h2"    id={"/matchtime/results.introduction.label3"} bold/>
                <Text variant="body2" id={"/matchtime/results.introduction.description2"} mb={2}/>
                <FormControl fullWidth variant="filled">
                {loading ? (
                    <Skeleton variant="rectangular" height={40} />
                ) : (
                    <TextareaAutosize
                    style={{background: theme.palette.background.main, border: "none", borderRadius: 5}}
                    maxLength={200}
                    id={"messages"}
                    variant="filled"
                    aria-label="minimum height"
                    minRows={5}
                    value={input["messages"]}
                    onChange={updateTextFields}
                    />
                )}
                {inputError["messages"] ? (
                    <FormHelperText>
                        {" "}<Text variant="h5" color="red" id={"pleaseEnterMessage"} />{" "}
                    </FormHelperText>
                ) : null}
                </FormControl>
            </Grid>
            <Grid item>
            {!loading && data == null &&
                <Button variant="contained" onClick={handleSave}>
                    <Text id={"/matchtime/results.introduction.submit"} color="primary" p={1}/>
                </Button>}
            </Grid>
            <Grid item>
            {!loading && data == null && props.goBack != null &&
                <Button variant="contained" color="white" onClick={props.goBack}>
                    <Text id={"/matchtime/results.introduction.cancel"} color="white" p={1}/>
                </Button>}
            </Grid>
        </Hidden>
    </Background>
    </Grid>
    </Zoom>);
  }
  