import { Button, ThemeProvider } from "@mui/material";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import { FormattedMessage } from "react-intl";

export default function Modal(props) {
    const open    = props.open;
    const onClose = props.onClose;

    return(
        <ThemeProvider theme={Theme_WIP}>
            {open && <Background container p={5} justifyContent="center" color="rgba(0,0,0,0.5)" sx={{position: "fixed", inlet: 0, zIndex: "9000 !important"}}>
                <Background gap={7} container item color="white" justifyContent="center" border="bottomLeft" mt={"25vh"} p={8} px={{xs: 5, md: 16}} maxWidth="sm" height="fit-content">
                    {props.children}
                    <Button onClick={onClose} sx={{textDecoration: "underline"}}><FormattedMessage id="Button_Text_OK"/></Button>
                </Background>
            </Background>}
        </ThemeProvider>
    );
}