import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { Chip} from "@mui/material";
import { useMutation } from "@apollo/client";
import Styles from "../../styles/component/EventTabs/EventVisitorsStyle";
import { useHistory } from "react-router-dom";
import {
  REMOVE_EVENT_MANAGER,
  MAKEAS_EVENT_MANAGER,
} from "../../apiServices/mutations";
import { FormattedMessage, useIntl } from "react-intl";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import UserPostedCard from "./Defaults/UserPostedCard";

const useStyles = makeStyles(Styles);

export default function VisitorCard(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const [eventdata, setEventData] = React.useState({});
  const buttonCondition = userId != props.data.userId && !props.data.userId.includes("@") && (eventdata.loginUserStatus == "Attending" || eventdata.loginUserStatus == "Manager");

  useEffect(() => {
    if (typeof props.eventDetails !== "undefined") {
      setEventData(props.eventDetails);
    }
  }, [props]);
  const [removeFromEvent, { loading }] = useMutation(
    REMOVE_EVENT_MANAGER,
    {
      onCompleted: (data) => {
        if (
          typeof data !== "undefined" &&
          typeof props.onRefresh === "function" &&
          data.removeFromEvent.errorCode === 0
        ) {
          props.onRefresh();
        }
      },
    }
  );
  const [
    makeManager,
    { loading: loadingEventManager },
  ] = useMutation(MAKEAS_EVENT_MANAGER, {
    onCompleted: (data) => {
      if (
        typeof data !== "undefined" &&
        typeof props.onRefresh === "function"
      ) {
        props.onRefresh();
      }
    },
  });
  const removeEvent = (data) => {
    const variables = {
      id: eventdata.id,
      loginProfileId: userId,
      profileId: data.userId,
    };
    removeFromEvent({
      variables: variables,
    });
  };

  const makeAsEventManager = (data) => {
    // console.log("data", data);
    const variables = {
      id: eventdata.id,
      loginProfileId: userId,
      profileId: data.userId,
    };
    makeManager({
      variables: variables,
    });
  };

  function navigateToActivity(href) {
    history.push(href, {
      request: {
        userId: props.data.userId,
        givenName:  props.data.givenName,
        familyName: props.data.familyName,
      },
      activity: {
        activity: {
          recipientUserProfileIds: props.data.userId,
          requesterUserProfileId:  props.data.userId
        },
        givenName:  props.data.givenName,
        familyName: props.data.familyName,
      },
    });
  };

  return (
    <UserPostedCard
    id            = {props.data.userId}
    username      = {props.data.givenName ? props.data.givenName + " " + props.data.familyName : props.data.userId}
    pfp           = {props.data.profilePictureUrl ?? "/assets/img/userProfile.png"}
    role          = {props?.data?.companyRole}
    company       = {props?.data?.companyName}
    circles       = {props?.data?.sharedCircles}
    label         = {!(
      eventdata.loginUserStatus === "Manager" &&
      props.data.userId === userId
    ) &&
      eventdata.loginUserStatus === "Manager" &&
      eventdata.loginUserStatus !== null ? (
      props.data.visitorStatus === "Manager" &&
        props.data.userId !== userId ? (
          <FormattedMessage id={"Event_attending"} />
      ) : props.data.visitorStatus === "Invited" ? (
          <FormattedMessage id={"VisitorCard_Pending"} />
      ) : (props.data.visitorStatus)
    ) : null}
    location      = {props?.data?.location}
    info          = 
      {props.data.userId !== userId ? (
        <span className={classes.violet}>
          {props.data.isMatch !== null && props.data.isMatch !== false
            ? intl.formatMessage({ id: "VisitorCard_Match" }) + " "
            : ""}
        </span>
      ) : (
        ""
      )}
    menuItems=
      {!(
        eventdata.loginUserStatus === "Manager" &&
        props.data.userId === userId
      ) &&
        eventdata.loginUserStatus === "Manager" &&
        (props.data.visitorStatus === "Attending" || props.data.visitorStatus === "Deelnemer")
      && <>
        <MenuItem onClick={() => makeAsEventManager(props.data)}>
          {" "}
          <FormattedMessage
            id={"EventVisitors_MenuItem_MakeEventManager"}
          />
        </MenuItem>
        <MenuItem onClick={() => removeEvent(props.data)}>
          {" "}
          <FormattedMessage
            id={"EventVisitors_MenuItem_RemoveFromEvent"}
          />
        </MenuItem>
      </>}
      respond={buttonCondition && (() => navigateToActivity("/sendMessage"))}
      meeting={buttonCondition && (() => navigateToActivity("/suggestOneToOne"))}
      tags   ={props?.data?.skills}
    >
      {props.children}
    </UserPostedCard>
  );
}
