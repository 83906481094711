import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@material-ui/core/Grid";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import SliderPage from "../SliderPage";
import LocationAutoComplete from "../Autocomplete/LocationAutoComplete";
import { Box } from "@mui/system";
import {
  masterDataCircleOperatesIn,
  masterDataCircleTags,
} from "../../apiServices/Constants";
import { removeItemOnce } from "../../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import CircleInfoStyle from "../../styles/component/Circles/CircleInfoStyle";

const useStyles = makeStyles(CircleInfoStyle);

export default function CircleFilter(props) {
  const classes = useStyles();
  const intl = useIntl();

  let [input, setInput] = React.useState({
    selectedPlace: null,
    maxDistance: null,
    selectedCircleTags: null,
    selectedOperatesIn: null,
    pageNumber: 1,
  });
  const onSliderChange = (value) => {
    // console.log("sliderValue", value);
    setInput((prevState) => ({
      ...prevState,
      maxDistance: value,
    }));
  };

  const onLocationChange = (location) => {
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        selectedPlace:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
    } else {
      setInput((prevState) => ({
        ...prevState,
        selectedPlace: null,
        maxDistance: 0,
      }));
    }
  };
  const onSearch = (event) => {
    // console.log("selcetd items", input);
    props.onSearch(input);
  };
  const onCircleTagSelection = (event) => {
    var newTags = input["selectedCircleTags"] ?? [];
    if (event.target.checked) {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        let tagsAll = masterDataCircleTags().map(({ key }) => key);
        newTags = tagsAll;
      } else if (!newTags.includes(event.target.name)) {
        newTags.push(event.target.name);
      }
    } else {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        newTags = [];
      } else {
        removeItemOnce(newTags, event.target.name);
      }
    }
    setInput((prevState) => ({
      ...prevState,
      selectedCircleTags: newTags,
    }));
  };
  const onCircleOperatesInSelection = (event) => {
    var newOperatesIn = input["selectedOperatesIn"] ?? [];
    if (event.target.checked) {
      if (!newOperatesIn.includes(event.target.name)) {
        newOperatesIn.push(event.target.name);
      }
    } else {
      removeItemOnce(newOperatesIn, event.target.name);
    }
    // console.log("newOperatesIn", newOperatesIn);
    setInput((prevState) => ({
      ...prevState,
      selectedOperatesIn: newOperatesIn,
    }));
  };
  const isCheckedTag = (option) => {
    const arrayCircleTags = input["selectedCircleTags"] ?? [];
    if (arrayCircleTags.includes(option)) {
      return true;
    }
    return false;
  };

  const isCheckedAllTag = () => {
    const arrayCircleTags = input["selectedCircleTags"] ?? [];
    if (arrayCircleTags.length === masterDataCircleTags().length) {
      return true;
    }
    return false;
  };
  const isCheckedOperatesIn = (option) => {
    const arrayOperatesIn = input["selectedOperatesIn"] ?? [];
    if (arrayOperatesIn.includes(option)) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <FormattedMessage id={"circlePage_FilterItem_Text"} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <Typography variant="h6" color={"textSecondary"}>
              <FormControlLabel
                control={<Checkbox checked={isCheckedAllTag()} />}
                label={intl.formatMessage({ id: "All" })}
                onChange={onCircleTagSelection}
                name={intl.formatMessage({ id: "All" })}
              />
            </Typography>
            {masterDataCircleTags().map((option, index) => (
              <Typography variant="h6" color={"textSecondary"} key={index}>
                <FormControlLabel
                  control={<Checkbox checked={isCheckedTag(option.key)} />}
                  label={option.key}
                  onChange={onCircleTagSelection}
                  name={option.key}
                />
              </Typography>
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.noAccordian}>
        <FormGroup>
          {masterDataCircleOperatesIn().map((option, index) => (
            <Typography variant="h6" color={"textSecondary"} key={index}>
              <FormControlLabel
                control={<Checkbox checked={isCheckedOperatesIn(option.key)} />}
                label={option.key}
                onChange={onCircleOperatesInSelection}
                name={option.key}
              />
            </Typography>
          ))}
        </FormGroup>
      </Accordion>
      {/* <Accordion className={classes.noAccordian}>
                <FormControl variant="filled" className={classes.noAccordian}>
                    <DatePicker updateDate={updateDate} isSetMinDate={false} />
                </FormControl>
            </Accordion> */}
      <Accordion>
        <AccordionDetails>
          <Box sx={{ width: "100%" }}>
            <LocationAutoComplete
              onChange={onLocationChange}
              placeHolder={intl.formatMessage({
                id: "circlePage_FilterItem_Location",
              })}
            //   placeholder={intl.formatMessage({ id: "circlePage_FilterItem_Location" })}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.noAccordian}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <SliderPage onChange={onSliderChange} disabled={false} position={input.maxDistance} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ButtonDarkBlue onClick={onSearch}>
              <FormattedMessage id={"circlePage_Filter_Button_Text_Search"} />
            </ButtonDarkBlue>
          </Grid>
        </Grid>
      </Accordion>
    </div>
  );
}
