
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DateRange, ListAlt, Person, RadioButtonUnchecked, Search } from '@material-ui/icons';
import styles from '../styles/component/FooterStyle'
import { useHistory } from 'react-router';
import theme from '../styles/container/Theme';
import { selectedBrandColor, updateBrandColor } from '../apiServices/Redux/Reducer';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Text from '../visualComponents/Text';
import Theme_WIP, { ThemeConstants } from '../styles/themes/main/Theme_WIP';
import {ReactComponent as MastersIcon} from "../svg/HeaderNavigationEtc/MastersIcon.svg";
import {ReactComponent as RequestsIcon} from "../svg/HeaderNavigationEtc/RequestsIcon.svg";
import {ReactComponent as TasksIcon} from "../svg/HeaderNavigationEtc/TaskIcon.svg";
import {ReactComponent as EventsIcon} from "../svg/HeaderNavigationEtc/EventsIcon.svg";
import {ReactComponent as CirclesIcon} from "../svg/HeaderNavigationEtc/CirclesIcon.svg";
import { BottomNavigation, BottomNavigationAction } from '@mui/material';


export default function Footer() {
    const iconProps = {

    };
    const barStyle = {
        width: "100%",
        bottom: 0,
        backgroundColor: ThemeConstants.palette.white.main,
        "button": {
            padding: "5px 0",
            minWidth: "unset"
        },
        ".MuiBottomNavigationAction-label": {
            marginTop: "5px",
            color: ThemeConstants.palette.primary.main,
            fontFamily: ThemeConstants.typography.body1.fontFamily,
            fontSize: ThemeConstants.typography.body1.fontSize
        },
        zIndex: 100000,
        borderTop: "1px solid #c4c4c4"
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState('recents');
    const [notification, setNotification] = React.useState(localStorage.getItem("notifications") ?? 0);

    const handleChange = (event, newValue) => {
        setNotification(localStorage.getItem("notifications"));
        setValue(newValue);
        dispatch(updateBrandColor(theme.palette.primary.main))
        if (newValue === "masters") {
            history.push("/dashboard/masters")
        } else if (newValue === "requests") {
            history.push("/dashboard/requests")
        } else if (newValue === "activity") {
            history.push("/dashboard/activity")
        } else if (newValue === "event") {
            history.push("/dashboard/events")
        } else if (newValue === "circles") {
            history.push("/dashboard/circles")
        }
    };

    useEffect(() => {
        setNotification(localStorage.getItem("notifications"));
    });

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0 }}>
                <BottomNavigation value={value} onChange={handleChange} sx={barStyle}>
                    <BottomNavigationAction {...iconProps} showLabel label="Personen" value="masters"  icon={<MastersIcon />} />
                    <BottomNavigationAction {...iconProps} showLabel label="Kansen"   value="requests" icon={<RequestsIcon />} />
                    <BottomNavigationAction {...iconProps} showLabel label="Taken"    value="activity" icon={<React.Fragment>
                        <TasksIcon />
                            { notification > 0 ? 
                            <span style={{
                                position: "absolute", top: 5, left: "50%",
                                backgroundColor: Theme_WIP.palette.notification.main, 
                                color: Theme_WIP.palette.notification.contrastText,
                                fontSize: 12,
                                fontWeight: Theme_WIP.typography.fontWeightBold,
                                padding: "5px 9px", 
                                borderRadius: "100%", 
                                aspectRatio: "1 / 1"}}>{notification}</span> 
                            : null}
                    </React.Fragment>} />
                    <BottomNavigationAction {...iconProps} showLabel label="Events"  value="event" icon={<EventsIcon />} />
                    <BottomNavigationAction {...iconProps} showLabel label="Cirkels" value="circles" icon={<CirclesIcon />} />
                </BottomNavigation>
            </AppBar>
        </React.Fragment>
    );
}
