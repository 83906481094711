import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import { useMutation } from "@apollo/client";
import Styles from "../../styles/component/Cards/EventNewsCardStyle";
import { useHistory } from "react-router-dom";
import { Stack, ThemeProvider } from "@mui/material";
import { getDateFormatNews } from "../../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import { updateBrandColor } from "../../apiServices/Redux/Reducer";
import theme from "../../styles/container/Theme";
import { useDispatch } from "react-redux";
import { DELETE_EVENT_NEWS } from "../../apiServices/mutations";
import AlertWithAction from "../Alert/AlertWithAction";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import UserPostedCard from "./Defaults/UserPostedCard";
import Text from "../../visualComponents/Text";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";

export default function EventNewsCard(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLess, setIsLess] = React.useState(true);
  const [isShowPreferenceAlert, setIsShowPreferenceAlert] = React.useState(false);

  const [removeNewsData, { loading }] = useMutation(DELETE_EVENT_NEWS, {
    onCompleted: (data) => {
      // console.log("remove news : ", data);
      if (
        typeof data !== "undefined" &&
        typeof props.onRefresh === "function" &&
        data.deleteEventNews.errorCode === 0
      ) {
        props.onRefresh();
      }
    },
  });

  const removeNews = () => {
    const variables = {
      eventId: props.data.eventId,
      loginProfileId: userId,
      newsId: props.data.id,
      newsInfo: props.data.newsInfo,
      sendNotification: false,
    };
    // console.log("remove news request :", variables);
    removeNewsData({
      variables: variables,
    });
  };

  useEffect(() => {
    // console.log("EventNewsCard props.data", props);
    if (typeof props.eventData !== "undefined") {
      // console.log("Role :", props.data.companyRole);
    }
  }, [props]);

  const navigateToEditNews = () => {
    history.push("/AddEventNews/", {
      newsData: props.data,
      eventDetails: props.eventData,
      isEdit: true,
    });
  };
  const navigateToRequest = () => {
    history.push("/respondToEventNews", {
      eventNews: props.data,
      eventDetails: props.eventData,
    });
    // console.log("EventNewscard props:", props.data);
  };

  const navigateToActivity = () => {
    dispatch(updateBrandColor(theme.palette.primary.main));
    history.push("/dashboard/activity", {
      activitySourceId: props.data.id,
    });
  };

  const handleCloseAlertAction = async () => {
    setIsShowPreferenceAlert(false)
  };
  const showPreferenceWarning = () => {
    setIsShowPreferenceAlert(true)
  }
  return (
    <UserPostedCard
    id            = {props.data.userId}
    username      = {props.data.givenName ? props.data.givenName + " " + props.data.familyName : props.data.userId}
    pfp           = {props.data.profilePictureUrl ?? "/assets/img/userProfile.png"}
    label         = {< FormattedMessage id={"newsUpdate"} />}
    location      = {props.data.location ? " - " + props.data.location : ""}
    date          = {getDateFormatNews(props.data.postedDate)}
    role          = {props?.data?.companyRole}
    company       = {props?.data?.companyName}
    circles       = {props?.data?.sharedCircles}
    info          =
      {props.data.createdProfileId !== userId ? (
        <span>
          {". " +
            props.data.sharedMatchCount +
            " " +
            intl.formatMessage({
              id: "shared_matches",
            })}
        </span>
      ) : null}
    menuItems     =
      {props.data?.createdProfileId === userId && <>
        <MenuItem onClick={navigateToActivity}>
          <FormattedMessage id={"allResponses"} />
        </MenuItem>
        <MenuItem onClick={navigateToEditNews}>
          <FormattedMessage id={"edit"} />
        </MenuItem>
        <MenuItem onClick={removeNews}>
          <FormattedMessage id={"delete"} />
        </MenuItem>
      </>}
    respond =
      {props.data.createdProfileId !== userId && navigateToRequest}
    onExpand={props.data.newsInfo.length > 150 && setIsLess}
    >
      {isShowPreferenceAlert && <Grid container spacing={1}>
          <AlertWithAction
            message={
              intl.formatMessage({ id: "preference_permission_error", })
            }
            title={
              intl.formatMessage({ id: "warning" })}
            handleClose={handleCloseAlertAction}
            open={true}
          />
      </Grid>}

      {isLess && props.data.newsInfo.length > 150 ? (
        <Text variant="body1" color="background" display="inline">
          {props.data.newsInfo.substring(0, 150) + "..."}
        </Text>
      ) : (
        <ThemeProvider theme={Theme_WIP}>
        <Text variant="body1" color="background">
          {props.data.newsInfo}
        </Text>
        </ThemeProvider>
      )}
    </UserPostedCard>
  );
}
