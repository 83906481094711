import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Styles from "../../../styles/component/Cards/RequestCardStyle";
import { Chip } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(Styles);

export default function MentoringCardDetails(props) {
  const classes = useStyles();

  const [requestDetails, setRequestDetails] = React.useState({});

  useEffect(() => {
    if (typeof props.request !== "undefined") {
      setRequestDetails(props.request);
    }
  }, [props]);

  return (
    <Grid item xs={12}>
      <Typography variant="h3" className={[classes.boldFont, classes.p15]}>
        <FormattedMessage id={"CompanyIndustry_PrefilledIndustryOfKnowledge"} />
        :{" "}
        <Typography variant="h3" className={classes.chip} display="inline">
          {(requestDetails.industries ?? []).map((industry, index) => (
            <Chip
              key={index}
              label={
                <Typography
                  variant="h3"
                  className={classes.p15}
                  display="inline"
                >
                  {industry}
                </Typography>
              }
              className={classes.chipItem2}
              size="small"
            />
          ))}
        </Typography>
      </Typography>
      <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
        <FormattedMessage id={"PreferredFieldsOfKnowledge"} />:{" "}
        <Typography variant="h3" className={classes.chip} display="inline">
          {(requestDetails.preferredFieldOfKnowledge ?? []).map(
            (field, index) => (
              <Chip
                key={index}
                label={
                  <Typography
                    variant="h3"
                    className={classes.p15}
                    display="inline"
                  >
                    {field}
                  </Typography>
                }
                className={classes.chipItem2}
                size="small"
              />
            )
          )}
        </Typography>
      </Typography>
      <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
        <FormattedMessage id={"WhatIWishFromAMentor"} />:
        <Typography
          variant="h3"
          className={classes.p15}
          display="inline"
        >
          {" "}
          {requestDetails.wishFromMentor ?? ""}
        </Typography>
      </Typography>
      {requestDetails.myChallenges ? (
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"MyChallenges"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {requestDetails.myChallenges ?? ""}
          </Typography>
        </Typography>
      ) : null}
    </Grid>
  );
}
MentoringCardDetails.prototype = {};
