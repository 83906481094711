import { ACCEPTED_INVITES } from "../Queries";
import { useCachedQuery } from "./CachedQuery";

export function useAcceptedInvites(userId) {
return useCachedQuery(
    "acceptedInvites",
    ACCEPTED_INVITES,
    { userId: userId },
    (data) => data?.acceptedInvites,
    0.25,
    true
);
}
  