import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import styles from "../styles/container/AccountSettingStyle";
import { getDateOnlyString, removeItemOnce } from "../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";
import PersonaInfoUpdate from "../components/AccountSettingsTab/PersonalInfoUpdate";
import ChangePassword from "../components/AccountSettingsTab/ChangePassword";
import {
  masterDataCompanyLevel,
  masterDataPreferenceList,
  PaymentStatus,
} from "../apiServices/Constants";
import { CANCEL_SUBSCRIPTION, UPDATE_NOTIFICATIONSEENBY_STATUS, UPDATE_PREFERENCE, UPDATE_USER_SUBSCRIPTION_NOTIFICATION_STATUS } from "../apiServices/mutations";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, Skeleton, Stack, ThemeProvider } from "@mui/material";
import { GET_CURRENT_PLAN, GET_CURRENT_SUBSCRIPTION_EXPIRY, GET_INVOICE_LIST, GET_PREFERENCE, GET_USER_SUBSCRIPTIONS_NOTIFICATION } from "../apiServices/Queries";
import { useHistory } from "react-router-dom";
import SuccessAlertWithAction from "../components/Alert/SuccessAlertWithAction";
import GoTo from "../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";
import { useSubInfo } from "../objects/Subscriptions";
import Theme_WIP from "../styles/themes/main/Theme_WIP";
import Background from "../visualComponents/Background";
import Text from "../visualComponents/Text";

const useStyles = makeStyles(styles);
export default function AccountSettings(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl()
  const [isShowChnagePassword, setIsShowChnagePassword] = React.useState(false);
  const [passwordChangeOutput, setPasswordChangeOutput] = React.useState("");
  const [selectedPreference, setSelectedPreference] = React.useState([]);
  const [currentPlan, setCurrentPlan] = React.useState(userRole);
  const [invoiceList, setInvoiceList] = React.useState([]);
  const [errorPreference, setErrorPreference] = React.useState("");
  const [showPaymentStatus, setShowPaymentStatus] = React.useState(false);
  const [cancelStatus, setCancelStatus] = React.useState(false);
  const [isNotificationSeenByUser, setIsNotificationSeenByUser] = React.useState(true);
  const [invoiceListError, setInvoiceListError] = React.useState("");
  const [subscriptionExpiry, setSubscriptionExpiry] = React.useState(null);
  const [userNewSubscriptionNotification, setUserNewSubscriptionNotification] = React.useState("");
  const [subscriptionType, setSubscriptionType] = React.useState();
  const handleCancelSubscription = async () => {
    const variable = {
      userid: userId,
    };
    // console.log("variable", variable);
    cancelSubscription({
      variables: variable,
    });
  };
  const subscriptionInfo = useSubInfo(handleCancelSubscription);

  const [updatePreference, { loading }] = useMutation(UPDATE_PREFERENCE);
  const [updateNotificationStatus] = useMutation(UPDATE_NOTIFICATIONSEENBY_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: (dataUpdateNotificationStatus) => {
      // console.log("dataUpdateNotificationStatus", dataUpdateNotificationStatus)
    },
  });

  // const [cancelSubscription, { loadingCancelSubscription, dataCancel }] = useMutation(CANCEL_SUBSCRIPTION,
  //   {
  //     onCompleted: (dataCancel) => {
  //       console.log("dataCancel", dataCancel)
  //       if (dataCancel.cancelCurrentPlanForUser.errorCode === 0) {
  //         setCancelStatus(true)
  //       }
  //     }
  //   });

  const [cancelSubscription, { data, loadingCancelSubscription }] = useMutation(
    CANCEL_SUBSCRIPTION,
    {
      onCompleted: (dataCancel) => {
        // console.log("dataCancel", dataCancel)
        if (dataCancel.cancelCurrentPlanForUser.errorCode === 0) {
          setCancelStatus(true)
          getInvoiceList()
        }
      },
    }
  );
  const [updateUserSubscriptionNotificationStatus] = useMutation(
    UPDATE_USER_SUBSCRIPTION_NOTIFICATION_STATUS,
    {
      onCompleted: (dataUpdateStatus) => {
        // console.log("dataUpdateStatus", dataUpdateStatus)
      },
    }
  );

  const [getInvoiceList] = useLazyQuery(
    GET_INVOICE_LIST,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: {
        userid: userId,
      },
      onCompleted: (dataInvoiceList) => {
        // console.log("dataInvoiceList", dataInvoiceList)
        if (typeof dataInvoiceList !== "undefined" && dataInvoiceList.invoicesListForUser.errorCode === 0) {
          setInvoiceList(dataInvoiceList.invoicesListForUser.result)
        }
        if (typeof dataInvoiceList !== "undefined" && dataInvoiceList.invoicesListForUser.result.showAlertMessage !== null
          && dataInvoiceList.invoicesListForUser.result.showAlertMessage === true) {
          setInvoiceListError(dataInvoiceList.invoicesListForUser.message)
        }
        getCurrentPlan()
      },
    }
  );


  const { loading: loadingPreference, data: dataPreference } = useQuery(
    GET_PREFERENCE,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: {
        userId: userId,
      },
    }
  );
  const { data: dataSubscriptionNotification } = useQuery(
    GET_USER_SUBSCRIPTIONS_NOTIFICATION,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: {
        userId: userId,
      },
      onCompleted: (dataSubscriptionNotification) => {
        // console.log("dataSubscriptionNotification", dataSubscriptionNotification)
        if (typeof dataSubscriptionNotification !== "undefined" &&
          dataSubscriptionNotification.userSubscriptionByUserId.result != null &&
          dataSubscriptionNotification.userSubscriptionByUserId.errorCode === 0
        ) {
          setSubscriptionType(dataSubscriptionNotification.userSubscriptionByUserId.result.subscriptionType);
          if (!dataSubscriptionNotification.userSubscriptionByUserId.result.alertMessageSeenByStatus) {
            setUserNewSubscriptionNotification(dataSubscriptionNotification.userSubscriptionByUserId.message)
            const variable = {
              userid: userId,
            };
            updateUserSubscriptionNotificationStatus({
              variables: variable,
            });
          } else {
            setUserNewSubscriptionNotification("")
          }
        }
      }
    }
  );
  const [getCurrentPlan] = useLazyQuery(
    GET_CURRENT_PLAN,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: {
        userId: userId,
      },
      onCompleted: (dataCurrentPlan) => {
        // console.log("dataCurrentPlan", dataCurrentPlan)
        if (typeof dataCurrentPlan !== "undefined" &&
          dataCurrentPlan.planWithUserId.errorCode === 0
        ) {
          // console.log("dataCurrentPlan", dataCurrentPlan)
          setCurrentPlan(dataCurrentPlan.planWithUserId.result.role.planName)
          setCancelStatus(dataCurrentPlan.planWithUserId.result.isCancelledByUser)
          setIsNotificationSeenByUser(dataCurrentPlan.planWithUserId.result.isNotificationSeenByUser)
          // if (dataCurrentPlan.planWithUserId.result.isCancelledByUser === true) {

          if ((dataCurrentPlan.planWithUserId.result.isNotificationSeenByUser === null || !dataCurrentPlan.planWithUserId.result.isNotificationSeenByUser)
            && invoiceListError.length) {
            // console.log("isNotificationSeenByUser", isNotificationSeenByUser)
            updateNotificationSeenByStatus()
          }
        }
      },
    }
  );
  React.useEffect(() => {
    // console.log("dataPreference", dataPreference);
    if (
      typeof dataPreference !== "undefined" &&
      typeof dataPreference.preferancebyUserId !== "undefined"
    ) {
      setPreferenceData(dataPreference.preferancebyUserId);
    }
    if (typeof props.location.state !== "undefined" && props.location.state !== null) {
      if (typeof props.location.state.paymentStatus !== "undefined" &&
        props.location.state.paymentStatus !== null) {
        setShowPaymentStatus(true)
      }
      // console.log("props.location.state ", props.location.state)
    }
    getInvoiceList()
    getCurrentPlan()

  }, [dataPreference, props]);

  const setPreferenceData = (dataSelectedPreference) => {
    const preferances = dataSelectedPreference.result.preferances ?? [];
    // console.log("preferances", preferances);
    // console.log("masterDataCompanyLevel()", masterDataCompanyLevel());

    const selectedPrefer = masterDataCompanyLevel()
      .filter((item) => {
        return preferances.includes(item.key);
      })
      .map((option, index) => {
        return option.value.toString();
      });
    // console.log("selectedPrefer", selectedPrefer);

    var currentPreference = masterDataPreferenceList()
      .filter((item) => {
        return selectedPrefer.includes(item.value);
      })
      .map((option, index) => {
        return option.key;
      });
    if (dataSelectedPreference.result.myMatchPreferance === 1) {
      currentPreference.push(intl.formatMessage({ id: "only_my_matches" }));
    }
    // console.log("currentPreference", currentPreference);
    setSelectedPreference(currentPreference);
  };

  const changePassword = () => {
    setIsShowChnagePassword(true);
  };
  const refresh = () => {
    setIsShowChnagePassword(false);
    setPasswordChangeOutput(true);
  };
  const onCompanyLevelSelection = (event) => {
    setErrorPreference("");
    // console.log("event.target.name", event.target.name)
    // console.log("event.target.name 2", intl.formatMessage({ id: "only_my_matches" }))

    var newTags = selectedPreference;
    if (event.target.checked) {
      if (
        event.target.name === intl.formatMessage({ id: "only_my_matches" })
      ) {
        newTags = [];
        newTags.push(event.target.name);
      } else {
        removeItemOnce(newTags, intl.formatMessage({ id: "only_my_matches" }))
        newTags.push(event.target.name);
      }
    } else {
      removeItemOnce(newTags, event.target.name);
    }
    // setSelectedPreference(newTags)
    setSelectedPreference((prevState) => [...newTags]);
  };
  const isCheckedTag = (option) => {
    const arrayCircleTags = selectedPreference;
    if (arrayCircleTags.includes(option)) {
      return true;
    }
    return false;
  };

  const getPreference = () => {
    var preference = masterDataPreferenceList()
      .filter((item) => {
        return item.value !== "5" && selectedPreference.includes(item.key);
      })
      .map((option, index) => {
        return option.value;
      });
    // if (preference.includes("2")) {
    //   preference.push("3");
    // }
    return preference;
  };
  const getMatchPreference = () => {
    var preference = masterDataPreferenceList().filter((item) => {
      return item.value === "5" && selectedPreference.includes(item.key);
    });
    return preference.length ? 1 : 0;
  };

  const handleSave = async () => {
    let isValid = await checkForErrors();
    const variable = {
      userID: userId,
      preferances: getPreference(),
      myMatchPreferance: getMatchPreference(),
    };
    // console.log("variable", variable);
    if (isValid) {
      updatePreference({
        variables: variable,
      });
    }
  };
  const updateNotificationSeenByStatus = () => {
    const variable = {
      userId: userId,
    };
    // console.log("variable updateNotificationSeenByStatus", variable)
    updateNotificationStatus({
      variables: variable,
    });
  }

  const checkForErrors = async () => {
    setErrorPreference(!selectedPreference.length ? "errorPreference" : "");
    return selectedPreference.length;
  };

  // const navigateToChangePremium = () => {
  //   history.push("/ChangePremium");
  // };
  const getInvoiceTitle = (dateCreated) => {
    let title = "Premium,"
    var mydate = new Date(dateCreated)
    title += " " + intl.formatMessage({ id: mydate.getMonthName() }) + " " + mydate.getFullYear()
    return title
  };
  const getPaymentStatus = (currentStatus) => {
    let status = PaymentStatus.filter((item) => {
      return item.key === currentStatus
    })
    // console.log("sttus", status)
    return status.length ? intl.formatMessage({ id: status[0].type }) : ""
  };
  const handleSucessAlert = () => {
  };
  const getExpiryMessage = (dateString) => {
    var dateValue = new Date(dateString)
    let message = dateValue.getDate() + " " + intl.formatMessage({ id: dateValue.getMonthName() }) + " " + dateValue.getFullYear()
    return intl.formatMessage({ id: "Active_Subscription" }) + message
  };
  const getExpiryMessageDateInfo = (dateString) => {
    var dateValue = new Date(dateString)
    if (Date.now() <= dateValue) {
      return true
    } else {
      return false
    }
  }

  const upgradable = subscriptionInfo.current && subscriptionInfo.current.CtaTextId == subscriptionInfo.all.freemium.CtaTextId;
  const subscriptionPaper =
    <ThemeProvider theme={Theme_WIP}>
      {subscriptionInfo.current && <Background color={upgradable ? "primary" : "white"} container item xs={12} p={7} border="bottomLeft" gap={4}>
        <Text item xs={12} variant="h1" bold color={upgradable ? "primary" : "background"} id={subscriptionInfo.current.name} />
        <Text item xs={12} variant="body1"   color={upgradable ? "primary" : "background"} id={subscriptionInfo.current.descriptionId}
              values={{date: globals.state.loggedInUser.role.subscriptionEndDate ? getDateOnlyString(new Date(globals.state.loggedInUser.role.subscriptionEndDate)) : "..."}}/>
        {subscriptionInfo.current.CtaTextId &&
          <Button fullWidth variant={upgradable ? "contained" : "text"} color={upgradable ? "white" : "primary"}  onClick={subscriptionInfo.current.CtaAction} style={upgradable ? {} : {width: "fit-content", textDecoration: "underline"}} >
            <FormattedMessage id={subscriptionInfo.current.CtaTextId} />
          </Button>
        }
      </Background>}
    </ThemeProvider>;

  return (
    <Container component="main" maxWidth="sm" className={classes.container}>
      <Box sx={{ flexGrow: 1, mt: theme.spacing(1), mb: theme.spacing(4), pointerEvents: "none !important" }}>
        <Grid container spacing={4}>
          <Grid item sm={12} xs={12}>
            {data?.cancelCurrentPlanForUser?.errorCode === 0 ? (
              <SuccessAlertWithAction
                message={<FormattedMessage id={"Subscription_cancelled_success"} />}
                open={true}
                handleClose={handleSucessAlert}
              />
            ) : null}
            {userNewSubscriptionNotification.length ? (
              <Alert severity={"info"}>
                {userNewSubscriptionNotification}
              </Alert>
            ) : null}
            {showPaymentStatus ? (
              <Alert severity={"info"} onClose={() => { setShowPaymentStatus(false) }}>
                {<FormattedMessage id={"Payment_status_message"} />}
              </Alert>
            ) : null}
            {invoiceListError.length && !isNotificationSeenByUser ?
              <Grid item xs={12} sm={12}>
                <Alert severity={"error"} >
                  {invoiceListError}
                </Alert>
              </Grid> : null
            }
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography
              component="h1"
              variant="h1"
              color={"textPrimary"}
              className={classes.mt20}
            >
              <FormattedMessage id={"settings"} />
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={12} className={classes.mt10}>
            <Typography component="h2" variant="h2" className={classes.btxt}>
              <FormattedMessage id={"preference"} />
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography
              component="h3"
              variant="h2"
              color={"textPrimary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"preference_title"} />
            </Typography>
            <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.mt10}
            >
              <FormattedMessage id={"setting_desc"} />
            </Typography>
            <Typography
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.mt7}
            >
              <FormGroup>
                {masterDataPreferenceList().map((option, index) =>
                  loading || loadingPreference ? (
                    <Skeleton variant="rectangular" height={30} />
                  ) : (
                    <Typography
                      variant="h6"
                      color={"textSecondary"}
                      key={index}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox checked={isCheckedTag(option.key)} />
                        }
                        label={option.key}
                        onChange={onCompanyLevelSelection}
                        name={option.key}
                      />
                    </Typography>
                  )
                )}
              </FormGroup>
            </Typography>
          </Grid>
          {errorPreference.length ? (
            <Grid item sm={12} xs={12}>
              <Typography variant="h5" component="span" color="error">
                <FormattedMessage id={errorPreference} />
              </Typography>
            </Grid>
          ) : null}
          <Grid item sm={12} xs={12}>
            <Grid item sm={2} xs={12}>
              {loading || loadingPreference ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <Typography component="h3" variant="h3">
                  <ButtonDarkBlue onClick={handleSave}>
                    <FormattedMessage id={"Button_Text_Save"} />
                  </ButtonDarkBlue>
                </Typography>
              )}
            </Grid>
          </Grid> */}
          <Grid item xs={12} sm={12} className={classes.mt20}>
            <Typography component="h2" variant="h2" className={classes.btxt}>
              <FormattedMessage id={"personal_details"} />
            </Typography>
          </Grid>

          <Grid item sm={12} xs={12}>
            <PersonaInfoUpdate userId={userId} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              component="h3"
              variant="h3"
              className={classes.mt10}
              id={"changePassword"}
            >
              {!isShowChnagePassword ?
                <Link variant="h3" onClick={changePassword} className={classes.mb10} color="secondary">
                  <FormattedMessage id={"changePassword"} />
                </Link> :
                <ChangePassword userId={userId} onRefresh={refresh} />
              }
              {passwordChangeOutput ? (
                <SuccessAlertWithAction
                  message={
                    <FormattedMessage id={"PasswordChangedSucessfully"} />
                  }
                  open={true}
                  handleClose={() => { }}
                />
              ) : null}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.mt20}>
            <Typography component="h2" variant="h2" className={classes.btxt}>
              <FormattedMessage id={"PlanAndBilling"} />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.mt10}>
            <Typography component="h3" variant="h2" color={"textPrimary"}>
              <FormattedMessage id={"YourPlan"} />
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            {subscriptionPaper}
          </Grid>
          {cancelStatus === true && subscriptionExpiry !== null && getExpiryMessageDateInfo(subscriptionExpiry) ?
            <Grid item xs={12} sm={12}>
              <Alert severity={"error"} >
                {getExpiryMessage(subscriptionExpiry)}
              </Alert>
            </Grid> : null
          }
          <Grid item xs={12} sm={12}>
            <Typography component="h3" variant="h2" color={"textPrimary"}>
              <FormattedMessage id={"Invoices"} />
            </Typography>
          </Grid>
          <Grid item container spacing={3} sm={12} xs={12}>
            {invoiceList.invoice?.map((option, index) => (
              <Grid item xs={12} sm={12}>
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Typography component="h3" variant="h3">
                    <Link
                      component="button"
                      variant="h3"
                      underline="always"
                      className={classes.invoiceStyle}
                      onClick={() => { history.push("/invoiceDetails", { invoiceDetails: option, userDetails: invoiceList }) }}
                    >
                      {getInvoiceTitle(option.createdDate)}
                    </Link>
                  </Typography>
                  <Typography
                    component="h3"
                    variant="h3"
                    color={"textSecondary"}
                    className={classes.mt20}
                  >
                    {getPaymentStatus(option.status)}
                  </Typography>
                </Stack>
              </Grid>
            ))}
            {
              (invoiceList.length === 0 || invoiceList.invoice?.length === 0 || invoiceList.invoice === null) &&
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  flex: "row",
                  justifyContent: "flex-start",
                  width: "50%",
                  height: 100,
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  sx={{ marginLeft: 1 }}
                >
                  <Typography
                    component="span"
                    variant="h3"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                </Stack>
              </Box>
            }
          </Grid>
        </Grid>
      </Box>
    </Container >
  );
}
