//TODO: right now we make requests for every data retrieved.
// Where possible I'd like to store this data in a global state so we can limit the amount of
// queries and implementations of queries. We could retrieve data from the global state and request refetches when needed.

//TODO: maybe for some data we can also store an expiration timestamp and automatically refetch the data if it expired
//TODO: there are a lot of places in the code where we can use the loggedin user profile and circles from the state
import { createContext, useEffect, useContext, useState } from "react";
import { GetUserId, IsAuthenticated, useUserRole } from "../CommonMethods";
import { useAdminUserIdQuery, useCurrentUserProfile } from "../CommonDataObjects";
import { useUserCircles } from "../Queries/Circles/GetUserCircles";
import { useCollabCategories } from "../Queries/Collabs/Categories/GetCollabCategories";
import { useGetContactList } from "../Queries/Activities/GetContactList";
import { useHasExtendedTrial } from "../Queries/HasExtendedTrial";
import { useAcceptedInvites } from "../Queries/AcceptedInvites";

export const GlobalContext = createContext({
    state: {
        admin: {
            userId: "0a95af54-31c9-4bea-bcb4-278d7adcbce8"
        },
        loggedInUser: {
            userId : "",
            profile: {
                createdDate         : "",
                skills              : [],
                givenName           : "",
                email               : "",
                familyName          : "",
                languages           : [],
                description         : "",
                country             : "",
                companyName         : "",
                profilePictureUrl   : "",
                loading             : true,
            },
            role   : {
                role                : "Freemium",
                responses           : 0,
                maxResponses        : 0,
                usedAllResponses    : false,
                subType             : "FREEMIUM_SUBSCRIPTION",
                subscriptionEndDate : null,
                loading             : true,
            },
            circles : [
                {
                    aboutus               : "",
                    brandColor            : "",
                    brandSecondColor      : "",
                    circleTypeId          : "",
                    id                    : "",
                    isCancelled           : false,
                    isCompany             : false,
                    isHideForPublic       : 0,
                    isPublished           : 1,
                    isSubCircle           : false,
                    location              : "",
                    loggedInUserRole      : "Member",
                    logo                  : "",
                    members               : [],
                    name                  : "",
                    parentCircleId        : "",
                    parentCircleName      : "",
                    shortDescription      : "",
                }
            ],
            lazyContactList : () => [
                {
                    fullName         : "",
                    pfp              : "",
                    userId           : "",
                    messageTime      : "",
                    messageSummary   : "",
                    pending          : true,
                    received         : true
                }
            ],
            events          : null,
            acceptedInvites : 0,
            extendedTrial   : false,
        },
        meta: {
            collaborationCategories: [{
                id          : "",
                title       : "",
                description : "",
                parentId    : ""
            }],
        },
        cache:{ //cache is used in conjunction with useCachedQuery
            get: [
                {
                    hash    : "",
                    expires : new Date(),
                    data    : {},
                    queried : 0
                }
            ],
            set: (newCache) => {}
        }
    },
    functions: {
        loggedInUser: {
            refetchRole: () => {}
        },
        meta: {
            refetchCollaborationCategories: () => {},
            getTopCategories: () => {},
            getSubCategories: (i) => {},
        
        }
    }
});

function AppGlobalStateProvider(props) {
    const userId                          = GetUserId();
    const [role   , refetch, loadingRole] = useUserRole(null, false);
    const {data: circles}                 = useUserCircles(userId);
    const profile                         = useCurrentUserProfile(); //todo: refetch logic
    const {data: admin}                   = useAdminUserIdQuery();
    const contactList                     = useGetContactList();
    const {data: categoriesFromQuery, updateVariables: refetchCategories} = useCollabCategories();
    const [extendedTrial]                 = useHasExtendedTrial();
    const {data: acceptedInvites}         = useAcceptedInvites(userId);
    const [cache, setCache]               = useState([]);

    const state = {
        admin: {
            userId: admin ?? "0a95af54-31c9-4bea-bcb4-278d7adcbce8"
        },
        loggedInUser: {
            userId          : userId,
            profile         : profile,
            role            : {...role, loading: loadingRole},
            circles         : circles,
            events          : null,
            lazyContactList : contactList.result,
            acceptedInvites : acceptedInvites,
            extendedTrial   : extendedTrial,
        },
        meta: {
            collaborationCategories: categoriesFromQuery
        },
        cache: {get: cache, set: setCache}
    };
    const functions  = {
        loggedInUser: {
            refetchRole: () => refetch(),
        },
        meta: {
            refetchCollaborationCategories: refetchCategories,
            getTopCategories: ( ) => state.meta.collaborationCategories.filter(c => c.parentId == null),
            getSubCategories: (i) => i != null ? state.meta.collaborationCategories.filter(c => c.parentId == i) : [],
        }
    };

    if(!IsAuthenticated()) //provide defaults where possible as a safety net
        return(
            <GlobalContext.Provider value={{state: {
                admin: {
                    userId: admin
                },
                loggedInUser: {
                    role: {
                        role: "Freemium",
                        usedAllResponses: true
                    }
                }
            }, functions: {
                loggedInUser: {
                    refetchRole: () => {}
                }
            }}}>
                {props.children}
            </GlobalContext.Provider>
        );
    return(
        <GlobalContext.Provider value={{state: state, functions: functions}}>
            {props.children}
        </GlobalContext.Provider>
    );
} 

export default AppGlobalStateProvider;

export const useGlobalContext = () => useContext(GlobalContext);