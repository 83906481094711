import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { ButtonDarkBlue } from "../components/Buttons/ButtonDarkBlue";
import { Alert, Hidden, Skeleton } from "@mui/material";
import Header from "../components/Header";
import Navigationbar from "../components/Navigationbar";
import { Box } from "@mui/system";
import { CollaborationTypes } from "../apiServices/Constants";
import { FormHelperText } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { CREATE_MATCHREQUEST } from "../apiServices/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ClearCache, GetUserId } from "../apiServices/CommonMethods";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import styles from "../styles/container/MatchRequestPageStyle";
import SuccessAlertDialog from "../components/Alert/SuccessAlertDialog";
import { FormattedMessage, useIntl } from "react-intl";
import { GET_COLLAB } from "../apiServices/Queries";
import { CssBaseline } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GoTo from "../apiServices/Navigation/NavigateWithReload";
import FormContainer from "../components/Forms/FormContainer";
import InputSection from "../components/Forms/InputSection";
import Footer from "../components/Footer";


const useStyles = makeStyles(styles);

export default function MatchRequestNew() {
    const userId = GetUserId();
    const [userProfile, setUserProfile] = React.useState();
    const intl = useIntl();
    const isKnownEachOther = intl.formatMessage({ id: "We_already_know_each_other" });
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    let location = useLocation();
    let [input, setInput] = React.useState({
        requestType: [],
        rquestMessage: "",
        IsKnownPerson: null,
    });
    let [inputError, setInputError] = React.useState({
        requestType: "",
        rquestMessage: "",
    });
    let [collabTypes, setCollabTypes] = React.useState([]);

    const [fetchCollaborations, { loading: loadingCollab }] = useLazyQuery(
        GET_COLLAB,
        {
            variables: {
                userId: location.state.selectedUser.userId,
            },
            fetchPolicy: "network-only",
            notifyOnNetworkStatusChange: true,
            onCompleted: (data) => {
                // console.log("collab types", data);
                if (typeof data !== "undefined" && data.allCollaborationInterest.errorCode === 0) {
                    let arrayCollaborations = generateCollabArray(
                        data.allCollaborationInterest.result
                    );
                    setCollabTypes(arrayCollaborations);
                    // console.log("arrayCollaborations", arrayCollaborations);
                }
            }
        }
    );
    const [createMatchRequest, { data, loading, error }] =
        useMutation(CREATE_MATCHREQUEST, {
            onCompleted: (dataMatch) => {
                ClearCache("profile");
                if (dataMatch.sendMatchRequest.errorCode !== 0 && dataMatch.sendMatchRequest.message === "FreemiumUserCannotAbleToSentMatchRequestToNonFellowCircleMembers") {
                    navigateToGetPremium()
                }
            }
        });
    const navigateToGetPremium = () => {
        GoTo(history, "/GetPremium");
    };
    // const arrayCollabList = RequestCollaborationOfInterestArray.map(
    //   (collab) => collab.itemType
    // );
    // arrayCollabList.push(isKnownEachOther);


    const generateCollabArray = (dataColllabFromServer) => {
        var arrayCollaborations = [];
        if (dataColllabFromServer.businessPartnerCollaborationInterest) {
            // let dataDict = {
            //   ...dataColllabFromServer.businessPartnerCollaborationInterest,
            //   itemType: CollaborationTypes.businessPartners,
            // };
            arrayCollaborations.push(CollaborationTypes.businessPartners);
        }
        if (dataColllabFromServer.investmentCollaborationInterest) {
            // let dataDict = {
            //   ...dataColllabFromServer.investmentCollaborationInterest,
            //   itemType: CollaborationTypes.investment,
            // };
            arrayCollaborations.push(CollaborationTypes.investment);
        }
        if (dataColllabFromServer.coFoundingPartnerCollaborationInterest) {
            // let dataDict = {
            //   ...dataColllabFromServer.coFoundingPartnerCollaborationInterest,
            //   itemType: CollaborationTypes.coFounding,
            // };
            arrayCollaborations.push(CollaborationTypes.coFounding);
        }
        if (dataColllabFromServer.projectPartnerCollaborationInterest) {
            // let dataDict = {
            //   ...dataColllabFromServer.projectPartnerCollaborationInterest,
            //   itemType: CollaborationTypes.projectPartners,
            // };
            arrayCollaborations.push(CollaborationTypes.projectPartners);
        }
        if (dataColllabFromServer.strategicPartnerCollaborationInterest) {
            // let dataDict = {
            //   ...dataColllabFromServer.strategicPartnerCollaborationInterest,
            //   itemType: CollaborationTypes.strategicPartners,
            // };
            arrayCollaborations.push(CollaborationTypes.strategicPartners);
        }
        if (dataColllabFromServer.mentoringCollaborationInterestPayload) {
            // let dataDict = {
            //   ...dataColllabFromServer.mentoringCollaborationInterestPayload,
            //   itemType: CollaborationTypes.mentoring,
            // };
            arrayCollaborations.push(CollaborationTypes.mentoring);
        }
        arrayCollaborations.push(isKnownEachOther);
        return arrayCollaborations;
    };

    // const handleChange = (event, newValues) => {
    //   const {
    //     target: { value },
    //   } = event;
    //   const newValue = newValues.props.value;
    //   if (checkForPurpose(newValue) || newValue === isKnownEachOther) {
    //     setInput((prevState) => ({
    //       ...prevState,
    //       requestType: typeof value === "string" ? value.split(",") : value,
    //     }));
    //     setInputError((prevState) => ({
    //       ...prevState,
    //       requestType: "",
    //     }));
    //   }
    // };

    const handleChange = (event) => {
        setInput((prevState) => ({
            ...prevState,
            requestType: event.target.value,
        }));
        setInputError((prevState) => ({
            ...prevState,
            requestType: "",
        }));
    };
    // const checkForPurpose = (newValue) => {
    //     const arrayFiltered = input["requestType"].filter((value) => {
    //         return value !== intl.formatMessage({ id: "We_already_know_each_other" });
    //     });
    //     return !arrayFiltered.length || input["requestType"].includes(newValue);
    // };
    const updateTextFileds = (event) => {
        const { id, value } = event.target;
        setInput((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        setInputError((prevState) => ({
            ...prevState,
            [id]: "",
        }));
    };
    const getRequestPurpose = (newValue) => {
        // const arrayFiltered = input["requestType"].filter((value) => {
        //   return value !== intl.formatMessage({ id: "We_already_know_each_other" });
        // });
        // return arrayFiltered.length ? arrayFiltered[0] : null;
        return input["requestType"] !== isKnownEachOther ? input["requestType"] : null
    };

    const handleSave = async () => {
        let isValid = await checkForErrors();
        // console.log("getRequestPurpose", !!getRequestPurpose());
        if (isValid) {
            let variable = {
                // recipientUserProfileIds: [userProfile.userId],
                // requesterUserProfileId: currentUserId,
                // requestPurpose: getRequestPurpose(),
                // messages: input["rquestMessage"],
                // activitySource: ActivitySource.matchRequest,
                // IsKnownPerson: input["requestType"].includes(isKnownEachOther)

                isKnownPerson: input["requestType"] === isKnownEachOther,
                message: input["rquestMessage"],
                recipientUserProfileID: userProfile.userId,
                requestType: getRequestPurpose(),
                requesterUserProfileID: userId,
            };
            // console.log("variable", variable);
            createMatchRequest({
                variables: variable,
            });
        }
    };

    const checkForErrors = async () => {
        setInputError((prevState) => ({
            ...prevState,
            requestType: !input["requestType"].length ? "requestTypeError" : "",
            rquestMessage: !input["rquestMessage"].trim() ? "rquestMessageError" : "",
        }));
        return input["rquestMessage"].trim() && input["requestType"].length;
    };

    React.useEffect(() => {
        if (location.state && location.state.selectedUser) {
            // console.log("Match request: ", location.state.selectedUser);
            setUserProfile(location.state.selectedUser);
            fetchCollaborations()
        }
    }, [data, location]);


  const proceed = () => {
    if (history.location?.state?.referrer)
      history.push(history.location?.state?.referrer, history.location?.state);
    else
      history.go(-2);
  };


    return (
        <React.Fragment>
            <CssBaseline />
            <Navigationbar />
            <FormContainer 
            modalMessage = {data?.sendMatchRequest?.errorCode ===  0 ? <FormattedMessage id={"RequestSentSuccessfully"} /> : undefined}
            onModalClose = {proceed}
            error        = {data?.sendMatchRequest?.errorCode === -1 ? data.sendMatchRequest.message + "wer" : (error ? <FormattedMessage id={"somethingWentWrong"} /> : undefined)}
            title        = {<FormattedMessage id="SendAMatchRequest"/>}
            description  = {<FormattedMessage id="MatchRequestPage_Description"/>}
            loading      = {loading || loadingCollab}
            onSave       = {handleSave}
            disabled     = {!input.rquestMessage.length || !input.requestType.length || !userProfile}
            onCancel     = {proceed}
            saveId       = "Button_text_Send"
            >
                <InputSection
                type     = "text"
                disabled = {loading || userProfile}
                id       = "master"
                title    = {<FormattedMessage id="MatchRequestPage_ToWho"/>}
                value    = {userProfile?.givenName + " " + userProfile?.familyName}
                onChange = {(newVal) => setInput({...input, role: newVal.target.value})}
                />
                                
                <InputSection
                  type     = "select"
                  disabled = {loading}
                  id       = "purpose"
                  title    = {undefined}//{<FormattedMessage id="PurposeMatchRequest"     />}
                  label    = {<FormattedMessage id="InputLabel_Text_Select" />}
                  onChange = {handleChange}
                  options  = {collabTypes.map((name) => ({key: name, value: name}))}
                  hint     = {inputError["requestType"] ? <FormattedMessage id={"PleaseSelectPurpose"} /> : undefined}
                />

                <InputSection
                type        = "textarea"
                disabled    = {loading}
                id          = "rquestMessage"
                placeholder = {intl.formatMessage({ id: "introduction_Field_input_user_type" })}
                title       = {<FormattedMessage id="writeAMessage"/>}
                onChange    = {updateTextFileds}
                value       = {input["rquestMessage"]}
                hint        = {inputError["rquestMessage"] ? <FormattedMessage id={"pleaseEnterMessage"} /> : undefined}
                max         = {250}
                />
            </FormContainer>
            <Hidden mdUp>
                <Footer />
            </Hidden>
        </React.Fragment>
    );
}
