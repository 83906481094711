import React from "react";
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { Grow } from "@mui/material";

const Icon = styled('span')((props) => ({
    borderRadius: 3,
    margin: 3,
    border: props.color ? props.theme.palette[props.color].main : props.theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "2px",
    width: 28,
    height: 28,
    backgroundColor: "rgba(0, 0, 0, 0)",
  }));

export default function Check(props) {
    const [fade,    setFade] = React.useState(true);
    const [checked, setChecked] = React.useState(props.checked);
    const fadeIn = () => {
        setFade(false);
        setTimeout(fadeIn_, 200);
        
    }
    const fadeIn_ = () => {
        setFade(true);
        setChecked(!checked);
    }

  return (
    <Grow
    in={fade}>
    <Checkbox 
    {...props}
    checked={props['checked'] != null ? props.checked : checked}
    onClick={() => {
      fadeIn();
      props.onClick();
    }}
    size="large"
    icon={<Icon color={props.color} />}
    />
    </Grow>
  );
}