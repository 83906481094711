import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import jsPDF from "jspdf";
import { SHARE_EVENT_URL } from "../../Config";
import Styles from "../../styles/component/EventTabs/EventInviteStyle";
import { FormattedMessage } from "react-intl";
import Container from "@material-ui/core/Container";
import { useCurrentUserProfile } from "../../apiServices/CommonDataObjects";
import InviteShareButtons from "../Generic/InviteShareButtons";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { useUUID } from "../../apiServices/Queries/Circles/GetUUID";


const useStyles = makeStyles(Styles);

export default function EventInvite(props) {
  const globals  = useGlobalContext();
  const admin    = globals.state.admin.userId;
  const profile  = useCurrentUserProfile();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [eventData, setEventData] = React.useState({});
  const [circleData, setCircleData] = React.useState(null);
  const [qrbase64, setQrbase64] = React.useState("");
  const [shareContent, setShareContent] = React.useState("");
  const {data: uuid} = useUUID(true, props.eventDetails.id);

  React.useEffect(() => {
    if (typeof props.eventDetails !== "undefined") {
      setEventData(props.eventDetails);
      setShareContent("Ik ga naar het event " + props.eventDetails.title + ". Ben je er ook bij? Check voor meer info: ")
      const canvas = document.getElementById("qrCodeId");
      const base64QrImage = canvas.toDataURL("image/png");
      setQrbase64(base64QrImage);
      // console.log("isInThePast", isInThePast(new Date(props.eventDetails.startDate)));
    }
    if (typeof props.circleDetails !== "undefined") {
      setCircleData(props.circleDetails);
      // console.log('Circle data :', props.circleDetails)
    }
  }, [props]);

  function generatePDF() {
    const title = "Wie zijn jouw toekomstige zakelijke partnerships?";
    const doc = new jsPDF("p", "pt", "a4");
    doc.setFont("Century Gothic", "bold");
    doc.setFontSize(36);
    const splitTitle = doc.splitTextToSize(title, 500);
    doc.text(splitTitle, 85, 100, "left");
    doc.setFontSize(20);
    doc.setFont("Century Gothic", "normal");
    doc.text("Scan de QR en ontdek wat anderen zoeken. ", 85, 210, "left");
    doc.text(eventData.title, 85, 270, "left");
    doc.addImage(qrbase64, "png", 70, 290, 140, 140);
    doc.addImage(
      "../assets/img/mastermatch_logo_petrol_blue_large.svg",
      "JPEG",
      70,
      680,
      300,
      80
    );
    doc.save(eventData.title + ".pdf");
  }


  return (
    <Container maxWidth="sm">
      <Box sx={{ flexGrow: 1, mt: theme.spacing(0), mb: theme.spacing(4) }}>
        <Grid container spacing={4}>
          <InviteShareButtons shareContent={shareContent} shareUrl={SHARE_EVENT_URL + uuid} />
          {eventData.loginUserStatus === "Manager" || admin == profile?.userId ? (
            <Grid item sm={5} xs={12} sx={{ mt: theme.spacing(10) }} >
              <Typography
                component="h3"
                variant="h3"
                color={"textSecondary"}
                className={classes.p7}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    history.push("/invitePeopleToEvent", {
                      eventDetails: eventData,
                    });
                  }}
                >
                  <FormattedMessage id={"EventInvite_InvitePeopleToEvent"} />
                </Button>
              </Typography>
              {
                eventData.circleId !== null ?
                  (
                    <Typography
                      component="h2"
                      variant="h2"
                      color={"textSecondary"}
                      className={classes.p7}
                    >
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                          history.push("/inviteAllCirclesToEvent", {
                            circleDetails: circleData,
                            eventDetails: eventData,
                          });

                        }}
                      >
                        <FormattedMessage id={"EventInvite_InviteAllCircleMembers"} />
                      </Button>
                    </Typography>
                  ) : null}
              <Typography
                component="h3"
                variant="h3"
                color={"textSecondary"}
                className={classes.p7}
              >
                <Button onClick={generatePDF} variant="outlined" fullWidth>
                  <FormattedMessage id={"dwnldQrCode"} />
                </Button>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Container>
  );
}
