import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Chip, FormHelperText } from "@material-ui/core";
import { isEmail } from "../General";
import { Alert, Autocomplete, Button, Grid, Skeleton, TextField, TextareaAutosize, ThemeProvider } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { SAVEPROFILE_INVITE } from "../../apiServices/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { userRoles } from "../../apiServices/Constants";
import SuccessAlertWithAction from "../Alert/SuccessAlertWithAction";
import { GET_PROFILE_INVITATION_LISTS } from "../../apiServices/Queries";
import Text from "../../visualComponents/Text";
import Background from "../../visualComponents/Background";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import { GoBack } from "../../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { useSubInfo } from "../../objects/Subscriptions";
import FormContainer from "../Forms/FormContainer";
import InputSection from "../Forms/InputSection";

export default function PremiumFeatures(props) {
  const globals  = useGlobalContext();
  const subInfo  = useSubInfo();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const intl = useIntl();
  const getPremiumPage = props.getPremium;
  const textColor      = getPremiumPage ? undefined : "primary"; //contrast
  const bgColor        = getPremiumPage ? "primary" : "background"; //can be reused for title
  const buttonColor    = getPremiumPage ? "primary" : "lightPetrol";
  const paperColor     = getPremiumPage ? "white"   : "primary";
  const [email          , setEmail          ] = React.useState([]);
  const [inputError     , setInputError     ] = React.useState(false);
  const [isEmailEmpty   , setIsEmailEmpty   ] = React.useState(false); //TODO: is always false. Clean this up
  const [isEmailError   , setIsEmailError   ] = React.useState(false);
  const [invitationCount, setInvitationCount] = React.useState(0);
  const [invitationError, setInvitationError] = React.useState(false);
  const [message        , setMessage        ] = React.useState("");
  const [error          , setError          ] = React.useState();

  const [input, setInput] = React.useState("");
  const history = useHistory();
  const [saveInvite, { data, loading }] = useMutation(
    SAVEPROFILE_INVITE,
    {
      onCompleted: (data) => {
        // console.log(data);
        setEmail([])
        if (
          data?.saveProfileInvite?.errorCode == 0
        ) {
          handleSucessAlert();
        }
        if (
          data?.saveProfileInvite?.errorCode != null &&
          data?.saveProfileInvite?.errorCode != 0
        ) {
          // console.log("error");
          refetch()
          setError(data.saveProfileInvite.message);
        }
      },
    }
  );
  const { loading: loadingInviteDetails, refetch } = useQuery(
    GET_PROFILE_INVITATION_LISTS,
    {
      variables: {
        requestedUserId: userId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: (dataInvite) => {
        // console.log("signinodc invitedata", dataInvite);
        if (
          typeof dataInvite !== "undefined" && dataInvite.profileInvitationList.errorCode === 0
        ) {
          if (typeof data !== "undefined" && data.saveProfileInvite !== "undefined") {
            if (dataInvite.profileInvitationList.result.length > 1) {
              setInvitationError(true)
            } else {
              setIsEmailError(true);
            }
          }
          setInvitationCount(dataInvite.profileInvitationList.result.length)
        }
      },
    }
  );
  const handleNext = () => {
    const isEmailEmpty = !(email.length >= (1 - invitationCount));
    setIsEmailEmpty(isEmailEmpty);
    if (!isEmailEmpty) {
      const emailFilter = email.map((option) =>
        !isEmail(option) ? "notvalidemail" : ""
      );
      let result = emailFilter.includes("notvalidemail");
      const isValidemail = result ? true : false;
      setInputError(isValidemail);
      if (isValidemail === false) {
        setIsEmailError(false);
        const variables = {
          invitedMembers: email,
          requestedUserId: userId,
          message: message
        };
        // console.log("saveInviteVariables", variables);
        saveInvite({
          variables: variables,
        });
      }
    } else {
      setIsEmailError(false);
    }
  };

  const onChange = (event, newvalue) => {
    setIsEmailEmpty(false);
    setInputError(false);
    setIsEmailError(false);
    setEmail(newvalue);
  };
  const updateTextFileds = (event) => {
    setInput(event.target.value);
  };
  React.useEffect(() => {
    if (localStorage.getItem("onBoardProgress") != null && localStorage.getItem("onBoardProgress") <= 3) {
      history.push("/OnboardingStepper");
    }
  }, [userRole]);

  const handleSucessAlert = () => {
    if(getPremiumPage) {
      GoBack(history, true);
    } else {
      localStorage.setItem("onBoardProgress", 3);
      setIsEmailError(false);
      props.showTab("LearnMasterMatch");
    }
  }

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Background page color={bgColor}/>
    <Grid container px={{xs: 0, sm: 10, md: 25, lg: 45, xl: 50}} rowGap={3} justifyContent="center" sx={{minWidth: "280px", maxWidth: "950px"}}>
      <Text item xs={12}
        variant="h1" align="center" bold color={bgColor} 
        id={getPremiumPage ? (subInfo.current.identifyer == subInfo.all.freemium.identifyer ? "GetPremium.title2" : "GetPremium.title1") : "OnboardingInvite.pageTitle"} />
      <Text
        item xs={12}
        variant="h2" align="center"
        color={bgColor}
        id={getPremiumPage ? 
          (subInfo.current.identifyer == subInfo.all.initialTrial.identifyer ? "GetPremium.description1" : (subInfo.current.identifyer == subInfo.all.freemium.identifyer ? "GetPremium.description2" : "GetPremium.description3"))
        : "OnboardingInvite.Pagedescription1"}
        values={{br: <br/>}}/>      

      {getPremiumPage && subInfo.current.identifyer == subInfo.all.initialTrial.identifyer && <Text
        item xs={12} mt={2}
        variant="body1" bold align="center"
        color={bgColor}
        id="GetPremium.description1.2"/>}

      <FormContainer props={{mt: 5}} color={getPremiumPage ? undefined : "primary"} noFullscreen
        modalMessage = {data?.saveProfileInvite?.errorCode ==  0 ? data.saveProfileInvite.message : undefined}
        onModalClose = {handleSucessAlert}
        error        = {data?.saveProfileInvite?.errorCode == -1 ? data.saveProfileInvite.message : undefined}
        loading      = {loading || loadingInviteDetails}
        onSave       = {handleNext}
        disabled     = {loading || loadingInviteDetails || !email?.length}
        onCancel     = {getPremiumPage ? () => {
          if(history.location.state?.path)
            history.push(history.location.state.path, history.location.state);
          else
            history.goBack();
        } : handleSucessAlert}
        cancelId     = {history.location.state?.path || !getPremiumPage ? "Skip" : "Button_Text_GoBack"}
        saveId       = "OnboardingInvite.sendInvites"
        saveColor    = {getPremiumPage ? undefined : "lightPetrol"}
        hint         = {<FormattedMessage id={getPremiumPage ? "GetPremium.bottomText" : "OnboardingInvite.bottomText"} values={{link: <a href="https://mastermatch.net/tarieven/" style={{color: Theme_WIP.palette.darkPetrol.main, textDecoration: "underline"}}><FormattedMessage id="GetPremium.bottomTextLink"/></a>}}/>}
      >

          {/* message */}
          <InputSection
          textColor   = {textColor}
          type        = "multipleText"
          disabled    = {loading || loadingInviteDetails}
          id          = "emailIds"
          label       = {<FormattedMessage id="Register_page.Email" />}
          title       = {<FormattedMessage id="OnboardingInvite.addEmail"/>}
          description = {<FormattedMessage id="OnboardingInvite_PageDescription"/>}
          onChange    = {onChange}
          hint        = {isEmailEmpty ? <FormattedMessage id="invite_minimum_email_error" /> : inputError && <FormattedMessage id="invite_email_valid_error" />}
          />

          <InputSection
          textColor   = {textColor}
          type        = "textarea"
          disabled    = {loading || loadingInviteDetails}
          id          = "message"
          placeholder = {intl.formatMessage({ id: "Type" })}
          title       = {<FormattedMessage id="OnboardingInvite.message.label"/>}
          onChange    = {(value) => setMessage(value.target.value)}
          max         = {300}
          />

        {error && <Text variant="body2" bold color={textColor} >{error}</Text>}
      </FormContainer>

    </Grid>
    </ThemeProvider>
  );
}
