import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { ButtonDarkBlue } from "./Buttons/ButtonDarkBlue";
import SliderPage from "../components/SliderPage";
import { Autocomplete } from "@mui/material";
import { Box, Chip } from "@material-ui/core";
import LocationAutoComplete from "./Autocomplete/LocationAutoComplete";
import {
  CollaborationOfInterestArray,
  masterDataCompanyLevel,
  masterDataIndustries,
  masterDataSkills,
  RequestCollaborationOfInterestArray,
} from "../apiServices/Constants";
import { removeItemOnce } from "../apiServices/CommonMethods";
import PropTypes from "prop-types";
import { selectedBrandColor } from "../apiServices/Redux/Reducer";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import theme from "../styles/container/Theme";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "../styles/component/AccordianStyle";


const useStyles = makeStyles(styles);

export default function Accordian(props) {
  const classes = useStyles();
  const brandColor = useSelector(selectedBrandColor);
  const intl = useIntl();
  let [input, setInput] = React.useState({
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessType: null,
    pageNumber: 1,
  });

  const onLocationChange = (location) => {
    // console.log("location 456", location)
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        location:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
      setInput((prevState) => ({
        ...prevState,
        locationPlaceID:
          typeof location === "string" || location instanceof String
            ? input["locationPlaceID"]
            : location.place_id,
      }));
    } else {
      setInput((prevState) => ({
        ...prevState,
        location: null,
        locationPlaceID: null,
        maxDistance: null
      }));
    }
  };

  const handleChangeAutoCompleteSkills = (event, newvalue) => {
    setInput((prevState) => ({
      ...prevState,
      skills: newvalue,
    }));
  };

  const onBusinessSelection = (event) => {
    var newBusinessTypes = input["businessType"] ?? [];
    if (event.target.checked) {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        let businessStageAll = masterDataCompanyLevel().map(({ key }) => key);
        newBusinessTypes = businessStageAll;
      } else if (!newBusinessTypes.includes(event.target.name)) {
        newBusinessTypes.push(event.target.name);
      }
    } else {
      if (event.target.name === intl.formatMessage({ id: "All" })) {
        newBusinessTypes = [];
      } else {
        removeItemOnce(newBusinessTypes, event.target.name);
      }
    }
    // console.log("newBusinessTypes", newBusinessTypes);
    setInput((prevState) => ({
      ...prevState,
      businessType: newBusinessTypes,
    }));
  };

  const onCollabSelection = (event) => {
    var newCollabTypes = input["collaborationType"] ?? [];
    if (event.target.checked) {
      if (event.target.name === "Co-founding") {
        newCollabTypes.push("Co founding");
      } else if (event.target.name === intl.formatMessage({ id: "All" })) {
        let collabAll = getCollabArray().map(({ itemType }) => itemType);
        removeItemOnce(collabAll, "Co-founding");
        newCollabTypes = collabAll;
        newCollabTypes.push("Co founding");
      } else if (!newCollabTypes.includes(event.target.name)) {
        newCollabTypes.push(event.target.name);
      }
    } else {
      if (event.target.name === "Co-founding") {
        removeItemOnce(newCollabTypes, "Co founding");
      } else if (event.target.name === intl.formatMessage({ id: "All" })) {
        newCollabTypes = [];
      } else {
        removeItemOnce(newCollabTypes, event.target.name);
      }
    }
    setInput((prevState) => ({
      ...prevState,
      collaborationType: newCollabTypes,
    }));
  };

  const getCollabArray = () => {
    if (typeof props.filterType !== "undefined") {
      if (props.filterType === "collabRequest") {
        return RequestCollaborationOfInterestArray;
      } else {
        return CollaborationOfInterestArray;
      }
    } else {
      return CollaborationOfInterestArray;
    }
  };
  const onIndustrySelection = (event) => {
    var newIndustries = input["industries"] ?? [];
    if (event.target.checked) {
      // console.log("event.target.name", masterDataIndustries());
      if (event.target.name === "Alle") {
        newIndustries = masterDataIndustries();
      } else if (!newIndustries.includes(event.target.name)) {
        newIndustries.push(event.target.name);
        if (
          newIndustries.length === masterDataIndustries().length - 1 &&
          !newIndustries.includes("Alle")
        ) {
          newIndustries.push("Alle");
        }
      }
    } else {
      if (event.target.name === "Alle") {
        newIndustries = [];
      } else {
        removeItemOnce(newIndustries, event.target.name);
        if (newIndustries.includes("Alle")) {
          removeItemOnce(newIndustries, "Alle");
        }
      }
    }
    // console.log("newIndustries", newIndustries);

    setInput((prevState) => ({
      ...prevState,
      industries: newIndustries,
    }));
  };

  const onSliderChange = (value) => {
    // console.log("sliderValue", value);
    setInput((prevState) => ({
      ...prevState,
      maxDistance: value,
    }));
  };

  const onSearch = (event) => {
    // console.log("selcetd items", input);
    props.onSearch(input);
  };

  const isCheckedCollabType = (option) => {
    const arrayCollabTypes = input["collaborationType"] ?? [];
    const optionSelected = option === "Co-founding" ? "Co founding" : option;
    if (arrayCollabTypes.includes(optionSelected)) {
      return true;
    }
    return false;
  };

  const isCheckedAllCollabType = () => {
    const arrayCollabTypes = input["collaborationType"] ?? [];
    if (arrayCollabTypes.length === getCollabArray().length) {
      return true;
    }
    return false;
  };

  const isCheckedBusinessStage = (option) => {
    const arrayCollabTypes = input["businessType"] ?? [];
    if (arrayCollabTypes.includes(option)) {
      return true;
    }
    return false;
  };

  const isCheckedAllBusinessStage = () => {
    const arrayBusinessType = input["businessType"] ?? [];
    if (arrayBusinessType.length === 5) {
      return true;
    }
    return false;
  };

  const isCheckedIndustry = (option) => {
    const arrayIndustries = input["industries"] ?? [];
    if (arrayIndustries.includes(option)) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Box sx={{ marginY: 2 }}>
        <Typography variant="h3" color={"textPrimary"}>
          <FormattedMessage
            id={"Filter_TopTitle"}
          />
        </Typography>
      </Box>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3" color={"textSecondary"}>
            <FormattedMessage
              id={"Master_Filter_SelectCollaborationsOfInterest"}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <Typography variant="h6" color={"textSecondary"}>
              <FormControlLabel
                control={<Checkbox checked={isCheckedAllCollabType()} />}
                label={intl.formatMessage({ id: "All" })}
                onChange={onCollabSelection}
                name={intl.formatMessage({ id: "All" })}
              />
            </Typography>
            {getCollabArray().map((option, index) => (
              <Typography variant="h6" color={"textSecondary"} key={index}>
                <FormControlLabel
                  control={
                    <Checkbox checked={isCheckedCollabType(option.itemType)} />
                  }
                  label={option.itemType}
                  onChange={onCollabSelection}
                  name={option.itemType}
                />
              </Typography>
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionDetails id="panel1a-header">
          <Autocomplete
            multiple
            id="ags-outlined"
            options={masterDataSkills()}
            size={"small"}
            onChange={handleChangeAutoCompleteSkills}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  key={index}
                  size="small"
                  className={classes.chip}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={intl.formatMessage({ id: "Master_TypeSkills" })}
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                    fontFamily: 'Montserrat', //h2
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  // type: 'search',
                  style: {
                    fontSize: 14,
                    fontFamily: 'Montserrat', //h2
                  },
                }}
              />
            )}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel3a-header"
        >
          <Typography color={"textSecondary"} variant="h3">
            <FormattedMessage id={"Master_SelectBusinessTypes"} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <Typography variant="h6" color={"textSecondary"}>
              <FormControlLabel
                control={<Checkbox checked={isCheckedAllBusinessStage()} />}
                label={intl.formatMessage({ id: "All" })}
                onChange={onBusinessSelection}
                name={intl.formatMessage({ id: "All" })}
              />
            </Typography>
            {masterDataCompanyLevel().map((option, index) => (
              <Typography variant="h6" color={"textSecondary"} key={index}>
                <FormControlLabel
                  control={
                    <Checkbox checked={isCheckedBusinessStage(option.key)} />
                  }
                  label={option.key}
                  onChange={onBusinessSelection}
                  name={option.key}
                />
              </Typography>
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel4a-header"
        >
          <Typography color={"textSecondary"} variant="h3">
            <FormattedMessage id={"Master_SelectIndustries"} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {masterDataIndustries().map((option, index) => (
              <Typography variant="h6" color={"textSecondary"} key={index}>
                <FormControlLabel
                  control={<Checkbox checked={isCheckedIndustry(option)} />}
                  label={option}
                  onChange={onIndustrySelection}
                  name={option}
                />
              </Typography>
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionDetails>
          <Box sx={{ width: "100%" }}>
            <LocationAutoComplete
              onChange={onLocationChange}
              placeHolder={intl.formatMessage({
                id: "Request_TypeCityOrCountry",
              })}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.noAccordian}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <SliderPage onChange={onSliderChange}
              disabled={input["location"] === null && typeof props.filterType !== "undefined" && props.filterType === "collabRequest"}
              position={input.maxDistance} />
          </Grid>
          <Grid item xs={12} sm={2}>
            {/* <ButtonYellow onClick={onSearch}>
              Search
            </ButtonYellow> */}
            {typeof props.isBrandColor === "boolean" &&
              brandColor !== theme.palette.primary.main ? (
              <Button
                fullWidth
                variant="contained"
                style={{ backgroundColor: brandColor, color: "white" }}
                onClick={onSearch}
                disableElevation
              >
                <FormattedMessage id={"Button_Text_Search"} />
              </Button>
            ) : (
              <ButtonDarkBlue onClick={onSearch}>
                <FormattedMessage id={"Button_Text_Search"} />
              </ButtonDarkBlue>
            )}
          </Grid>
        </Grid>
      </Accordion>
    </div>
  );
}
Accordian.prototype = {
  onSearch: PropTypes.func,
  filterType: PropTypes.func,
};
