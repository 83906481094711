import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Styles from "../../styles/container/RequestStyle";
import {
Chip,
  TextField,
} from "@material-ui/core";
import DatePicker from "../DatePicker/DatePicker";
import { Autocomplete,  } from "@mui/material";
import {
  masterDataSkills
} from "../../apiServices/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import Text from "../../visualComponents/Text";

const useStyles = makeStyles(Styles);

export default function MastersForMaster(props) {
  const classes = useStyles();
  const intl = useIntl();
  let [isStartDataError, setIsStartDataError] = React.useState(false);

  const onErrorStartDate = (error) => {
    setIsStartDataError(error ? true : false);
  };

  const handleChangeAutoCompleteOfferedSkills = (event, newvalue) => {
    props.setInput((prevState) => ({
      ...prevState,
      skills2: newvalue,
    }));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"OfferedSkills"} />
        <Text variant="body2" id={"EditBioInfo_Field_Description"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <Autocomplete
                multiple
                id="skills2"
                freeSolo
                autoSelect={true}
                options={masterDataSkills()}
                value={props.input.skills2}
                onChange={handleChangeAutoCompleteOfferedSkills}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      key={index}
                      label={option}
                      {...getTagProps({ index })}
                      size="small"
                      className={classes.chip}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // placeholder="Add"
                    variant="outlined"
                  />
                )}
              />
          </FormControl>
        </Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id="DurationInMonths" />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <TextField
                id="months"
                label={intl.formatMessage({
                  id: "introduction_Field_input_user_type",
                })}
                size="small"
                value={props.months}
                onChange={props.onChange}
                type={"number"}
              />
          </FormControl>
        </Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"EstimatedHours"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <TextField
                id="hours"
                label={intl.formatMessage({
                  id: "introduction_Field_input_user_type",
                })}
                size="small"
                value={props.input.hours}
                onChange={props.updateTextFields}
                type={"number"}
              />
          </FormControl>
        </Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"StartDate"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <DatePicker
                updateDate={props.updateDate}
                initialValue={props.input.startDate ?? null}
                onError={onErrorStartDate}
              />
            {isStartDataError ? (
              <Text variant="body2" component="span" color="red" id={"PleaseEnterValidDate"} />
            ) : null}
          </FormControl>
        </Text>
      </Grid>
    </Grid>
  );
}
MastersForMaster.prototype = {
  receivers: PropTypes.array,
  updateStatus: PropTypes.func,
};
