import React, { useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AutosearchPage from "../components/AutosearchPage";
import Accordian from "../components/Accordian";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FormGroup from "@mui/material/FormGroup";
import UserPostedCard from "../components/Cards/Defaults/UserPostedCard";
import { useLazyQuery } from "@apollo/client";
import {
  GET_MASTERS_MATCHES,
} from "../apiServices/Queries";
import { Chip, CircularProgress, Stack, ThemeProvider } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import styles from "../styles/container/MatchRequestPageStyle";
import { MastersTabItems, userRoles } from "../apiServices/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import GoTo from "../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";
import { useListMasters } from "../apiServices/Queries/Masters/ListMasters";
import Theme_WIP, { ThemeConstants } from "../styles/themes/main/Theme_WIP";
import Text from "../visualComponents/Text";
const useStyles = makeStyles(styles);

export default function Masters() {
  const globals    = useGlobalContext();
  const circleData = globals.state.loggedInUser.circles;
  const userRole   = globals.state.loggedInUser.role.role;
  const userId     = globals.state.loggedInUser.userId;
  const classes    = useStyles();
  const history    = useHistory();
  const intl       = useIntl();
  const [masterTabType, setMasterTabType] = React.useState({
    type: intl.formatMessage({ id: MastersTabItems[0].type }),
    key: MastersTabItems[0].key,
  });
  const [isShowFilter, setIsShowFilter] = React.useState(false);
  const [mastersMatch, setMastersMatch] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [isSecondDegreeMatches, setIsSecondDegreeMatches] =
    React.useState(false);
  const [paginationCount, setPaginationCount] = React.useState(1);
  const [masterTabItemList, setMasterTabItemList] = React.useState([]);
  const [selectedCircle, setSelectedCircle] = React.useState({
    type: null,
    key: null,
  });

  let [input, setInput] = React.useState({
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessType: null,
    searchInput: null,
    showLoggedInUser: false,
  });
  const getVariableMymatches = {
    userId: userId,
    pageNumber: page,
    location: input["location"],
    industries: input["industries"],
    skills: input["skills"],
    maxDistance: input["maxDistance"],
    collaborationType: input["collaborationType"],
    businessType: input["businessType"],
    pageSize: 10,
    isMyMatches: !isSecondDegreeMatches,
    searchInput: input["searchInput"],
  };

  const variables = {
    userId: userId,
    pageNumber: page,
    location: input["location"],
    industries: input["industries"],
    skills: input["skills"],
    maxDistance: input["maxDistance"],
    collaborationType: input["collaborationType"],
    businessType: input["businessType"],
    pageSize: 10,
    isMyMatches: false,
    searchInput: input["searchInput"],
    circleId: selectedCircle.key,
    showLoggedInUser: input["showLoggedInUser"],
  };
  const {data: masters, loading: loading, updateVariables: updateVariables, hash: hash} = useListMasters(variables);
  useEffect(() => {
    if (masterTabType.type !== intl.formatMessage({ id: "filter_title_mymatches" }))
      setPagination(masters);
  }, [masters]);

  const [fetchMatches,
    { loading: loadingMatches }] = useLazyQuery(
      GET_MASTERS_MATCHES,
      {
        variables: getVariableMymatches,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
          // console.log("GET_MASTERS_MATCHES", data)
          if (data.allProfilesByUserIds.errorCode === 0) {
            setMastersMatch(data.allProfilesByUserIds.result.profiles);
            if (
              masterTabType.type ===
              intl.formatMessage({ id: "filter_title_mymatches" })
            ) {
              setPagination(data.allProfilesByUserIds.result.profiles);
            }
          } else if (data.allProfilesByUserIds.errorCode !== 0) {
            setMastersMatch([]);
            if (
              masterTabType.type ===
              intl.formatMessage({ id: "filter_title_mymatches" })
            ) {
              setPagination([]);
            }
          }
        }
      }
    );

  useEffect(() => {
    if (masterTabType.type == intl.formatMessage({ id: "filter_title_all" }))
      setPagination(masters);

    if (
      masterTabType.type ===
      intl.formatMessage({ id: "filter_title_mymatches" })
    ) {
      fetchMatches();
    }

    const circleTabItems = circleData ? circleData.map(
      circle => {
        return {
          type: circle.name,
          key: circle.id,
        };
      }
    ) : [];
    const masterTabList = MastersTabItems.map(item => {
      return {
        type: intl.formatMessage({
          id: item.type,
        }),
        key: item.key,
      };
    });
    setMasterTabItemList([...masterTabList, ...circleTabItems]);

  }, [masterTabType, isSecondDegreeMatches, circleData, selectedCircle, masters]);

  useEffect(() => {
    updateVariables({...variables, pageNumber: page, circleId: selectedCircle.key});
  }, [page, selectedCircle]);

  const setPagination = data => {
    if (data && data?.length) {
      const master = data[0];
      const count = Math.floor(master.totalCount / 10);
      const remainder = master.totalCount % 10;
      setPaginationCount(count < 1 ? 1 : remainder > 0 ? count + 1 : count);
    } else {
      setPaginationCount(0);
    }
  };

  const handleChange = (event, newValue) => {
    setPage(1);
    setIsSecondDegreeMatches(false);
    let filteredMastersTab = masterTabItemList.filter(tabItem => {
      return tabItem.type === newValue;
    });
    setMasterTabType(filteredMastersTab[0]);
    setPagination(getCurrentMastersData(newValue));
    if (
      filteredMastersTab[0].type !==
      intl.formatMessage({ id: "filter_title_mymatches" }) &&
      filteredMastersTab[0].type !==
      intl.formatMessage({ id: "filter_title_all" })
    ) {
      setSelectedCircle(filteredMastersTab[0]);
    } else {
      setSelectedCircle({
        type: null,
        key: null,
      });
    }
  };

  const showFilter = () => {
    setIsShowFilter(!isShowFilter);
  };
  const handleChangePage = (event, value) => {
    setPage(value);
    if (
      masterTabType.type ===
      intl.formatMessage({ id: "filter_title_mymatches" })
    ) {
      // fetchMoreMatches({
      //   variables: {
      //     pageNumber: parseInt(value),
      //   },
      // });
      fetchMatches()
    }
  };

  const filterAction = inputs => {
    if (userRole === userRoles.freemium) {
      navigateToGetPremium();
    } else {
      // console.log("filterAction input", inputs)
      // setInput(inputs);
      setPage(1)
      setInput(prevState => ({
        ...prevState,
        showLoggedInUser: false,
        pageNumber: 1,
        location: inputs["location"],
        industries: inputs["industries"],
        skills: inputs["skills"],
        maxDistance: inputs["maxDistance"],
        collaborationType: inputs["collaborationType"],
        businessType: inputs["businessType"],
      }));
      setIsShowFilter(!isShowFilter);
      // if (
      //   masterTabType.type ===
      //   intl.formatMessage({ id: "filter_title_mymatches" })
      // ) {
      //   fetchMore({
      //     variables: {
      //       pageNumber: parseInt(1),
      //     },
      //   });
      // } else {
      //   if (
      //     masterTabType.type ===
      //     intl.formatMessage({ id: "filter_title_mymatches" })
      //   ) {
      //     fetchMore({
      //       variables: {
      //         pageNumber: parseInt(1),
      //       },
      //     });
      //   } else {
      //     fetchMoreMatches({
      //       variables: {
      //         pageNumber: parseInt(1),
      //       },
      //     });
      //   }
      // }
    }
  };
  const onFocus = () => {
    // console.log("onFocus", userRole)
    if (userRole === userRoles.freemium) {
      navigateToGetPremium();
    }
  };

  const navigateToGetPremium = () => {
    GoTo(history, "/GetPremium");
  };

  const searchAction = masterList => { };
  const onUserSelection = userName => {
    setPage(1);
    setInput(prevState => ({
      ...prevState,
      searchInput: userName,
      location: null,
      industries: null,
      skills: null,
      maxDistance: null,
      collaborationType: null,
      businessType: null,
    }));
  };

  const updateSecondDegreeMatches = () => {
    if (userRole === userRoles.freemium) {
      navigateToGetPremium();
    } else {
      setIsSecondDegreeMatches(!isSecondDegreeMatches);
      // fetchMoreMatches({
      //   variables: {
      //     pageNumber: page,
      //     isMyMatches: !isSecondDegreeMatches,
      //   },
      // });

      fetchMatches()

    }
  };

  const getCurrentMastersData = type => {
    if (type === intl.formatMessage({ id: "filter_title_mymatches" })) {
      return mastersMatch;
    } else {
      return masters;
    }
  };
  const currentMasters = getCurrentMastersData(masterTabType.type);

  const onReset = () => {
    const variable = {
      location: null,
      industries: null,
      skills: null,
      maxDistance: null,
      collaborationType: null,
      businessType: null,
      pageNumber: 1,
      showLoggedInUser: false
    };
    setInput(variable);
    if (
      masterTabType.type ===
      intl.formatMessage({ id: "filter_title_mymatches" })
    ) {
      
    } else {
      // fetchMoreMatches({
      //   variables: {
      //     pageNumber: parseInt(1),
      //   },
      // });
      fetchMatches()

    }
  };

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container item mt={6} gap={ThemeConstants.custom.spacings.md} pb={14}>
      <Grid container item xs={12} gap={2}>
        <Grid item sm={8} xs={10}>
          <AutosearchPage
            showLoggedInUser={false}
            onSearch={searchAction}
            onFocus={onFocus}
            onUserSelection={onUserSelection}
            placeHolder={intl.formatMessage({
              id: "introduction_Field_input_type_username",
            })}
          />
        </Grid>
        <Grid item sm={1} xs={1}>
          <FilterAltOutlinedIcon
            className={classes.iconFilter}
            onClick={showFilter}
          />
        </Grid>
        {(input.businessType != null && input.businessType?.length) ||
          (input.collaborationType != null &&
            input.collaborationType?.length) ||
          (input.skills != null && input.skills?.length) ||
          (input.industries != null && input.industries?.length) ||
          input.location != null ||
          input.maxDistance != null ? (
          <Grid item sm={1} xs={1}>
            <Typography
              component="h5"
              variant="h5"
              className={classes.iconReset}
              onClick={onReset}
            >
              <FormattedMessage id={"Resetten"} />
            </Typography>
          </Grid>
        ) : null}
      </Grid>

      {isShowFilter &&
        <Grid item xs={12}>
          <Accordian onSearch={filterAction} />
        </Grid>
      }

      {!isShowFilter &&
      <>
        <Grid item xs={12}>
          <Tabs
            value={masterTabType.type}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
          >
            {masterTabItemList.map(tabItem => (
              <Tab
                key={tabItem.key}
                value={tabItem.type}
                label={tabItem.type}
                className={classes.masterTab}
                wrapped
              />
            ))}
          </Tabs>
          {masterTabType.type ===
            intl.formatMessage({ id: "filter_title_mymatches" }) &&
            <FormGroup>
              <Typography variant="body2" color={"textSecondary"}>
                <FormControlLabel
                  color={"textSecondary"}
                  control={
                    <Checkbox
                      checked={isSecondDegreeMatches}
                      onClick={updateSecondDegreeMatches}
                    />
                  }
                  label={
                    <FormattedMessage
                      id={
                        "Masters_SearchSecondDegreeConnectionsOfYourMatches"
                      }
                    />
                  }
                />
              </Typography>
            </FormGroup>
          }
        </Grid>
        <Grid item xs={12}>
          {!loading && paginationCount > 0 && (
            <Pagination
              count    = {paginationCount}
              page     = {page}
              onChange = {handleChangePage}
            />
          )}
        </Grid>
        <Grid item container xs={12} gap={{xs: ThemeConstants.custom.spacings.md}}>
          {loading || loadingMatches ? (
            <CircularProgress />
          ) : currentMasters?.map(
                (card, index) => {
                  return <UserPostedCard 
                  props    = {{
                    //md: currentMasters.length > 6 ? 5.5 : undefined, 
                    style:{height: "auto"}}}
                  id       = {card.userId} 
                  username = {card.givenName + " " + card.familyName} 
                  company  = {card.companyName}
                  location = {card.location}
                  role     = {card.role}
                  circles  = {card.sharedCircles}
                  pfp      = {card.profilePictureUrl}
                  label    = {card.matchStatus ? "Match" : undefined}
                  tags     = {card.skills}
                  />
                }
              )}

            {!currentMasters?.length &&
              <Text item xs={12} mt={4} align="center" variant="h2" color="background" id={"noDataAvailable"} />
            }
        </Grid>
      </>}
      
    </Grid>
    </ThemeProvider>
  );
}
