const EventInviteStyle = (theme) => ({
  root: {
    width: "100%",
  },
  p7: {
    marginTop: 7,
  },
  p15: {
    marginTop: 15,
  },
  mt10: {
    marginTop: 10,
  },
  m20: {
    marginTop: 20,
  },
  txtarea: {
    width: "100%",
    border: "none",
    backgroundColor: "#e8e8e8",
    borderRadius: 6,
    padding: "2%",
  },
  ml4: {
    marginLeft: 4,
  },
  submit: {
    color: "#fff",
  },
  tabcontent: {
    margin: "8px 0 15px 0",
    padding: "10px !important",
  },
  button: {
    width: "100%",
  },
  Select: {
    width: "100%",
  },
  redLine: {
    color: "#ff3366",
    marginTop: 10,
  },
});

export default EventInviteStyle;
