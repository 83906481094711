import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import styles from "../../styles/container/PreRegisterPageStyle";
import { CssBaseline } from "@material-ui/core";
import { GlobalStyles, Divider } from '@mui/material';
import { ButtonLightBlue } from "../../components/Buttons/ButtonLightBlue";
import { ButtonGrey } from "../../components/Buttons/ButtonGrey";
import { FormattedMessage } from "react-intl";
import VideoPlayer, { VimeoVideo } from "../../components/Media/VideoPlayer";
import { useAdminMetaData } from "../../apiServices/Queries/GetAdminMetaData";

const useStyles = makeStyles(styles);

export default function PreRegisterPage() {
  const {data: systemInfo} = useAdminMetaData();
  const [progress, setProgress] = React.useState(1);
  const [watchedIntro, setWatchedIntro] = React.useState(false);
  const classes = useStyles();

  const watched = () => setWatchedIntro(true);
  const completeFlow = () => {
    localStorage.setItem("seenVideo", true)
    window.location.reload();
  };

    const page1 = () => (
    <Grid container spacing={3} flexDirection={"column"} alignItems="center">
        <Grid item>
            <Typography variant="h1" align="center" className={classes.h1}>
            <b>master</b>match
            </Typography>
        </Grid>
        <Grid item sx={{width: "100%"}}>
            <Divider className={classes.line}/>
        </Grid>
        <Grid item mt={4} alignSelf="center" justifyContent="center" alignItems="center">
            <Typography 
            style={{padding: "0px 30px"}}
            className={classes.h2}
            variant="h2" 
            align="center">
                <FormattedMessage id={"PreRegisterPage.Text3"} />
            </Typography>
        </Grid>
        <Grid item className={classes.button} mt={6}>
            <ButtonLightBlue
            onClick={setProgress} 
            value={2} >
                <span className={classes.buttonText}><FormattedMessage id={"PreRegisterPage_Lets_Start"} /></span>
            </ButtonLightBlue>
        </Grid>
    </Grid>
    );

    const page2 = () => (
    <Grid container spacing={6} flexDirection={"column"} justifyContent="center" alignItems="center" >
        <Grid item >
            <Typography variant="h1" align="center" className={classes.h1}>
            <b>master</b>match
            </Typography>
        </Grid>
        <Grid item sx={{width: "100%"}} mt={-2}>
            <Divider className={classes.line}/>
        </Grid>
        <Grid item>
            <Typography 
            className={classes.h2}
            variant="h2" 
            align="center">
                <FormattedMessage id={"PreRegisterPage_Video"} />
            </Typography>
        </Grid>
        {systemInfo?.preregisterVideoId && video()}
        <Grid item sx={{width: "300px"}}>
            {!watchedIntro?
            <ButtonGrey onClick={completeFlow} value={3} >
                <span className={classes.buttonText}><FormattedMessage id={"Skip"} /></span>
            </ButtonGrey>
            :
            <ButtonLightBlue onClick={completeFlow} value={3} >
                <span className={classes.buttonText}><FormattedMessage id={"PreRegisterPage_Continue"} /></span>
            </ButtonLightBlue>
            }
        </Grid>
    </Grid>
    );

    const video = () => (
    <Grid item sx={{aspectRatio:"16 / 11", width: "100%", height: "auto"}}>
        <VimeoVideo id={systemInfo?.preregisterVideoId} onComplete={watched}/>
    </Grid>
    )

  return (
    <React.Fragment>
      <CssBaseline />
      <GlobalStyles
        styles={{
            body: { 
                backgroundColor: "#00394D !important",
                color: "white"
            }
        }}/>
      <Container component="main" maxWidth="sm" className={classes.container}>
        {progress==1? page1() : page2()}
      </Container>
    </React.Fragment>
  );
}