const AccordianStyle = (theme) => ({
  submit: {
    color: "#fff",
    fontSize: 13,
    padding: "6px 0 6px 0",
  },
  mt10: {
    marginTop: "10px",
  },
  mt15: {
    marginTop: "15px",
  },
  mt20: {
    marginTop: "20px",
  },
  mt30: {
    marginTop: "30px",
  },
  closeicon: {
    float: "right",
    fontSize: 32,
    color: "#bdbdbd",
   
  },
  Select: {
    width: "100%",
    marginTop: 7,
  },
  W100: {
    width: "94%",
    margin: "0px 3% 2% 3%",
  },
  noAccordian: {
    padding: 16,
  },
  right: {
    textAlign: "right",
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.color5.main,
    borderRadius: 5,
  },
  typographyStyle: {
    fontSize: 10,
    fontFamily: "Helvetica",
  },
});

export default AccordianStyle;
