import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ButtonDarkBlue } from '../Buttons/ButtonDarkBlue';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import Styles from '../../styles/component/Onboarding/MeetCollaborationStyle'
import { FormattedMessage } from 'react-intl';
import Image from "../Media/Image";
import { useCurrentUserProfile } from '../../apiServices/CommonDataObjects';

const useStyles = makeStyles(Styles);


export default function MeetCollaborations(props) {
  const profile = useCurrentUserProfile();

  const classes = useStyles();
  const history = useHistory()

  function handleClick(href) {
    localStorage.setItem("onBoardProgress", 4);
    history.push(href)
  };
  React.useEffect(() => {
    if (localStorage.getItem("onBoardProgress") != null && localStorage.getItem("onBoardProgress") <= 3) {
      history.push("/OnboardingStepper");
    }
  }, []);
  return ((profile == null)? (<span></span>) :
    (<Grid container spacing={4} justifyContent='center' >

      <Grid item xs={5} sm={3}>
        <Image
          sx={{
            borderRadius: '20px 20px 20px 0px',
          }}
          width={"100%"}
          src="/assets/img/iamFull.png"/>
      </Grid>

      <Grid item sm={12} xs={12}>
        <Typography variant="h1" color={"textPrimary"} className={classes.mt10} align='center'>
          <FormattedMessage id={"OnboardingEvents.Pagedescription"} values={{firstname: profile.givenName}} />
        </Typography>
      </Grid>

      <Grid item sm={12} xs={12}>
        <Typography component="h3" variant="h2" color={"textPrimary"} className={classes.mt10} align='center'>
          <FormattedMessage id={"OnboardingEvents.Pagedescription1"} />
        </Typography>
      </Grid>
      <Grid item sm={4} xs={12}>
        <ButtonDarkBlue onClick={() => handleClick('/dashboard/requests')}><FormattedMessage id={"toRequests"} /></ButtonDarkBlue>
      </Grid>

      <Grid item sm={12} xs={12}>
        <Typography component="h3" variant="h2" color={"textPrimary"} className={classes.mt10} align='center'>
          <FormattedMessage id={"OnboardingEvents.Pagedescription3"} />
        </Typography>
      </Grid>
      <Grid item sm={4} xs={12}>
        <ButtonDarkBlue onClick={() => handleClick('/dashboard/profile')} ><FormattedMessage id={"toMyProfile"} /></ButtonDarkBlue>
      </Grid>

      <Grid item sm={12} xs={12}>
        <Typography component="h3" variant="h2" color={"textPrimary"} className={classes.mt10} align='center'>
          <FormattedMessage id={"OnboardingEvents.Pagedescription2"} />
        </Typography>
      </Grid>
      <Grid item sm={4} xs={12}>
        <ButtonDarkBlue onClick={() => handleClick('/dashboard/activity')} ><FormattedMessage id={"toMessages"} /></ButtonDarkBlue>
      </Grid>

      <Grid item xs={12}></Grid>
    </Grid>)
  );
}