const InviteTabStyle = (theme) => ({
  root: {
    width: "100%",
  },
  logo: {
    width: "100%",
  },
  p7: {
    marginTop: 7,
  },
  p10: {
    marginTop: 10,
  },
  p15: {
    marginTop: 15,
  },
  p20: {
    marginTop: 20,
  },
  txtarea: {
    color: "#828282",
    margin: "0px !important",
    width: "50% !important",
    "@media (max-width: 500px)": {
      width: "100% !important",
    },
  },
  ml4: {
    marginLeft: 4,
  },
  submit: {
    color: "#fff",
  },
  tabcontent: {
    margin: "8px 0 15px 0",
    padding: "10px !important",
  },
  Select: {
    width: "100%",
  },
  icoClose: {
    fontSize: 16,
    display: "block",
  },
  ml20: {
    marginLeft: 20,
    marginTop: 15,
  },
  mt6: {
    marginTop: "6px !important",
  },
});
export default InviteTabStyle;
