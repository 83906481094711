import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router";
import { GET_ALL_FEEDS } from "../../apiServices/Queries";
import { Waypoint } from "react-waypoint";
import FeedsCard from "../../components/Cards/FeedsCard";
import { Alert, CircularProgress, Grid, Stack } from "@mui/material";
import Box from "@material-ui/core/Box";
import { FormattedMessage, useIntl } from "react-intl";
import { FeedsTabItems, userRoles } from "../../apiServices/Constants";
import RequestCard from "../../components/Cards/RequestCard";
import Styles from "../../styles/component/Circles/CircleFeedsStyle";
import Accordian from "../Accordian";
import GoTo from "../../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import NewRequestCard from "../Cards/NewRequestCard";
import { useCachedQuery } from "../../apiServices/Queries/CachedQuery";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import { ClearCache } from "../../apiServices/CommonMethods";

const useStyles = makeStyles(Styles);

export default function CircleFeed(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId  = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const [hint, setHint] = React.useState("all");

  const [circledata, setCircleData] = React.useState({});
  const [isShowFilter, setIsShowFilter] = React.useState(false);
  const [tabType, setTabType] = React.useState(
    intl.formatMessage({
      id: FeedsTabItems[0].type,
    })
  );
  const [feeds, setFeeds] = React.useState([]);
  const [selectedPostID, setSelectedPostID] = React.useState("");

  let [input, setInput] = React.useState({
    searchInput: "",
    pageNumber: 1,
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessTypes: null,
    locationPlaceID: null
  });

  const getVariable = (newInput) => {
    const input_ = newInput ?? input;
    const variable = {
      circleId: circledata.id,
      loginProfileId: userId,
      pageNumber: input_["pageNumber"],
      pageSize: parseInt(10),
      searchInput: input_["searchInput"],
      hint: hint,
      sourceID: selectedPostID ? selectedPostID : null,
      location: input_["location"],
      industries: input_["industries"],
      skills: input_["skills"],
      maxDistance: input_["maxDistance"],
      collaborationType: input_["collaborationType"],
      businessTypes: input_["businessTypes"],
      locationPlaceID: input_["locationPlaceID"],
    };
    // console.log("feeds variable", variable);
    return variable;
  };

  const { data, loading, error, updateVariables, refetch: refetch } = useCachedQuery(
    "circle_feed",
    GET_ALL_FEEDS,
    getVariable(),
    (data) => data?.allCircleFeeds?.result,
    5,
    true
  );
  const reload = () => {
    globals.functions.loggedInUser.refetchRole(); 
    ClearCache("allCollabRequests");
    ClearCache("circle_feed", globals);
    setInput({...input, pageNumber: 1});
    setFeeds([]);
    setSelectedPostID(null);
    setTimeout(() => refetch({...getVariable(), pageNumber: 1}), 100);
  };

  useEffect(() => {
    if(!data) return;
    if (input["pageNumber"] === 1)
      setFeeds(data);
    else if (data.length > 0)
      setFeeds((prevState) => [
        ...prevState,
        ...data,
      ]);
  }, [data]);

  useEffect(() => {
    if (props.selectedPostID !== null && props.selectedPostID.length > 0) {
      setSelectedPostID(props.selectedPostID);
      setTabType(
        intl.formatMessage({
          id: FeedsTabItems[2].type,
        })
      );
      setHintType(intl.formatMessage({
        id: "filter_title_circle_post",
      }));
    }
    if (typeof props.circleDetails !== "undefined") {
      setCircleData(props.circleDetails);
    }

  }, [props]);

  const handleChange = (event, newValue) => {
    setFeeds([]);
    setSelectedPostID("");
    let filteredTab = FeedsTabItems.filter((tabItem) => {
      return (
        intl.formatMessage({
          id: tabItem.type,
        }) === newValue
      );
    });
    // console.log("Filtered tab :", filteredTab);
    setTabType(
      intl.formatMessage({
        id: filteredTab[0].type,
      })
    );
    setHintType(newValue);
    // console.log("filtered tab", intl.formatMessage({
    //   id: filteredTab[0].type,
    // }))
    setInput((prevState) => ({
      ...prevState,
      pageNumber: 1,
    }));
    // console.log("newvalue", newValue, "type :", tabType);
  };

  const setHintType = (type) => {
    let filter = "";
    if (
      type ===
      intl.formatMessage({
        id: "filter_title_all",
      })
    ) {
      filter = "all";
    } else if (
      type ===
      intl.formatMessage({
        id: "filter_title_circle_post",
      })
    ) {
      filter = "circle posts";
    } else if (
      type ===
      intl.formatMessage({
        id: "filter_title_requests",
      })
    ) {
      filter = "requests";
    }
    setHint(filter);
  };

  const navigateToProfile = (feeds) => {
    history.push("/dashboard/profile/?id=" + feeds.userId, {
      selectedUserId: feeds.userId,
    });
  };

  const onUserSelection = (userName) => {
    const name = userName.replace(" ", "", "g");
    // console.log("Name selected", name)
    setInput((prevState) => ({
      ...prevState,
      searchInput: name,
      location: null,
      industries: null,
      skills: null,
      maxDistance: null,
      collaborationType: null,
      businessTypes: null,
      locationPlaceID: null,
      pageNumber: parseInt(1),
    }));
  };
  const onReset = () => {
    setFeeds([]);
    const variable = {
      circleId: circledata.id,
      userId: userId,
      pageNumber: 1,
      pageSize: parseInt(100),
      searchInput: null,
      skills: null,
      industries: null,
      collaborationType: null,
      businessTypes: null,
      location: null,
      maxDistance: null,
    };
    setInput(variable);
  };
  const showFilter = () => {
    // setMembers([]);
    setInput(prevState => ({
      ...prevState,
      pageNumber: parseInt(1),
    }));
    setIsShowFilter(!isShowFilter);
  };
  const onSearch = () => {

  };
  const filterAction = inputs => {
    if (userRole === userRoles.freemium && (circledata.loggedInUserRole !== "Moderator" &&
      circledata.loggedInUserRole !== "Member" &&
      circledata.loggedInUserRole !== "SuperUser")) {
      navigateToGetPremium();
    } else {
      setFeeds([]);
      setInput(inputs);
      setInput(prevState => ({
        ...prevState,
        searchInput: null,
      }));
      updateVariables(getVariable({
        ...input,
        searchInput: null,
      }));
      setIsShowFilter(!isShowFilter);
    }
  };
  const navigateToGetPremium = () => {
    GoTo(history, "/GetPremium");
  };
  const getRequestCardData = (card) => {
    const requestCardDetails = { ...card.requestFeeds, ...card };
    // console.log("requestCardDetails 111", requestCardDetails);
    return requestCardDetails;
  };

  return (
    <Grid container>
      {(circledata.loggedInUserRole === "Moderator" ||
        circledata.loggedInUserRole === "Member" ||
        circledata.loggedInUserRole === "Superuser")
      && <Grid container mb={4}><NewRequestCard circleId={circledata.id} onCompleted={reload}/></Grid>}     
      <Grid container className={classes.mb15}>
        {error ? (
          <Grid item xs={12}>
            <Alert severity="error">
              {error.length ? (
                error
              ) : (
                <FormattedMessage id={"somethingWentWrong"} />
              )}
            </Alert>
          </Grid>
        ) : null}
        {/*  SEARCH BAR
        <Grid item sm={6} xs={7}>
          <CircleAutosearchPage
            circleDetails={circledata}
            onSearch={onSearch}
            onUserSelection={onUserSelection}
            placeHolder={intl.formatMessage({
              id: "introduction_Field_input_type_username",
            })}
          />
        </Grid>
        <Grid item sm={1} xs={1}>
          <FilterAltOutlinedIcon
            className={classes.iconFilter}
            onClick={showFilter}
          />
        </Grid>
        <Grid item sm={1} xs={1}>
          {
            (input.businessType != null && input.businessType.length) ||
              (input.collaborationType != null && input.collaborationType.length) ||
              (input.skills != null && input.skills.length) ||
              (input.industries != null && input.industries.length) ||
              input.location != null ||
              input.maxDistance != null ? (
              <Typography
                component="h5"
                variant="h5"
                className={classes.iconReset}
                onClick={onReset}
              >
                <FormattedMessage id={"Resetten"} />
              </Typography>
            ) : null}
        </Grid>

        <Grid item sm={1} xs={1} /> */}
      </Grid>

      {
        isShowFilter ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Accordian onSearch={filterAction} isBrandColor={true} filterType={"collabRequest"} />
            </Grid>
          </Grid>
        ) : loading && !feeds.length ? (
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              alignItems: "center",
              flex: "row",
              justifyContent: "center",
              width: "100%",
              height: 300,
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <CircularProgress />{" "}
            </Stack>
          </Box>
        ) : feeds.length > 0 ? (
          <Grid container gap={ThemeConstants.custom.spacings.md} mt={ThemeConstants.custom.spacings.md}>
            {feeds.map((card, index) => (
              <React.Fragment key={index}>
                {typeof card.circlePosts !== "undefined" &&
                  card.circlePosts !== null ? (
                  <Grid item sm={12} xs={12}>
                    <FeedsCard
                      data={card}
                      onRefresh={reload}
                      circleData={circledata}
                      key={index}
                    />
                  </Grid>
                ) : (
                  <Grid container item sm={12} xs={12}>
                    <RequestCard
                      request={getRequestCardData(card)}
                      onRefresh={reload}
                      circleData={circledata}
                      fromProfile={true}
                      key={index}
                      onUserSelection={navigateToProfile}
                      isFromCircle={true}
                    />
                  </Grid>
                )}
                {index === feeds.length - 2 && (
                  <Waypoint
                    scrollableAncestor={window}
                    onEnter={() => {
                      const newPage = input["pageNumber"] + 1;
                      setInput((prevState) => ({
                        ...prevState,
                        pageNumber: newPage,
                      }));
                    }}
                  />
                )}
              </React.Fragment>
            ))}{" "}
          </Grid>
        ) : (
          <Paper elevation={3} className={classes.tabcontent}>
            <Grid container spacing={4}>
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 200,
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Typography component="span" variant="h3" color={"textPrimary"}>
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Paper>
        )
      }
    </Grid >
  );
}
