import React from "react";
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { GET_USER_SUBSCRIPTIONS } from "../apiServices/Queries";
import { useQuery } from "@apollo/client";
import SuccessAlertDialog from './Alert/SuccessAlertDialog';
import Text from '../visualComponents/Text';
import { ThemeProvider } from '@mui/material';
import Theme_WIP from '../styles/themes/main/Theme_WIP';
import Background from "../visualComponents/Background";
import { useGlobalContext } from '../apiServices/Providers/AppGlobalStateProvider';

export default function BottomBar(props) {
    const globals = useGlobalContext();
    const userId  = globals.state.loggedInUser.userId;
    let [subscription, setSubscription] = React.useState();
    const [modal, setModal] = React.useState(!localStorage.getItem("acceptedInvites"));
    let acceptedInvites  = globals.state.loggedInUser.acceptedInvites;
    const history = useHistory();

    useQuery(GET_USER_SUBSCRIPTIONS, {
        variables: {
            userId: userId,
            pageNumber: 1,
            pageSize: 1
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (typeof data !== "undefined" && data.allUserSubscriptions.errorCode === 0) {
                const response = data.allUserSubscriptions.result;
                const userSubscriptions = response.map((userSubscription) => {
                    return createData(
                        userSubscription.subscriptionEndDate,
                        userSubscription.subscriptionType,
                    );
                })
                if(userSubscriptions.length > 0) {
                    setSubscription(userSubscriptions[0]);
                }
            }
        }
    })

    function createData(endDate, subscriptionType) {
        return { endDate, subscriptionType };
    }

    function closeNotification() {
        localStorage.setItem("acceptedInvites", true);
        setModal(false);
    }
    
    function notification() {
        return(modal && acceptedInvites ? (
            <SuccessAlertDialog 
            onClose = {closeNotification}
            message = {<FormattedMessage id={"Premium_Activated_After_Invite"} />} 
            open    = {acceptedInvites} />
        ) : (<span></span>));
    }
    
    return (
        <ThemeProvider theme={Theme_WIP}>
            {  subscription != null 
            && (subscription.subscriptionType == "PREMIUM_TRIAL_SUBSCRIPTION" || subscription.subscriptionType == "FREEMIUM_SUBSCRIPTION")
            && !localStorage.getItem("acceptedInvites")
            && 
            <Background container color="primary" mb={{xs: 14, md: 0}} p={5} sx={{
                zIndex: "0 !important",
                position: "fixed",
                width: '100vw',
                height: "fit-content",
                borderStyle: "none",
                top: "auto",
                bottom: 0,
                left: 0,
                right: 0,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
            }}>
                <Text color="primary" align="center" id="Bottom_Bar_Trial_Countdown_With_Invite" mr={1}/>
                <Text color="primary" align="center" onClick={() => history.push("/dashboard/profile", {tab: 3})} id="Bottom_Bar_Trial_Countdown_With_Invite_Link" sx={{textDecoration: "underline"}}/>
            </Background>}

            {notification()}
        </ThemeProvider>
    );
}