import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import {CardMedia} from "@material-ui/core";
import { Avatar, Button, Hidden, IconButton, Menu, Stack, ThemeProvider } from "@mui/material";
import { useGlobalContext } from "../../../apiServices/Providers/AppGlobalStateProvider";
import Text from "../../../visualComponents/Text";
import Background from "../../../visualComponents/Background";
import Theme_WIP, { ThemeConstants } from "../../../styles/themes/main/Theme_WIP";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { FormattedMessage } from "react-intl";
import {ReactComponent as Message} from "../../../svg/ui/message.svg";
import {ReactComponent as Introduce} from "../../../svg/ui/introduce.svg";
import {ReactComponent as Meeting} from "../../../svg/ui/meeting.svg";
import {ReactComponent as More} from "../../../svg/ui/more.svg";
import { ArrowForwardIos } from "@mui/icons-material";

export default function UserPostedCard({
  children, 
  title, id, username, pfp, role, company, label, location, circles, info, date, tags,
  respond, introduce, meeting, menuItems, onExpand, props
}) {
    const globals   = useGlobalContext();
    const userId    = globals.state.loggedInUser.userId;
    const mine      = id == userId;
    const history   = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [expanded, setExpanded] = useState(true);
    useEffect(() => {
      if(onExpand)
        onExpand(expanded);
    }, [expanded]);

    const onUserSelection = () => {
        history.push("/dashboard/profile/", {selectedUserId: id});
      };

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Background p={{xs: ThemeConstants.custom.spacings.sm, sm: 5}} color="white" container item xs={12} border="bottomLeft" {...props} sx={userId === null ? {backgroundColor: "#F2F2F2", filter: 'blur(4px)', pointerEvents: "none !important", height: "fit-content"} : {height: "fit-content"}}>
        <Grid container gap={ThemeConstants.custom.spacings.md} sx={{overflowWrap: "anywhere"}}>

          <Grid container item columnGap={ThemeConstants.custom.spacings.sm} xs onClick={onUserSelection}>
            <Grid item style={{width: "fit-content"}}> {/* PFP */}
              <CardMedia
                component="img"
                image={pfp ?? "/assets/img/userProfile.png"}
                alt="profile"
                style={{
                    width: "90%",
                    maxWidth: tags ? "70px" : "45px",
                    minWidth: tags ? "70px" : "45px",
                    borderRadius: "5px 5px 5px 0px",
                    aspectRatio: "1 / 1"
                }}
              />
            </Grid>
            <Grid item xs>
              {/* Name */}
              <Text variant="body1" bold color="background" sx={{whiteSpace: "nowrap", overflowX: "hidden", textOverflow: "ellipsis"}}>
                {username}
              </Text>
              {/* role, company*/}
              <Text variant="body2" color="background">
                {role}{role && company && ", "}{company}
              </Text>
              {/* Location - matches */}
              <Text variant="body2" color="background">
                {location}{location && circles && !mine && " - "}{!mine && circles && <span style={{color: ThemeConstants.palette.lightPetrol.main}}><b>{circles.map((sharedcircle, index) => (
                    (index ? ", " : "") + sharedcircle
                ))}</b></span>}
              </Text>
            </Grid>
          </Grid>
          <Grid item xs={3}>{/* Request Type - post date */}
            <Stack direction="column" alignItems="end" spacing={2} >
              {/* Request Type */}
              {label &&
              <Chip
                label={label}
                color="background"
              />}
              {/* Post Date - All responses */}
              <Text align="right" variant="body2" color="background">
                {date}
              </Text>
            </Stack>
          </Grid>

          {/* Title */}
          {title &&
            <Text variant="h2" color="background" item xs={12} bold>
              {title}
            </Text>
          }

          {tags && <Grid container item columnGap={ThemeConstants.custom.spacings.sm} rowGap={ThemeConstants.custom.spacings.md}>
            {tags.map((s) => <Chip label={s} color="background"/>).slice(0, 5)}
          </Grid>}
          {children && <Grid container item gap={ThemeConstants.custom.spacings.md}>
            {children}
          </Grid>}

          {(menuItems || respond || introduce || meeting || onExpand) && <Grid container item xs={12} gap={3}>
            {respond &&
            <Hidden smDown>
              <Button
                variant="contained"
                color="background"
                sx={{path: {fill: ThemeConstants.palette.background.contrastText}}}
                startIcon={
                  <Message/>
                }
                onClick={respond}
              >
                <FormattedMessage id={"Reageren"} />
              </Button>
            </Hidden>
            }

            {introduce &&
            <Hidden smDown>
              <Button
                variant="contained"
                color="background"
                sx={{"circle, path, g, mask, svg": {maskType: "alpha", opacity: 1, fill: ThemeConstants.palette.background.contrastText, stroke: ThemeConstants.palette.background.contrastText}}}
                startIcon={
                  <Introduce fill={ThemeConstants.palette.background.contrastText} stroke={ThemeConstants.palette.background.contrastText}/>
                }
                onClick={introduce}
              >
                <FormattedMessage id={"Introduceren"} />
              </Button>
              </Hidden>
            }

            {meeting &&
              <Hidden smDown>
              <Button
                variant="contained"
                color="background"
                sx={{path: {fill: ThemeConstants.palette.background.contrastText}}}
                startIcon={
                  <Meeting/>
                }
                onClick={meeting}
              >
                <FormattedMessage id={"suggest1on1"} />
              </Button>
              </Hidden>
            }

            <Grid container item xs justifyContent="flex-end" gap={5}>
              {respond &&
              <Hidden smUp>
                <IconButton onClick={respond} sx={{p: 0.5}}>
                    <Message/>
                </IconButton>
              </Hidden>
              }
              {introduce &&
              <Hidden smUp>
                <IconButton onClick={introduce} sx={{p: 0.5}}>
                    <Introduce/>
                </IconButton>
              </Hidden>
              }
              {meeting &&
              <Hidden smUp>
                <IconButton onClick={meeting} sx={{p: 0.5}}>
                    <Meeting/>
                </IconButton>
              </Hidden>
              }

              {onExpand && <Grid item xs onClick={() => setExpanded(!expanded)} mt={2} sx={{maxWidth: "fit-content"}}>
                {expanded ? 
                <Text color="background" bold align="right" id="readMore" /> 
                : <ArrowForwardIos sx={{maxWidth: "fit-content", rotate: "270deg"}}/>}
              </Grid>}

              {menuItems && <>
              <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <More />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={anchorEl != null}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {menuItems}
              </Menu>
              </>}
            </Grid>
          </Grid>}
        </Grid>
    </Background>
    </ThemeProvider>
  );
}
