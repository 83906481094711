import { tabStyle } from "./CommonStyle";

const MatchRequestPageStyle = (theme) => ({
  ...tabStyle,
  submit: {
    marginBottom: 20,
    color: "#fff",
    fontSize: 13,
    padding: "6px 0 6px 0",
  },
  mt7: {
    marginTop: "7px",
  },
  mt10: {
    marginTop: "10px",

  },
  mt15: {
    marginTop: "15px",
  },
  mt20: {
    marginTop: "20px",
  },
  mt30: {
    marginTop: "30px",
  },
  closeicon: {
    float: "right",
    fontSize: 32,
    color: "#bdbdbd",
  },
  Select: {
    width: "100%",
  },
  txtarea: {
    width: "100%",
    border: "none",
    backgroundColor: "#e8e8e8",
    borderRadius: 6,
    padding: "2%",
  },
  btxt: {
    marginTop: 15,
    color: "#72B6CD",
  },
  chip: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  chipItem: {
    borderRadius: 5,
  },
  btnActivity: {
    width: "100%",
    color: theme.palette.color5.main,
    justifyContent: "left",
    marginBottom: 15,
  },
  SelectWithTopMargin: {
    width: "100%",
    marginTop: 7,
  },
  iconSearch: {
    position: "relative",
    right: 50,
    top: 15,
    color: theme.palette.color5.main,
  },
  iconFilter: {
    position: "relative",
    //top: 7,
    //left: 7,
    color: theme.palette.color5.main,
    marginTop: "9px",
    padding: "0 !important",
  },
  iconReset: {
    marginTop: "13px",
    padding: "0 !important",
    color: theme.palette.color5.main,
  },
  alignLeft: {
    justifyContent: "left",
  },
});
export default MatchRequestPageStyle;
