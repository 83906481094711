//This is the new theme as of december 2023. Try to refer to this theme in as many places as possible.
//replace implementations of src/style/containers/theme.js
//note that for this theme to apply we should use @mui not @material-ui/core
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const ThemeConstants = {
    spacing: 4,
    typography: {
        fontFamily: 'Montserrat',
        h1: {
            fontSize  : "22px",
            fontFamily: 'Montserrat',
            fontWeight: 400,
            lineHeight: "33px",
            fontStyle : "normal",
            "@media screen and (max-width: 600px)": {
                fontSize  : "18px",
                lineHeight: "27px"
            }
        },
        h2: {
            fontSize  : "18px",
            fontFamily: 'Montserrat',
            fontWeight: 400,
            lineHeight: "27px",
            fontStyle : "normal",
            "@media screen and (max-width: 600px)": {
                fontSize  : "16px",
                lineHeight: "18px"
            }
        },
        body1: {
            fontSize  : "13px",
            fontFamily: 'Montserrat',
            fontWeight: 400,
            lineHeight: "18px",
            fontStyle : "normal"
        },
        body2: {
            fontSize  : "10px",
            fontFamily: 'Montserrat',
            fontWeight: 400,
            lineHeight: "15px",
            fontStyle : "normal"
        },
        body3: {
            fontSize  : "8px",
            fontFamily: 'Montserrat',
            fontWeight: 400,
            lineHeight: "12px",
            fontStyle : "normal",
            "@media screen and (max-width: 600px)": {
                fontSize  : "10px",
                lineHeight: "15px"
            }
        },
        button: {
            textTransform: 'none'
        },

        fontWeightRegular: 400,
        fontWeightBold   : 700,
    },
    palette: {
        sideNav: {
            main        : "#DBECF1",
            contrastText: "#00394D"
        },
        darkPetrol: {
            main        : "#00394D",
            contrastText: "#72B6CD"
        },
        primary: {
            dark      : "#00394D",
            main      : "#006080",
            light     : "#72B6CD",
            contrastText: "#FFFFFF"
        },
        white: {
            main      : "#FFFFFF",
            contrastText: "#00394D"
        },
        lightPetrol: {
            dark        : "#006080",
            main        : "#5EB0CC",
            light       : "#72B6CD",
            contrastText: "#FFFFFF"
        },
        lighterPetrol: {
            main        : "#C2E3ED",
            contrastText: "#006080"
        },
        background: {
            main        : "#E4F1F5",
            contrastText: "#006080"
        },
        notification: {
            main      : "#d91640",
            contrastText: "#FFFFFF"
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (themeParam) => ({
                body: {
                    // backgroundColor: themeParam.palette.darkPetrol.main + " !important"
                },
                '#root': {
                    position: "absolute",
                    zIndex: "-101"
                }
            }),
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    fontSize: "12px"
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "none"
                }
            },
        }
    },
    custom: {
        spacings: {
            lg: "30px",
            ml: "20px", //medium-large, hence ml
            md: "15px",
            sm: "10px",
            xs: "5px"
        },
        borderRadius: {
            none       : "0px",
            topLeft    : "0px 15px 15px 15px",
            topRight   : "15px 0px 15px 15px",
            bottomRight: "15px 15px 0px 15px",
            bottomLeft : "15px 15px 15px 0px",
            bottomLeftTopRight : "15px 0px 15px 0px",
            bottom     : "15px 15px 0px 0px",
        },
    }
};

export default createTheme(ThemeConstants);
