import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress, Hidden } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AutosearchPage from "../components/AutosearchPage";
import Accordian from "../components/Accordian";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RequestCard from "../components/Cards/RequestCard";
import { ButtonDarkBlue } from "../components/Buttons/ButtonDarkBlue";
import { Stack, ThemeProvider } from "@mui/material";
import Icon from "@mui/material/Icon";
import { MastersTabItems, userRoles } from "../apiServices/Constants";
import RequestsStyle from "../styles/container/RequestsStyle";
import { Waypoint } from "react-waypoint";
import { FormattedMessage, useIntl } from "react-intl";
import GoTo from "../apiServices/Navigation/NavigateWithReload";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";
import NewRequestCard from "../components/Cards/NewRequestCard";
import Text from "../visualComponents/Text";
import Theme_WIP, { ThemeConstants } from "../styles/themes/main/Theme_WIP";
import { useListRequests } from "../apiServices/Queries/Collabs/GetCollabRequests";
import { ClearCache } from "../apiServices/CommonMethods";

const useStyles = makeStyles(RequestsStyle);

export default function Requests() {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role;
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();
  const [isShowFilter, setIsShowFilter] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [wp, setWp] = React.useState(false);

  const [tabType, setTabType] = React.useState({
    type: intl.formatMessage({ id: MastersTabItems[0].type }),
    key: MastersTabItems[0].key,
  });

  const location = useLocation();

  const [requests, setRequests] = React.useState([]);
  const [requestTabItemList, setRequestTabItemList] = React.useState([]);
  const [selectedCircle, setSelectedCircle] = React.useState({
    type: null,
    key: null,
  });
  const [selectedRequestId, setSelectedRequestId] = React.useState(null);

  let [input, setInput] = React.useState({
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessType: null,
    searchInput: null,
    pageNumber: 1,
    locationPlaceID: null,
    matchesOnly: false
  });
  const getVariable = () => ({
    userId: userId,
    pageNumber: input["pageNumber"],
    location: input["location"],
    industries: input["industries"],
    skills: input["skills"],
    maxDistance: input["maxDistance"],
    collaborationType: input["collaborationType"],
    businessTypes: input["businessType"],
    searchInput: input["searchInput"],
    pageSize: parseInt(10),
    circleId: selectedCircle.key,
    requestId: selectedRequestId,
    eventId: null,
    locationPlaceID: input["locationPlaceID"],
    matchesOnly: input["matchesOnly"],
    loginProfileId: null,
    profileReqOnly: null
  });

  const {data: req, loading: loading, refetch: refetch, updateVariables} = useListRequests(getVariable());
  const reload = () => {
    globals.functions.loggedInUser.refetchRole(); 
    ClearCache("allCollabRequests");
    ClearCache("circle_feed", globals);
    setInput({...input, pageNumber: 1});
    setRequests([]);
    setTimeout(() => refetch({...getVariable(), pageNumber: 1}), 100);
  };

  useEffect(() => updateVariables(getVariable()), [input, selectedCircle, selectedRequestId]);
  useEffect(() => {
    if(!req || !req.length)
      return;
    if (input["pageNumber"] === 1)
      setRequests(req);
    else
      setRequests(prevState => [
        ...prevState,
        ...req,
      ]);
  }, [req]);

  function handleCircleData () {
    const circleTabItems = !globals.state.loggedInUser.circles ? [] : globals.state.loggedInUser.circles.map(
      circle => {
        return {
          type: circle.name,
          key: circle.id,
        };
      }
    );
    const masterTabList = MastersTabItems.map(item => {
      return {
        type: intl.formatMessage({
          id: item.type,
        }),
        key: item.key,
      };
    });
    setRequestTabItemList([...masterTabList, ...circleTabItems]);
  }
  useEffect(() => {
    handleCircleData();
  }, []);

  const handleChange = (event, newValue) => {
    // console.log("newvalue", newValue);
    setIsLoading(false)
    setSelectedRequestId(null)
    setInput((prevState) => ({
      ...prevState,
      pageNumber: 1,
    }));
    let filteredTab = requestTabItemList.filter(tabItem => {
      return tabItem.type === newValue;
    });

    setTabType(filteredTab[0]);
    if (filteredTab[0].type !== intl.formatMessage({ id: "filter_title_mymatches" }) &&
      filteredTab[0].type !== intl.formatMessage({ id: "filter_title_all" })) {
      setSelectedCircle(filteredTab[0]);
      setInput((prevState) => ({
        ...prevState,
        matchesOnly: false,
      }));
    } else {
      setSelectedCircle({
        type: null,
        key: null,
      });
      setInput((prevState) => ({
        ...prevState,
        matchesOnly: filteredTab[0].type === intl.formatMessage({ id: "filter_title_mymatches" }),
      }));
    }
    setRequests([]);
    globals.functions.loggedInUser.refetchRole();

  };

  const showFilter = () => {
    // setRequests([]);
    setSelectedRequestId(null)
    setIsShowFilter(!isShowFilter);
  };

  const navigateToNewRequestTab = () => {
    // console.log('User role ,', userRole);
    if (userRole.role == userRoles.freemium) {
      navigateToGetPremium();
    } else {
      history.push("/requestCreate");
    }

  };

  useEffect(() => {
    globals.functions.loggedInUser.refetchRole();
    // console.log("locationKeyRequest", location.key);
    if (location.state && typeof location.state.selectedRequestId !== "undefined" &&
      location.key !== localStorage.getItem("locationKeyRequest")) {
      setSelectedRequestId(location.state.selectedRequestId);
      // console.log("selectedRequestId", location.state.selectedRequestId);
    }
    localStorage.setItem("locationKeyRequest", location.key);

  }, [location]);

  const onSearch = searchData => { };
  const onFocus = () => {
    if (userRole.role == userRoles.freemium) {
      navigateToGetPremium();
    }
  };
  const onUserSelection = userName => {
    setRequests([]);
    setSelectedRequestId(null)
    setIsLoading(false);
    // const name = userName.split(" ");
    setInput(prevState => ({
      ...prevState,
      searchInput: userName,
      location: null,
      industries: null,
      skills: null,
      maxDistance: null,
      collaborationType: null,
      businessType: null,
      pageNumber: 1,
    }));
  };

  // const handleOnScroll = () => {
  //     if ((window.innerHeight + window.scrollY) === document.body.offsetHeight && !isShowFilter) {
  //         console.log("handle scrolll page", input["pageNumber"])
  //         const newPage = input["pageNumber"] + 1
  //         setInput(prevState => ({
  //             ...prevState,
  //             "pageNumber": newPage
  //         }));
  //         if (tabType.type === "my matches") {
  //             fetchMoreMyMatches({
  //                 variables: {
  //                     pageNumber: newPage,
  //                 },
  //             })
  //         } else {
  //             fetchMore({
  //                 variables: {
  //                     pageNumber: newPage,
  //                 },
  //             })
  //         }
  //     }
  // }
  const navigateToGetPremium = () => {
    GoTo(history, "/GetPremium");
  };

  const filterAction = inputs => {
    if (userRole.role == userRoles.freemium) {
      navigateToGetPremium();
    } else {
      // console.log("filterAction inputs", inputs);
      setRequests([]);
      setInput(inputs);
      if (inputs.collaborationType !== null && inputs.collaborationType.length === 11) {
        setInput(prevState => ({
          ...prevState,
          collaborationType: null,
        }));
      }
      setInput(prevState => ({
        ...prevState,
        searchInput: null,
        matchesOnly: tabType.type === intl.formatMessage({ id: "filter_title_mymatches" }),
      }));
      globals.functions.loggedInUser.refetchRole();
      setIsShowFilter(!isShowFilter);
    }
  };

  const navigateToProfile = request => {
    if (userRole.role == userRoles.freemium &&
      request.sharedCircles.length <= 0) {
      navigateToGetPremium();
    } else {
      history.push("/dashboard/profile/" + request.userId, {
        selectedUserId: request.userId,
      });
    }
  };

  const onReset = () => {
    const variable = {
      location: null,
      industries: null,
      skills: null,
      maxDistance: null,
      collaborationType: null,
      businessType: null,
      pageNumber: 1,
    };
    setRequests([]);
    // setRequestsMyMatches([]);
    setInput(variable);
  };

  return (
    <>
      <NewRequestCard mt={10} onCompleted={reload}/>
{/* SEARCH BAR    
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container>
              <Stack
                direction={"row"}
                sx={{ spacing: 2, alignItems: "center", width: "100%" }}
              >
                <Grid item xs={11}>
                  <AutosearchPage
                    onFocus={onFocus}
                    onSearch={onSearch}
                    onUserSelection={onUserSelection}
                    placeHolder={intl.formatMessage({
                      id: "introduction_Field_input_type_username",
                    })}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FilterAltOutlinedIcon
                    className={classes.iconFilter}
                    onClick={showFilter}
                  />
                </Grid>
                {(input.businessType != null && input.businessType.length) ||
                  (input.collaborationType != null &&
                    input.collaborationType.length) ||
                  (input.skills != null && input.skills.length) ||
                  (input.industries != null && input.industries.length) ||
                  input.location != null ||
                  input.maxDistance != null ? (
                  <Grid item sm={1} xs={1}>
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.iconReset}
                      onClick={onReset}
                    >
                      <FormattedMessage id={"Resetten"} />
                    </Typography>
                  </Grid>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </Grid> */}

        <ThemeProvider theme={Theme_WIP}>
          <Grid container mt={ThemeConstants.custom.spacings.lg}>
            <Text item xs={12} bold color="background" pt={3} mb={ThemeConstants.custom.spacings.xs} id="SharedRequests" sx={{":not(p)":{borderTop: "solid 1px " + Theme_WIP.palette.lightPetrol.main}}}/>
          </Grid>
        </ThemeProvider>

        {isShowFilter ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Accordian
                onSearch={filterAction}
                filterType={"collabRequest"}
              />
            </Grid>
          </Grid>
        ) : (
            <Grid container spacing={2} className={classes.mt15}>
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    width: {
                      xs: window.screen.width - 20,
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                    },
                  }}
                >
                  <Tabs
                    value={tabType.type}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable auto tabs example"
                    TabIndicatorProps={{
                      style: { background: theme.palette.color2.main },
                    }}
                  >
                    {requestTabItemList.map(tabItem => (
                      <Tab
                        key={tabItem.key}
                        value={tabItem.type}
                        label={tabItem.type}
                        wrapped
                        className={classes.masterTab}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Grid>
              <Grid container item xs={12} sm={12}>
                {(loading) &&
                  !requests.length ? (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-flex",
                      alignItems: "center",
                      flex: "row",
                      justifyContent: "center",
                      width: "100%",
                      height: 300,
                    }}
                  >
                    <Stack
                      spacing={2}
                      direction="row"
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <CircularProgress />
                      <Typography
                        component="h5"
                        variant="h5"
                        className={classes.title}
                      ></Typography>
                    </Stack>
                  </Box>
                ) : requests.length > 0 ? (
                  <Grid container gap={ThemeConstants.custom.spacings.md}>
                    {requests.map(
                      (card, index) => (
                        <React.Fragment key={index}>
                          <Grid container item xs={12} sm={12} key={index}>
                            <RequestCard
                              userRole={userRole}
                              request={card}
                              fromProfile
                              onRefresh={reload}
                              onUserSelection={navigateToProfile}
                            />
                          </Grid>
                          {index ===
                            requests.length -
                            2 && (
                              <Waypoint
                                scrollableAncestor={window}
                                onEnter={() => {
                                  if(loading)
                                    return;
                                  const newPage = input["pageNumber"] + 1;
                                  setIsLoading(true);
                                  setInput(prevState => ({
                                    ...prevState,
                                    pageNumber: newPage,
                                  }));
                                }}
                              />
                            )}
                        </React.Fragment>
                      )
                    )}
                  </Grid>
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-flex",
                      alignItems: "center",
                      flex: "row",
                      justifyContent: "center",
                      width: "100%",
                      height: 300,
                    }}
                  >
                    <Typography
                      component="span"
                      variant="h3"
                      color={"textPrimary"}
                    >
                      {selectedRequestId !== null ?
                        <FormattedMessage id={"Removed_NoData_Message"} /> :
                        <FormattedMessage id={"noDataAvailable"} />}
                    </Typography>
                  </Box>
                )}
                {isLoading && !requests.length ? (
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-flex",
                      alignItems: "center",
                      flex: "row",
                      justifyContent: "center",
                      width: "100%",
                      height: 300,
                    }}
                  >
                    <Stack
                      spacing={1}
                      direction="row"
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <CircularProgress />
                    </Stack>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
        )}
    </>
  );
}
