const RequestStyle = (theme) => ({
    submit: {
        marginBottom: 20,
        color: "#fff",
        fontSize: 13,
        padding: "6px 0 6px 0",
    },
    mt7: {
        marginTop: "7px",
    },
    mt10: {
        marginTop: "10px",
    },
    mt15: {
        marginTop: "15px",
    },
    mt20: {
        marginTop: "20px",
    },
    mt30: {
        marginTop: "30px",
    },
    closeicon: {
        float: "right",
    fontSize: 32,
    color: theme.palette.color5.main,
    },
    Select: {
        width: "100%",
        backgroundColor: "white",
    },
    whiteBg: {
        backgroundColor: "white !important",
    },
    txtarea: {
        width: "96%",
        border: 'none',
        backgroundColor: "white",
        borderRadius: 6,
        padding: "2%",
    },
    btxt: {
        marginTop: 15,
        color: "#72B6CD"
    },
    warn: {
        marginTop: 15,
        color: "#00394D"
    },
    chip: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chipItem: {
        borderRadius: 5
    },
});
export default RequestStyle;