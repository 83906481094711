import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress, Hidden } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ButtonBlue } from "../components/Buttons/ButtonBlue";
import { Stack, ThemeProvider } from "@mui/material";
import styles from "../styles/container/ActivityPageStyle";
import MatchRequestCard from "../components/Cards/ActivityCards/MatchRequestCard";
import {
  GET_ALL_ACTIVITIES,
  GET_NEW_ACTIVITIES,
  GET_PENDING_ACTIVITIES,
} from "../apiServices/Queries";
import { onlyUnique } from "../apiServices/CommonMethods";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ActivityItems, userRoles } from "../apiServices/Constants";
import ActivityMessageCard from "../components/Cards/ActivityCards/ActivityMessageCard";
import ActivityThankyouCard from "../components/Cards/ActivityCards/ActivityThankyouCard";
import ActivityRespondToRequest from "../components/Cards/ActivityCards/ActivityRespondToRequestCard";
import ActivityIntroductionCard from "../components/Cards/ActivityCards/ActivityIntroductionCard";
import ActivitySuggestionCard from "../components/Cards/ActivityCards/ActivitySuggestionCard";
import ActivityLeftCircleCard from "../components/Cards/ActivityCards/ActivityLeftCircleCard";
import ActivityCircleInvite from "../components/Cards/ActivityCards/ActivityCircleInvite";
import ActivityNewsUpdate from "../components/Cards/ActivityCards/ActivityNewsUpdate";
import ActivityCircleContactManager from "../components/Cards/ActivityCards/ActivityCircleContactManager";
import ActivityNewsRespond from "../components/Cards/ActivityCards/ActivityNewsRespond";
import ActivityCirclePostRespond from "../components/Cards/ActivityCards/ActivityCirclePostRespond";
import ActivityCirclePost from "../components/Cards/ActivityCards/ActivityCirclePost";
import ActivityMatchRequestAcceptCard from "../components/Cards/ActivityCards/ActivityMatchRequestAcceptCard";
import ActivitySuggestionResponseCard from "../components/Cards/ActivityCards/ActivitySuggestionResponseCard";
import ActivityEventJoinAsGuest from "../components/Cards/ActivityCards/ActivityEventJoinAsGuest";
import ActivityEventJoinAsGuestRespond from "../components/Cards/ActivityCards/ActivityEventJoinAsGuestRespond";

import ActivityEventInvite from "../components/Cards/ActivityCards/ActivityEventInvite";
import ActivityMakeEventManager from "../components/Cards/ActivityCards/ActivityMakeEventManager";
import ActivityEventCancel from "../components/Cards/ActivityCards/ActivityEventCancel";
import ActivityEventNewsUpdateCard from "../components/Cards/ActivityCards/ActivityEventNewsUpdateCard";
import ActivityEventNewsRespond from "../components/Cards/ActivityCards/ActivityEventNewsRespond";
import { FormattedMessage, useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";
import ActivityCollabRequest from "../components/Cards/ActivityCards/ActivityCollabRequest";
import AlertWithAction from "../components/Alert/AlertWithAction";
import ActivityPageUserSearch from "../components/ActivityPageUserSearch";
import { UPDATE_FEED_CHECK } from "../apiServices/mutations";
import GoTo from "../apiServices/Navigation/NavigateWithReload";
import YesCard from "../components/Cards/ActivityCards/YesCard";
import MatchSuggestionFoundCard from "../components/Cards/ActivityCards/MatchSuggestionFoundCard";
import { useGlobalContext } from "../apiServices/Providers/AppGlobalStateProvider";
import Text from "../visualComponents/Text";
import Theme_WIP, { ThemeConstants } from "../styles/themes/main/Theme_WIP";

const useStyles = makeStyles(styles);

export default function ActivityPage() {
  const globals  = useGlobalContext();
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const intl = useIntl();
  const location = useLocation();
  const [activityType, setActivityType] = React.useState(ActivityItems[0]);
  const [newActivities, setNewActivities] = React.useState([]);
  const [allActivities, setAllActivities] = React.useState([]);
  const [pendingActivities, setPendingActivities] = React.useState([]);
  const [activitySourceId, setActivitySourceId] = React.useState(null);
  const [activityUserID, setActivityUserID] = React.useState(null);
  const [userNameSelected, setUserNameSelected] = React.useState("");
  const [searchTextCheck, setSearchTextCheck] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [updateFeedCheck] = useMutation(UPDATE_FEED_CHECK);
  const [isShowPreferenceAlert, setIsShowPreferenceAlert] = React.useState(false);
  const todos = location.state?.activityUserID == undefined;

  const getVariables = () => {
    const variable = {
      userId: userId,
      activitySourceId: activitySourceId,
      pageNumber: pageNumber,
      pageSize: 50
    };
    // console.log("variable to pending", variable);
    return variable;
  };

  const getVariablesLatest = () => {
    const variable = {
      userId: userId,
      activitySourceId: activitySourceId,
      pageNumber: pageNumber,
      pageSize: 5
    };
    // console.log("variable latest", variable)
    return variable;
  };

  const getVariablesToAll = () => {
    const variable = {
      userId: userId,
      activityUserID: activityUserID,
      pageNumber: pageNumber,
      pageSize: 5
    };
    return variable;
  };

  const [fetchNewActivities, { loading }] = useLazyQuery(
    GET_NEW_ACTIVITIES,
    {
      variables: getVariablesLatest(),
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        // console.log("latestActivities", data);

        // if (
        //   typeof data !== "undefined" &&
        //   data.latestActivities.errorCode === 0
        // ) {
        //   setNewActivities(data.latestActivities.result);
        // }
        if (
          typeof data !== "undefined" &&
          data.latestActivities.errorCode === 0
        ) {
          if (pageNumber === 1) {
            setNewActivities(data.latestActivities.result);
          } else {
            if (data.latestActivities.result.length) {
              setNewActivities(prevState => [
                ...prevState,
                ...data.latestActivities.result,
              ]);
            }
          }
        }
      },
    }
  );

  const [
    fetchPendingActivities,
    { loading: loadingPendingActivities },
  ] = useLazyQuery(GET_PENDING_ACTIVITIES, {
    variables: getVariables(),
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (dataPendingActivities) => {
      // console.log("dataPendingActivities", dataPendingActivities);

      if (
        typeof dataPendingActivities !== "undefined" &&
        dataPendingActivities.pendingActivities.errorCode === 0
      ) {
        if (pageNumber === 1) {
          setPendingActivities(dataPendingActivities.pendingActivities.result);
        } else {
          if (dataPendingActivities.pendingActivities.result.length) {
            setPendingActivities(prevState => [
              ...prevState,
              ...dataPendingActivities.pendingActivities.result,
            ]);
          }
        }
      }
    },
  });

  const [
    fetchAllActivities,
    { loading: loadingAllActivities },
  ] = useLazyQuery(GET_ALL_ACTIVITIES, {
    variables: getVariablesToAll(),
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (dataAllActivities) => {
      // console.log("dataAllActivities", dataAllActivities);

      if (
        typeof dataAllActivities !== "undefined" &&
        dataAllActivities.toAllActivities.errorCode === 0
      ) {
        if (pageNumber === 1) {
          setAllActivities(dataAllActivities.toAllActivities.result);
        } else {
          if (dataAllActivities.toAllActivities.result.length) {
            setAllActivities(prevState => [
              ...prevState,
              ...dataAllActivities.toAllActivities.result,
            ]);
          }
        }
      }
    },
  });

  useEffect(() => {
    if(location.state?.fullname)
      setUserNameSelected(location.state.fullname);
    if (location.state?.activityUserID) {
      setAllActivities([]);
      setActivityUserID(location.state.activityUserID);
      setPageNumber(1);
      fetchAllActivities({variables: {
        userId: userId,
        activityUserID: location.state.activityUserID,
        pageNumber: 1,
        pageSize: 5
      }});
    }
  }, [location.state?.activityUserID]);

  useEffect(() => {
    updateFeedCheck();
    if (
      !location.state &&
      localStorage.getItem("locationKey") !== location.key &&
      !userNameSelected.length
    ) {
      setActivitySourceId(null);
      setActivityUserID(null);
    }
    if (location.state && location.state.activitySourceId) {
      // console.log(
      //   "location.state.activitySourceId",
      //   location.state.activitySourceId
      // );
      setActivitySourceId(location.state.activitySourceId);
    }
    if (location.state && location.state.activityUserID) {
      // console.log(
      //   "location.state.activityUserID",
      //   location.state.activityUserID
      // );
      setActivityUserID(location.state.activityUserID);
      fetchAllActivities();
    }

    if (activityType.type === "Todos") {
      // console.log("New 1");
      fetchNewActivities();
    } else {
      fetchPendingActivities();
    }
    localStorage.setItem("locationKey", location.key);
  }, [location]);

  const handleChange = (event, newValue) => {
    setPageNumber(1)
    
    let filteredActivity = ActivityItems.filter((activity) => {
      return activity.type === newValue;
    });
    setActivityType(filteredActivity[0]);
    if (filteredActivity[0].type === "Todos") {
      // console.log("New 3");
      fetchNewActivities();
    } else if (filteredActivity[0].type === "Pending") {
      // console.log("fetchPendingActivities");
      fetchPendingActivities();
    } else if (filteredActivity[0].type === "All") {
      fetchAllActivities();
    }
    
  };
  function navigateToActivity(href) {
    history.push(href, {
      request: {
        userId: activityUserID,
        givenName: userNameSelected.split(" ")[0],
        familyName: userNameSelected.split(" ")[1],
      },
      activity: {
        activity: {
          recipientUserProfileIds: activityUserID,
          requesterUserProfileId: activityUserID
        },
        givenName: userNameSelected.split(" ")[0],
        familyName: userNameSelected.split(" ")[1],
      },
    });
  };
  
  const onSearch = (masters) => {
    // console.log("onSearch", masters[0]);
    if (masters.length) {
      setSearchTextCheck(false);
      setActivityUserID(masters[0].userId);
      // console.log("All 3");
      fetchAllActivities();
    }
  };
  const onUserSelection = (userName) => {
    // console.log("onUserSelection", userName);
    setUserNameSelected(userName);
    setPageNumber(1)
    if (!userName.length) {
      setActivityUserID(null);
      fetchNewActivities();
    }
  };
  const onRefresh = () => {
    // refetch();
    // refetchPending();
    setPageNumber(1)
    if (activityUserID) {
      fetchAllActivities();
    } else if (activityType.type === "Todos") {
      // console.log("New 4");
      fetchNewActivities();
    } else {
      fetchPendingActivities();
    }
  };
  const onRefreshAllActivity = (activity) => { //requires activity to be passed
    // console.log("selectedUserId", selectedUserId);
    history.push("/dashboard/activity", {activityUserID: activity.userId, fullname: activity.givenName + " " + activity.familyName});

    setActivityUserID(activity.userId);
    // console.log("All 1");
    setPageNumber(1)
    fetchAllActivities();
  };

  const loadCard = (activity) => {
    if(
      (activity.activity.activityType == "UPDATE" && activity.activity.requesterUserProfileId == userId)
      ||
      (!todos && activity.userId != userId && activity.userId != location.state?.activityUserID))
      return null;
    var card = () => {
      if (activity.activity.activityType === "MATCH_REQUEST") {
        return (
          <MatchRequestCard
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (
        activity.activity.activityType === "RESPONSE_TO_REQUEST" &&
        activity.activity.activitySource !== "CIRCLE_NEWS"
      ) {
        return (
          <ActivityRespondToRequest
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "MESSAGE") {
        return (
          <ActivityMessageCard
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "THANK_YOU") {
        return (
          <ActivityThankyouCard
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "INTRODUCTION") {
        return (
          <ActivityIntroductionCard
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "SUGGESTION") {
        return (
          <ActivitySuggestionCard
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (
        activity.activity.activityType === "CIRCLE_UPDATE" ||
        activity.activity.activityType === "PROFILE_INVITATION" ||
        activity.activity.activityType === "CIRCLE_CLOSED"
      ) {
        return (
          <ActivityLeftCircleCard
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "CIRCLE_INVITATION") {
        return (
          <ActivityCircleInvite
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "CIRCLE_NEWS_UPDATE") {
        return (
          <ActivityNewsUpdate
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "CIRCLE_CONTACT_FORM") {
        return (
          <ActivityCircleContactManager
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "CIRCLE_NEWS_RESPOND") {
        return (
          <ActivityNewsRespond
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "CIRCLE_POST") {
        return (
          <ActivityCirclePost
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "CIRCLE_POST_RESPOND") {
        return (
          <ActivityCirclePostRespond
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "MATCH_REQUEST_ACCEPT") {
        return (
          <ActivityMatchRequestAcceptCard
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "SUGGESTION_RESPOND") {
        return (
          <ActivitySuggestionResponseCard
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "JOIN_AS_GUEST") {
        return (
          <ActivityEventJoinAsGuest
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "JOIN_AS_GUEST_RESPOND") {
        return (
          <ActivityEventJoinAsGuestRespond
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "EVENT_INVITATION") {
        return (
          <ActivityEventInvite
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "EVENT_UPDATE") {
        return (
          <ActivityMakeEventManager
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "EVENT_CLOSED") {
        return (
          <ActivityEventCancel
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "EVENT_NEWS_UPDATE") {
        return (
          <ActivityEventNewsUpdateCard
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "EVENT_NEWS_RESPOND") {
        return (
          <ActivityEventNewsRespond
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "UPDATE") {
        return (
          <ActivityCollabRequest
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "YES") {
        return (
          <YesCard
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      } else if (activity.activity.activityType === "MATCH_SUGGESTION_FOUND" && todos) {
        return (
          <MatchSuggestionFoundCard
            activity={activity}
            onRefresh={onRefresh}
            onRefreshAllActivity={onRefreshAllActivity}
            onShowPreferenceWarning={showPreferenceWarning}
          />
        );
      }
    }
    return <Grid item xs={12} sm={12} sx={{pointerEvents: "none !important"}}>
      {card()}
    </Grid>
  };

  const getCurrentActivityList = () => {
    if (activityUserID) {
      return allActivities.filter(onlyUnique);
    } else if (activityType.type === "Todos") {
      return newActivities.filter(onlyUnique);
    } else if (activityType.type === "Pending") {
      return pendingActivities.filter(onlyUnique);
    } else if (activityType.type === "All") {
      return allActivities.filter(onlyUnique);
    }
  };

  const onReset = () => {
    // console.log("pagesetSearchTextCheck", searchTextCheck);
    setPageNumber(1)
    setActivitySourceId(null);
    setActivityUserID(null);
    setSearchTextCheck(true);
  };

  const handleSucessAlert = async () => {
    setIsShowPreferenceAlert(false)
  };

  const showPreferenceWarning = () => {
    setIsShowPreferenceAlert(true)
  }

  const interactionButtons = () => {
    return activityUserID && 
    <Grid container item xs={12} sm={7} spacing={2}>
      <Grid item sm={4} xs={12}>
        <ButtonBlue onClick={() => navigateToActivity("/sendMessage")}>
          <FormattedMessage id={"ChooseActivity_SendAMessage"} />
        </ButtonBlue>
      </Grid>

      <Grid item sm={4} xs={12}>
        <ButtonBlue onClick={() => navigateToActivity("/suggestOneToOne")}>
          <FormattedMessage id={"ChooseActivity_Meeting"} />
        </ButtonBlue>
      </Grid>

      <Grid item sm={4} xs={12}>
        <ButtonBlue onClick={() => navigateToActivity("/introduction")}>
          <FormattedMessage id={"ChooseActivity_MakeAnIntroduction"} />
        </ButtonBlue>
      </Grid>

    </Grid>;
  };

  return (
      <>
        {location.state?.fullname && 
        <ThemeProvider theme={Theme_WIP}>
          <Text my={4} id="ConversationWith" variant="h1" bold values={{name: location.state.fullname.split(" ")[0]}}/>
        </ThemeProvider>
        }
          <Grid container spacing={4}>
            {isShowPreferenceAlert ? (
              <AlertWithAction
                message={
                  intl.formatMessage({ id: "preference_permission_error", })
                }
                title={
                  intl.formatMessage({ id: "warning" })}
                handleClose={handleSucessAlert}
                open={true}
              />
            ) : null}
            {/* <Grid item sm={6} xs={8}>
              <Grid container>
                <Stack
                  direction={"row"}
                  sx={{ spacing: 2, alignItems: "center", width: "100%" }}
                >
                  <Grid item sm={12} xs={12}>
                    <ActivityPageUserSearch
                      showLoggedInUser={false}
                      onSearch={onSearch}
                      onUserSelection={onUserSelection}
                      serchTextCheck={searchTextCheck}
                      placeHolder={intl.formatMessage({
                        id: "CircleMember_SearchBar_Text",
                      })}
                    />
                  </Grid>
                </Stack>
              </Grid>
            </Grid> */}
            {interactionButtons()}
          </Grid>

          {!activityUserID &&
          <Grid container spacing={4} mt={2}>
            <Grid item xs={12} sm={12}>
              <Tabs
                value={activityType.type}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: { background: theme.palette.color2.main },
                }}
              >
                {
                  ActivityItems.map((activity) => (
                    <Tab
                      key={activity.key}
                      value={activity.type}
                      label={intl.formatMessage({
                        id: activity.type,
                      })}
                      wrapped
                      className={classes.masterTab}
                    />
                  ))
                }
              </Tabs>
            </Grid>
          </Grid>}

          {(loading || loadingPendingActivities || loadingAllActivities) && pageNumber === 1 ? (
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                flex: "row",
                justifyContent: "center",
                width: "100%",
                height: 300,
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <CircularProgress />
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.title}
                ></Typography>
              </Stack>
            </Box>
          ) : (
            <Grid container gap={ThemeConstants.custom.spacings.md} className={classes.mt5} sx={{pointerEvents: "none !important"}}>
              {getCurrentActivityList().length ? (
                getCurrentActivityList().map((activity, index) => (
                  <React.Fragment key={index}>
                    {loadCard(activity)}
                    {index ===
                      getCurrentActivityList().length -
                      2 && (
                        <Waypoint
                          scrollableAncestor={window}
                          onEnter={() => {
                            // console.log("index", index);
                            const newPage = pageNumber + 1;
                            // console.log("newPage", newPage);
                            setPageNumber(newPage)
                          }}
                        />
                      )}
                  </React.Fragment>
                ))
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-flex",
                    alignItems: "center",
                    flex: "row",
                    justifyContent: "center",
                    width: "100%",
                    height: 300,
                  }}
                >
                  <Typography
                    component="span"
                    variant="h3"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
      </>
  );
}
