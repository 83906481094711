import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { MARK_AS_DONE_MATCH_REQUEST } from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { ActivityStatus } from "../../../apiServices/Constants";
import Message from "../../../visualComponents/specificImplementations/Messages/Message";
import { Button, ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";
import MatchProfileCard from "../../Matchtime/MatchProfileCard";
import { getValueFromJsonKeyValue } from "../../../apiServices/CommonMethods";


export default function YesCard(props) {
  const history = useHistory();
  const [activity, setActivity] = React.useState({});
  const [MarkAsDone, { data: dataMarkAsDone }] =
    useMutation(MARK_AS_DONE_MATCH_REQUEST);

  useEffect(() => {
    if (typeof props.activity !== "undefined") {
      setActivity(props.activity);
    }
    if (typeof dataMarkAsDone !== "undefined") {
      props.onRefresh();
    }
  }, [props, dataMarkAsDone]);

  const markAsDoneMatchRequestAction = async () => {
    if (activity.activity.status !== ActivityStatus.Closed) {
      const variables = {
        activityType: activity.activity.activityTypeDesc,
        activityId: activity.activity.id,
      };
      MarkAsDone({
        variables: variables,
      });
    }
  };

  return (
      <ThemeProvider theme={Theme_WIP}>
        <Message 
          matchtime
          checked={activity?.activity?.status == ActivityStatus.Closed} 
          onCheck={
            () => activity?.activity?.status != null && activity?.activity?.status != ActivityStatus.Closed ? 
                markAsDoneMatchRequestAction() : null
          }
          date={activity?.activity?.createdDate}
          name="iAm"
        //   onReply={() => alert("reply action")}
          iam
          // menuItems={
          //   <React.Fragment>
          //       <MenuItem onClick={() => history.push("/suggestOneToOne", {matchtime: true, selectedUser: {
          //           userId: activity?.userId,
          //           givenName: activity?.givenName,
          //           familyName: activity?.familyName
          //       } })}>
          //           <Text id={"Activity.Yes.Meeting"}/>
          //       </MenuItem>
          //       <MenuItem onClick={() => history.push('/dashboard/profile/', { selectedUserId: activity?.userId })}>
          //           <Text id={"Activity.Yes.Profile"} values={{first_name: activity?.givenName}}/>
          //       </MenuItem>
          //   </React.Fragment>
          // }
        >
          <Grid container item gap={3}>
            <Text variant="body1" color="primary" id="Activity.Yes.Description" values={{first_name: activity?.givenName}}/>
            <MatchProfileCard
              imgSrc                  = {activity?.profilePictureUrl ?? "/logo512.png"}
              userId                  = {activity?.userId}
              introduction            = {getValueFromJsonKeyValue(activity?.activity?.metaData, "introduction")  ?? ""}
              request                 = {getValueFromJsonKeyValue(activity?.activity?.metaData, "request")       ?? ""}
              aiGeneratedIntroduction = {getValueFromJsonKeyValue(activity?.activity?.metaData, "introductionGenerated")}
              aiGeneratedRequest      = {getValueFromJsonKeyValue(activity?.activity?.metaData, "requestGenerated")}
            />
            <Button variant="contained" color="darkPetrol" onClick={() => history.push("/suggestOneToOne", {matchtime: true, selectedUser: {
                userId: activity?.userId,
                givenName: activity?.givenName,
                familyName: activity?.familyName
            } })}>
                <Text id="Activity.Yes.Meeting" color="primary" bold p={1}/>
            </Button>
            <Button variant="contained" color="darkPetrol" onClick={() => history.push('/dashboard/profile/', { selectedUserId: activity?.userId })}>
                <Text id="Activity.Yes.Profile" color="primary" bold p={1} values={{first_name: activity?.givenName}}/>
            </Button>
          </Grid>
        </Message>
      </ThemeProvider>
    );
}
