import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Hidden, TextareaAutosize } from "@material-ui/core";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Button, CssBaseline } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip, FormHelperText } from "@material-ui/core";
import { Alert, Skeleton, ThemeProvider } from "@mui/material";
import { isEmail } from "../../components/General";
import { SAVE_EVENTS_INVITE } from "../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { GetUserId } from "../../apiServices/CommonMethods";
import Styles from "../../styles/container/Event/InvitePeopleToEventStyle";
import { FormattedMessage, useIntl } from "react-intl";
import SuccessAlertWithAction from "../../components/Alert/SuccessAlertWithAction";
import { Save_Circle_Invite } from "../../apiServices/mutations";
import { useCachedQuery } from "../../apiServices/Queries/CachedQuery";
import { useUUID } from "../../apiServices/Queries/Circles/GetUUID";
import { useListMasters } from "../../apiServices/Queries/Masters/ListMasters";
import Text from "../../visualComponents/Text";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";

const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(Styles);

export default function InvitePeople(props) {
  const intl = useIntl();
  const isEvent = props.source == "event";
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [message, setMessage] = React.useState("");
  const [copiedLink, setCopiedLink] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [email, setEmail] = React.useState([]);
  const [email_, setEmail_] = React.useState([]); //used to keep track of selected autosuggested users. The autoSuggestList changes based on input so it's not always persistent
  const [csvarr, setCsvArr] = React.useState([]);
  const sourceData = isEvent && location.state?.eventDetails ? location.state.eventDetails : location.state?.circleDetails;
  const [inputError, setInputError] = React.useState(false);
  const [csvError, setCsvError] = React.useState(false);
  const [isFormValid, setisFormValid] = React.useState(false);
  const [isCsvValid, setisCsvValid] = React.useState(false);
  const [isCsvValidtypeError, setIsCsvValidtypeError] = React.useState(false);
  const userId = GetUserId();

  const variables = {
    userId: userId,
    pageNumber: 1,
    pageSize: 10,
    isMyMatches: false,
    searchInput: input,
    showLoggedInUser: false,
  };
  const {data: masters, loading: loadingMasters, updateVariables: updateVariables} = useListMasters(variables);
  const autoSuggestList = (masters? [...masters] : [])
  ?.sort ((a,b)  => a && b ? a.givenName.localeCompare(b.givenName) : -1)
  .map   (master => master.userId+","+master.givenName+" "+master.familyName)
  .filter(s      => !email.includes(s)) 
  ?? [];

  const onChange = (source, newvalue) => {
    setEmail(newvalue);
    if(newvalue.length && autoSuggestList.includes(newvalue[newvalue.length - 1]))
      setEmail_([...email_, newvalue[newvalue.length - 1]]);
  };

  const {data: uuid, loadingUUID, updateVariables: reloadUUID} = useUUID(isEvent, sourceData?.id);
  useEffect(() => reloadUUID(isEvent? {
      eventId: sourceData?.id,
  } : {
      circleId: sourceData?.id,
  }), [sourceData]);
  
  const [saveInvite, { data, loading }] = useMutation(isEvent? SAVE_EVENTS_INVITE : Save_Circle_Invite);
  const updateTextFileds = (event) => {
    updateVariables({
      ...variables,
      searchInput: event.target.value
    });
    setInput(event.target.value);
  };

  const handleSave = () => {
    const isFormValid = email.length === 0 && csvarr.length === 0;
    setisFormValid(isFormValid);
    if (!isFormValid) {
      const emailFilter = email.map((option) =>
        !isEmail(option) && !email_.includes(option) ? "notvalidemail" : ""
      );
      let result = emailFilter.includes("notvalidemail");
      const isValidemail = result ? true : false;
      setInputError(isValidemail);
      const csvFilter = csvarr.map((option) =>
        !isEmail(option) ? "notvalidcsvemail" : ""
      );
      let csvresult = csvFilter.includes("notvalidcsvemail");
      const isValidcsv = csvresult ? true : false;
      setCsvError(csvresult);
      if (isValidemail === false && isValidcsv === false) {
        const invitedarr = email.length > 0 ? email.map(e => email_.includes(e) ? e.split(",")[0] : e ) : csvarr;
        const variables = isEvent? {
          eventId: sourceData.id,
          requestedUserId: userId,
          invitedMembers: invitedarr,
          message: message
        } : {
            circleId: sourceData.id,
            requestedUserId: userId,
            invitedMembers: invitedarr,
            message: message
        };
        
        saveInvite({
          variables: variables,
        });
      }
    }
  };
  const openFile = (_source) => {
    setInputError(false);
    setCsvError(false);
    setisFormValid(false);
    setIsCsvValidtypeError(false);
    var filename = _source.target.files[0].name;
    var filetype = _source.target.files[0].type; //"text/csv"
    if (
      filetype === "text/csv" ||
      filetype === "application/vnd.ms-excel" ||
      filetype === "application/csv"
    ) {
      var reader = new FileReader();
      reader.onload = function () {
        var filedata = reader.result;
        // console.log();
        if (filedata && filedata.trim().length > 0) {
          setisCsvValid(false);
          // console.log("filedata", filedata);
          var data = filedata.replace(/\n|\r/g, ","); //remove /n/r
          data = data.replace(/,+/g, ","); //remove doublecomma
          data = data.replace(/,\s*$/, ""); //lastcomma remove
          // console.log("result", data);
          var contentarr = data.split(",");
          setCsvArr(contentarr);
          // console.log("result", contentarr);
          document.getElementById("uploadedfile").innerHTML = filename;
        } else {
          setisCsvValid(true);
        }

        _source.target.value = "";
      };
      // start reading the file. When it is done, calls the onload event defined above.
      reader.readAsBinaryString(_source.target.files[0]);
    } else {
      setIsCsvValidtypeError(true);
    }
  };

  const handleSucessAlert = () => {
    if(isEvent){
        history.push("/dashboard/eventDetails/?id=" + sourceData.id, {
            eventDetails: sourceData,
            selectedTab: 4,
        });
    } else {
        history.push("/dashboard/circleDetails/?id=" + sourceData.id, {
            circleDetails: sourceData,
            selectedTab: 6,
        });
    }
  };

  return (
    <ThemeProvider theme={Theme_WIP}>
      <CssBaseline/>
      <Navigationbar />
      <Grid container justifyContent="center">
      <Grid container maxWidth="sm" mt={16} item pb={10}>
        <Grid container spacing={4}>
          <Grid item sm={12} xs={12}>
            {typeof data !== "undefined" &&
              (isEvent? data.saveEventInvite.errorCode : data.saveCircleInvite.errorCode) === 0 ? (
              <SuccessAlertWithAction
                open={true}
                message={<FormattedMessage id={"event_invite_success"} />}
                handleClose={handleSucessAlert}

              />
            ) : null}
            {typeof data !== "undefined" &&
              (isEvent? data.saveEventInvite.errorCode : data.saveCircleInvite.errorCode) === -1 ? (
              <Alert severity={"error"}>
                {(isEvent? data.saveEventInvite : data.saveCircleInvite).message}
              </Alert>
            ) : null}
          </Grid>

          <Text bold item xs={11} variant="h1" color="background" id={isEvent ? "Send_Sign_Up_Link_Event" : (sourceData.isSubCircle ? "Send_Sign_Up_Link_Group" : "Send_Sign_Up_Link_Circle")} />
          <Grid item sm={1} xs={1}>
            <CloseIcon
              style={{float: 'right', color: Theme_WIP.palette.primary.main}}
              onClick={() => {
                history.goBack();
              }}
            />
          </Grid>
          <Text item xs={12} variant="h2" color="background" id="Send_Sign_Up_Link_Subtitle"/>

          {loadingUUID && <Skeleton width={60} height={40}/>}
          {uuid && !loadingUUID && <>
          <Text item xs={12} variant="h2"    color="background" id="Send_Sign_Up_Link2" bold/>
          <Text item xs={12} variant="body1" color="background" id="Send_Sign_Up_Link_Description"/>
          <Text item xs={12} variant="body1" color="background" id="Send_Sign_Up_Link_Description2" bold/>

          <Grid item sm={3} xs={12}>
            <Button 
            variant="outlined" 
            fullWidth
            onClick={() => {
                navigator.clipboard.writeText(window.location.protocol + '//' + window.location.hostname + 
                "/dashboard/"+ (isEvent?"event":"circle") +"Details/?uuid=" + uuid);
                setCopiedLink(true);
              }}>
              <FormattedMessage id={"Copy_Link"} />
            </Button>
            {copiedLink && <Text variant="body2" color="gray" id="Copied_Link"/>}
          </Grid>
          </>}

        </Grid>
        <Grid container spacing={2} mt={4}>
          <Text item xs={12} variant="h1" color="background" id={"events_invite_title"} bold/>

          {isEvent?
          <Text item xs={12} variant="body1" color="background" id="invite_description" />
          :
          <>
          <Text item xs={12} variant="body1" color="background"  id="CircleInvite_description1" />
          <Text item xs={12} variant="body1" color="background"  id="CircleInvite_description2" />
          </>
          }

          <Grid item xs={12} sm={12}>

            <Text item xs={12} variant="h2" bold color="background" id="CircleInvite_AddEmailAddresses" />
            <Text item xs={12} variant="body1"   color="background" id="CircleInvite_AddEmailAddresses_Description" mb={2}/>

            {loading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <FormControl variant="filled" className={classes.select}>
                <Autocomplete
                  multiple
                  freeSolo
                  size={"small"}
                  id="emailIds"
                  autoSelect={false}
                  onChange={onChange}
                  filterOptions={(x) => x}
                  disableClearable
                  options={(input && autoSuggestList.length) ? autoSuggestList : [""]}
                  renderOption={(props, option, state) => 
                    option == "" ?
                    input && !loadingMasters && <Text p={1}><FormattedMessage id="notFoundGiveMail"/></Text>
                    :
                    <li {...props}>{option.split(",")[1]}</li>}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={email_.includes(option) ? option.split(",")[1] : option}
                        {...getTagProps({ index })}
                        size="small"
                        className={classes.chip}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      placeholder={intl.formatMessage({id: "CircleInvite_Placeholder"})}
                      {...params}
                      onChange={updateTextFileds}
                      value={input}
                      InputProps={{
                        ...params.InputProps,
                        type: "input",

                      }}
                    />
                  )}
                />

                {isFormValid ? (
                  <FormHelperText>
                    {" "}
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"invite_email_error"} />
                    </Typography>{" "}
                  </FormHelperText>
                ) : null}
                {inputError ? (
                  <FormHelperText>
                    {" "}
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"invite_email_valid_error"} />
                    </Typography>{" "}
                  </FormHelperText>
                ) : null}
              </FormControl>
            )}

            <Grid item xs={12} sm={12} my={6}>
              <Text item xs={12} variant="h2" bold color="background" id="CircleInvite_importcsv"/>
              {loading ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <label htmlFor="contained-button-file">
                  <Input
                    accept=".csv"
                    id="contained-button-file"
                    type="file"
                    onChange={openFile}
                  />
                  <Button
                    variant="outlined"
                    component="span"
                    style={{ width: "20%" }}
                  >
                    <FormattedMessage id={"Upload"} />
                  </Button>{" "}
                  <span id="uploadedfile"></span>
                </label>
              )}
              {isCsvValidtypeError ? (
                <FormHelperText>
                  {" "}
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={"Invite_Valdation_Filevalidation"} />
                  </Typography>{" "}
                </FormHelperText>
              ) : null}
              {csvError ? (
                <FormHelperText>
                  {" "}
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={"Invite_Valdation_Emailvalidation"} />
                  </Typography>{" "}
                </FormHelperText>
              ) : null}
              {isCsvValid ? (
                <FormHelperText>
                  {" "}
                  <Typography variant="h5" component="span" color="error">
                    <FormattedMessage id={"Invite_Valdation_Atleast1Email"} />
                  </Typography>{" "}
                </FormHelperText>
              ) : null}
            </Grid>

            <Text item xs={12} variant="h2"    bold color="background" id="OnboardingInvite.message.label"       mt={6} />
            <Text item xs={12} variant="body1"      color="background" id="OnboardingInvite.message.description" mb={2} />

            <FormControl style={{paddingBottom: 10}} className={classes.select}>
              <TextareaAutosize
                maxLength   = {300}
                minRows     = {4}
                style       = {{padding: 10, background: "none"}}
                placeholder = {intl.formatMessage({id: "Type"})}
                onChange    = {(value) => setMessage(value.target.value)}
                value       = {message}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            {loading ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <Button variant="contained" onClick={handleSave}>
                <FormattedMessage id={"Button_Text_SendInvite"} />
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      </Grid>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </ThemeProvider>
  );
}
