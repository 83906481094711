import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Button, CssBaseline, Hidden, TextField } from "@mui/material";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Box } from "@mui/system";
import { useHistory, useLocation } from "react-router";
import { useMutation } from "@apollo/client";
import { SAVE_NEW_EVENT } from "../../apiServices/mutations";
import { ClearCache, GetUserId } from "../../apiServices/CommonMethods";
import { Alert, InputLabel, MenuItem, Select, Skeleton, ThemeProvider } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import Styles from "../../styles/container/Event/EventNewStyle";
import SuccessAlertWithAction from "../../components/Alert/SuccessAlertWithAction";
import { useSelector } from "react-redux";
import { selectedBrandColor } from "../../apiServices/Redux/Reducer";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";
import Background from "../../visualComponents/Background";
import InputSection from "../../components/Forms/InputSection";
import FormContainer from "../../components/Forms/FormContainer";

const useStyles = makeStyles(Styles);

export default function EventNew() {
  const userId = GetUserId();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [parentCircleDataID, setParentCircleID] = React.useState(null);
  const [parentCircleData, setParentCircleData] = React.useState({});
  const brandColor = useSelector(selectedBrandColor);

  const [saveNewEvent, { data, loading, error }] = useMutation(SAVE_NEW_EVENT);
  let [input, setInput] = React.useState({
    name: "",
    role: null
  });
  let [inputError, setInputError] = React.useState({
    name: "",
  });

  React.useEffect(() => {
    // console.log("data", location.state);

    if (location.state && location.state.parentCircleData) {
      setParentCircleID(location.state.parentCircleData.id);
      setParentCircleData(location.state.parentCircleData);
    }
  }, [location]);

  const updateTextField = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const saveNewEventAction = async () => {
    let isValid = await checkForErrors();
    const variable = {
      title: input["name"].trim(),
      loginProfileId: userId,
      isOrganizer: input.role,
      circleId: parentCircleDataID,
    };
    if (isValid) {
      saveNewEvent({
        variables: variable,
        onCompleted: () => {
          ClearCache("allEvents");
        }
        
      });
    }
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      name: !input["name"].trim() ? "nameError" : "",
    }));
    return input["name"].trim();
  };

  const handleSucessAlert = () => {
    history.push("/dashboard/eventDetails/?id=" + data.saveEvents.result.id, {
      eventDetails: { ...data.saveEvents.result, "loginUserStatus": "Manager" },
      selectedTab: 5,
    });
  };

  return (
    <ThemeProvider theme={Theme_WIP}>
      <CssBaseline />
      <Navigationbar />
        <FormContainer
          modalMessage = {data?.saveEvents?.errorCode ===  0 ? <FormattedMessage id={"EventCreatedSuccessfully"} /> : undefined}
          onModalClose = {handleSucessAlert}
          error        = {data?.saveEvents?.errorCode === -1 ? data.saveEvents.message : (error ? <FormattedMessage id={"somethingWentWrong"} /> : undefined)}
          title        = {<FormattedMessage id="AddAnEvent"/>}
          loading      = {loading}
          onSave       = {saveNewEventAction}
          disabled     = {!input.name || (input.role == undefined)}
          onCancel     = {() => { history.goBack(); }}
        >
            <InputSection
              type     = "select"
              disabled = {loading}
              id       = "role"
              title    = {<FormattedMessage id="RoleEvent"/>}
              onChange = {(newVal) => setInput({...input, role: newVal.target.value})}
              options  = {[
                {value: false, key: <FormattedMessage id={"Event_attending"}/>}, 
                {value: true , key: <FormattedMessage id={"Event_organisor"}/>
              }]}
            />

            {input.role == false && <Text mt={-2} values={{"br": <span><br/><br/></span>}} variant="body1" color="background" id={"Add_Event_Desc1"} />}

            <InputSection
              type     = "text"
              disabled = {loading}
              id       = "name"
              title    = {<FormattedMessage id="NameEvent"/>}
              hint     = {inputError["name"] ? <FormattedMessage id={"Add_Event_Validation"} /> : undefined}
              value    = {input["name"]}
              max      = {50}
              onChange = {updateTextField}
            />
        </FormContainer>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </ThemeProvider>
  );
}
