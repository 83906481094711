
const CircleInviteStyle = (theme) => ({
    root: {
        width: "100%",
    },
    p7: {
        marginTop: 7,
    },
    mt10: {
        marginTop: 10,
    },
    m20: {
        marginTop: 20,
    },
    txtarea: {
        width: "100%",
        border: 'none',
        backgroundColor: "#e8e8e8",
        borderRadius: 6,
        padding: "2%",
    },
    ml4: {
        marginLeft: 4,
    },
    button: {
        width: "100%",
    },
    Select: {
        width: "100%",
    },
});
export default CircleInviteStyle;
