import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GetUserId } from "../../apiServices/CommonMethods";
import { useListAllCircles } from "../../apiServices/Queries/Circles/GetUserCircles";

export default function CircleSearch(props) {
  const [searchText, setSearchText] = React.useState("");
  const [value, setValue] = React.useState("");

  const userId = GetUserId();
  const vars = {
      isCompany: props.companies,
      // selectedPlace: null,
      // maxDistance: null,
      // selectedCircleTags: null,
      // selectedOperatesIn: null,
      searchInput: searchText,
      pageNumber: 1,
      pageSize: 100,
      isPublished: props.circleTabType === "All" ? 1 : null,
      memberId: props.circleTabType === "All" ? null : userId,
      loggedInUser: userId,
    };
  const {data: data, loading: loading, updateVariables: fetchMore} = useListAllCircles(vars, 60, true);

  const updateTextFileds = (event) => {
    // console.log("updateTextFileds", event.target.value);
    setSearchText(event.target.value);
    fetchMore({
      ...vars,
      searchInput: event.target.value,
    });
    if (typeof props.onSearchTextChanges === "function") {
      props.onSearchTextChanges(event.target.value);
      setValue("");
    }
  };
  const onChange = (event, newvalue) => {
    setValue(newvalue);
    const arryayCircles = data.filter((item) => {
      return item.name === newvalue;
    });
    props.onSearch(arryayCircles[0]);
  };

  return (
    <Autocomplete
      freeSolo
      size={"small"}
      value={value}
      id="free-solo-2-demo"
      onChange={onChange}
      filterOptions={(x) => x}
      disableClearable
      options={ data && data.length &&
          searchText.length
          ? data.map((option, index) => option.name)
          : []
      }
      renderInput={(params) => (
        <TextField
          label={props.placeHolder}
          {...params}
          onChange={updateTextFileds}
          value={searchText}
          InputLabelProps={{
            style: {
              fontSize: 14,
              fontFamily: 'Montserrat', //h2
              fontWeight: 800,
            }
          }}
          InputProps={{
            ...params.InputProps,
            type: "search",
            style: {
              fontSize: 14,
              fontFamily: 'Montserrat', //h2
              fontWeight: 800,
            },
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : !searchText.length ? (
                  <SearchIcon />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
CircleSearch.prototype = {
  onSearch: PropTypes.func,
};
