import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Styles from "../../../../styles/container/ActivityPageStyle";
import { CardMedia } from "@material-ui/core";
import { getDateString } from "../../../../apiServices/CommonMethods";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(Styles);

export default function UserInfo(props) {
  const classes = useStyles();
  const sent = props.sent?? false;
  const intl = useIntl();
  const activity = props.activity;
  const onClick = props.onClick;
  const activityType = props.activityType;

  return (
        <Grid item container xs={12} spacing={2} direction={sent ?"row":"row-reverse"}>
          <Grid item xs={2} sm={1}>
            <CardMedia
              component="img"
              image={activity.profilePictureUrl ?? "/assets/img/userProfile.png"}
              alt="profile"
              className={classes.logosmall}
              onClick={onClick}
            />
          </Grid>
          <Grid item xs={10} sm={10}>
            <Typography
              align={sent ?"left":"right"}
              component="h3"
              variant="h3"
              color={"textSecondary"}
              className={classes.p10}
            >
              <Typography
                variant="h3"
                className={classes.violet}
                display="inline"
              >
                <FormattedMessage id={activityType} />
              </Typography>{" "}
              {(sent
                ? intl.formatMessage({ id: "from" }) + " "
                : intl.formatMessage({ id: "to" })) +
                " " +
                activity.givenName +
                " " +
                activity.familyName}{" "}
              <span className={classes.dateTime}>
                {getDateString(
                  activity.activity ? activity.activity.updatedDate : ""
                )}
              </span>
            </Typography>
          </Grid>
        </Grid>
  );
}
