import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Styles from "../../../styles/container/ActivityPageStyle";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import {
  ACCEPT_EVENT_INVITE,
  DECLINE_EVENT_INVITE,
  MARK_AS_DONE_MATCH_REQUEST,
} from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { ActivityStatus } from "../../../apiServices/Constants";
import { FormattedMessage } from "react-intl";
import UserInfo from "./components/UserInfo";
import { ButtonDarkBlue } from "../../Buttons/ButtonDarkBlue";
import { useGlobalContext } from "../../../apiServices/Providers/AppGlobalStateProvider";
import { ClearCache } from "../../../apiServices/CommonMethods";

const useStyles = makeStyles(Styles);

export default function ActivityEventInvite(props) {
  const globals = useGlobalContext();
  const userId  = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const history = useHistory();
  const [activity, setActivity] = React.useState({});
  const [MarkAsDone, { data: dataMarkAsDone, loading: loadingMarkAsDone }] =
    useMutation(MARK_AS_DONE_MATCH_REQUEST);
  const [acceptInvitation, { data: dataAccept, loading: loadingAccept }] =
    useMutation(ACCEPT_EVENT_INVITE);
  const [declineInvitation, { data: dataDecline, loading: loadingDecline }] =
    useMutation(DECLINE_EVENT_INVITE);

  useEffect(() => {
    if (typeof props.activity !== "undefined") {
      setActivity(props.activity);
    }
    if (
      typeof dataMarkAsDone !== "undefined" ||
      typeof dataAccept !== "undefined" ||
      typeof dataDecline !== "undefined"
    ) {
      props.onRefresh();
    }
    // console.log("dataAccept", dataAccept);
    // console.log("Propsssss :", props.activity);
  }, [props, dataMarkAsDone, dataAccept, dataDecline]);

  const acceptInvitationAction = (meeting) => {
    ClearCache("allEvents");
    const variables = {
      activityId: activity.activity.id,
      loginUserId: userId,
    };
    // console.log("Accept invite ", variables);
    acceptInvitation({
      variables: variables,
    });
  };
  const declineInvitationAction = () => {
    const variables = {
      activityId: activity.activity.id,
      loginUserId: userId,
    };
    // console.log("Accept decline ", variables);
    declineInvitation({
      variables: variables,
    });
  };
  const markAsDoneMatchRequestAction = async () => {
    if (activity.activity.status !== ActivityStatus.Closed) {
      const variables = {
        activityType: activity.activity.activityTypeDesc,
        activityId: activity.activity.id,
      };
      // console.log("Mark as done variables", variables);
      MarkAsDone({
        variables: variables,
      });
    }
  };
  const navigateToProfile = () => {
    if (activity.showProfileBasedOnPreference !== true) {
      props.onShowPreferenceWarning()
    } else if (activity.activity.userRole !== 1) {
      history.push("/dashboard/profile/", {
        selectedUserId: activity.userId,
      });
    }
  };

  const checkCurrentUser = () => {
    return (
      activity.activity && activity.activity.requesterUserProfileId !== userId
    );
  };

  const navigateToEventsDetail = () => {
    // console.log("Event Iddd :,", activity.activity.eventId);
    // history.push("/dashboard/eventDetails", {
    //   selectedEventId: activity.activity.eventId,
    // });
    history.push("/dashboard/eventDetails/?id=" + activity.activity.eventId, {
      selectedEventId: activity.activity.eventId,
    });
  };
  return (
    <Grid container spacing={4} className={classes.mt15}>
      {!checkCurrentUser() && <Grid item container xs={0} sm={1} md={3} spacing={2}></Grid>}
      <Grid item container xs={12} sm={11} md={9} spacing={2}>
        <UserInfo sent={checkCurrentUser()} activity={activity} onClick={navigateToProfile} activityType={"event_card_title"}/>
        <Grid item container xs={12} sm={12}>
          <div
            className={checkCurrentUser() ? classes.tabcontentInner : classes.cardSent}
          >
            {typeof activity.activity !== "undefined" ? (
              <Typography
                variant="h3"
                color={"textSecondary"}
                className={classes.p3}
              >
                <FormattedMessage id={"event_invite_card_title"} />
                {activity.activity.status === ActivityStatus.Closed ? (
                  <span className={classes.circleName}>
                    {activity.activity.eventName}
                  </span>
                ) : (
                  <Link
                    component={"button"}
                    underline={"always"}
                    onClick={navigateToEventsDetail}
                  >
                    {activity.activity.eventName}
                  </Link>
                )}
              </Typography>
            ) : (
              ""
            )}

            {checkCurrentUser() ? (
              <Grid item xs={12} sm={12}>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Grid item xs={12} sm={12} className={classes.rightAlign}>
                  {loadingMarkAsDone ? (
                    <CircularProgress />
                  ) : activity.activity &&
                  (activity.activity.status ===
                    ActivityStatus.activityEventInviteAccepted ||
                    activity.activity.status ===
                    ActivityStatus.activityEventInviteDeclined ||
                    activity.activity.status === ActivityStatus.Closed) &&
                  (activity.activity.status === ActivityStatus.Closed ? (
                    <Button
                      variant="text"
                      startIcon={
                        <Avatar
                          src={"/assets/img/icons8-checked-checkbox-50.png"}
                          style={{ width: 15, height: 15 }}
                        />
                      }
                      className={classes.leftAlign}
                    >
                      <Typography
                        component="span"
                        variant="h5"
                        color={"textSecondary"}
                      >
                        Klaar
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      startIcon={
                        <Avatar
                          src={"/assets/img/icons8-checked-checkbox-OFF.png"}
                          style={{ width: 15, height: 15 }}
                        />
                      }
                      className={classes.leftAlign}
                      onClick={markAsDoneMatchRequestAction}
                    >
                      <Typography
                        component="span"
                        variant="h5"
                        color={"textSecondary"}
                      >
                        Klaar
                      </Typography>
                    </Button>
                  ))}

                  {activity.activity.status !== ActivityStatus.Closed &&
                    activity.activity.status !==
                    ActivityStatus.activityEventInviteAccepted &&
                    activity.activity.status !==
                    ActivityStatus.activityEventInviteDeclined ? (
                    loadingAccept || loadingDecline ? (
                      <CircularProgress />
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item>
                        <ButtonDarkBlue onClick={acceptInvitationAction}>
                          <FormattedMessage id={"EventVisitors_MenuItem_Accept"} />
                        </ButtonDarkBlue>
                        </Grid>
                        <Grid item>
                        <ButtonDarkBlue onClick={declineInvitationAction}>
                          <FormattedMessage id={"EventVisitors_MenuItem_Decline"} />
                        </ButtonDarkBlue>
                        </Grid>
                      </Grid>
                    )
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
