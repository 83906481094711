import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Styles from "../../styles/component/ProfileTabs/EditProfileHeaderStyle";
import { Alert, Skeleton, Stack, TextareaAutosize } from "@mui/material";
import { Box, styled } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import { useMutation } from "@apollo/client";
import {
  EDIT_PROFILEHEADER,
  UPDATE_PROFILE_IMAGE,
} from "../../apiServices/mutations";
import LocationAutoComplete from "../Autocomplete/LocationAutoComplete";
import { Container, FormHelperText, Hidden } from "@material-ui/core";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import { toBase64 } from "../../apiServices/CommonMethods";
import { FormattedMessage } from "react-intl";
import { clearSelfProfileCache } from "../../apiServices/Queries/Profiles/GetProfile";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(Styles);

export default function EditProfileHeader(props) {
  const classes = useStyles();
  const [fileSelected, setFileSelected] = React.useState("");
  const [ProfileUpdate, { data, loading, error }] =
    useMutation(EDIT_PROFILEHEADER, {onCompleted: (data) => {
      clearSelfProfileCache();
    }});
  const [
    ProfileImageUpdate,
    {
      data: dataImageUpdate,
      loading: loadingImageUpdate,
      error: errorImageUpdate,
    },
  ] = useMutation(UPDATE_PROFILE_IMAGE, {onCompleted: (data) => {
    clearSelfProfileCache();
  }});

  let [input, setInput] = React.useState({
    firstName: "",
    lastName: "",
    description: "",
    companyRole: "",
    companyName: "",
    country: "",
    fileSelected: "",
    locationPlaceID: "",
  });
  let [inputError, setInputError] = React.useState({
    firstName: "",
    lastName: "",
    description: "",
    companyRole: "",
    companyName: "",
    country: "",
    fileSelected: "",
  });

  React.useEffect(() => {
    if (
      typeof dataImageUpdate !== "undefined" &&
      typeof dataImageUpdate.saveProfileImage !== "undefined"
    ) {
      // console.log("dataImageUpdate", dataImageUpdate);
      props.saveAction(data.saveProfileHeader);
    }
    if (
      typeof data !== "undefined" &&
      typeof data.updateProfileHeader !== "undefined"
    ) {
      // console.log("profile data update", data.updateProfileHeader);
      if (data.updateProfileHeader.errorCode === 0) {
        ProfileImageUpdate({
          variables: {
            userID: props.userId,
            profileImg: fileSelected,
          },
        });
      }
    }
    if (
      typeof props.profileInfo !== "undefined" &&
      typeof props.profileInfo.givenName !== "undefined"
    ) {
      setInput((prevState) => ({
        ...prevState,
        firstName: props.profileInfo.givenName,
        lastName: props.profileInfo.familyName,
        description: props.profileInfo.description,
        companyRole: props.profileInfo.role,
        companyName: props.profileInfo.companyName,
        country: props.profileInfo.country,
        fileSelected: props.profileInfo.profilePictureUrl,
        locationPlaceID: props.profileInfo.locationPlaceID,
      }));
    }
  }, [data, props.profileInfo, dataImageUpdate]);

  const handleSave = async () => {
    let isError = await checkForErrors();
    if (isError) {
      ProfileUpdate({
        variables: {
          userId: props.userId,
          givenName: input["firstName"],
          familyName: input["lastName"],
          description: input["description"],
          role: input["companyRole"],
          company: input["companyName"],
          location: input["country"],
          locationPlaceID: input["locationPlaceID"],
        },
      });
    }
  };

  const goBack = () => {
    props.saveAction();
  };
  const onImageSelection = async (event) => {
    let fileSelect = event.target.files[0];
    if (typeof fileSelect !== "undefined") {
      let imageUrl = await toBase64(fileSelect);
      // console.log("imageUrl", imageUrl);
      setFileSelected(imageUrl);
      setInput((prevState) => ({
        ...prevState,
        fileSelected: URL.createObjectURL(event.target.files[0]),
      }));
      setInputError((prevState) => ({
        ...prevState,
        fileSelected: "",
      }));
    }
  };

  const deleteImage = () => {
    setFileSelected("");
    setInput((prevState) => ({
      ...prevState,
      fileSelected: "",
    }));
  };
  const onLocationChange = (location) => {
    // console.log("onLocationChange", location);
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        country:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
      setInput((prevState) => ({
        ...prevState,
        locationPlaceID:
          typeof location === "string" || location instanceof String
            ? input["locationPlaceID"]
            : location.place_id,
      }));
      setInputError((prevState) => ({
        ...prevState,
        country: "",
      }));
    }
  };
  const handleOnChange = (event) => {
    const { id, value } = event.target;
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      firstName: !input["firstName"] ? "firstNameError" : "",
      lastName: !input["lastName"] ? "lastNameError" : "",
      description: !input["description"] ? "descriptionError" : "",
      companyRole: !input["companyRole"] ? "companyRoleError" : "",
      companyName: !input["companyName"] ? "companyNameError" : "",
      country: !input["country"] ? "locationError" : "",
      fileSelected: !input["fileSelected"] ? "fileSelectedError" : "",
    }));
    // console.log("country", input);

    return (
      input["firstName"] &&
      input["lastName"] &&
      input["description"] &&
      input["companyRole"] &&
      input["companyName"] &&
      input["country"] &&
      input["fileSelected"]
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {error || errorImageUpdate ? (
          <Grid item sm={12}>
            <Alert severity="error">
              {<FormattedMessage id={"somethingWentWrong"} />}
            </Alert>
          </Grid>
        ) : null}
        {data && data.updateProfileHeader.errorCode === -1 ? (
          <Grid item sm={12}>
            <Alert severity="error">{data.updateProfileHeader.message}</Alert>
          </Grid>
        ) : null}
        {!input["fileSelected"] && !(loading || loadingImageUpdate) ? (
          <Grid item xs={10}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={onImageSelection}
                />
                <Button
                  variant="contained"
                  component="span"
                  size="small"
                  color="default"
                  className={classes.margin}
                >
                  <FormattedMessage
                    id={"EditProfileHeader_Button_Text_UploadProfilePicture"}
                  />
                  *:
                </Button>
              </label>
            </Stack>
            {inputError["fileSelected"] ? (
              <Typography
                component="span"
                variant="h6"
                color={"error"}
                className={classes.p15}
              >
                <FormattedMessage id={"SelectProfileImage"} />
              </Typography>
            ) : null}
          </Grid>
        ) : (
          <Grid item xs={10}>
            {loading || loadingImageUpdate ? (
              <Skeleton variant="rectangular" width={150} height={150} />
            ) : (
              <Avatar
                sx={{ bgcolor: "grey", width: 150, height: 150, boxShadow: 3 }}
                alt="Remy Sharp"
                src={
                  input["fileSelected"]
                    ? input["fileSelected"]
                    : "../assets/img/Editprofile.png"
                }
                variant={"square"}
              />
            )}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ paddingTop: 2, width: 150 }}
            >
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={onImageSelection}
                />
                <Typography
                  component="span"
                  variant="h5"
                  color={"textSecondary"}
                  className={classes.p15}
                >
                  <FormattedMessage id={"edit"} />
                </Typography>
              </label>
              <label htmlFor="contained-button-file">
                <Typography
                  component="span"
                  variant="h5"
                  color={"textSecondary"}
                  className={classes.p15}
                  onClick={deleteImage}
                >
                  <FormattedMessage id={"delete"} />
                </Typography>
              </label>
            </Stack>
          </Grid>
        )}
        <Grid item xs={2}>
          <CloseIcon className={classes.closeicon} onClick={goBack} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography
            component="span"
            variant="h2"
            color={"textPrimary"}
            className={classes.p15}
          >
            <FormattedMessage id={"firstName"} />
            *:
          </Typography>
          <FormControl variant="filled" className={classes.Select}>
            {loading || loadingImageUpdate ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <TextField
                id="firstName"
                variant="filled"
                className={classes.txtfield}
                size={"small"}
                value={input["firstName"]}
                onChange={handleOnChange}
                helperText={
                  inputError["firstName"] ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"firstNameError"} />
                    </Typography>
                  ) : null
                }
              />
            )}
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography
            component="span"
            variant="h2"
            color={"textPrimary"}
            className={classes.p15}
          >
            <FormattedMessage id={"lastName"} />
            *:
          </Typography>
          <FormControl variant="filled" className={classes.Select}>
            {loading || loadingImageUpdate ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <TextField
                id="lastName"
                autoComplete="current-password"
                variant="filled"
                className={classes.txtfield}
                size={"small"}
                value={input["lastName"]}
                onChange={handleOnChange}
                helperText={
                  inputError["lastName"] ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"lastNameError"} />
                    </Typography>
                  ) : null
                }
              />
            )}
          </FormControl>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography
            component="span"
            variant="h5"
            color={"textPrimary"}
            className={classes.p15}
          >
            <FormattedMessage id={"EditProfileHeader_Description1"} />
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography
            component="span"
            variant="h2"
            color={"textPrimary"}
            className={classes.p15}
          >
            <FormattedMessage id={"SmallIntroduction"} />
            *:
          </Typography>
          <FormControl variant="filled" className={classes.Select}>
            {
              loading || loadingImageUpdate ? (
                <Skeleton variant="rectangular" height={40} />
              ) : (
                <TextareaAutosize
                  variant="filled"
                  id="description"
                  onChange={handleOnChange}
                  aria-label="minimum height"
                  minRows={5}
                  // placeholder="Type..."
                  className={classes.txtfield}
                  maxLength={100}
                  value={input["description"]}
                />
              )

              // <TextField
              //     id="description"
              //     autoComplete="current-password"
              //     variant="filled"
              //     className={classes.txtfield}
              //     size={"small"}
              //     value={input["description"]}
              //     onChange={handleOnChange}
              //     helperText={inputError["description"] ? <Typography variant="h5" component="span" color="error">
              //         Please enter your introduction
              //     </Typography> : null}
              // />
            }
            {inputError["description"] ? (
              <FormHelperText>
                <Typography variant="h5" component="span" color="error">
                  <FormattedMessage id={"Pleaseenteryourintroduction"} />
                </Typography>
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item sm={5} xs={12}>
          <Typography
            component="span"
            variant="h2"
            color={"textPrimary"}
            className={classes.p15}

          >
            <FormattedMessage id={"Companyrole"} />
            *:
          </Typography>
          <FormControl
            variant="filled"
            className={classes.Select}
            display={"inline"}
          >
            {loading || loadingImageUpdate ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <TextField
                id="companyRole"
                autoComplete="current-password"
                variant="filled"
                className={classes.txtfield}
                size={"small"}
                onChange={handleOnChange}
                value={input["companyRole"]}
                inputProps={{
                  maxLength: 100,
                }}
                helperText={
                  inputError["companyRole"] ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"Pleaseenteryourcompanyrole"} />
                    </Typography>
                  ) : null
                }
              />
            )}
          </FormControl>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={1} xs={1}>
            <Container>
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 6,
                }}
              >
                {/* <Typography variant="h2" color={"textPrimary"}>
                  @
                </Typography> */}
              </Box>
            </Container>
          </Grid>
        </Hidden>
        <Grid item sm={6} xs={12}>
          <Typography
            component="span"
            variant="h2"
            color={"textPrimary"}
            className={classes.p15}
          >
            <FormattedMessage id={"Company"} />
            *:
          </Typography>
          <FormControl variant="filled" className={classes.Select}>
            {loading || loadingImageUpdate ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <TextField
                id="companyName"
                autoComplete="current-password"
                variant="filled"
                className={classes.txtfield}
                size={"small"}
                onChange={handleOnChange}
                value={input["companyName"]}
                inputProps={{
                  maxLength: 100,
                }}
                helperText={
                  inputError["companyName"] ? (
                    <Typography variant="h5" component="span" color="error">
                      <FormattedMessage id={"Pleaseentercompany"} />
                    </Typography>
                  ) : null
                }
              />
            )}
          </FormControl>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography
            component="span"
            variant="h2"
            color={"textPrimary"}
            className={classes.p15}
          >
            <FormattedMessage id={"Location"} />
            *:
          </Typography>
          <FormControl variant="filled" className={classes.Select}>
            {loading || loadingImageUpdate ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <LocationAutoComplete
                onChange={onLocationChange}
                value={input["country"]}
              />
            )}
            {inputError["country"] ? (
              <FormHelperText>
                {" "}
                <Typography variant="h5" component="span" color="error">
                  <FormattedMessage id={"Pleaseenterlocation"} />
                </Typography>{" "}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ButtonDarkBlue
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={handleSave}
          >
            <FormattedMessage id={"Button_Text_Save"} />
          </ButtonDarkBlue>
        </Grid>
      </Grid>
    </div>
  );
}
EditProfileHeader.prototype = {
  saveAction: PropTypes.func,
  profileInfo: PropTypes.object,
  userId: PropTypes.string,
};
