import React from "react";
import Grid from "@mui/material/Grid";
import MatchRequestCard from "../../../components/Cards/ActivityCards/MatchRequestCard";
import { onlyUnique } from "../../../apiServices/CommonMethods";
import ActivityMessageCard from "../../../components/Cards/ActivityCards/ActivityMessageCard";
import ActivityThankyouCard from "../../../components/Cards/ActivityCards/ActivityThankyouCard";
import ActivityRespondToRequest from "../../../components/Cards/ActivityCards/ActivityRespondToRequestCard";
import ActivityIntroductionCard from "../../../components/Cards/ActivityCards/ActivityIntroductionCard";
import ActivitySuggestionCard from "../../../components/Cards/ActivityCards/ActivitySuggestionCard";
import ActivityLeftCircleCard from "../../../components/Cards/ActivityCards/ActivityLeftCircleCard";
import ActivityCircleInvite from "../../../components/Cards/ActivityCards/ActivityCircleInvite";
import ActivityNewsUpdate from "../../../components/Cards/ActivityCards/ActivityNewsUpdate";
import ActivityCircleContactManager from "../../../components/Cards/ActivityCards/ActivityCircleContactManager";
import ActivityNewsRespond from "../../../components/Cards/ActivityCards/ActivityNewsRespond";
import ActivityCirclePostRespond from "../../../components/Cards/ActivityCards/ActivityCirclePostRespond";
import ActivityCirclePost from "../../../components/Cards/ActivityCards/ActivityCirclePost";
import ActivityMatchRequestAcceptCard from "../../../components/Cards/ActivityCards/ActivityMatchRequestAcceptCard";
import ActivitySuggestionResponseCard from "../../../components/Cards/ActivityCards/ActivitySuggestionResponseCard";
import ActivityEventJoinAsGuest from "../../../components/Cards/ActivityCards/ActivityEventJoinAsGuest";
import ActivityEventJoinAsGuestRespond from "../../../components/Cards/ActivityCards/ActivityEventJoinAsGuestRespond";

import ActivityEventInvite from "../../../components/Cards/ActivityCards/ActivityEventInvite";
import ActivityMakeEventManager from "../../../components/Cards/ActivityCards/ActivityMakeEventManager";
import ActivityEventCancel from "../../../components/Cards/ActivityCards/ActivityEventCancel";
import ActivityEventNewsUpdateCard from "../../../components/Cards/ActivityCards/ActivityEventNewsUpdateCard";
import ActivityEventNewsRespond from "../../../components/Cards/ActivityCards/ActivityEventNewsRespond";
import { Waypoint } from "react-waypoint";
import ActivityCollabRequest from "../../../components/Cards/ActivityCards/ActivityCollabRequest";
import YesCard from "../../../components/Cards/ActivityCards/YesCard";
import MatchSuggestionFoundCard from "../../../components/Cards/ActivityCards/MatchSuggestionFoundCard";

export default function ActivityList(props) {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [isShowPreferenceAlert, setIsShowPreferenceAlert] = React.useState(false);
  const activities           = props.activities;
  const onRefresh            = props.onRefresh ?? (() => {});
  const onRefreshAllActivity = props.onRefreshAllActivity ?? (() => {});

  const loadCard = (activity) => {
    if (activity.activity.activityType === "MATCH_REQUEST") {
      return (
        <MatchRequestCard
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (
      activity.activity.activityType === "RESPONSE_TO_REQUEST" &&
      activity.activity.activitySource !== "CIRCLE_NEWS"
    ) {
      return (
        <ActivityRespondToRequest
          activity={activity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "MESSAGE") {
      return (
        <ActivityMessageCard
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "THANK_YOU") {
      return (
        <ActivityThankyouCard
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "INTRODUCTION") {
      return (
        <ActivityIntroductionCard
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "SUGGESTION") {
      return (
        <ActivitySuggestionCard
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (
      activity.activity.activityType === "CIRCLE_UPDATE" ||
      activity.activity.activityType === "PROFILE_INVITATION" ||
      activity.activity.activityType === "CIRCLE_CLOSED"
    ) {
      return (
        <ActivityLeftCircleCard
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "CIRCLE_INVITATION") {
      return (
        <ActivityCircleInvite
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "CIRCLE_NEWS_UPDATE") {
      return (
        <ActivityNewsUpdate
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "CIRCLE_CONTACT_FORM") {
      return (
        <ActivityCircleContactManager
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "CIRCLE_NEWS_RESPOND") {
      return (
        <ActivityNewsRespond
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "CIRCLE_POST") {
      return (
        <ActivityCirclePost
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "CIRCLE_POST_RESPOND") {
      return (
        <ActivityCirclePostRespond
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "MATCH_REQUEST_ACCEPT") {
      return (
        <ActivityMatchRequestAcceptCard
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "SUGGESTION_RESPOND") {
      return (
        <ActivitySuggestionResponseCard
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "JOIN_AS_GUEST") {
      return (
        <ActivityEventJoinAsGuest
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "JOIN_AS_GUEST_RESPOND") {
      return (
        <ActivityEventJoinAsGuestRespond
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "EVENT_INVITATION") {
      return (
        <ActivityEventInvite
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "EVENT_UPDATE") {
      return (
        <ActivityMakeEventManager
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "EVENT_CLOSED") {
      return (
        <ActivityEventCancel
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "EVENT_NEWS_UPDATE") {
      return (
        <ActivityEventNewsUpdateCard
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "EVENT_NEWS_RESPOND") {
      return (
        <ActivityEventNewsRespond
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "UPDATE") {
      return (
        <ActivityCollabRequest
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "YES") {
      return (
        <YesCard
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    } else if (activity.activity.activityType === "MATCH_SUGGESTION_FOUND") {
      return (
        <MatchSuggestionFoundCard
          activity={activity}
          onRefresh={onRefresh}
          onRefreshAllActivity={onRefreshAllActivity}
          onShowPreferenceWarning={showPreferenceWarning}
        />
      );
    }
  };

  const getCurrentActivityList = () => activities.filter(onlyUnique);

  const showPreferenceWarning = () => {
    setIsShowPreferenceAlert(true)
  }

  return (
      <Grid container maxWidth="md">
        {getCurrentActivityList().map((activity, index) => (
            <React.Fragment key={index}>
            <Grid item xs={12} sm={12} key={index} sx={{pointerEvents: "none !important"}}>
                {loadCard(activity)}
            </Grid>
            {index ===
                getCurrentActivityList().length -
                2 && (
                <Waypoint
                    scrollableAncestor={window}
                    onEnter={() => {
                    // console.log("index", index);
                    const newPage = pageNumber + 1;
                    // console.log("newPage", newPage);
                    setPageNumber(newPage)
                    }}
                />
                )}
            </React.Fragment>
        ))
        }
      </Grid>
  );
}
