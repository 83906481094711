import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Styles from "../../../styles/component/Cards/RequestCardStyle";
import { Chip } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(Styles);

export default function BrainStormingPartnerCardDetails(props) {
  const classes = useStyles();
  const [requestDetails, setRequestDetails] = React.useState({});
  useEffect(() => {
    if (typeof props.request !== "undefined") {
      setRequestDetails(props.request);
    }
  }, [props]);

  return (
    <Grid item xs={12}>
      <Typography variant="h3" className={classes.p15} display="inline">
        {requestDetails.partnershipDescription ?? ""}
      </Typography>
      <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
        <FormattedMessage id={"Location"} />:
        <Typography
          variant="h3"
          className={classes.p15}
          display="inline"
        >
          {" "}
          {requestDetails.location ?? ""}
        </Typography>
      </Typography>

      <Typography variant="h3" className={[classes.boldFont, classes.p15]}>
        <FormattedMessage id={"RequiredSkillsPartner"} />:{" "}
        <Typography variant="h3" className={classes.chip} display="inline">
          {(requestDetails.skills ?? []).map((skill, index) => (
            <Chip
              key={index}
              label={
                <Typography
                  variant="h3"
                  className={classes.p15}
                  display="inline"
                >
                  {skill}
                </Typography>
              }
              className={classes.chipItem2}
              size="small"
            />
          ))}
        </Typography>
      </Typography>
      {requestDetails.frequencyInMonths &&
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"FrequencyInMonths"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {requestDetails.frequencyInMonths ?? ""}
          </Typography>
        </Typography>}
      {requestDetails.entrepreneurshipLevel && requestDetails.entrepreneurshipLevel.length > 0 &&
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"RequiredLevelInEntrepreneurship"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {requestDetails.entrepreneurshipLevel ? requestDetails.entrepreneurshipLevel.join(', ') : ""}
          </Typography>
        </Typography>
      }
      {requestDetails.expertiseLevel && requestDetails.expertiseLevel.length > 0 &&
        <Typography variant="h3" className={[classes.boldFont, classes.p7]}>
          <FormattedMessage id={"RequiredLevelOfExpertise"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {requestDetails.expertiseLevel ? requestDetails.expertiseLevel.join(', ') : ""}
          </Typography>
        </Typography>
      }
    </Grid>
  );
}
BrainStormingPartnerCardDetails.prototype = {};
