import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { MARK_AS_DONE_MATCH_REQUEST } from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { ActivityStatus } from "../../../apiServices/Constants";
import Message from "../../../visualComponents/specificImplementations/Messages/Message";
import { Button, ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";
import MatchProfileCard from "../../Matchtime/MatchProfileCard";
import { getValueFromJsonKeyValue } from "../../../apiServices/CommonMethods";


export default function MatchSuggestionFoundCard(props) {
  const [activity, setActivity] = React.useState({});
  const [MarkAsDone, { data: dataMarkAsDone }] =
    useMutation(MARK_AS_DONE_MATCH_REQUEST);

  useEffect(() => {
    if (typeof props.activity !== "undefined") {
      setActivity(props.activity);
    }
    if (typeof dataMarkAsDone !== "undefined") {
      props.onRefresh();
    }
  }, [props, dataMarkAsDone]);

  const markAsDoneMatchRequestAction = async () => {
    if (activity.activity.status !== ActivityStatus.Closed) {
      const variables = {
        activityType: activity.activity.activityTypeDesc,
        activityId: activity.activity.id,
      };
      MarkAsDone({
        variables: variables,
      });
    }
  };

  return (
      <ThemeProvider theme={Theme_WIP}>
        <Message 
          matchtime
          checked={activity?.activity?.status == ActivityStatus.Closed} 
          onCheck={
            () => activity?.activity?.status != null && activity?.activity?.status != ActivityStatus.Closed ? 
                markAsDoneMatchRequestAction() : null
          }
          date={activity?.activity?.createdDate}
          name="iAm"
          iam
        >
          <Grid container item gap={3}>
            <Text variant="body1" color="primary" id="Activity.MatchSuggestion.Description" />
          </Grid>
        </Message>
      </ThemeProvider>
    );
}
