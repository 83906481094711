import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Styles from "../../../styles/container/ActivityPageStyle";
import {
  Avatar,
  Button,
  CardMedia,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import { MARK_AS_DONE_MATCH_REQUEST } from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { getDateString, GetUserId } from "../../../apiServices/CommonMethods";
import { ArrowForwardIos } from "@material-ui/icons";
import { ActivityStatus } from "../../../apiServices/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import UserInfo from "./components/UserInfo";

const useStyles = makeStyles(Styles);

export default function ActivityNewsRespond(props) {
  const classes = useStyles();
  const history = useHistory();
  const userId = GetUserId();
  const intl = useIntl();
  const [isLess, setIsLess] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activity, setActivity] = React.useState({});
  const [MarkAsDone, { data: dataMarkAsDone, loading: loadingMarkAsDone }] =
    useMutation(MARK_AS_DONE_MATCH_REQUEST);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const readMore = () => {
    setIsLess(false);
  };
  const readLessAction = () => {
    setIsLess(true);
  };
  useEffect(() => {
    if (typeof props.activity !== "undefined") {
      setActivity(props.activity);
    }
    if (typeof dataMarkAsDone !== "undefined") {
      props.onRefresh();
    }
  }, [props, dataMarkAsDone]);

  const markAsDoneMatchRequestAction = async () => {
    if (activity.activity.status !== ActivityStatus.Closed) {
      setAnchorEl(null);
      const variables = {
        activityType: activity.activity.activityTypeDesc,
        activityId: activity.activity.id,
      };
      // console.log("Mark as done variables", variables);
      MarkAsDone({
        variables: variables,
      });
    }
  };
  const navigateToProfile = () => {
    if (activity.showProfileBasedOnPreference !== true) {
      props.onShowPreferenceWarning()
    } else if (activity.activity.userRole !== 1) {
      history.push("/dashboard/profile/", {
        selectedUserId: activity.userId,
      });
    }
  };

  const navigateToMessage = () => {
    setAnchorEl(null);
    history.push("/sendMessage", {
      activity: activity,
    });
  };

  const checkCurrentUser = () => {
    return (
      activity.activity && activity.activity.requesterUserProfileId !== userId
    );
  };
  const fetchAllActivity = () => {
    props.onRefreshAllActivity(activity);
  };

  return (
    <Grid container spacing={4} className={classes.mt15}>
      {!checkCurrentUser() && <Grid item container xs={0} sm={1} md={3} spacing={2}></Grid>}
      <Grid item container xs={12} sm={11} md={9} spacing={2}>
        <UserInfo sent={checkCurrentUser()} activity={activity} onClick={navigateToProfile} activityType={"ACTIVITY_NEWS_RESPOND"}/>
        <Grid item container xs={12} sm={12}>
          <div
            className={checkCurrentUser() ? classes.tabcontentInner : classes.cardSent}
          >
            <Typography
              variant="h3"
              color={"textPrimary"}
              className={classes.p3}
            >
              {isLess &&
                activity.activity &&
                activity.activity.messages.length > 150 ? (
                <Typography
                  component="span"
                  variant="h3"
                  color={"textSecondary"}
                  display="inline"
                >
                  {activity.activity
                    ? activity.activity.messages.substring(0, 150) + " "
                    : ""}
                  <Link
                    component="button"
                    variant="body2"
                    onClick={readMore}
                    underline={"none"}
                  >
                    {<FormattedMessage id={"readMore"} />}
                  </Link>
                </Typography>
              ) : (
                <Typography
                  component="span"
                  variant="h3"
                  color={"textSecondary"}
                  display="inline"
                >
                  {activity.activity ? activity.activity.messages : ""}
                </Typography>
              )}
            </Typography>

            {!isLess ? (
              <Grid item xs={12} sm={12}>
                <ArrowForwardIos
                  className={classes.bottomArrow}
                  onClick={readLessAction}
                />
              </Grid>
            ) : null}

            {checkCurrentUser() ? (
              <Grid item xs={12} sm={12}>
                <Divider style={{ marginTop: !isLess ? 30 : 10, marginBottom: 10 }} />
                <Grid item xs={12} sm={12} className={classes.rightAlign}>
                  {loadingMarkAsDone ? (
                    <CircularProgress />
                  ) : activity.activity.status === ActivityStatus.Closed ? (
                    <Button
                      variant="text"
                      startIcon={
                        <Avatar
                          src={"/assets/img/icons8-checked-checkbox-50.png"}
                          style={{ width: 15, height: 15 }}
                        />
                      }
                      className={classes.leftAlign}
                    >
                      <Typography
                        component="span"
                        variant="h5"
                        color={"textSecondary"}
                      >
                        Klaar
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      startIcon={
                        <Avatar
                          src={"/assets/img/icons8-checked-checkbox-OFF.png"}
                          style={{ width: 15, height: 15 }}
                        />
                      }
                      className={classes.leftAlign}
                      onClick={markAsDoneMatchRequestAction}
                    >
                      <Typography
                        component="span"
                        variant="h5"
                        color={"textSecondary"}
                      >
                        Klaar
                      </Typography>
                    </Button>
                  )}

                  {activity.activity.status !== ActivityStatus.Closed ? (
                    <Button
                      variant="text"
                      startIcon={
                        <Avatar
                          src={"/assets/img/icons8-u-turn-to-right-60.png"}
                          style={{ width: 15, height: 15 }}
                        />
                      }
                      className={classes.leftAlign}
                      onClick={handleClick}
                    >
                      <Typography
                        component="span"
                        variant="h5"
                        color={"textSecondary"}
                      >
                        Opvolgen
                      </Typography>
                    </Button>
                  ) : null}

                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem onClick={navigateToMessage}>
                      <FormattedMessage id={"Message"} />
                    </MenuItem>
                    <MenuItem onClick={fetchAllActivity}>
                      <FormattedMessage id={"ToAllActivities"} />
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
