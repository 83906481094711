import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { CardMedia, CircularProgress, Container, Hidden } from "@mui/material";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import styles from "../../styles/container/ProfileStyle";
import { useMutation } from "@apollo/client";
import { Skeleton, ThemeProvider, useTheme } from "@mui/material";
import { UNMATCH_MASTER } from "../../apiServices/mutations";
import SuccessAlertDialog from "../Alert/SuccessAlertDialog";
import { FormattedMessage, useIntl } from "react-intl";
import GoTo from "../../apiServices/Navigation/NavigateWithReload";
import Background from "../../visualComponents/Background";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Text from "../../visualComponents/Text";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { useGetProfile } from "../../apiServices/Queries/Profiles/GetProfile";
import { ClearCache } from "../../apiServices/CommonMethods";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {ReactComponent as More} from "../../svg/ui/more.svg";

const useStyles = makeStyles(styles);

export default function ProfileHeader(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role;
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const [value, setValue] = React.useState(location.state?.tab ?? 0);
  const [profile, setProfile] = React.useState(props.profileData);

  const [
    unmatchMaster,
    { data: dataUnmatch, loading: loadingUnmatch },
  ] = useMutation(UNMATCH_MASTER, {onCompleted: (data) => {
    ClearCache("profile");
  }});

  React.useEffect(() => {
    if (
      props.profileData != null &&
      props.profileData.givenName != null
    ) {
      setProfile(props.profileData);
    }
  }, [props.profileData, props.userId]);

  const handleChange = (event, newValue) => {
    // console.log("newValue", newValue);
    setValue(newValue);
    props.handleChange(event, newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const unMatchUserAction = () => {
    setAnchorEl(null);
    const variable = {
      loginUserProfileId: userId,
      unmatchUserProfileId: props.userId,
    };
    unmatchMaster({
      variables: variable,
    });
  };
  const navigateToActivity = () => {
    setAnchorEl(null);
    history.push("/dashboard/activity", {
      activityUserID: props.userId,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editProfileHeader = () => {
    handleClose();
    setValue(0);
    props.handleChange(null, 0);
    props.editProfileHeader();
  };
  const goBack = () => {
    props.goBack();
  };
  const navigateToMatchRequest = () => {
    // console.log("profile.circles", profile)
    // console.log("userRole", userRole)

    if (!userRole.usedAllResponses || profile.circles.length > 0) {
      history.push("/getpremium", {
        path: "/matchRequest", selectedUser: profile, referrer: window.location.pathname + "?id=" + props.userId
      });
    } else {
      navigateToGetPremium();
    }
  };

  const navigateToGetPremium = () => {
    GoTo(history, "/GetPremium");
  };
  const navigateToAddActivity = () => {
    history.push("/chooseActivity", {
      selectedUser: profile,
    });
  };
  return (
    <ThemeProvider theme={Theme_WIP}>
      <Grid container rowGap={ThemeConstants.custom.spacings.md} columnGap={ThemeConstants.custom.spacings.ml}>
        <Grid item sm={12} xs={12}>
          {typeof dataUnmatch !== "undefined" &&
            dataUnmatch?.sendUnMatch?.errorCode === 0 ? (
            <SuccessAlertDialog
              message={<FormattedMessage id={"UnmatchedSuccessfully"} />}
              open={true}
            />
          ) : null}
        </Grid>
        <Background item xs={3} sx={{maxWidth: "170px !important", minWidth: "90px"}} border="bottomLeft" color="transparent" zIndex="100 !important">
          {!profile ? (
            <Skeleton variant="rectangular" width={"100%"} height={120} />
          ) : (
            <CardMedia
              component="img"
              image={profile?.profilePictureUrl ?? "/assets/img/userProfile.png"}
              alt="profile"
              className={classes.logo}
            />
          )}
        </Background>
        <Grid container item xs gap={ThemeConstants.custom.spacings.xs} sx={{height: "fit-content"}}>
          <Text variant="h1" bold color="background" item xs={12}>
            {!profile ? (
              <Skeleton variant="text" height={30} width={"100%"} />
            ) : (
              props.uuid ? <FormattedMessage id="UuidInvite.Title" values={{name: profile?.givenName + " " + profile?.familyName}} /> : profile?.givenName + " " + profile?.familyName
            )}
          </Text>
          {(profile?.description || props.uuid) && <Hidden smDown><Text item xs={12} variant="h2" color="background">
              {props.uuid ? <FormattedMessage id="UuidInvite.Subtitle"/> : profile?.description}
          </Text>
          </Hidden>}

          {!props.uuid && <React.Fragment>
            {(profile?.role || profile?.companyName) && <Text variant="body1" color="background" item xs={12}>
              {profile?.role ? profile.role + " @ " + profile?.companyName : ""}
            </Text>}
            {profile?.country && <Text variant="body1" color={ThemeConstants.palette.lightPetrol.main} item xs={12}>
              {profile?.country}
            </Text>}
            {/* {props.userId === userId && */}
              {/* <Text variant="body2" color="white"> */}
                {/* <div className={classes.matches}>
                  {" "}
                  {!profile ? (
                    <Skeleton variant="text" width={"40%"} height={30} />
                  ) : (
                    profile?.myMatchesCount +
                    " " +
                    intl.formatMessage({ id: "ProfileHeader_Matches" })
                  )} */}
                  {/* <br></br>
                  {!profile ? (
                    <Skeleton variant="text" width={"40%"} height={30} />
                  ) : (
                    profile.thankyouCount +
                    " " +
                    intl.formatMessage({ id: "ProfileHeader_ThankYousReceived" })
                  )} */}
                {/* </div> */}
              {/* </Text>
            } */}
          </React.Fragment>}
        </Grid>

        <Grid item>
          <Button onClick={handleClick}>
            {loadingUnmatch ? (
              <CircularProgress />
            ) : props.userId === userId ||
              (props.userId !== userId &&
                profile?.matchStatus === true) ? (
              <More />
            ) : null}
          </Button>
          <Menu
            style={{zIndex: 5000}}
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {props.userId !== userId &&
              profile?.matchStatus === true ? (
              <MenuItem onClick={unMatchUserAction}>
                <Text variant="body1" color="white" id="Unmatch" />
              </MenuItem>
            ) : null}
            {props.userId !== userId &&
            profile?.matchStatus === true ? (
              <MenuItem onClick={navigateToActivity}>
                <Text variant="body1" color="white" id="ToAllActivities" />
              </MenuItem>
            ) : null}
            {props.userId === userId ? (
              <MenuItem onClick={editProfileHeader}>
                <Text variant="body1" color="white" id="ProfileHeader_Edit_Profile" />
              </MenuItem>
            ) : null}
          </Menu>
        </Grid>

        {(profile?.description || props.uuid) && <Hidden smUp><Text item xs={12} variant="h2" color="background">
            {props.uuid ? <FormattedMessage id="UuidInvite.Subtitle"/> : profile?.description}
        </Text>
        </Hidden>}

        <Grid container item xs={12}>
          <Grid item xs={3} sx={{maxWidth: "170px !important", minWidth: "fit-content"}}>
          { profile && props.userId != userId &&
            props.isNonMM != undefined &&
            !props.isNonMM && (
            profile.matchStatus === true ? (
                <Button variant="contained" color="primary" onClick={navigateToAddActivity} fullWidth>
                  <FormattedMessage id={"Button_Text_AddActivity"} />
                </Button>
            ) : (
                <Button variant="contained" color="primary" onClick={navigateToMatchRequest} fullWidth>
                  <FormattedMessage id={"ProfileHeader_Button_Text_Match"} />
                </Button>
            )
          )}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
ProfileHeader.prototype = {
  editProfileHeader: PropTypes.func,
  userId: PropTypes.string,
  handleChange: PropTypes.func,
  goBack: PropTypes.goBack,
  profileInfo: PropTypes.func,
  profileData: PropTypes.object,
};
