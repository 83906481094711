import React, { useEffect, useState } from "react";
import Text from "../../../visualComponents/Text";
import { Button, Grid, Grow, TextField } from "@mui/material";
import { Fade } from "@material-ui/core";
import Image from "../../../components/Media/Image";

export default function MTCountdown(props) {
    const [page, setPage] = useState(<Page1 setPage={updatePage}/>);

    function updatePage(page) {setPage(page)}
    return(page);
}

function Page1(props) {return(PageTemplate(
    "/matchtime/countdown.title",
    "/matchtime/countdown.description",
    "/matchtime/countdown.button",
    () => {window.location.href = "/";}//props.setPage(<Page2 setPage={props.setPage}/>)
));}

function nextMonday() {
    var d = new Date();
    return d.setDate(d.getDate() + (1 + 7 - d.getDay()) % 7);
}
function countdown() {
    return new Date(new Date(nextMonday()).setHours(0,0,0)) - new Date();
}
function dhm(t){
    var cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(t / cd),
        h = Math.floor( (t - d * cd) / ch),
        m = Math.round( (t - d * cd - h * ch) / 60000),
        pad = function(n){ return n < 10 ? '0' + n : n; };
  if( m === 60 ){
    h++;
    m = 0;
  }
  if( h === 24 ){
    d++;
    h = 0;
  }
  return pad(d)+"d "+ pad(h)+"u "+ pad(m)+"m";
}

function PageTemplate(title, description, buttonText, onNextPage) {return(
    <React.Fragment>
        <Fade in={true} timeout={500}>
            <Text item xs={12} align="center" variant="body1" fontSize={35} mt={-3} color="primary">
                {dhm(countdown())}
            </Text>
        </Fade>
        <Fade in={true} timeout={500}>
            <Text item xs={12} align="center" variant="h1" mt={3} bold id={title}/>
        </Fade>
        <Fade in={true} timeout={500}>
            <Text item xs={12} align="center" variant="h2" id={description} my={3}/>
        </Fade>
        <Grid container item xs={12} justifyContent="center">
            <Fade in={true} timeout={500}>
                <Image src     = "/assets/img/matchtime/card1.png"
                    alt     = "intro"
                    sx      = {{
                            minWidth: "150px", 
                            maxWidth: "250px", 
                            height: "fit-content"
                    }}
                    width   = "45%"/>
            </Fade>
            <Fade in={true} timeout={500}>
                <Image src     = "/assets/img/matchtime/card2.png"
                        alt     = "intro"
                        sx      = {{
                            minWidth: "150px", 
                            maxWidth: "250px",
                            height: "fit-content",
                            marginTop: 4
                        }}
                        width   = "40%"/>
            </Fade>
        </Grid>
        <Grid item xs={6} mt={4}>
            <Fade in={true} timeout={1000}>
                <Button fullWidth variant="text" color="white" onClick={onNextPage}>
                    <Text p={0.5} item xs={12} align="center" variant="body2" color="primary" id={buttonText} sx={{textDecoration: 'underline'}}/>
                </Button>
            </Fade>
        </Grid>
    </React.Fragment>);}

