import * as React from 'react';
import PropTypes from 'prop-types';
import Slider, { SliderThumb } from '@mui/material/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};


function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};
export default function SliderPage(props) {
  const [position, setPosition] = React.useState(0);
  React.useEffect(() => {
    setPosition(typeof props.position !== "undefined" ? props.position : 0)
  }, [props.position]);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        <Box sx={{ float: 'right', }}>
          <Typography variant="h3" color={"textSecondary"} >{position} km</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box>
          <Slider
            aria-label="time-indicator"
            size="small"
            value={position}
            min={0}
            step={5}
            max={1000}
            onChange={(_, value) => {
              setPosition(value)
              props.onChange(value)
            }
            }
            sx={{
              color: '#828282',
              height: 4,
              '& .MuiSlider-thumb': {
                width: 15,
                height: 15,
              },
              '& .MuiSlider-rail': {
                opacity: 0.28,
              },
            }}
            disabled={typeof props.disabled != "undefined" ? props.disabled : false}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
SliderPage.prototype = {
  onChange: PropTypes.func,
};
