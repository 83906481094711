import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Styles from "../../../styles/component/Cards/RequestCardStyle";
import { Chip } from "@material-ui/core";
import { getDateOnlyString } from "../../../apiServices/CommonMethods";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(Styles);

export default function ProjectPartnerCardDetails(props) {
  const classes = useStyles();
  const [requestDetails, setRequestDetails] = React.useState({});

  useEffect(() => {
    if (typeof props.request !== "undefined") {
      setRequestDetails(props.request);
    }
  }, [props]);

  return (
    <Grid item xs={12}>
      <Typography variant="h3" className={classes.p15} display="inline"> {/* Description */}
        {requestDetails.projectDescription ?? ""}
      </Typography>
      <Typography variant="h3" color={"textPrimary"} className={[classes.boldFont, classes.p15]}> {/* Industry */}
        <FormattedMessage id={"ProjectIndustry"} />:{" "}
        <Typography variant="h3" className={classes.chip} display="inline">
          {(requestDetails.industries ?? []).map((industry, index) => (
            <Chip
              key={index}
              label={
                <Typography
                  variant="h3"
                  className={classes.p15}
                  display="inline"
                >
                  {industry}
                </Typography>
              }
              className={classes.chipItem2}
              size="small"
            />
          ))}
        </Typography>
      </Typography>
      <Typography variant="h3" color={"textPrimary"} className={[classes.boldFont, classes.p15]}> {/* Skills */}
        <FormattedMessage id={"RequiredSkills"} />:{" "}
        <Typography variant="h3" className={classes.chip} display="inline">
          {(requestDetails.skills ?? []).map((skill, index) => (
            <Chip
              key={index}
              label={
                <Typography
                  variant="h3"
                  className={classes.p15}
                  display="inline"
                >
                  {skill}
                </Typography>
              }
              className={classes.chipItem2}
              size="small"
            />
          ))}
        </Typography>
      </Typography>

      <Typography variant="h3" color={"textPrimary"} className={[classes.boldFont, classes.p15]}> {/* Location */}
        <FormattedMessage id={"Location"} />:
        <Typography
          variant="h3"
          className={classes.p15}
          display="inline"
        >
          {" "}
          {requestDetails.location ?? ""}
        </Typography>
      </Typography>
      {requestDetails.averageDurationInMonths &&
        <Typography variant="h3" color={"textPrimary"} className={[classes.boldFont, classes.p15]}> {/* Months */}
          <FormattedMessage id={"DurationProjectInMonths"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {requestDetails.averageDurationInMonths ?? ""}
          </Typography>
        </Typography>}
      {requestDetails.hoursPerWeek ? (
        <Typography variant="h3" color={"textPrimary"} className={[classes.boldFont, classes.p15]}> {/* Hours */}
          <FormattedMessage id={"HoursPerWeek"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {requestDetails.hoursPerWeek ?? ""}
          </Typography>
        </Typography>
      ) : null}
      {requestDetails.startDate ? (
        <Typography variant="h3" color={"textPrimary"} className={[classes.boldFont, classes.p15]}> {/* Start Date */}
          <FormattedMessage id={"StartDateProject"} />:
          <Typography
            variant="h3"
            className={classes.p15}
            display="inline"
          >
            {" "}
            {getDateOnlyString(new Date(requestDetails.startDate))}
          </Typography>
        </Typography>
      ) : null}
    </Grid>
  );
}
ProjectPartnerCardDetails.prototype = {
  request: PropTypes.object,
};
