//called "experts" in code & db, but actually used as "jobs" in frontend
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Text from "../../visualComponents/Text";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Styles from "../../styles/container/RequestStyle";
import {
  TextField,
} from "@material-ui/core";
import DatePicker from "../DatePicker/DatePicker";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(Styles);

export default function Experts(props) {
  const classes = useStyles();
  const intl = useIntl();
  
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"Optional"} />
        <Text variant="h2" id={"DurationJobInMonths"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <TextField
                id="months"
                label={intl.formatMessage({
                  id: "introduction_Field_input_user_type",
                })}
                size="small"
                value={props.input.months}
                onChange={props.onChange}
                type={"number"}
              />
          </FormControl>
        </Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"HoursPerWeek"} />
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <TextField
                id="hours"
                label={intl.formatMessage({
                  id: "introduction_Field_input_user_type",
                })}
                size="small"
                value={props.input.hours}
                onChange={props.onChange}
                type={"number"}
              />
          </FormControl>
        </Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Text variant="h2" id={"StartDateProject"} />:
        <Text variant="h3">
          <FormControl variant="filled" className={classes.Select}>
              <DatePicker
                updateDate={props.updateDate}
                initialValue={props.input.startDate ?? null}
              />
          </FormControl>
        </Text>
      </Grid>
    </Grid>
  );
}
Experts.prototype = {
  receivers: PropTypes.array,
  updateStatus: PropTypes.func,
};
