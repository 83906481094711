import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { CssBaseline, Hidden } from "@material-ui/core";
import { ButtonDarkBlue } from "../../components/Buttons/ButtonDarkBlue";
import Navigationbar from "../../components/Navigationbar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Box } from "@mui/system";
import { useHistory, useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import Styles from "../../styles/container/Event/InviteAllCirclesToEventStyle";
import SuccessAlertWithAction from "../../components/Alert/SuccessAlertWithAction";
import { INVITE_TO_ALL_CIRCLE_MEMBERS } from "../../apiServices/Queries";
import { useLazyQuery } from "@apollo/client";
import { GetUserId } from "../../apiServices/CommonMethods";
import { Alert, Skeleton } from "@mui/material";


const useStyles = makeStyles(Styles);

export default function InviteAllCirclesToEvent() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const userId = GetUserId();
  const location = useLocation();

  const [circleId, setCircleId] = React.useState("");
  const [eventId, setEventId] = React.useState("");
  const [eventData, setEventData] = React.useState({});


  const getVariables = () => {
    const variable = {
      loginUserId: userId,
      circleId: circleId,
      eventId: eventId,
      requestedUserId: userId
    };
    // console.log('Send Invite all', variable)
    return variable;
  };
  const [sendInviteAll, { loading, data }] =
    useLazyQuery(INVITE_TO_ALL_CIRCLE_MEMBERS, {
      variables: getVariables(),
    });

  useEffect(() => {
    if (location.state && typeof location.state.eventDetails !== "undefined") {
      setEventData(location.state.eventDetails)
      setEventId(location.state.eventDetails.id)
      setCircleId(location.state.eventDetails.circleId)
      // console.log('Circle id :', location.state.eventDetails.circleId)
    }
    // console.log('Invite DATAAAA : ', data);

    // if (location.state && typeof location.state.circleId !== "undefined") {
    //   console.log('Circle id :', location.state.circleDetails)

    //   setCircleId(location.state.circleId)
    // }

  }, [location, data]);

  const onSendInvite = () => {

    sendInviteAll()
  }
  const handleSucessAlert = () => {
    // history.push("/dashboard/eventDetails", {
    //   eventDetails: eventData,
    //   selectedTab: 4,
    // });
    history.push("/dashboard/eventDetails/?id=" + eventData.id, {
      eventDetails: eventData,
      selectedTab: 4,
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <Container maxWidth="sm">
        <Box sx={{ flexGrow: 1, mt: theme.spacing(2), mb: theme.spacing(4) }}>
          <Grid container spacing={4}>
            <Grid item sm={12} xs={12}>
              {typeof data !== "undefined" &&
                data.inviteAllCircleMembersForEvent.errorCode === 0 ? (
                <SuccessAlertWithAction
                  open={true}
                  message={<FormattedMessage id={"event_invite_success"} />}
                  handleClose={handleSucessAlert}

                />
              ) : null}
              {typeof data !== "undefined" &&
                data.inviteAllCircleMembersForEvent.errorCode === -1 ? (
                <Alert severity={"error"}>
                  {data.inviteAllCircleMembersForEvent.message}
                </Alert>
              ) : null}
            </Grid>
            <Grid item sm={11} xs={10}>
              <Typography component="h1" variant="h1" color={"textPrimary"}>
                <FormattedMessage id={"InviteAllCircleMembersToYourEvent"} />
              </Typography>
            </Grid>
            <Grid item sm={1} xs={2}>
              <CloseIcon
                className={classes.closeicon}
                onClick={() => {
                  history.goBack();
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item sm={12} xs={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"InviteAllCirclesToEvent_Description"} />
              </Typography>
            </Grid>
            <Grid item sm={3} xs={12}>
              {loading ? <Skeleton variant="rectangular" height={40} /> :
                <ButtonDarkBlue onClick={onSendInvite}>
                  <FormattedMessage id={"Button_Text_SendInvite"} />
                </ButtonDarkBlue>}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Hidden mdUp>
        <Footer />
      </Hidden>
    </React.Fragment>
  );
}
