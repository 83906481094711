import * as React from "react";
import Navigationbar from "../../components/Navigationbar";
import Header from "../../components/Header";
import { CssBaseline, Grid, Hidden, ThemeProvider, Button } from "@mui/material";
import PremiumFeatures from "../../components/Onboarding/PremiumFeatures";
import { useHasExtendedTrial } from "../../apiServices/Queries/HasExtendedTrial";
import GetPremium from "../../components/AccountSettingsTab/GetPremium";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import { useSubInfo } from "../../objects/Subscriptions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Text from "../../visualComponents/Text";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import { FormattedMessage } from "react-intl";

export default function ExtendTrial() {
  const globals = useGlobalContext();
  const subInfo = useSubInfo();
  const history = useHistory();
  const notPaid = subInfo.current && subInfo.current.identifyer != subInfo.all.premium.identifyer && subInfo.current.identifyer != subInfo.all.business.identifyer;
  
  const RedirectLogic = () => {
    if(history.location.state?.path && (!notPaid || globals.state.loggedInUser.acceptedInvites > 0)) {
      history.push(history.location.state?.path, history.location.state);
    }

    return <div></div>
  };

  return (
  (subInfo.current.identifyer == subInfo.all.premium.identifyer || subInfo.current.identifyer == subInfo.all.business.identifyer)
  && globals.state.loggedInUser.role?.usedAllResponses ?
    <ThemeProvider theme={Theme_WIP}>
      <CssBaseline />
      <Navigationbar />
      <Background page color="primary"/>
      <Grid container mt={16} gap={8} px={{xs: 5, sm: 10, md: 18, lg: 25, xl: 35}} py={10} justifyContent="center">
        <Text variant="h1" color="primary"               item xs={12} align="center" id="NoCredits.title" bold/>
        <Text variant="h2" color="primary" maxWidth="sm" item xs={12} align="center" id="NoCredits.description"/>
        <Grid item xs={12} container justifyContent="center">
          <Button variant="text" onClick={() => history.goBack()}>
            <Text variant="body1" color="primary" id="Button_Text_GoBack" sx={{textDecoration: "underline"}}/></Button></Grid>
      </Grid>
    </ThemeProvider>
  :
  <>
    <RedirectLogic/>
      {(subInfo.current?.identifyer == subInfo.all.longTrial.identifyer 
    || (subInfo.current?.LowerCaseRole == "freemium" && globals.state.loggedInUser.acceptedInvites > 0))
    
    ? <GetPremium/> : 
    <React.Fragment>
      <CssBaseline />
      <Navigationbar />
      <Grid container px={{xs: 5, sm: 10, md: 18, lg: 25, xl: 35}} py={10} justifyContent="center">
        <PremiumFeatures getPremium/>
      </Grid>
    </React.Fragment>}
    </>
  );
}
